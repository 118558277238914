import { connect } from 'react-redux'
import HomePage from '../components/Home'


const mapStateToProps = state => {
    return {
        selectedFacility: state.selectedFacility,
        patients: state.patients,
        appointments: state.appointments,
        staff: state.staff,
        counters: state.home.counters,
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const HomeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage)

export default HomeContainer