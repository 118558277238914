import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import {Typography, Drawer} from '@material-ui/core';
import classNames from 'classnames'
import MenuContent from './MenuContent';


const drawerWidth = 240;

const styles = (theme) => ({
	drawerPaper: {
		width: drawerWidth,
		[theme.breakpoints.up('md')]: {
			position: 'relative'
		},
		border : "0px solid white",
	},
	toolbar: theme.mixins.toolbar,
	title: {
		paddingLeft: '20px',
		height: "70px",
		backgroundColor: "#3b3b53",
		display: 'flex',
		alignItems: 'center',
		borderBottom: '1px solid #303051d9'
	}
	,
	bg:{
		backgroundColor : "#43425d"
	},
	drawerHeader:{
		fontFamily: 'Source Sans Pro, Regular',
		textTransform: "uppercase",
		color:"white"
	},
	divider:{
		backgroundColor: "#323246",
		height: '2px',
		marginBottom: "-9px"
	},
	fixed: {
		position: 'fixed'
	},

	
});

class LeftSideBar extends Component {

	render() {
		const { classes, handleDrawerToggle, mobileOpen, menuItems, notifications, activeMenuItem} = this.props;

		const drawer = (
			<div className={classes.bg}>
				<div className={classNames(classes.toolbar, classes.title)} >
					<Typography noWrap className={classes.drawerHeader} variant="title">aquii</Typography>
				</div>

				<MenuContent menuItems={menuItems} notifications={notifications} activeItem={activeMenuItem}
							onClickMenuItem={this.props.onClickMenuItem}
				/>
			</div>
		);

		return (
			<React.Fragment>
				<Hidden mdUp>
					<Drawer
						variant="temporary"
						anchor="left"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css" className={classes.fixed}>
					<Drawer
						variant="permanent"
						open
						classes={{
							paper: classes.drawerPaper
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
			</React.Fragment>
		);
	}
}

LeftSideBar.propTypes = {
	classes: PropTypes.object.isRequired,
	tagItem: PropTypes.array.isRequired,
	notifications: PropTypes.object.isRequired,
	activeMenuItem: PropTypes.string.isRequired,
	onClickMenuItem: PropTypes.func.isRequired,
}

LeftSideBar.defaultProps = {
	tagItem: [],
	notifications: {},
	activeMenuItem: '',
}

export default withStyles(styles)(LeftSideBar);
