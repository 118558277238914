import { connect } from 'react-redux'
import {changeRegistrationInfo, setSettings, setSettingsFetchingStatus} from '../../../services/actions'
import RegistrationPage from '../components/RegistrationPage1'


//Check if planPrices is set, if not fetch it from server by ajax request

const mapStateToProps = state => {
    return {
        plan: state.registration.plan,
        organization: state.registration.organization,
        primaryContact: state.registration.primaryContact,
        planPrices: state.registration.planPrices,
        settings: state.settings
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (info, value) => {
            dispatch(changeRegistrationInfo(info, value))
        },

        setSettings: (value) => {
            dispatch(setSettings(value))
        },

        setSettingsFetchingStatus: (value) => {
            dispatch(setSettingsFetchingStatus(value))
        }
    }
}

const RegistrationPage1 = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationPage)

export default RegistrationPage1