import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import Header from './Header'
import FacilityLabels from './FacilityLabels'
import FacilityRow from './FacilityRow'

const styles = theme => ({
    container: {
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        padding: '95px 40px 40px 40px',
        fontFamily: 'Helvetica Neue',
        fontSize: '36px',
        color: '#43425D',
        backgroundColor: '#FFFFFF',
    },
    wrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: '1703px',
        minHeight: '100vh',
    },
    tableHead: {
        display: 'flex',
        alignSelf: 'flex-start',
        width: '70%',
        //border: '1px solid blue',
    },
    tableBody: {
        /*display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',*/
        width: '100%',
        //border: '1px solid red',
    },
});

const facilities = [
    {title: 'Highland Medical Clinic - Eastside', address1: '12556 Factoria Boulevard', address2: 'Bellevue WA 98006',
        manager: 'Dr. Alan Smith', managerPhone: '(206) 234-1212', managerEmail: 'alan.smith@highlandclinics.org',
        contact: 'Mrs Andrea Johnson', contactPhone: '(206) 234-1212', contactEmail: 'andrea@highlandclinics.org'},
    {title: 'Highland Medical Clinic - North', address1: '12521 8th Avenue NE', address2: 'Shorline WA 98108',
        manager: 'Dr. Alan Smith', managerPhone: '(206) 234-1212', managerEmail: 'alan.smith@highlandclinics.org',
        contact: 'Mrs Andrea Johnson', contactPhone: '(206) 234-1212', contactEmail: 'andrea@highlandclinics.org'},
];

class Home extends React.Component{
    render() {
        const {classes, className, history, match} = this.props;

        return(
            <div className={classNames(classes.container, className)}>
                <div className={classes.wrapper}>

                    <Header name={'John'} label={'Your Facilities:'} history={history} match={match}/>

                    <div className={classes.tableHead}>
                        <FacilityLabels title={'Name & Address'} manager={'Manager'} contact={'Contact'}/>
                    </div>

                    <div className={classes.tableBody}>
                        {facilities.map((facility, index) => {
                            return(
                                <FacilityRow key={index} title={facility.title} address1={facility.address1}
                                             address2={facility.address2} manager={facility.manager}
                                             managerPhone={facility.managerPhone} managerEmail={facility.managerEmail}
                                             contact={facility.contact} contactPhone={facility.contactPhone}
                                             contactEmail={facility.contactEmail}
                                />
                            );
                        })}
                    </div>

                </div>
            </div>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

Home.defaultProps = {
    className: '',
    strings: {},
};
export default withStyles(styles)(Home);