import { connect } from 'react-redux'
import { changePatientInfo } from '../../../../../../common/services/actions'
import NewPatientPage from '../components/NewPatientPage'


//Check if planPrices is set, if not fetch it from server by ajax request

const mapStateToProps = state => {
    return {
        demographics: state.newPatient.demographics,
        placeOfBirth: state.newPatient.placeOfBirth,
        status: state.newPatient.status,
        contact: state.newPatient.contact,
        settings: state.settings.item,
        currentStep: state.newPatient.currentStep,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNewPatientChange: (info, value) => {
            dispatch(changePatientInfo(info, value))
        }
    }
}

const NewPatientPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewPatientPage)

export default NewPatientPageContainer