import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import Button from "@material-ui/core/Button/Button";


const styles = theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        width: '100%',
    },
    facilityDetails: {
        display: 'flex',
        flexFlow: 'row nowrap',
        marginBottom: '18px',
        padding: '15px 26px 15px 28px',
        width: '70%',
        maxWidth: '1033px',
        fontFamily: 'Helvetica Neue',
        fontSize: '14px',
        textAlign: 'left',
        color: '#707070',
        borderRadius: '10px',
        border: '1px solid rgb(112, 112, 112, .5)',
        backgroundColor: 'rgb(112, 112, 112, .09)',
    },
    nameAndAddress: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '38%',
        borderRight: '1px solid rgb(112, 112, 112, .5)',
    },
    contentWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '80%',
    },
    manager: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '31%',
        borderRight: '1px solid rgb(112, 112, 112, .5)',
    },
    contact: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-end',
        alignContent: 'space-between',
        width: '31%',
    },
    designation: {
        fontSize: '20px',
        fontWeight: 'bold',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'calc(30%-20px)',
        marginLeft: '20px',
    },
    editButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        width: '185px',
        border: 'none',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro !important',
        fontSize: '18px',
        textAlign: 'center',
        textTransform: 'capitalize',
        color: '#FFFFFF !important',
        backgroundColor: '#43425D !important',
        boxShadow: 'none !important',
        cursor: 'pointer',
        outline: '0px !important',
    }
});

class FacilityRow extends React.Component{
    render() {
        const {classes, className} = this.props;
        return(
            <div className={classNames(classes.container, className)}>
                <div className={classes.facilityDetails}>
                    <div className={classes.nameAndAddress}>
                        <div className={classes.contentWrapper}>
                            <span className={classes.designation}>{this.props.title}</span>
                            <span>{this.props.address1}</span>
                            <span>{this.props.address2}</span>
                        </div>
                    </div>
                    <div className={classes.manager}>
                        <div className={classes.contentWrapper}>
                            <span className={classes.designation}>{this.props.manager}</span>
                            <span>{this.props.managerPhone}</span>
                            <span>{this.props.managerEmail}</span>
                        </div>
                    </div>
                    <div className={classes.contact}>
                        <div className={classes.contentWrapper}>
                            <span className={classes.designation}>{this.props.contact}</span>
                            <span>{this.props.contactPhone}</span>
                            <span>{this.props.contactEmail}</span>
                        </div>
                    </div>
                </div>
                <div className={classes.buttonWrapper}>
                    <Button className={classes.editButton}>Edit</Button>
                </div>
            </div>
        );
    }
}

FacilityRow.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

FacilityRow.defaultProps = {
    className: '',
    strings: {},
};
export default withStyles(styles)(FacilityRow);