import React from "react"
import {Route, Switch, Redirect} from "react-router-dom"
import RegistrationPage1 from '../containers/RegistrationPage1'
import RegistrationPage2 from "../containers/RegistrationPage2"
import RegistrationPage3 from "../containers/RegistrationPage3"
import RegistrationPage4 from "../containers/RegistrationPage4"



const Page = () => <Switch>
                        <Route path={`/registration/:plan/step-1`} component={RegistrationPage1} />
                        <Route path={`/registration/:plan/step-2`} component={RegistrationPage2} />
                        <Route path={`/registration/:plan/step-3`} component={RegistrationPage3} />
                        <Route path={`/registration/:plan/step-4`} component={RegistrationPage4} />
                        <Route render={() => <Redirect to={`/registration/premium/step-1`}/>}/>
                   </Switch>



export default Page