import { connect } from 'react-redux'
import { changeRegistrationInfo } from '../../../services/actions'
import RegistrationPage from '../components/RegistrationPage2'


const mapStateToProps = state => {
    return {
        plan: state.registration.plan,
        planPrice: state.registration.planPrices.item[state.registration.plan.name],
        currentStep: state.registration.currentStep
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (info, value) => {
            dispatch(changeRegistrationInfo(info, value))
        }
    }
}

const RegistrationPage2 = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationPage)

export default RegistrationPage2