import React, { Component } from "react";
import { Line } from 'react-chartjs-2';


class LineChart extends Component {
    render() {
        this.charData = (canvas) => {
            const ctx = canvas.getContext("2d")

            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, "#A3A0FB");
            gradient.addColorStop(1, "rgba(255, 255, 255, 0.3");

            return {
                labels: ['8:00', '8:05', '8:10', '8:15', '8:20', '8:25', '8:30'],
                datasets: [
                    {
                        label: 'Arrivals',
                        fill: true,
                        backgroundColor: gradient,
                        borderColor: '#A3A0FB',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'mitter',
                        pointBorderColor: '#A3A0FB',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                        pointHoverBorderWidth: 2,
                        pointRadius: 4,
                        pointHitRadius: 8,
                        lineTension:0.3,
                        data: [65, 59, 80, 81, 56, 55, 40]
                    }
                ]
            }
        };


        return (
            <div>
                <Line data={this.charData} height={80}/>
            </div>
        );
    }
}

export default LineChart
