import React, { Component } from 'react';
import { Typography, Grid, Divider, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Strings from '../../../../common/services/strings';
import globalContext from '../../../../common/services/context';
import CounterCard from '../../../../common/components/CounterCard';
import AttendanceList from '../../../../common/components/AttendanceList';
import Pie from '../../../../common/components/Pie';
import LineChart from '../../../../common/components/LineChart'


const strings = Strings.ContentMenu;
const stringsTwo = Strings.counterCardMenu;

const styles = (theme) => ({

    root: {
        flexGrow: 1,
        padding: '95px 10px 40px 40px',
        backgroundColor: 'white',
    },
    toolbar: theme.mixins.toolbar,
    title: {
        color: '#43425D',
        fontSize: '30px',
        fontFamily: 'Source Sans Pro, Regular',
    },
    grow: {
        flexGrow: 1
    },
    svg: {
        display: "inline-block",
        verticalAlign: "middle",
        transformOrigin: "50% 50%",
        animation: "scale .6s",
        margin: "10px"

    },
    marge: {
        marginTop: '30px'
    }
});

/*const data = {
    labels: [
        'Absent (%)',
        'Present (%)'
    ],
    datasets: [{
        data: [50, 50],
        backgroundColor: [
            '#f0f2f8',
            '#ffda83'
        ],
        hoverBackgroundColor: [
            '#f0f2f8',
            '#ffda83'
        ]
    }]
};

const options = {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
        position: 'top',
        labels: {
            boxWidth: 20
        }
    }
}*/

class Home extends Component {

    constructor(props) {

        super(props)
        this.state = { data: [70, 30] }
    }

    render() {
        const { classes } = this.props;
        var colors = ['#FFC06A', '#f0f2f8'];

        var facility = this.props.facility
        if (facility == null) {
            facility = globalContext.facility
        }

        return (
            <Grid container className={classes.root}>
                <div className={classes.toolbar} />
                <Grid container spacing={24}>
                    <Grid item sm={12} xs={12} md={12} lg={12}>
                        <Typography noWrap className={classes.title}>
                            {/* {strings.contentHeaderText} */}
                            {facility.name}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={12}>
                        <Grid container spacing={16}>
                            {
                                stringsTwo.listOne.map(elt => (

                                    <Grid item sm={12} xs={12} lg={4} md={12} key={elt.title} style={{ maxWidth: '370px' }}>
                                        <CounterCard data={elt} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={12} style={{ marginTop: "45px" }}>
                        <Typography noWrap className={classes.title} style={{ color: "#766842", textTransform: "capitalize", float: "left", top: "-40px", position: "relative" }}>
                            {strings.contentSecondaryText}
                        </Typography>

                        <a href="/">
                            <Typography noWrap style={{ color: "#425276", textTransform: "capitalize", float: "right", fontSize: "16px", top: "-25px", textDecoration: "underline", position: "relative" }}>
                                {strings.showall}
                            </Typography>
                        </a>
                        <Divider style={{ border: "0.01em solid rgb(188, 188, 203)", background: "#BCBCCB" }} />

                    </Grid>
                    <Grid item sm={12}>
                        <LineChart />
                    </Grid>
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12} className={classes.marge}>
                    <Grid container spacing={16}>

                        <Grid item md={8} xs={12} sm={12} >
                            <Paper style={{ boxShadow: "0px 2px 6px -4px rgba(0,0,0,0.4)", padding: "10px" }}>
                                <AttendanceList />
                            </Paper>
                        </Grid>
                        <Grid item md={4} xs={12} sm={12} >
                            <Paper style={{ boxShadow: "0px 2px 6px -4px rgba(0,0,0,0.4)", padding: "10px" }}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <div style={{ height: "20px", width: "50px", background: "#FFC06A", float: "left", margin: "10px 2px 10px 10px" }}></div>
                                        <Typography noWrap style={{ color: "#FFC06A", textTransform: "capitalize", fontSize: "13px", position: "relative", top: "15px", textDecoration: "none" }}>
                                            <b>&nbsp;PRESENT</b> : {this.state.data[1]} %
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ height: "20px", width: "50px", background: "#f5f6fa", float: "left", margin: "10px 2px 10px 10px" }}></div>
                                        <Typography noWrap style={{ color: "rgb(103, 77, 71)", textTransform: "capitalize", fontSize: "13px", position: "relative", top: "15px", textDecoration: "none" }}>
                                            <b>&nbsp;ABSENT</b> : {this.state.data[0]} %
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper style={{ boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.4)", padding: "10px", textAlign: "center" }}>
                                <Pie
                                    data={this.state.data}
                                    radius={135}
                                    hole={40}
                                    colors={colors}
                                    labels={true}
                                    percent={true}
                                    strokeWidth={3}
                                    stroke={'FFF'}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);
