import {combineReducers} from 'redux'
import {
    SET_PATIENTS_FETCHED_STATUS,
    SET_PATIENTS,
} from '../actions'


const fetched = (state = false, action) => {

    switch (action.type) {

        case SET_PATIENTS_FETCHED_STATUS:

            return action.value

        default:

            return state
    }
}

const  items = (state = [], action) => {

    switch (action.type) {

        case SET_PATIENTS:

            return action.patients

        default:

            return state
    }
}

export const patients = combineReducers({
    fetched,
    items,
})



