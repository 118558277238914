import React, { Component } from "react";

import LeftSection from "../../../components/LeftSection";

class Error extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="container-fuild">
          <div className="row mh-100vh m-0 justify-content-center align-items-stretch">
            <LeftSection />
            <section className="col-lg-6">
              <span className="form-reset">
                <h3 className="loginFormTitle">Error : Path does not exist.</h3>

                <div className="row" style={{ marginTop: "90%" }}>
                  <p className=" mb-3 text-muted col-lg-12 text-center termsOfUse">
                    Term of use. Privacy policy
                  </p>
                </div>
              </span>
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Error;
