import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'

const styles = theme => ({
    root: {},

});

class Pane extends React.Component {

    render() {
        const {classes, className, children} = this.props;

        return (
            <div className={classNames(classes.root, className)}>
                {children}
            </div>
        );
    }
}

Pane.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

Pane.defaultProps = {
    className: '',
    strings: {},
};

export default withStyles(styles)(Pane);