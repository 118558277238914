import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames'
// import Strings from '../../../../common/services/strings';
import PersonList from '../../../../../../common/components/PersonList/index'

// const strDashboardHome = Strings.ClinicDashboardHome;


const styles = (theme) => ({
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        padding: '95px 20px 40px 40px',
        backgroundColor: '#F0F0F7',
    },
    primaryContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        width: '100%',
        height: 'auto',

    },
    sideSelectorContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '60px',
        height: '100%',
        paddingTop: '70px',
    },
    sideSelector: {
        display: 'flex',
        flexFlow: 'column nowrap',
        fontFamily: 'Helvetica Neue',
        fontSize: '20px',
        color: '#707070',
        textTransform: 'uppercase',
        lineHeight: '24px',
    },
    sideSelectorItem: {
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:first-child': {
            fontSize: '30px',
            fontWeight: 'bold',
        }
    },
    sideSelectorItemSelected: {
        color: '#C40909',
    },
    personsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: 'calc(100% - 60px)',
        maxWidth: '1150px',
        margin: '0px',
        padding: '0px !important',
        [theme.breakpoints.up('xl')]: {
            maxWidth: '1170px',
        },
    },
    '@media (max-width: 1300px)' : {
        primaryContainer: {
            flexFlow: 'column nowrap',
        },
        sideSelectorContainer: {
            flexFlow: 'row nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            height: '60px',
            paddingTop: '0px',
        },
        sideSelector: {
            flexFlow: 'row nowrap',
            lineHeight: '24px',
        },
        sideSelectorItem: {
            marginRight: '5px',
            '&:first-child': {
                marginRight: '10px',
            }
        },
        personsContainer: {
            width: '100%',
        },
    },
});

/*const patientsPage = [
    {
        id: 1,
        name: 'David James',
        info: 'Seeing Dr ERICA DAWSON at 1:30 PM',
        phone: '871.567.4877',
        avatar: 'D'
    },
    {
        id: 2,
        name: 'Tony Stark',
        info: 'Seeing Dr ERICA DAWSON at 1:30 PM',
        phone: '871.567.4877',
        avatar: 'T'
    },
    {
        id: 3,
        name: 'Bessie Berry',
        info: 'Seeing Dr ERICA DAWSON at 1:30 PM',
        phone: '871.567.4877',
        avatar: 'B'
    },
    {
        id: 4,
        name: 'Gleb Kuznetsov',
        info: 'Seeing Dr ERICA DAWSON at 1:30 PM',
        phone: '871.567.4877',
        avatar: 'G'
    },
    {
        id: 5,
        name: 'Andrey Prokopenko',
        info: 'Seeing Dr ERICA DAWSON at 1:30 PM',
        phone: '871.567.4877',
        avatar: 'A'
    },
    {
        id: 6,
        name: 'Nick Herasimenka',
        info: 'Seeing Dr ERICA DAWSON at 1:30 PM',
        phone: '871.567.4877',
        avatar: 'N'
    },
    {
        id: 7,
        name: 'Valentin Salmon',
        info: 'Seeing Dr ERICA DAWSON at 1:30 PM',
        phone: '871.567.4877',
        avatar: 'V'
    },
    {
        id: 8,
        name: 'Miro Kirov',
        info: 'Seeing Dr ERICA DAWSON at 1:30 PM',
        phone: '871.567.4877',
        avatar: 'M'
    },
    {
        id: 9,
        name: 'Vanessa Cobre',
        info: 'Seeing Dr ERICA DAWSON at 1:30 PM',
        phone: '871.567.4877',
        avatar: 'V'
    },
    {
        id: 10,
        name: 'Charlotte Hale',
        info: 'Seeing Dr ERICA DAWSON at 1:30 PM',
        phone: '871.567.4877',
        avatar: 'C'
    }
]*/

const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

class Patients extends Component {

    constructor(props) {

        super(props)

        this.state = {
            selectedSideSelector: '',
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleSideSelectorClick = (sideSelectorItem) => () => {

        this.setState({selectedSideSelector: sideSelectorItem})
    }

    navigateToCreatePatient() {

        let { history, match } = this.props
        history.push(match.url + '/create-new-patient')
    }

    navigateToPatientItem(patientId) {

        let { history, match } = this.props
        history.push(match.url + '/' + patientId  + '/view')
    }

    handlePersonListBtnClick = () => {

        this.navigateToCreatePatient()
    }

    handlePersonClick = (patient) => () => {

        this.navigateToPatientItem(patient.id)
    }

    render() {
        const { classes, patients } = this.props
        const { selectedSideSelector } = this.state
        return (
            <Grid container className={classes.root}>

                <div className={classes.primaryContainer}>

                    <div className={classes.sideSelectorContainer}>
                        <span className={classes.sideSelector}>
                            <span className={classNames(classes.sideSelectorItem, {[classes.sideSelectorItemSelected]: selectedSideSelector === ''})}
                                  onClick={this.handleSideSelectorClick('')}>
                                *
                            </span>
                            {
                                letters.map((letter, index) => (
                                    <span key={index} className={classNames(classes.sideSelectorItem, {[classes.sideSelectorItemSelected]: selectedSideSelector === letter})}
                                          onClick={this.handleSideSelectorClick(letter)}>
                                        {letter}
                                    </span>
                                ))
                            }
                        </span>
                    </div>

                    <div  className={classes.personsContainer}>
                        <PersonList list={patients.items} countLabel={{one: 'Patient', many: 'Patients'}} itemPerPage={8} withButton countable paginate
                                    buttonLabel="Create New Patient" onButtonClick={this.handlePersonListBtnClick} onItemClick={this.handlePersonClick}
                                    startingWith={selectedSideSelector}
                        />
                    </div>

                </div>
            </Grid>
        );
    }
}

Patients.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Patients)
