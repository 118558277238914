import { connect } from 'react-redux'
import { setSettings, setSettingsFetchingStatus } from '../../../services/actions'
import SettingsPage from '../components/Settings'


//Check if planPrices is set, if not fetch it from server by ajax request

const mapStateToProps = state => {
    return {
        settings: state.settings,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeSettings: (value) => {
            dispatch(setSettings(value))
        },
        onChangeSettingsFetchingStatus: (value) => {
            dispatch(setSettingsFetchingStatus(value))
        },
    }
}

const SettingsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsPage)

export default SettingsContainer