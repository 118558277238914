import React, { Component } from "react";
import moment from "moment";

import Blockquote from "./Blockquote";

class LeftSection extends Component {
  
  localTimer = null;

 constructor(props) {
   super(props)

   this.state = {
    blockquote: {
      message:
        "“Sometimes it is to your advantage for people to think you are crazy”",
      author: "Thelonious Monk"
    },
    timer: {
      time: "2:35 PM",
      date: "September 15th, 2018"
    },
    currentTime: moment().format("LT"),
    currentDate:  moment().format("MMMM Do, YYYY")
  };
 }

  componentDidMount() {

    const timeFunc = () => {
      this.setState({ currentTime: moment().format("LT"), currentDate: moment().format("MMMM Do, YYYY") });
    }
    

    this.localTimer = setInterval(function() {
      timeFunc();
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.localTimer);
  }

  render() {
    const { blockquote } = this.state;
    //console.log("1", this.props);
    return (
      <div
        id="left-section"
        className="col-lg-6  sectionLeft d-xs-none d-xs-none d-flex  justify-content-between
      align-items-center flex-column"
      >
        <div className="row mt-0">
          <Blockquote blockquote={blockquote} />
        </div>
        <div className="align-self-end">
          <div className="row" style={{}}>
            <p className="col-lg-12 text-center sectionLeftTimer font-weight-bold login-time">
              {this.state.currentTime}
            </p>
          </div>
          <div className="row" style={{}}>
            <p className="col-lg-12 text-center sectionLeftDate">
              {this.state.currentDate}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default LeftSection;
