import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import Button from '../../Button'
import Menu from './Menu'
import Tags from './Tags'
import Icons from '../../../assets/icon'
import ConversationList from  './ConversationList'
import ConversationView from './ConversationView'
import Reply from './Reply'
import Compose from './Compose'

const styles = theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        color: '#4D4F5C',
    },
    leftSection: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '200px',
        borderRight: '1px solid gainsboro',
    },
    composeButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '86px',
        padding: '25px 0px',
        borderBottom: '1px solid gainsboro',
    },
    composeButton: {
        width: '156px',
        height: '35px',
        fontSize: '15px',
        backgroundColor: '#3B86FF !important'
    },
    menuContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        padding: '25px 22px',
        borderBottom: '1px solid gainsboro',
    },
    tagContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        padding: '25px 22px',
        overflow: 'hidden',
        '&:hover': {
            overflow: 'auto',
        }
    },
    conversationSection: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '300px',
        borderRight: '1px solid gainsboro',
    },
    searchContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '86px',
        padding: '25px 25px',
        borderBottom: '1px solid gainsboro',
    },
    searchIcon: {
        width: '16px',
        height: '16px',
        margin: '0px 15px 0px 0px',
    },
    searchInput: {
        width: '100%',
        height: '35px',
        border: 'none',
        fontSize: '13px',
        color: '#4D4F5C',
        outline: '0px !important',
        '&::placeholder': {
            opacity: 0.5
        },
        '&:placeholder': {
            opacity: 0.5
        }
    },
    conversationsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'calc(100% - 86px)',
        padding: '0px',
        margin: '0px',
        overflow: 'hidden',
        '&:hover': {
            overflow: 'auto',
        }
    },
    empty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '85px',
        fontSize: '20px',
        color: '#43425D',
        opacity: '0.3'
    },
    rightSection: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexBasis: '0px',
        flexGrow: 1,
        height: '100%',
        overflow: 'hidden',
        '&:hover': {
            overflow: 'auto'
        }
    },
    rightSectionContent: {
        width: '100%',
        height: 'auto',
    },
    noSelection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        fontSize: '28px',
        color: '#43425D',
        opacity: '0.3'
    },
    replyContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        padding: '30px',
        marginBottom: '30px',
    },
    composeContainer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexBasis: '0px',
        flexGrow: 1,
        height: '100%',
        padding: '45px 10% 0px 10%',
        overflow: 'hidden',
        '&:hover': {
            overflow: 'auto'
        }
    },

});


class Inbox extends React.Component {

    constructor(props) {

        super(props)
        this.state = {
            compose:{
                editing: false,
                to: '',
                cc: '',
                subject: '',
                content: '',
            },
            selectedMenuItem: 'inbox',
            search: '',
            selectedConversation: null,
            replies: []
        }
    }

    handleChange = (value, childName, subChildName) => {

        if (subChildName === undefined) {
            console.log(subChildName, childName, value)
            this.setState({[childName] : value})

        } else {

            let child = Object.assign({}, this.state[childName])
            child[subChildName] = value
            this.setState({[childName]: child})
        }
    }

    handleMenuChange = (selectedMenuItem) => {

        let {selectedConversation, search} = this.state
        let conversations = this.getDisplayedConversation(search, selectedMenuItem)
        let currentConversation = (selectedConversation !== null && selectedConversation !== undefined) ?
            conversations.find((conversation) => conversation.id === selectedConversation.id) : undefined

        if (currentConversation === undefined || currentConversation === null) {
            this.setState({selectedConversation: null, selectedMenuItem})
        } else {
            this.setState({selectedMenuItem})
        }
    }

    handleConversationChange = (conversation) => {

        const {replies} = this.state

        let reply = replies.find((reply) => conversation.id === reply.conversation.id)

        if (reply === undefined) {

            reply = [{conversation, editing: false, text: ''}]
            this.setState({selectedConversation: conversation, replies: replies.concat(reply)})

        } else {

            this.setState({selectedConversation: conversation})
        }
    }

    handleReplyClick = () => {

        const {selectedConversation, replies} = this.state
        let replyIndex

        if (selectedConversation !== null && selectedConversation !== undefined) {
            replyIndex = replies.findIndex((reply) => selectedConversation.id === reply.conversation.id)
        }

        if (replyIndex !== undefined && replyIndex !== null) {
            let newReplies = replies.slice()
            newReplies[replyIndex].editing = true
            this.setState({reply: newReplies})
        }
    }

    handleReplyTextChange = (text) => {

        const {selectedConversation, replies} = this.state
        let replyIndex

        if (selectedConversation !== null && selectedConversation !== undefined) {
            replyIndex = replies.findIndex((reply) => selectedConversation.id === reply.conversation.id)
        }

        if (replyIndex !== undefined && replyIndex !== null) {
            let newReplies = replies.slice()
            newReplies[replyIndex].text = text
            this.setState({reply: newReplies})
        }
    }

    handleReplySend = () => {

        console.log('reply send click')
    }

    getDisplayedConversation(search, selectedMenuItem) {

        const {conversations} = this.props
        selectedMenuItem = (selectedMenuItem === undefined) ? this.state.selectedMenuItem : selectedMenuItem

        let matchedConversation = conversations.filter((conversations) => {

            if (conversations.subject.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                return true
            }

            for (let message of conversations.messages) {

                if (message.recipient.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    return true
                }

                if (message.sender.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    return true
                }

                if (message.content.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    return true
                }
            }

            return false
        })

        if (selectedMenuItem === 'inbox') {
            return matchedConversation
        } else if (selectedMenuItem === 'sent') {
            return  matchedConversation.filter((conversation) => conversation.messages[0].isSender)
        } else  {
            return []
        }
    }


    render() {
        const {classes, className, menuItems, menuNotif, tagItems} = this.props;
        const {selectedMenuItem, search, selectedConversation, replies, compose} = this.state
        let conversations = this.getDisplayedConversation(search)
        let reply

        if (selectedConversation !== null && selectedConversation !== undefined) {
            reply = replies.find((reply) => selectedConversation.id === reply.conversation.id)
        }

        return (
            <div className={classNames(classes.root, className)}>

                <div className={classes.leftSection}>

                    <div className={classes.composeButtonContainer}>
                        <Button className={classes.composeButton} onClick={() => this.handleChange(true, 'compose', 'editing')}>Compose</Button>
                    </div>

                    <div className={classes.menuContainer}>
                        <Menu menuItems={menuItems} menuNotif={menuNotif} selectedItem={selectedMenuItem} onChange={this.handleMenuChange}/>
                    </div>

                    <div className={classes.tagContainer}>
                        <Tags tagItems={tagItems} />
                    </div>

                </div>

                {!compose.editing &&
                    <div className={classes.conversationSection}>

                        <div className={classes.searchContainer}>
                            <img src={Icons['icon_search']} alt="search" className={classes.searchIcon}/>

                            <input type="text" className={classes.searchInput} value={search} placeholder="Search Message or Name"
                                   onChange={(e) => this.handleChange(e.target.value, 'search')}
                            />
                        </div>

                        <div className={classes.conversationsContainer}>
                            {conversations.length > 0 &&
                            <ConversationList list={conversations} selectedConversation={selectedConversation} tags={tagItems} onChange={this.handleConversationChange}/>
                            }
                            {conversations.length <= 0 && this.props.conversations.length <= 0 &&
                            <div className={classes.empty}>
                                No messages
                            </div>
                            }
                            {conversations.length <= 0 && this.props.conversations.length > 0 &&
                            <div className={classes.empty}>
                                No matched messages
                            </div>
                            }
                        </div>
                    </div>
                }

                {!compose.editing &&
                    <div className={classes.rightSection}>
                        {(selectedConversation === null || selectedConversation === undefined) &&
                            <div className={classes.noSelection}>
                               Select a message
                            </div>
                        }
                        <div className={classes.rightSectionContent}>

                            {selectedConversation !== null && selectedConversation !== undefined &&
                                <ConversationView conversation={selectedConversation} tags={tagItems}/>
                            }

                            {selectedConversation !== null && selectedConversation !== undefined &&
                                <div className={classes.replyContainer}>
                                    <Reply text={reply.text} editing={reply.editing} onReplyClick={this.handleReplyClick} onChangeText={this.handleReplyTextChange} onSendClick={this.handleReplySend}/>
                                </div>
                            }

                        </div>

                    </div>
                }

                {compose.editing &&
                    <div className={classes.composeContainer}>
                        <Compose compose={compose} onChange={this.handleChange}/>
                    </div>
                }




            </div>
        );
    }
}

Inbox.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    menuNotif: PropTypes.object.isRequired,
    tagItems: PropTypes.array.isRequired,
    conversations: PropTypes.array.isRequired,
};

Inbox.defaultProps = {
    className: '',
    strings: {},
    menuItems: [],
    menuNotif: {},
    tagItems: [],
    conversations: [],
};

export default withStyles(styles)(Inbox);