import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Icon } from '@material-ui/core';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';

import { Bar } from 'react-chartjs-2';

const styles = theme => ({
    card: {
        display: 'flex',
        borderRadius: '8px',
        boxShadow: "0px 2px 6px -4px rgba(0,0,0,0.4)",
        backgroundColor: "rgba(0, 0, 120, 0.4)",
        cursor: 'pointer',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
        fontFamily: 'Source Sans Pro, Regular'
    },
    cover: {
        // width: 151,
        // height: 151,
        boxShadow: "none",
        //verticalAlign:"middle",
        marginTop: 48
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit + 8,
    },
    icon: {
        fontSize: "10px",
        fontWeight: "800",
        marginTop: "2px",
        fontFamily: 'Source Sans Pro, Regular',
    },
    cardTitle: {
        fontFamily: 'Source Sans Pro, Regular',
        color: 'black', //"#43425D",
        opacity: 0.5,
        fontSize: "18px",
        fontWeight: "bold"
    },
    cardValue: {
        fontFamily: 'Source Sans Pro, Regular',
        fontWeight: "bold",
        color: "##4D4F5C",
        fontSize: "60px"
    },
    cardValueNoChart: {
        fontFamily: 'Source Sans Pro, Regular',
        fontWeight: "bold",
        color: "##4D4F5C",
        fontSize: "60px",
        marginLeft:"40px"
    },
});


class CounterCard extends Component {

    render() {
        const { classes } = this.props;
        const data = this.props.data;


        this.chartData = (canvas) => {
            const ctx = canvas.getContext("2d")
            const gradient = ctx.createLinearGradient(0, 0, 0, 50);
            gradient.addColorStop(0, data.color1);
            gradient.addColorStop(1, data.color2);

            return {
                labels: ["", "", "", "", "", "", ""],
                datasets: [
                    {
                        backgroundColor: gradient,
                        borderColor: 'rgba(255,255,255,1)',
                        borderWidth: 0.5,
                        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                        hoverBorderColor: 'rgba(255,99,132,1)',
                        data: [65, 59, 80, 81, 56, 55, 40]
                    }
                ]
            }
        }

        const variationColor = data.percentage > 0 ? "#119a55" : "#FF4141"
        const arrowIcon = data.percentage > 0 ? "arrow-up" : "arrow-down"

        const cardStyle = this.props.showChart ? classes.cardValue : classes.cardValueNoChart
        return (
            <Card className={classes.card} onClick={this.props.onClick}>
                <div className={classes.details}>
                    <CardContent className={classes.content} style={{ width: "100%" }}>
                        <Typography className={classes.cardTitle}>{data.title}</Typography>
                    </CardContent>
                    <div className={classes.controls}>
                        <Typography className={cardStyle} variant="headline" >{data.number}</Typography>
                    </div>
                    <div className={classes.controls} >
                        {data.percentage &&
                            <Typography style={{ color: variationColor, fontSize: "18px", fontWeight: "800", backgroundColor:'rgba(255, 255, 255, 0.4)', padding:"2px 4px", minWidth:"70px", textAlign: "center", borderRadius: "8px" }}>
                                <Icon className={classNames(classes.icon, 'fa fa-' + arrowIcon)} />{data.percentage}%
                            </Typography>
                        }
                        {!data.percentage &&
                            <span style={{display: 'flex', width: '0px', height: '30px'}} />
                        }

                    </div>
                </div>
                <Paper
                    className={classes.cover}
                >
                    {this.props.showChart && <Bar data={this.chartData} labels={this.labels} options={{
                        maintainAspectRatio: false, legend: {
                            display: false
                        }, scaleShowGridLines: false, scales: {
                            xAxes: [{
                                gridLines: {
                                    display: false,
                                    drawBorder: false,
                                }, categoryPercentage: 1.0,
                                barPercentage: 1.0
                            }],
                            yAxes: [{
                                gridLines: {
                                    display: false,
                                    drawBorder: false,
                                },
                                ticks: {
                                    display: false
                                }
                            }]
                        }
                    }} width={112} height={75} />}
                </Paper>
            </Card>
        );
    }
}

CounterCard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CounterCard);
