import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Button from '../../../../../../common/components/Button'

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        minHeight: '100vh',
        padding: '95px 40px 200px 40px',
        backgroundColor: 'white',
        fontFamily: 'Source Sans Pro',
        fontSize: '24px',
        color: '#707070',
    },
    wrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: '1340px',
        height: '100%',
        padding: '0px',
        backgroundColor: 'inherit',
    },
    title: {
        fontSize: '36px',
        color: '#43425D',
        marginBottom: '73px',
    },
    subTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '10px',
        marginBottom: '50px',
        borderBottom: '2px solid #707070',
        fontFamily: 'Helvetica Neue',
        fontSize: '26px',
        fontWeight: 'bold',
    },
    section: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        marginBottom: '45px',
        padding: '0px 10px 0px 20px',
    },
    sectionTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '10px',
        marginBottom: '34.5px',
        borderBottom: '1px solid #707070',
        fontWeight: 'bold',
    },
    block:{
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        paddingLeft: '20px',
        paddingBottom: '32px',
        borderBottom: '1px solid #707070',
    },
    blockFirstSection: {
        paddingBottom: '0px',
        borderBottom: 'none',
    },
    line: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: '13px',
    },
    inputGroup: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: 'auto',
        width: 'calc(50% - 15px)',
        '&:first-child': {
            marginRight: '30px',
        }
    },
    label: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '160px',
        margin: '0px 25px 0px 0px',
        padding: '0px',
    },
    input: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        height: '43px',
        padding: '0px 15px',
        border: '1px solid #707070',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro',
        fontSize: '20px',
        color: '#707070',
        outline: '0px !important',
        '&:focus': {
            border: '1px solid #3B86FF'
        }
    },
    formControlSelect: {
        display: 'flex',
        flexGrow: 1,
        height: 'auto',

    },
    noDisplay: {
        display: 'none !important'
    },
    selectRoot: {
        display: 'flex',
        width: '100%',
        height: '43px',
        padding: '0px 0px',
        border: 'none',
        borderRadius: '4px',
        fontSize: '20px',
        color: "#707070",
    },
    selectSelect: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '0px 15px',
        border: '1px solid #707070',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro',
        fontSize: '20px',
        boxSizing: 'border-box',
        '&:focus': {
            border: '1px solid #3B86FF',
            borderRadius: '4px',
            backgroundColor: 'white'
        }
    },
    selectNotchedOutline: {
        border: 'none',
    },
    selectItem: {
        padding: '10px 15px',
        fontFamily: 'Source Sans Pro',
        fontSize: '20px',
        color: '#707070'
    },
    radioGroup: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        flexGrow: 1,
        padding: '0px 0px',
        height: '43px',
        fontSize: '26px',
        color: "#707070",
        '&:last-child': {
            marginRight: '0px',
        },
    },
    controlLabelRoot: {
        marginBottom: '0px',
    },
    controlLabelLabel: {
        fontFamily: 'Source Sans Pro',
        fontSize: '26px',
        color: '#707070',
    },
    radioColor: {
        color: '#3B86FF !important',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: '10px',
    },
    button: {
        width: '185px',
        height: '50px',
        fontSize: '18px',
        '&:last-child': {
            marginLeft: '30px',
        }
    },
    cancelButton: {
        backgroundColor: 'white !important',
        border: '1px solid #43425D',
        color: '#43425D',
    }

});

const user = {
    firstName: 'John',
}

const states = [
    'Alabama',
    'Alaska',
    'Missouri',
    'Nevada',
    'Texas',
    'New York',
    'California',
]

const countries = [
    'Angola',
    'Belgium',
    'Canada',
    'United State',
]

class NewEditFacilityPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            facility: {
                name: '',
                phone: '',
                address: '',
                address2: '',
                city: '',
                stateProvince: '',
                postalCode: '',
                country: '',
            },
            manager: {
                salutation: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                title: '',
            },
            contact: {
                salutation: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                title: '',
            }
        }
    }

    handleChange = (value, childName, subChildName) => {

        if (subChildName === undefined) {
            console.log(subChildName, childName, value)
            this.setState({[childName] : value})

        } else {

            let child = Object.assign({}, this.state[childName])
            child[subChildName] = value
            this.setState({[childName]: child})
        }
    }

    handleSaveClick = () => {

        console.log('bonjour')
    }

    handleCancelClick = () => {

        console.log('cancel click')
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {classes, className} = this.props;
        const {facility, manager, contact} = this.state

        return (
            <div className={classNames(classes.root, className)}>
                <div className={classes.wrapper}>

                    <span className={classes.title}>{'Welcome, ' + user.firstName + '!'}</span>

                    <span className={classes.subTitle}>Add/Edit Facility</span>

                    <div className={classNames(classes.section)}>
                        <span className={classes.sectionTitle}>Facility Details:</span>

                        <div className={classNames(classes.block, classes.blockFirstSection)}>

                            <div className={classes.line}>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="clinic-name" className={classes.label}>Clinic name</label>
                                    <input type="text" className={classes.input} id="clinic-name"
                                           value={facility.name} onChange={(e) => this.handleChange(e.target.value, 'facility', 'name')}
                                    />
                                </div>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="phone" className={classes.label}>Phone Number</label>
                                    <input type="text" className={classes.input} id="phone"
                                           value={facility.phone} onChange={(e) => this.handleChange(e.target.value, 'facility', 'phone')}
                                    />
                                </div>
                            </div>

                            <div className={classes.line}>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="address" className={classes.label}>Address</label>
                                    <input type="text" className={classes.input} id="address"
                                           value={facility.address} onChange={(e) => this.handleChange(e.target.value, 'facility', 'address')}
                                    />
                                </div>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="address2" className={classes.label}>Address2</label>
                                    <input type="text" className={classes.input} id="address2"
                                           value={facility.address2} onChange={(e) => this.handleChange(e.target.value, 'facility', 'address2')}
                                    />
                                </div>
                            </div>

                            <div className={classes.line}>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="city" className={classes.label}>City</label>
                                    <input type="text" className={classes.input} id="city"
                                           value={facility.city} onChange={(e) => this.handleChange(e.target.value, 'facility', 'city')}
                                    />
                                </div>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="stateProvince" className={classes.label}>State/Province</label>
                                    <FormControl variant="outlined" className={classes.formControlSelect}>
                                        <InputLabel
                                            classes={{root: classes.noDisplay}}
                                        />
                                        <Select
                                            value={facility.name} onChange={(e) => this.handleChange(e.target.value, 'facility', 'stateProvince')}
                                            input={<OutlinedInput labelWidth={0} classes={{notchedOutline: classes.selectNotchedOutline}}/>}
                                            classes={{root: classes.selectRoot, select: classes.selectSelect,}}
                                        >
                                            {
                                                states.map((state, index) =>(
                                                    <MenuItem key={index} value={state} className={classes.selectItem}> {state} </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className={classes.line}>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="postalCode" className={classes.label}>Postal Code</label>
                                    <input type="text" className={classes.input} id="postalCode"
                                           value={facility.postalCode} onChange={(e) => this.handleChange(e.target.value, 'facility', 'postalCode')}
                                    />
                                </div>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="country" className={classes.label}>Country</label>
                                    <FormControl variant="outlined" className={classes.formControlSelect}>
                                        <InputLabel
                                            classes={{root: classes.noDisplay}}
                                        />
                                        <Select
                                            value={facility.country} onChange={(e) => this.handleChange(e.target.value, 'facility', 'country')}
                                            input={<OutlinedInput labelWidth={0} classes={{notchedOutline: classes.selectNotchedOutline}}/>}
                                            classes={{root: classes.selectRoot, select: classes.selectSelect,}}
                                        >
                                            {
                                                countries.map((country, index) =>(
                                                    <MenuItem key={index} value={country} className={classes.selectItem}> {country} </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={classes.section}>
                        <span className={classes.sectionTitle}>Manager:</span>

                        <div className={classes.block}>

                            <div className={classes.line}>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="Msalutation" className={classes.label}>Salutation</label>
                                    <RadioGroup aria-label="gender" name="gender2" className={classes.radioGroup}
                                                value={manager.salutation} onChange={(e) => this.handleChange(e.target.value, 'manager', 'salutation')} id="Msalutation">
                                        <FormControlLabel
                                            value="miss"
                                            control={<Radio classes={{root: classes.radioColor, checked: classes.radioColor }}/>}
                                            label="Miss"
                                            labelPlacement="end"
                                            classes={{
                                                root: classes.controlLabelRoot,
                                                label: classes.controlLabelLabel,
                                            }}
                                        />
                                        <FormControlLabel
                                            value="mrs"
                                            control={<Radio classes={{root: classes.radioColor, checked: classes.radioColor }}/>}
                                            label="Mrs"
                                            labelPlacement="end"
                                            classes={{
                                                root: classes.controlLabelRoot,
                                                label: classes.controlLabelLabel,
                                            }}
                                        />
                                        <FormControlLabel
                                            value="mr"
                                            control={<Radio classes={{root: classes.radioColor, checked: classes.radioColor }}/>}
                                            label="Mr"
                                            labelPlacement="end"
                                            classes={{
                                                root: classes.controlLabelRoot,
                                                label: classes.controlLabelLabel,
                                            }}
                                        />
                                    </RadioGroup>
                                </div>

                            </div>

                            <div className={classes.line}>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="MfirstName" className={classes.label}>First Name</label>
                                    <input type="text" className={classes.input} id="MfirstName"
                                           value={manager.firstName} onChange={(e) => this.handleChange(e.target.value, 'manager', 'firstName')}
                                    />
                                </div>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="MlastName" className={classes.label}>Last Name</label>
                                    <input type="text" className={classes.input} id="MlastName"
                                           value={manager.lastName} onChange={(e) => this.handleChange(e.target.value, 'manager', 'lastName')}
                                    />
                                </div>
                            </div>

                            <div className={classes.line}>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="Memail" className={classes.label}>Email</label>
                                    <input type="text" className={classes.input} id="Memail"
                                           value={manager.email} onChange={(e) => this.handleChange(e.target.value, 'manager', 'email')}
                                    />
                                </div>

                                <div className={classes.inputGroup}>
                                    <label htmlFor="Mphone" className={classes.label}>Phone</label>
                                    <input type="text" className={classes.input} id="Mphone"
                                           value={manager.phone} onChange={(e) => this.handleChange(e.target.value, 'manager', 'phone')}
                                    />
                                </div>
                            </div>

                            <div className={classes.line}>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="Mtitle" className={classes.label}>Title</label>
                                    <input type="text" className={classes.input} id="Mtitle"
                                           value={manager.title} onChange={(e) => this.handleChange(e.target.value, 'manager', 'title')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.section}>
                        <span className={classes.sectionTitle}>Contact:</span>

                        <div className={classes.block}>

                            <div className={classes.line}>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="ContactSalutation" className={classes.label}>Salutation</label>
                                    <RadioGroup aria-label="salutation" name="salutation" className={classes.radioGroup}
                                                value={contact.salutation} onChange={(e) => this.handleChange(e.target.value, 'contact', 'salutation')} id="ContactSalutation">
                                        <FormControlLabel
                                            value="miss"
                                            control={<Radio classes={{root: classes.radioColor, checked: classes.radioColor }}/>}
                                            label="Miss"
                                            labelPlacement="end"
                                            classes={{root: classes.controlLabelRoot, label: classes.controlLabelLabel,}}
                                        />
                                        <FormControlLabel
                                            value="mrs"
                                            control={<Radio classes={{root: classes.radioColor, checked: classes.radioColor }}/>}
                                            label="Mrs"
                                            labelPlacement="end"
                                            classes={{root: classes.controlLabelRoot, label: classes.controlLabelLabel,}}
                                        />
                                        <FormControlLabel
                                            value="mr"
                                            control={<Radio classes={{root: classes.radioColor, checked: classes.radioColor }}/>}
                                            label="Mr"
                                            labelPlacement="end"
                                            classes={{root: classes.controlLabelRoot, label: classes.controlLabelLabel,}}
                                        />
                                    </RadioGroup>
                                </div>

                            </div>

                            <div className={classes.line}>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="ContactfirstName" className={classes.label}>First Name</label>
                                    <input type="text" className={classes.input} id="ContactfirstName"
                                           value={contact.firstName} onChange={(e) => this.handleChange(e.target.value, 'contact', 'firstName')}
                                    />
                                </div>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="ContactlastName" className={classes.label}>Last Name</label>
                                    <input type="text" className={classes.input} id="ContactlastName"
                                           value={contact.lastName} onChange={(e) => this.handleChange(e.target.value, 'contact', 'lastName')}
                                    />
                                </div>
                            </div>

                            <div className={classes.line}>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="Contactemail" className={classes.label}>Email</label>
                                    <input type="text" className={classes.input} id="Contactemail"
                                           value={contact.email} onChange={(e) => this.handleChange(e.target.value, 'contact', 'email')}
                                    />
                                </div>

                                <div className={classes.inputGroup}>
                                    <label htmlFor="Contactphone" className={classes.label}>Phone</label>
                                    <input type="text" className={classes.input} id="Contactphone"
                                           value={contact.phone} onChange={(e) => this.handleChange(e.target.value, 'contact', 'phone')}
                                    />
                                </div>
                            </div>

                            <div className={classes.line}>
                                <div className={classes.inputGroup}>
                                    <label htmlFor="Contacttitle" className={classes.label}>Title</label>
                                    <input type="text" className={classes.input} id="Contacttitle"
                                           value={contact.title} onChange={(e) => this.handleChange(e.target.value, 'contact', 'title')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.buttonContainer}>
                        <Button className={classNames(classes.button, classes.cancelButton)} onClick={this.handleCancelClick}>
                            CANCEL
                        </Button>
                        <Button className={classNames(classes.button)} onClick={this.handleSaveClick}>SAVE</Button>
                    </div>

                </div>
            </div>
        );
    }
}

NewEditFacilityPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

NewEditFacilityPage.defaultProps = {
    className: '',
    strings: {},
};

export default withStyles(styles)(NewEditFacilityPage);