import React, { Component } from "react";
import Input from "../../../components/Input";
import Strings from "../../../../../services/strings"
import { withRouter } from 'react-router-dom'
import firebase from 'firebase';

const strings = Strings.ResetPassword;

const RequestStatus = {
  none: "none",
  success: strings.success
}

class ResetForm extends Component {

  constructor(props) {
    super(props);

    this.state = { emailAddress: "", status: RequestStatus.none };
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetSuccess = this.resetSuccess.bind(this);
    this.goToLoginPage = this.goToLoginPage.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  async handleResetPassword() {
    const emailAddress = this.state.emailAddress;

    try {
      firebase.auth().useDeviceLanguage();
      await firebase.auth().sendPasswordResetEmail(emailAddress)

    } catch (error) {

    }

    return true

  }

  handleInputChange(event) {
    this.setState({ status: RequestStatus.none });

    if (event.target.id === "inputEmail") {
      this.setState({ emailAddress: event.target.value });
    }
  }


  resetSuccess() {
    this.setState({ status: RequestStatus.success })
  }

  goToLoginPage(history) {
    history.push('/login')
  }

  render() {
    const { inputs } = this.props.resetForm;

    const ResetButton = withRouter(({ history }) => (
      <button
        className="btn btn-lg btn-primary btn-block loginBtn mb-3"
        onClick={() => this.handleResetPassword().then((status) => {
          if (status) {
            this.resetSuccess()
          }

        })}
      >{strings.send}</button>
    ))

    const LoginButton = withRouter(({ history }) => (
      <button
        className="btn btn-lg btn-primary btn-block loginBtn mb-3"
        onClick={() => {
          this.goToLoginPage(history)
        }}
      > {strings.login}</button >
    ))

    var info = "";
    if (this.state.status === RequestStatus.success) {
      info = strings.formatString(strings.success, this.state.emailAddress);
    }

    return (
      <form className="form-reset" onSubmit={this.handleSubmit}>
        <h3 className="loginFormTitle">{strings.aquiiAdmin}</h3>
        <h6 className="resetFormSubitle">
          {strings.title}
        </h6>

        {inputs.map(elt => (
          <Input key={elt.id} inputData={elt} onChange={this.handleInputChange} />
        ))}

        <div className="row pt-4">
          <div className="col-lg-12 ">
            {this.state.status === RequestStatus.none && <ResetButton />}
            {this.state.status === RequestStatus.success && <p>{info}</p>}
            {this.state.status === RequestStatus.success && <LoginButton />}
          </div>


        </div>
      </form>
    );
  }
}

export default ResetForm;
