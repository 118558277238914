import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        padding: '0px',
        margin: '0px',
    },
    list: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        margin: '0px',
        padding: '0px',
        listStyle: 'none',
    },
    tagItem: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        cursor: 'pointer',
        marginBottom: '15px',
        '&:hover' : {
            fontWeight: 'bold',
        },
        '&:last-child': {
            marginBottom: '0px'
        }
    },
    selected: {
        fontWeight: 'bold',
        color: '#3B86FF'
    },

});

class Menu extends React.Component {

    render() {
        const {classes, className, onChange, menuItems, selectedItem, menuNotif} = this.props;

        return (
            <nav className={classNames(classes.root, className)}>
                <ul className={classes.list}>
                    {
                        menuItems.map((menuItem, index) => (

                            <li key={index} className={classNames(classes.tagItem, {[classes.selected]: menuItem.name.toLowerCase() === selectedItem.toLowerCase()})}
                                onClick={() => onChange(menuItem.name) }>

                                <span className="">{menuItem.label}</span>

                                {menuNotif[menuItem.name] !== undefined &&  menuNotif[menuItem.name] > 0 &&
                                    <span>{menuNotif[menuItem.name] }</span>
                                }

                            </li>
                        ))
                    }
                </ul>
            </nav>
        );
    }
}

Menu.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    menuItems: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedItem: PropTypes.string.isRequired,
    menuNotif: PropTypes.object.isRequired,
};

Menu.defaultProps = {
    className: '',
    strings: {},
    menuItems: [],
    selectedItem: '',
    menuNotif: {},
};

export default withStyles(styles)(Menu);