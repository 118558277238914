import { connect } from 'react-redux'

import StaffCreationPage from '../components/StaffCreationPage'
import {changeRegistrationInfo} from "../../../../../../common/services/actions";


const mapStateToProps = state => {
    return {
        settings: state.settings.item,
        currentOrganization: state.currentOrganization,
        selectedFacility: state.selectedFacility,
    }
}


const mapDispatchToProps = dispatch => {
    return {

    }
}


const StaffCreationContainer = connect(
    mapStateToProps,
    mapDispatchToProps()
)(StaffCreationPage)

export default StaffCreationContainer