import LocalizedStrings from 'react-localization';



let studentStrings = new LocalizedStrings(
    {
        en: {
            parents: "Parents:",
            checkIn: 'Check In',
            checkOut: 'Check Out',
        }
    }
)

let classroomStrings = new LocalizedStrings(
    {
        en: {
            attendance: 'attendance',
            abscences: 'abscences',
            staff: 'staff',
            clkCheckOut: 'Click to check-out',
            clkCheckIn: 'Click to check-in',
            absent: 'absent'
        }
    }
)

let activitiesStrings = new LocalizedStrings(
    {
        en: {
            createNew: 'Create New ...',
            fieldTrip: 'Field Trip',
            churchCleaning: 'Church cleaning',
            otherActivity: 'Other Activity',
            physicalEducation: 'Physical Education',
        }
    }
)

let createFieldTripStrings = new LocalizedStrings(
    {
        en: {
            title: 'St Monica Parish School > Create Field Trip',
            location: 'location',
            placeholder: 'placeholder',
            locationIndication: 'Enter the location of the field trip',
            date: 'date',
            departure: 'departure',
            departureIndication: 'Enter the departure time of the field trip',
            return: 'return',
            returnIndication: 'Enter the return time of the field trip',
            cost: 'cost',
            costIndication: 'Enter the cost for each student',
            transportationMode: 'transportation mode',
            beacons: 'beacons',
            save: 'save',
            saveDraft: 'save draft',
            sendPermissionSlip: 'send permission slip'
        }
    }
)



let Strings = {
    Student: studentStrings,
    Classroom: classroomStrings,
    Activities: activitiesStrings,
    CreateFieldTrip: createFieldTripStrings
}

export default Strings