import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'


const styles = theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        width: '100%',
        maxWidth: '1033px',
        height: '52px',
        marginTop: '42.5px',
        marginBottom: '13px',
        padding: '15px 26px 15px 28px',
        fontFamily: 'Helvetica Neue',
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'left',
        textTransform: 'capitalize',
        color: '#FFFFFF',
        backgroundColor: '#515269',
    },
    nameAndAddress: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '38%',
        //border: '1px solid cyan',
    },
    contentWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '192px',
    },
    manager: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '31%',
        //border: '1px solid cyan',
    },
    contact: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-end',
        alignContent: 'space-between',
        width: '31%',
        //border: '1px solid cyan',
    },
});

class FacilityRow extends React.Component{
    render() {
        const {classes, className} = this.props;
        return(
            <div className={classNames(classes.container, className)}>
                <div className={classes.nameAndAddress}>
                    <div className={classes.contentWrapper}>
                        <span>{this.props.title}</span>
                    </div>
                </div>
                <div className={classes.manager}>
                    <div className={classes.contentWrapper}>
                        <span>{this.props.manager}</span>
                    </div>
                </div>
                <div className={classes.contact}>
                    <div className={classes.contentWrapper}>
                        <span>{this.props.contact}</span>
                    </div>
                </div>
            </div>
        );
    }
}

FacilityRow.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

FacilityRow.defaultProps = {
    className: '',
    strings: {},
};
export default withStyles(styles)(FacilityRow);