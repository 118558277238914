import React from 'react'
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Strings from "../../../../../services/strings";
import classNames from "classnames";
import Button from "@material-ui/core/Button/Button";
import Webcam from "react-webcam";

const strPatient = Strings.StaffPageStrings;

const styles = theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        minHeight: '100vh',
        fontFamily: 'Source Sans Pro',
        fontSize: '18px',
        color: '#707070',
        padding: '95px 40px 40px 40px',
        backgroundColor: 'white',
    },
    wrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '100%',
        maxWidth: '1703px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '100%',
        borderBottom: '4px solid #707070',
        fontFamily: 'Helvetica',
        fontSize: '36px',
    },
    coloredTitle: {
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#3B86FF',
    },
    insuranceCompanyContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        padding: '32.4px 0px 40px 0px',
        borderBottom: '1px solid #707070',
    },
    insuranceCompanyGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingTop: '45px',
        width: '50%',
    },
    inputSection: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        fontWeight: 'bold',
    },
    inputLabel: {
        width: '180px',
        marginBottom: '0px',
        textAlign: 'right',
    },
    inputWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'calc(60%)',
        marginLeft: '25px',
    },
    inputField: {
        width: 'calc(100%)',
        maxWidth: '394px',
        height: '43px',
        padding: '0px 15px',
        border: '1px solid #707070',
        borderRadius: '4px',
        fontSize: '16px',
        color: "#707070",
        '&:focus': {
            border: '1px solid #3B86FF',
            outline: 0,
        },
    },
    errorMessage: {
        color: '#D7373F',
        fontFamily: 'Helvetica',
        fontSize: '12px',
        fontWeight: 'normal',
    },
    insuranceCardContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '32.4px 0px 40px 0px',
        fontWeight: 'bold',
        borderBottom: '1px solid #707070',
    },
    insuranceCardWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    insuranceCardGroup: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    scanContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '428px',
        maxWidth: '428px',
        height: '229px',
        maxHeight: '229px',
        border: '1px solid #707070',
        borderRadius: '6px',
        marginLeft: '17px',
        boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.16)',
        '&:last-child': {
            marginLeft: '31px',
        },
        cursor: 'pointer'
    },
    streamContainer: {
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '428px',
        maxWidth: '428px',
        height: '229px',
        maxHeight: '229px',
        marginLeft: '17px',
        '&:last-child': {
            marginLeft: '31px',
        },
        cursor: 'pointer'
    },
    labelScan: {
        //padding: '59px 138px 107px 138px',
        fontWeight: 'bold',
        fontSize: '40px',
        fontFamily: 'Source Sans Pro',
        textAlign: 'center',
        color: '#707070',
        opacity: '.39',
    },
    labelScanClick: {
        display: 'flex',
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: '20px',
    },
    buttonContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    buttonGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    navigationButton: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        height: '50px',
        width: '185px',
        marginTop: '25px',
        border: 'none',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro !important',
        fontSize: '18px',
        textTransform: 'uppercase',
        color: 'white !important',
        backgroundColor: '#3B86FF !important',
        boxShadow: 'none !important',
        cursor: 'pointer',
        outline: '0px !important',
    },
    insuranceContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        width: '185px',
        paddingTop: '5px',
        //border: '1px solid blue',
    },
    insurance: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        textAlign: 'center',
        color: '#43425D',
    },
    '@media (max-width: 1080px)' : {
        insuranceCompanyGroup: {
            width:'100%',
        },
        insuranceCardGroup: {
            flexFlow: 'column',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
        },
        scanContainer: {
            height: '229px',
            width:'428px',
            margin: '0px',
            '&:last-child': {
                margin: '0px',
            },
        },
        streamContainer: {
            height: '229px',
            width:'428px',
            margin: '0px',
            '&:last-child': {
                margin: '0px',
            },
        },
    },
});

class NewPatientPage2 extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.currentStep != 2) {
            this.props.history.push(`${this.props.match.url.replace('2', '')}`);
        }
    }
    state = {
        errors: {
            insuranceCompany: '',
            planName: '',
            phoneNumber: '',
            insuredMemberName: '',
            groupId: '',
            memberId: '',
            copay: '',
        },
    };
    setRef = (webcam) => {
        this.webcam = webcam;
    };
    captureFrontCard = () => {
        if(this.state.frontCardSet){
            this.props.onNewPatientChange('primaryInsuranceInfo', {'frontCardPath': this.webcam.getScreenshot()});
            this.setState({frontCardSet: false});
        }
        if(this.props.primaryInsuranceInfo.frontCardPath === null) {
            this.setState({backCardSet: true});
        } else{
            this.setState({backCardSet: false});
        }
    };
    captureBackCard = () => {
        if(this.state.backCardSet){
            this.props.onNewPatientChange('primaryInsuranceInfo', {'backCardPath': this.webcam.getScreenshot()});
            this.setState({backCardSet: false});
        }
    };
    invalidInput = () => {
        let errors = this.state.errors;
        let invalidity = false;
        //console.log(errors);
        Object.values(errors).forEach(error => {
            error.length > 0 && (invalidity = true);
        });

        return invalidity;
    };
    handleChange = (root, field, value, regExp) => {
        const { onNewPatientChange } = this.props;

        onNewPatientChange([root], {[field]: value});
        this.validation(field, value, regExp);
    };
    validation(field, value, regExp){
        let errors = this.state.errors;
        //console.log(value);
        errors[field] = regExp.test(value) ? '' : 'Invalid ' + strPatient[field] + '!';
        console.log('field', field, 'value', value, 'error', errors[field]);
        this.setState({errors: errors});
    }
    handleFrontScanClick = () =>{
        const {primaryInsuranceInfo} = this.props;
        if(((primaryInsuranceInfo.frontCardPath === null && primaryInsuranceInfo.backCardPath === null) || (primaryInsuranceInfo.frontCardPath !== null && primaryInsuranceInfo.backCardPath !== null)) && !this.state.backCardSet){
            this.setState({frontCardSet: true});
        }
    };
    handleBackScanClick = () =>{
        const {primaryInsuranceInfo} = this.props;
        if(primaryInsuranceInfo.frontCardPath === null && primaryInsuranceInfo.backCardPath === null) {
            this.setState({frontCardSet: true});
        } else if(primaryInsuranceInfo.frontCardPath !== null && primaryInsuranceInfo.backCardPath !== null && !this.state.frontCardSet){
            this.setState({backCardSet: true});
        }
    };
    handlePreviousButtonClick = () => {
        const {history, match} = this.props;
        this.props.onNewPatientChange('currentStep', 1);
        history.push(`${match.url.replace('2', '')}`);
    };
    handleNextButtonClick = () => {
        const {history, match} = this.props;
        if(!this.invalidInput()){
            this.props.onNewPatientChange('currentStep', 3);
            history.push(`${match.url.replace('2', '3')}`);
        } else{
            console.log('invalid');
        }
    };
    render() {
        const {classes, className, primaryInsuranceInfo} = this.props;
        const videoConstraints = {
            width: 428,
            height: 229,
            facingMode: "user"
        };
        console.log(this.props);
        return (
            <div className={classNames(classes.container, className)}>

                <div className={classes.wrapper}>

                    <div className={classes.header}>
                        <span>{strPatient.clinicName}</span>
                        <span className={classes.coloredTitle}>{strPatient.newPatient}</span>
                    </div>

                    <div className={classes.insuranceCompanyContainer}>
                        <span className={classes.coloredTitle}>{strPatient.primaryInsuranceInfo}</span>
                        <div className={classes.insuranceCompanyGroup}>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strPatient.insuranceCompany}>{strPatient.insuranceCompany}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.insuranceCompany.length > 0) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('primaryInsuranceInfo','insuranceCompany', event.target.value, /^[a-zA-Z]+$/)} type="text" value={primaryInsuranceInfo.insuranceCompany} name={'insuranceCompany'} id={'insuranceCompany'}/>
                                    {
                                        this.state.errors.insuranceCompany.length > 0 && (
                                            <span className={classes.errorMessage}>{this.state.errors.insuranceCompany}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strPatient.planName}>{strPatient.planName}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.planName.length > 0) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('primaryInsuranceInfo','planName', event.target.value, /^[a-zA-Z]+$/)} type="text" value={primaryInsuranceInfo.planName} name={'planName'} id={'planName'}/>
                                    {
                                        this.state.errors.planName.length > 0 && (
                                            <span className={classes.errorMessage}>{this.state.errors.planName}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strPatient.phoneNumber}>{strPatient.phoneNumber}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.phoneNumber.length > 0) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('primaryInsuranceInfo','phoneNumber', event.target.value, /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/)} type="text" value={primaryInsuranceInfo.phoneNumber} name={'phoneNumber'} id={'phoneNumber'}/>
                                    {
                                        this.state.errors.phoneNumber.length > 0 && (
                                            <span className={classes.errorMessage}>{this.state.errors.phoneNumber}</span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.insuranceCompanyContainer}>
                        <div className={classes.insuranceCompanyGroup}>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strPatient.insuredMemberName}>{strPatient.insuredMemberName}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.insuredMemberName.length > 0) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('primaryInsuranceInfo','insuredMemberName', event.target.value, /^[a-zA-Z]+$/)} type="text" value={primaryInsuranceInfo.insuredMemberName} name={'insuredMemberName'} id={'insuredMemberName'}/>
                                    {
                                        this.state.errors.insuredMemberName.length > 0 && (
                                            <span className={classes.errorMessage}>{this.state.errors.insuredMemberName}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strPatient.groupId}>{strPatient.groupId}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.groupId.length > 0) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('primaryInsuranceInfo','groupId', event.target.value, /^[a-zA-Z0-9]+$/)} type="text" value={primaryInsuranceInfo.groupId} name={'groupId'} id={'groupId'}/>
                                    {
                                        this.state.errors.groupId.length > 0 && (
                                            <span className={classes.errorMessage}>{this.state.errors.groupId}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strPatient.memberId}>{strPatient.memberId}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.memberId.length > 0) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('primaryInsuranceInfo','memberId', event.target.value, /^[a-zA-Z0-9]+$/)} type="text" value={primaryInsuranceInfo.memberId} name={'memberId'} id={'memberId'}/>
                                    {
                                        this.state.errors.memberId.length > 0 && (
                                            <span className={classes.errorMessage}>{this.state.errors.memberId}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strPatient.copay}>{strPatient.copay}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.copay.length > 0) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('primaryInsuranceInfo','copay', event.target.value, /^[a-zA-Z]+$/)} type="text" value={primaryInsuranceInfo.copay} name={'copay'} id={'copay'}/>
                                    {
                                        this.state.errors.copay.length > 0 && (
                                            <span className={classes.errorMessage}>{this.state.errors.copay}</span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.insuranceCardContainer}>
                        <span className={classes.inputLabel}>{strPatient.insuranceCard}</span>
                        <div className={classes.insuranceCardWrapper}>
                            <div className={classes.insuranceCardGroup}>
                                { !this.state.frontCardSet ?
                                    <div onClick={this.handleFrontScanClick} className={classes.scanContainer}>
                                        { primaryInsuranceInfo.frontCardPath === null ?
                                            <span className={classes.labelScan}>{strPatient.front}</span> :
                                            <img style={{width: '428px', height: '229px'}} src={primaryInsuranceInfo.frontCardPath} alt={strPatient.front}/>
                                        }
                                    </div> :
                                    <div onClick={this.captureFrontCard} className={classes.streamContainer}>
                                        <Webcam audio={false} height={229} ref={this.setRef} screenshotFormat="image/jpeg" width={428} videoConstraints={videoConstraints} />
                                    </div>
                                }
                                { !this.state.backCardSet ?
                                    <div onClick={this.handleBackScanClick} className={classes.scanContainer}>
                                        {primaryInsuranceInfo.backCardPath === null ?
                                            <span className={classes.labelScan}>{strPatient.back}</span> :
                                            <img style={{width: '428px', height: '229px'}} src={primaryInsuranceInfo.backCardPath} alt={strPatient.back}/>
                                        }
                                    </div> :
                                    <div onClick={this.captureBackCard} className={classes.streamContainer}>
                                        <Webcam audio={false} height={229} ref={this.setRef} screenshotFormat="image/jpeg" width={428} videoConstraints={videoConstraints} />
                                    </div>
                                }
                            </div>
                            <span className={classes.labelScanClick}>{strPatient.clickToScan}</span>
                        </div>
                    </div>
                    <div className={classes.buttonContainer}>
                        <div className={classes.buttonGroup}>
                            <Button onClick={this.handlePreviousButtonClick} className={classes.navigationButton}>{strPatient.previous}</Button>
                            <div className={classes.insuranceContainer}>
                                <span className={classes.insurance}>{strPatient.demographics}</span>
                            </div>
                        </div>
                        <div className={classes.buttonGroup}>
                            <Button onClick={this.handleNextButtonClick} className={classes.navigationButton}>{strPatient.next}</Button>
                            <div className={classes.insuranceContainer}>
                                <span className={classes.insurance}>{strPatient.secondaryInsuranceInfo}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
NewPatientPage2.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

NewPatientPage2.defaultProps = {
    className: '',
    strings: {},
};
export default withStyles(styles)(NewPatientPage2);