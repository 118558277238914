import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import MessageView from './MessageView'

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        padding: '30px 0px 0px 0px',
    },
    header: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        padding: '0px 30px',
        marginBottom: '20px',
    },
    tag: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '75px',
        height: '24px',
        marginRight: '12px',
        borderRadius: '14px',
        fontSize: '11px',
        fontWeight: 'bold',
        color: 'white',
    },
    subject: {
        display: 'flex',
        fontSize: '18px',
    },
    messagesContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        padding: '0px',
        margin: '0px',
    },

});

class ConversationView extends React.Component {

    render() {
        const {classes, className, conversation, tags} = this.props;
        let tagName, tag

        if (conversation.messages[0].isSender) {
            tagName = conversation.messages[0].recipient.tag
        } else {
            tagName = conversation.messages[0].sender.tag
        }

        tag = (tagName !== undefined && tagName.trim() !== '') ? tags.find((tag) => tag.name.toLowerCase() === tagName) : undefined

        console.log(tagName)

        return (
            <div className={classNames(classes.root, className)}>

                <div className={classes.header}>
                    {tag !== undefined && tag !== null &&
                        <span className={classes.tag} style={{backgroundColor: tag.color}}>{tag.label}</span>
                    }
                    <span className={classes.subject}>{conversation.subject}</span>
                </div>

                <div className={classes.messagesContainer}>
                    {conversation.messages.length > 1 &&
                        conversation.messages.map((message, index) => (
                            index !== (conversation.messages.length - 1) ?
                                <MessageView message={message} key={index}/> :
                                <MessageView message={message} key={index} initialExpanded/>

                        ))
                    }

                    {conversation.messages.length <= 1 &&
                        conversation.messages.map((message, index) => (
                            <MessageView message={message} key={index} initialExpanded/>
                        ))
                    }
                </div>



            </div>
        );
    }
}

ConversationView.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    conversation: PropTypes.object.isRequired,
    tags: PropTypes.array.isRequired,
};

ConversationView.defaultProps = {
    className: '',
    strings: {},
    conversation: {},
    tags: [],
};

export default withStyles(styles)(ConversationView);