import React from 'react'
import PropTypes from "prop-types";
import validate from 'validate.js'
import {withStyles} from "@material-ui/core";
import Strings from "../../../../../services/strings";
import classNames from "classnames";
import Button from "@material-ui/core/Button/Button";
import Radio from "@material-ui/core/Radio/Radio";
import 'react-dates/lib/css/_datepicker.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import Webcam from "react-webcam";
import photo from "../../../../../../common/assets/img/photo.png";

const strPatient = Strings.StaffPageStrings;

const styles = theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        minHeight: '100vh',
        fontFamily: 'Source Sans Pro',
        fontSize: '18px',
        color: '#707070',
        padding: '95px 40px 40px 40px',
        backgroundColor: 'white',
    },
    wrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '100%',
        maxWidth: '1703px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '100%',
        borderBottom: '4px solid #707070',
        fontFamily: 'Helvetica',
        fontSize: '36px',
        textAlign: 'left',
    },
    coloredTitle: {
        fontSize: '30px',
        fontWeight: 'bold',
        alignSelf: 'flex-start',
        textAlign: 'center',
        color: '#3B86FF',
    },
    genderContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        width: '100%',
        paddingTop: '36.5px',
        paddingBottom: '23.5px',
        borderBottom: '1px solid #707070',
    },
    genderGroup: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '76px',
    },
    sectionLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        width: '210px',
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
    },
    radioGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignSelf: 'center',
        //marginLeft: '57px',
        fontSize: '16px',
    },
    radio: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        margin: '0px',
    },
    radioLabel: {
        marginBottom: '0px',
    },
    inputGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        width: 'calc(48%)',
        marginLeft: '30px',
    },
    inputSection: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        fontWeight: 'bold',
    },
    inputWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'calc(60%)',
        marginLeft: '25px',
    },
    inputLabel: {
        width: '200px',
        marginBottom: '0px',
        textAlign: 'right',
    },
    inputField: {
        width: '100%',
        maxWidth: '394px',
        height: '43px',
        padding: '0px 15px',
        borderRadius: '4px',
        fontSize: '16px',
        color: "#707070",
        '&:focus': {
            border: '1px solid #3B86FF',
            outline: 0,
        },
    },
    errorMessage: {
        color: '#D7373F',
        fontFamily: 'Helvetica',
        fontSize: '12px',
        fontWeight: 'normal',
    },
    photoGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20%',
        textAlign: 'center',
    },
    photo: {
        width: '120px',
        maxWidth: '120px',
        height: '120px',
        maxHeight: '120px',
        cursor: 'pointer',
    },
    dateWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'calc(90%)',
        maxWidth: '256px',
        marginLeft: '25px',
    },
    date: {
        width: 'calc(100%)',
        height: '43px',
        padding: '0px 15px',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        color: "#707070",
        border: '1px solid #707070',
        '&:focus': {
            border: '1px solid #3B86FF',
            outline: 0,
        },
    },
    dateError: {
        width: 'calc(100%)',
        height: '43px',
        padding: '0px 15px',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        color: "#707070",
        border: '1px solid #D7373F',
        '&:focus': {
            border: '1px solid #3B86FF',
            outline: 0,
        },
    },
    birthContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: '32.5px',
        paddingBottom: '23.5px',
        //border: '1px solid pink',
        borderBottom: '1px solid #707070',
    },
    birthGroup: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        paddingTop: '16px',
        width: '100%',
    },
    birthGroupCell: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    maritalStatusContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignSelf: 'flex-start',
        width: '100%',
        paddingTop: '32.5px',
        paddingBottom: '23.5px',
        //border: '1px solid orange',
        borderBottom: '1px solid #707070',
    },
    addressContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        paddingTop: '39.5px',
        paddingBottom: '23.5px',
        marginBottom: '42.5px',
        //border: '1px solid orange',
        borderBottom: '1px solid #707070',
    },
    addressGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: 'calc(48%)',
        '&:first-child':{
            marginRight: '20px',
        }
    },
    nextButton: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        height: '50px',
        width: '185px',
        marginTop: '27.5px',
        border: 'none',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro !important',
        fontSize: '18px',
        textTransform: 'uppercase',
        color: 'white !important',
        backgroundColor: '#3B86FF !important',
        boxShadow: 'none !important',
        cursor: 'pointer',
        outline: '0px !important',
    },
    errorNextButton: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        height: '50px',
        width: '185px',
        marginTop: '27.5px',
        border: 'none',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro !important',
        fontSize: '18px',
        textTransform: 'uppercase',
        color: 'white !important',
        backgroundColor: 'gainsboro  !important',
        boxShadow: 'none !important',
        cursor: 'context-menu',
        outline: '0px !important',
    },
    insuranceContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        width: '185px',
        paddingTop: '5px',
    },
    insurance: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        textAlign: 'center',
        color: '#43425D',
    },

    placeOfBirthLabel: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignSelf: 'flex-start',
        width: '200px',
        marginBottom: '18px',
    },

    '@media (min-width: 1200px)' : {
        inputSection: {
            justifyContent: 'flex-start',
        }
    },
    '@media (max-width: 700px)' : {
        genderGroup: {
            flexFlow: 'column nowrap',
        },
        radioGroup: {
            flexFlow: 'row nowrap',
        },
        inputGroup: {
            justifyContent: 'center',
            alignSelf: 'flex-start',
            width: '70%',
        },
        birthGroup: {
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
        },
        birthGroupCell: {
            justifyContent: 'center',
            alignSelf: 'flex-start',
            width: '70%',
        },
        placeOfBirthLabel: {
            alignSelf:'center'
        },
        addressContainer: {
            flexFlow: 'column nowrap',
        },
        addressGroup: {
            width: '70%',
            '&:first-child': {
                marginRight: '0px',
            }
        }
    }
});

class NewPatientPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentPane: strPatient.newPatient,
            loading: true,
            loadingError: false,
            emailAddress: '',
            cameraSet: false,
            submitMode: false,
            errors: {
                firstName: '',
                middleName: '',
                lastName: '',
                imagePath: '',
                dateOfBirth: '',
                city: '',
                state: '',
                country: '',
                homePhone: '',
                workPhone: '',
                cellPhone: '',
                emailAddress: '',
            }
        };
    }
    componentDidMount() {
        const {demographics, onNewPatientChange} = this.props;
        window.scrollTo(0, 0);
        let imageSrc = demographics.imagePath.length > 0 ? demographics.imagePath : photo;
        onNewPatientChange('demographics', {'imagePath': imageSrc});
    }
    setRef = webcam => {
        this.webcam = webcam;
    };
    capture = () => {
        const { onNewPatientChange } = this.props;
        if(this.state.cameraSet){
            onNewPatientChange('demographics', {'imagePath': this.webcam.getScreenshot()});
        }
        this.setState({ cameraSet: !this.state.cameraSet });
        //console.log("Captured!" + this.state.imagePath);
    };
    handleDoBChange = (date) => {
        const { onNewPatientChange } = this.props;
        onNewPatientChange('placeOfBirth', {'dateOfBirth': date});
        this.softValidation('dateOfBirth', date);
        //console.log(date);
    };
    handleChange = (root, field, value, regExp) => {
        const { onNewPatientChange } = this.props;
        //console.log('root',root , 'field', field, 'value', value);
        if (root.length > 0) {
            onNewPatientChange([root], {[field]: value});
        } else {
            onNewPatientChange([field], value);
        }
        if (this.state.submitMode) {
            this.hardValidation(field, value, regExp);
            //console.log('hard ' + field + value + value.length);
        } else{
            this.softValidation(field, value);
            //console.log('soft');
        }
    };
    invalidInput = () => {
        let errors = this.state.errors;
        let invalidity = false;
        //console.log(errors);
        Object.values(errors).forEach(error => {
            error.length > 0 && (invalidity = true);
        });

        return invalidity;
    };
    softValidation = (field, value) => {
        let errors = this.state.errors;
        errors[field] = (value === null || value.length === 0) ? strPatient[field] + ' Field should not be empty!' : '';
        this.setState({errors: errors});
        //console.log('field', field, 'value', value, 'message', message);
    };
    hardValidation(field, value, regExp){
        let errors = this.state.errors;
        regExp = (regExp == undefined || regExp == null) ? /^[a-zA-Z]{2,}/ : regExp;
        errors[field] = regExp.test(value) ? '' : 'Invalid ' + strPatient[field] + '!';
        this.setState({errors: errors});
    }
    validation = () => {
        const {demographics, placeOfBirth, contact} = this.props;
        //validation of state input
        this.hardValidation('firstName', demographics.firstName, /^[a-zA-Z]{2,}/);

        //validation of state input
        this.hardValidation('middleName', demographics.middleName, /^[a-zA-Z]{2,}/);

        //validation of state input
        this.hardValidation('lastName', demographics.lastName, /^[a-zA-Z]{2,}/);

        //validation of state input
        this.hardValidation('city', placeOfBirth.city, /^[a-zA-Z]{2,}/);

        //validation of state input
        this.hardValidation('state', placeOfBirth.state, /^[a-zA-Z]{2,}/);

        //validation of country input
        this.hardValidation('country', placeOfBirth.country, /^[a-zA-Z]{2,}/);

        //validation of homePhone input
        this.hardValidation('homePhone', contact.homePhone, /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/);

        //validation of cellPhone input
        this.hardValidation('cellPhone', contact.cellPhone, /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/);

        //validation of workPhone input
        this.hardValidation('workPhone', contact.workPhone, /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/);

        //validation of emailAddress input
        this.hardValidation('emailAddress', contact.emailAddress, /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };
    handleActionButtonClick = async (event) => {
        event.preventDefault();
        const {history, match} = this.props;

        this.setState({submitMode: true});
        this.validation();
        if(this.invalidInput()){
            //console.log('fields invalid');
        } else{
            this.props.onNewPatientChange('currentStep', 2);
            history.push(`${match.url.replace('create-new-patient', 'create-new-patient2')}`);
        }
    };

    render() {
        const {classes, className, demographics, placeOfBirth, status, contact} = this.props;
        const videoConstraints = {
            width: 120,
            height: 120,
            facingMode: "user"
        };
        console.log(this.props);
        return (
            <div className={classNames(classes.container, className)}>

                <div className={classes.wrapper}>

                    <div className={classes.header}>
                        <span>{strPatient.clinicName}</span>
                        <span className={classes.coloredTitle}>{strPatient.newPatient}</span>
                    </div>

                    <div className={classes.genderContainer}>
                        <span className={classes.coloredTitle}>{strPatient.demographics}</span>
                        <div className={classes.genderGroup}>
                            <span className={classes.sectionLabel}>{strPatient.gender}</span>
                            <div className={classes.radioGroup}>
                                <div className={classes.radio}>
                                    <Radio id={strPatient.male} checked={demographics.gender === strPatient.male} onChange={ event => this.handleChange('demographics','gender', event.target.value) } value={strPatient.male} color="default" name="radio-button-demo" aria-label={strPatient.male}/>
                                    <label className={classes.radioLabel} htmlFor={strPatient.male}>{strPatient.male}</label>
                                </div>
                                <div className={classes.radio}>
                                    <Radio id={strPatient.female} checked={demographics.gender === strPatient.female} onChange={ event => this.handleChange('demographics','gender', event.target.value) } value={strPatient.female} color="default" name="radio-button-demo" aria-label={strPatient.female}/>
                                    <label className={classes.radioLabel} htmlFor={strPatient.female}>{strPatient.female}</label>
                                </div>
                                <div className={classes.radio}>
                                    <Radio id={strPatient.other} checked={demographics.gender === strPatient.other} onChange={ event => this.handleChange('demographics','gender', event.target.value) } value={strPatient.other} color="default" name="radio-button-demo" aria-label={strPatient.other}/>
                                    <label className={classes.radioLabel} htmlFor={strPatient.other}>{strPatient.other}</label>
                                </div>
                            </div>
                            <div className={classes.inputGroup}>
                                <div className={classes.inputSection}>
                                    <label className={classes.inputLabel} htmlFor={strPatient.firstName}>{strPatient.firstName}</label>
                                    <div className={classes.inputWrapper}>
                                        <input className={classes.inputField} style={(this.state.errors.firstName.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('demographics','firstName', event.target.value) } type="text" value={demographics.firstName} name={'firstName'} id={'firstName'}/>
                                        {
                                            this.state.errors.firstName.length > 1 && (
                                                <span className={classes.errorMessage}>{this.state.errors.firstName}</span>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={classes.inputSection}>
                                    <label className={classes.inputLabel} htmlFor={strPatient.middleName}>{strPatient.middleName}</label>
                                    <div className={classes.inputWrapper}>
                                        <input className={classes.inputField} style={(this.state.errors.middleName.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('demographics','middleName', event.target.value) } type="text" value={demographics.middleName} name={'middleName'} id={'middleName'}/>
                                        {
                                            this.state.errors.middleName.length > 1 && (
                                                <span className={classes.errorMessage}>{this.state.errors.middleName}</span>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={classes.inputSection}>
                                    <label className={classes.inputLabel} htmlFor={strPatient.lastName}>{strPatient.lastName}</label>
                                    <div className={classes.inputWrapper}>
                                        <input className={classes.inputField} style={(this.state.errors.lastName.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('demographics','lastName', event.target.value) } type="text" value={demographics.lastName} name={'lastName'} id={'lastName'}/>
                                        {
                                            this.state.errors.lastName.length > 1 && (
                                                <span className={classes.errorMessage}>{this.state.errors.lastName}</span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={classes.photoGroup}>
                                <div className={classes.photo} onClick={this.capture}>
                                    { !this.state.cameraSet ?
                                        <img src={demographics.imagePath} alt={strPatient.takePhoto}/> :
                                        <Webcam audio={false} height={120} ref={this.setRef} screenshotFormat="image/jpeg" width={120} videoConstraints={videoConstraints} />
                                    }
                                </div>
                                <span>{strPatient.takePhoto}</span>
                            </div>
                        </div>
                    </div>

                    <div className={classes.birthContainer}>
                        <div className={classes.birthGroup}>
                            <div className={classes.birthGroupCell}>
                                <div className={classes.inputSection}>
                                    <label className={classes.inputLabel} htmlFor={strPatient.dateOfBirth}>{strPatient.dateOfBirth}</label>
                                    <div className={classes.dateWrapper}>
                                        <DatePicker
                                            placeholderText=''
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className={this.state.errors.dateOfBirth.length > 1 ? classes.dateError : classes.date}
                                            selected={placeOfBirth.dateOfBirth}
                                            maxDate={new Date()}
                                            disabledKeyboardNavigation
                                            onChange={this.handleDoBChange}
                                        />
                                        {
                                            this.state.errors.dateOfBirth.length > 1 && (
                                                <span className={classes.errorMessage}>{this.state.errors.dateOfBirth}</span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={classes.inputGroup}>
                                <span className={classNames(classes.sectionLabel, classes.placeOfBirthLabel)}>{strPatient.placeOfBirth}</span>
                                <div className={classes.inputSection}>
                                    <label className={classes.inputLabel} htmlFor={strPatient.city}>{strPatient.city}</label>
                                    <div className={classes.inputWrapper}>
                                        <input className={classes.inputField} style={(this.state.errors.city.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('placeOfBirth','city', event.target.value, /^[a-zA-Z]{2,}/)} type="text" value={placeOfBirth.city} name={'city'} id={'city'}/>
                                        {
                                            this.state.errors.city.length > 1 && (
                                                <span className={classes.errorMessage}>{this.state.errors.city}</span>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={classes.inputSection}>
                                    <label className={classes.inputLabel} htmlFor={strPatient.state}>{strPatient.state}</label>
                                    <div className={classes.inputWrapper}>
                                        <input className={classes.inputField} style={(this.state.errors.state.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('placeOfBirth','state', event.target.value, /^[a-zA-Z]{2,}/)} type="text" value={placeOfBirth.state} name={'state'} id={'state'}/>
                                        {
                                            this.state.errors.state.length > 1 && (
                                                <span className={classes.errorMessage}>{this.state.errors.state}</span>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={classes.inputSection}>
                                    <label className={classes.inputLabel} htmlFor={strPatient.country}>{strPatient.country}</label>
                                    <div className={classes.inputWrapper}>
                                        <input className={classes.inputField} style={(this.state.errors.country.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('placeOfBirth','country', event.target.value, /^[a-zA-Z]{2,}/)} type="text" value={placeOfBirth.country} name={'country'} id={'country'}/>
                                        {
                                            this.state.errors.country.length > 1 && (
                                                <span className={classes.errorMessage}>{this.state.errors.country}</span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.maritalStatusContainer}>
                        <span className={classes.sectionLabel}>{strPatient.maritalStatus}</span>
                        <div className={classes.radioGroup}>
                            <div className={classes.radio}>
                                <Radio id={strPatient.single} checked={status === strPatient.single} onChange={ event => this.handleChange('','status', event.target.value) } value={strPatient.single} color="default" name="radio-button-demo" aria-label={strPatient.single}/>
                                <label className={classes.radioLabel} htmlFor={strPatient.single}>{strPatient.single}</label>
                            </div>
                            <div className={classes.radio}>
                                <Radio id={strPatient.married} checked={status === strPatient.married} onChange={ event => this.handleChange('','status', event.target.value) } value={strPatient.married} color="default" name="radio-button-demo" aria-label={strPatient.married}/>
                                <label className={classes.radioLabel} htmlFor={strPatient.married}>{strPatient.married}</label>
                            </div>
                            <div className={classes.radio}>
                                <Radio id={strPatient.widowed} checked={status === strPatient.widowed} onChange={ event => this.handleChange('','status', event.target.value) } value={strPatient.widowed} color="default" name="radio-button-demo" aria-label={strPatient.widowed}/>
                                <label className={classes.radioLabel} htmlFor={strPatient.widowed}>{strPatient.widowed}</label>
                            </div>
                        </div>
                    </div>

                    <div className={classes.addressContainer}>
                        <div className={classes.addressGroup}>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strPatient.homePhone}>{strPatient.homePhone}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.homePhone.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('contact','homePhone', event.target.value, /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/)} type="text" value={contact.homePhone} name={'homePhone'} id={'homePhone'}/>
                                    {
                                        this.state.errors.homePhone.length > 1 && (
                                            <span className={classes.errorMessage}>{this.state.errors.homePhone}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strPatient.cellPhone}>{strPatient.cellPhone}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.cellPhone.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('contact','cellPhone', event.target.value, /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/)} type="text" value={contact.cellPhone} name={'cellPhone'} id={'cellPhone'}/>
                                    {
                                        this.state.errors.cellPhone.length > 1 && (
                                            <span className={classes.errorMessage}>{this.state.errors.cellPhone}</span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={classes.addressGroup}>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strPatient.workPhone}>{strPatient.workPhone}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.workPhone.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('contact','workPhone', event.target.value, /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/)} type="text" value={contact.workPhone} name={'workPhone'} id={'workPhone'}/>
                                    {
                                        this.state.errors.workPhone.length > 1 && (
                                            <span className={classes.errorMessage}>{this.state.errors.workPhone}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strPatient.emailAddress}>{strPatient.emailAddress}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.emailAddress.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleChange('contact','emailAddress', event.target.value, /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)} type="email" value={contact.emailAddress} name={'emailAddress'} id={'emailAddress'}/>
                                    {
                                        this.state.errors.emailAddress.length > 1 && (
                                            <span className={classes.errorMessage}>{this.state.errors.emailAddress}</span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <Button onClick={this.handleActionButtonClick} className={(!this.invalidInput()) ? classes.nextButton : classes.errorNextButton} disabled={this.invalidInput()}>{strPatient.next}</Button>

                    <div className={classes.insuranceContainer}>
                        <span className={classes.insurance}>{strPatient.insuranceInfo}</span>
                    </div>
                </div>
            </div>
        );
    }
}
NewPatientPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

NewPatientPage.defaultProps = {
    className: '',
    strings: {},
};
export default withStyles(styles)(NewPatientPage);