import { connect } from 'react-redux'
import { changeRegistrationInfo} from '../../../services/actions'
import RegistrationPage from '../components/RegistrationPage3'


const mapStateToProps = state => {
    return {
        organization: state.registration.organization,
        primaryContact: state.registration.primaryContact,
        plan: state.registration.plan,
        paymentMethod: state.registration.paymentMethod,
        billInfo: state.registration.billInfo,
        cardInfo: state.registration.cardInfo,
        currentStep: state.registration.currentStep,
        planPrice: state.registration.planPrices.item[state.registration.plan.name],
        settings: state.settings.item,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (info, value) => {
            dispatch(changeRegistrationInfo(info, value))
        },
    }
}

const RegistrationPage3 = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationPage)

export default RegistrationPage3