import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import Typography from "@material-ui/core/Typography/Typography";
import Button from '../../../components/Button'
import Strings from '../../../services/strings'
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import Table from "@material-ui/core/Table/Table";
import TableCell from "@material-ui/core/TableCell/TableCell";

const strInvoice = Strings.InvoicesPage

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#F5F6FA',
        fontFamily: 'Source Sans Pro',
        color: '#A3A6B4',
        fontSize: '17px',
        fontWeight: 'bold',
        textAlign: 'left',
        height: '45px !important',
        padding: '0 0 0 20px',
        border: 'none !important',
        textTransform: 'uppercase'
    },
    body: {
        fontSize: 18,
    },
}))(TableCell);

const styles = theme => ({
    root: {
        flexGrow: '1',
        minHeight: '100vh',
        padding: '95px 40px 40px 75px',
        fontFamily: 'Source Sans Pro',
        fontSize: '17px',
        color: '#4D4F5C',
        backgroundColor: '#F0F0F7',
    },
    title: {
        color: '#43425D',
        fontSize: '34px',
        fontFamily: 'Source Sans Pro',
        marginBottom: '25px',
        textTransform: 'capitalize',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        maxWidth: '1900px',
        padding: '40px 40px 34px 40px',
        backgroundColor: 'white'
    },
    head: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 0px 50px 0px',
        borderBottom: '1px solid gainsboro'
    },
    headFirst: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#43425D',
        fontSize: '32px',
        textTransform: 'capitalize',
    },
    headSecond: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '16px',
    },
    companyAddress: {
        maxWidth: '96px',
    },
    printButton: {
        width: '131px',
        fontSize: '19px',
    },
    info: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        height: 'auto',
        padding: '30px 0px 0px 0px',
    },
    customerInfo: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '300px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    invoiceToLabel: {
        fontSize: '18px',
        fontWeight: 'bold'
    },
    orderInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: 'auto',
    },
    orderInfoLine: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    orderLabel: {
        fontSize: '18px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
    },
    orderStatus: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '24px',
        padding: '15px',
        borderRadius: '14px',
        backgroundColor: 'rgba(255, 202, 131, 0.3)',
        color: 'rgba(0, 0, 0, 0.5)',
        textTransform: 'capitalize'
    },

    tableContainer: {
        width: '100%',
        marginTop: '20px',
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    table: {
        minWidth: 700,
        marginBottom: '8px'
    },
    tableHead: {
        height: '45px !important',
        maxHeight: '45px !important'
    },
    row: {
        borderBottom: '1px solid #F1F1F3',
        height: '50px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F1F1F3'
        }

    },
    cell: {
        fontFamily: 'Source Sans Pro',
        fontSize: '17px',
        color: '#4D4F5C',
        padding: '0 0 0 20px',
        border: 'none'
    },
    totalContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        width: '100%',
        marginTop: '24px',
    },
    totalAmount: {
        marginTop: '10px',
        fontSize: '32px',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    submitButton: {
        alignSelf: 'flex-end',
        width: '131px',
        marginTop: '19px',
        backgroundColor: '#3B86FF !important',
    }

});

const strings = {
    company: 'St Monica Parish School',
    companyAddress: 'Mercer Island, WA P: (123) 456-7890',
    customerName: 'John Doe',
    customerAddress1: '795 Folsom Ave',
    customerAddress2: 'Bellevue, WA 98006',
    orderDate: 'March 15, 2019',
    orderStatus: 'Pending',
    orderId: '35697',
    subTotal: '2930.00',
    discount: '12.9',
    vat: '12.9',
    totalAmount: '2930.00'

}

const orders = [
    {
        item: 'Woman Peacoat',
        description: 'Lorem ipsum dolor sit amet Consectetur',
        quantity: '432',
        price: '9,928',
    },
    {
        item: 'Woman Peacoat',
        description: 'Lorem ipsum dolor sit amet Consectetur',
        quantity: '432',
        price: '9,928',
    },
    {
        item: 'Woman Peacoat',
        description: 'Lorem ipsum dolor sit amet Consectetur',
        quantity: '432',
        price: '9,928',
    },
    {
        item: 'Woman Peacoat',
        description: 'Lorem ipsum dolor sit amet Consectetur',
        quantity: '432',
        price: '9,928',
    },
]

class InvoicesPage extends React.Component {

    handlePrintClick = () => {

        console.log('handle print click')
    }

    handleSubmitClick = () => {

        console.log('handle submit click')
    }

    handleRowClick = (order) => () => {

        console.log('handle row click :', order)
    }

    render() {
        const {classes, className} = this.props;

        return (
            <div className={classNames(classes.root, className)}>

                <Typography noWrap className={classes.title}>
                    {strInvoice.invoices}
                </Typography>

                <div className={classes.main}>

                    <div className={classes.head}>
                        <div className={classes.headFirst}>
                            <span> {strings.company} </span>
                            <span> {strInvoice.invoice} </span>
                        </div>
                        <div className={classes.headSecond}>
                            <div className={classes.companyAddress}>
                                {strings.companyAddress}
                            </div>
                            <Button className={classes.printButton} onClick={this.handlePrintClick}>
                                {strInvoice.print}
                            </Button>
                        </div>

                    </div>

                    <div className={classes.info}>
                        <div className={classes.customerInfo}>
                            <span className={classes.invoiceToLabel}> {strInvoice.invoiceTo} </span>
                            <span> {strings.customerName} </span>
                            <span> {strings.customerAddress1} </span>
                            <span> {strings.customerAddress2} </span>
                        </div>
                        <div className={classes.orderInfo}>
                            <div className={classes.orderInfoLine}>
                                <span className={classes.orderLabel}>
                                    {strInvoice.orderDate}
                                </span> &nbsp; : &nbsp;
                                <span> {strings.orderDate} </span>
                            </div>
                            <div className={classes.orderInfoLine}>
                                <span className={classes.orderLabel}>
                                    {strInvoice.orderStatus}
                                </span> &nbsp; : &nbsp;
                                <span className={classes.orderStatus}> {strings.orderStatus} </span>
                            </div>
                            <div className={classes.orderInfoLine}>
                                <span className={classes.orderLabel}>
                                    {strInvoice.orderId}
                                </span> &nbsp; : &nbsp;
                                <span> #{strings.orderId} </span>
                            </div>
                        </div>
                    </div>

                    <div className={classes.tableContainer}>

                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.tableHead}>
                                    <CustomTableCell className={classes.firstColumn}>#</CustomTableCell>
                                    <CustomTableCell>{strInvoice.item}</CustomTableCell>
                                    <CustomTableCell>{strInvoice.description}</CustomTableCell>
                                    <CustomTableCell>{strInvoice.quantity}</CustomTableCell>
                                    <CustomTableCell>{strInvoice.price}</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map((order, index) => {
                                    return (
                                        <TableRow className={classes.row} key={index} onClick={this.handleRowClick(order)}>
                                            <CustomTableCell component="th" scope="row" className={classes.cell}>
                                                {index + 1}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.cell}>{order.item}</CustomTableCell>
                                            <CustomTableCell className={classes.cell}>{order.description}</CustomTableCell>
                                            <CustomTableCell className={classes.cell}>{order.quantity}</CustomTableCell>
                                            <CustomTableCell className={classes.cell}>${order.price}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>

                    <div className={classes.totalContainer}>
                        <div className={classes.orderInfoLine}>
                                <span className={classes.orderLabel}>
                                    {strInvoice.subTotal}
                                </span> &nbsp; : &nbsp;
                            <span> {strings.subTotal} </span>
                        </div>
                        <div className={classes.orderInfoLine}>
                                <span className={classes.orderLabel}>
                                    {strInvoice.discount}
                                </span> &nbsp; : &nbsp;
                            <span> {strings.discount}% </span>
                        </div>
                        <div className={classes.orderInfoLine}>
                                <span className={classes.orderLabel}>
                                    {strInvoice.vat}
                                </span> &nbsp; : &nbsp;
                            <span> {strings.vat}% </span>
                        </div>

                        <span className={classes.totalAmount}>{strInvoice.usd + ' ' + strings.totalAmount}</span>
                    </div>

                    <Button className={classes.submitButton} onClick={this.handleSubmitClick}> {strInvoice.submit} </Button>

                </div>

            </div>
        );
    }
}

InvoicesPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
}

InvoicesPage.defaultProps = {
    className: '',
    strings: {},
}

export default withStyles(styles)(InvoicesPage);