import React, { Component } from "react";

class Blockquote extends Component {
  state = {};
  render() {
    const { blockquote } = this.props;

    const author = "-  " + blockquote.author + "  -"
    return (
      <React.Fragment>
        <blockquote className="blockquote col-lg-12 text-center randomMessage mt-0 pt-4">
          <p className="mb-0 blockquote-message">{blockquote.message}</p>
          {/* <footer className="blockquote-footer"> */}
            <p className="mb-0 blockquote-author">{author}</p>
          {/* </footer> */}
          
        </blockquote>
      </React.Fragment>
    );
  }
}

export default Blockquote;
