import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../../components/Button'
import classNames from 'classnames'
import logo from '../../../assets/img/logo.png';


const styles = theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh',
        height: '1080px',
        width: '100%',
        fontFamily: 'Source Sans Pro',
        fontSize: '24px',
        color: '#707070',
    },
    header: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        height: '158px',
        width: '100%',
        borderBottom: '1px solid #707070',
    },
    logoTenkolo: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        width: '200px',
        height: '100px',
        marginLeft: '64px',
    },
    logo: {
        width: '200px',
        height: '100px',
    },
    headerTitle: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignSelf: 'flex-start',
        width: '534px',
        height: '97px',
        marginTop: '57px',
        textAlign: 'center',
    },
    top: {
        fontSize: '36px',
        margin: '0px'
    },
    bottom: {
        fontSize: '30px',
        margin: '4px 0px 0px 0px'
    },
    special: {
        color: '#3B86FF',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    help: {
        display: 'flex',
        alignSelf: 'flex-end',
        width: 'auto',
        height: '97px',
        marginRight: '33px',
        fontSize: '26px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#3B86FF',
        '&:last-child': {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginBottom: '7.5px'
        },
    },
    steeper: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'stretch',
        height: '61px',
        width: '1011px',
        margin: '34.5px 0px 66px 0px',
    },
    stepLabelLabel: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        color: '#4D4F5C !important',
    },
    stepLabelActive: {
        color: '#4D4F5C !important',
        fontWeight: 'bold !important'
    },
    stepLabelIconActive: {
        color: '#3B86FF !important',
    },
    stepLabelIconCompleted: {
        color: '#3B86FF !important',
    },
    content: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '1216px',
        height: 'auto',
    },
    sectionTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '13.5px',
        marginBottom: '34.5px',
        borderBottom: '1px solid #707070',
        fontWeight: 'bold'
    },
    accountNumber: {
        marginLeft: 'auto',
        fontSize: '26px',
        fontWeight: 'normal',
        color: '#3B86FF',
    },
    bold: {
        fontWeight: 'bold',
    },
    planLabel: {
        marginLeft: '27px',
        fontSize: '26px',
        fontWeight: 'bold',
        color: '#3B86FF',
        textTransform: 'uppercase',
    },
    noDisplay: {
        display: 'none !important'
    },
    controlLabelRoot: {
        marginBottom: '0px',
    },
    controlLabelLabel: {
        fontFamily: 'Source Sans Pro',
        fontSize: '26px',
        color: '#707070',
    },
    radioColor: {
        color: '#3B86FF !important',
    },
    centerContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: 'auto',
        color: '#6C6C6C',
    },
    infoContainer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '864px',
        height: 'auto',
        fontSize: '20px',
    },
    nextLabel: {
        margin: '66.5px 0px 20px 0px',
        fontSize: '26px',
    },
    labelInstruction: {
        marginBottom: '30px',
    },
    thankLabel: {
        marginTop: '60px',
        fontSize: '26px',
    },
    footer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '1216px',
        height: 'auto',
        margin: '200px 0px 50px 0px',
    },
    backButton: {
        height: '50px',
        width: '185px',
        marginLeft: '12px',
        fontSize: '18px',
        textTransform: 'uppercase',
        backgroundColor: '#3B86FF !important',
    },

});

const steps = [ 'YOUR ORGANIZATION', 'YOUR PLAN', 'PAYMENT', 'FINISHED' ];

const accountNumber = 'P1QTSWJGG001'

class RegistrationPage4 extends React.Component {

    constructor(props) {

        super(props);

        let {plan, onChange, currentStep} = this.props

        if (currentStep) {
            this.handleBackClick(currentStep)
        }

        let planName = 'premium'

        if (this.props.match.params.plan !== undefined && this.props.match.params.plan.toLowerCase() === 'starter') {
            planName = 'starter'
        } else if (this.props.match.params.plan !== undefined && this.props.match.params.plan.toLowerCase() === 'pro') {
            planName = 'pro'
        }

        if (planName !== plan.name) {
            onChange('plan', {name: planName})
        }

        this.state = {
            numberWaitingRoom: 1,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    navigateToPreviousStep(step) {
        const {history, match} = this.props
        history.push(`${match.url.replace('step-4', 'step-' + step)}`)
    }

    navigateToLogin() {
        const {history} = this.props
        history.push('/')
    }

    handleCloseClick = () => {
        this.navigateToLogin()
    }

    handleBackClick = (step) => {
        this.navigateToPreviousStep(step)
    }

    render() {
        const {classes, className} = this.props

        return (
            <div className={classNames(classes.root, className)}>

                <div className={classes.header}>
                    <div className={classes.logoTenkolo}>
                        <img src={logo} alt="logo" className={classes.logo}/>
                    </div>

                    <div className={classes.headerTitle}>
                        <span className={classes.top}>AQUII - Subscription</span>
                    </div>

                    <span className={classes.help}>Need help? Call us at (844) 250-9500</span>
                </div>

                <div className={classes.steeper}>
                    <Stepper activeStep={3} alternativeLabel>
                        {steps.map(label => {
                            return (
                                <Step key={label}>
                                    <StepLabel
                                        StepIconProps={{
                                            classes : {
                                                active: classes.stepLabelIconActive,
                                                completed: classes.stepLabelIconCompleted,
                                            }
                                        }}
                                        classes={{
                                            label: classes.stepLabelLabel,
                                            active: classes.stepLabelActive,
                                        }}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>

                <div className={classes.content}>

                    <div className={classes.sectionTitle}>
                        <span>You’re all set!</span>
                        <span className={classes.accountNumber}>Your account number: <span className={classes.bold}>{accountNumber}</span></span>
                    </div>

                    <div className={classes.centerContainer}>
                        <div className={classes.infoContainer}>
                            <span className={classes.nextLabel}>What happens next?</span>
                            <span className={classes.labelInstruction}>Your account executive will contact you to setup an appointment and discuss details of your installation.</span>
                            <span className={classes.bold}>Your account executive is: Baine Moshe</span>
                            <span className={classes.bold}>Phone number: 123-777-7782</span>
                            <span className={classes.bold}>Email: baine@tenkolo.com</span>
                            <span className={classes.thankLabel}>Thank you for your business!</span>
                        </div>
                    </div>

                </div>

                <div className={classes.footer}>

                    <Button className={classNames(classes.backButton)} onClick={this.handleCloseClick}>
                        Close
                    </Button>
                </div>

            </div>
        );
    }
}

RegistrationPage4.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

RegistrationPage4.defaultProps = {
    className: '',
    strings: {},
};

export default withStyles(styles)(RegistrationPage4);