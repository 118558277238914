import { connect } from 'react-redux'
import { changePatientInfo } from '../../../../../../common/services/actions'
import NewPatientPage3 from '../components/NewPatientPage3'


//Check if planPrices is set, if not fetch it from server by ajax request

const mapStateToProps = state => {
    return {
        demographics: state.newPatient.demographics,
        placeOfBirth: state.newPatient.placeOfBirth,
        status: state.newPatient.status,
        contact: state.newPatient.contact,
        primaryInsuranceInfo: state.newPatient.primaryInsuranceInfo,
        secondaryInsuranceInfo: state.newPatient.secondaryInsuranceInfo,
        currentOrganization: state.currentOrganization.item,
        currentFacility: state.currentFacility,
        settings: state.settings.item,
        currentStep: state.newPatient.currentStep,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNewPatientChange: (info, value) => {
            dispatch(changePatientInfo(info, value))
        }
    }
}

const NewPatientPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewPatientPage3)

export default NewPatientPage