import {CHANGE_PATIENT_INFO} from '../actions'

export default function newPatient (state = {}, action) {

    switch (action.type) {

        case CHANGE_PATIENT_INFO:

            if (typeof action.value === 'object' && action.value !== null) {
                //console.log('object:', action.info, action.value);

                return Object.assign({}, state, {[action.info]: Object.assign({}, state[action.info], action.value)})

            } else {
                //console.log('!object:', action.info, action.value);

                return Object.assign({}, state, {[action.info]: action.value})
            }

        default:
            return state
    }
}


