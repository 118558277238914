import { connect } from 'react-redux'
import PatientsPage from '../components/AppointmentsPage'


const mapStateToProps = state => {
    return {
        patients: state.patients,
        appointments: state.appointments,
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const AppointmentsPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientsPage)

export default AppointmentsPageContainer