import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'

const styles = theme => ({
    root: {
        maxWidth: '570px',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% - 20px) !important',
            margin: '15px 0px !important',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 10px)',
            margin: '15px 0px',
        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(50% - 30px) !important',
            margin: '15px 0px !important',
        },
    },
    label: {
        fontFamily: 'Source Sans Pro',
        fontSize: '28px',
        color: '#43425D',
        margin: 0
    }
});

class PersonCount extends React.Component {

    render() {
        const {classes, count, label} = this.props;

        return (
            <Grid item container className={classes.root} alignItems='center'>
                <FormLabel className={classes.label}>{count + ' ' + label}</FormLabel>
            </Grid>
        );
    }
}

PersonCount.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
};

PersonCount.defaultProps = {
    count: 0,
    label: '',
};

export default withStyles(styles)(PersonCount);