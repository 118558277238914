import { connect } from 'react-redux'
import StaffListPage from '../components/StaffListPage'


const mapStateToProps = state => {
    return {
        staff: state.staff,
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const StaffListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffListPage)

export default StaffListContainer