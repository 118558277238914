import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'

const styles = theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: 'auto',
        justifyContent: 'flex-start',
        flexFlow: 'column nowrap'
    },
    tabs: {
        position: 'relative',
        height: '45px',
        width: '100%',
    },
    tab: {
        position: 'relative',
        top: '0px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '100px',
        height: '45px',
        padding: '14px 14px 12px 14px',
        marginRight: '3px',
        backgroundColor: '#F8F8F8',
        color: '#43425D',
        border: '1px solid #E9E9F0',
        borderRadius: '4px 4px 0 0',
        fontFamily: 'Source Sans Pro',
        fontSize: '15px',
        cursor: 'pointer',
    },
    tabSelected: {
        backgroundColor: 'white !important',
        color: '#3B86FF !important',
        fontWeight: 'bold',
        borderBottom: 'none'
    },
    tabNotification: {
        height: '16px',
        width: '16px',
        position: 'absolute',
        top: '-5px',
        right: '-1px',
    },
    nav: {
        listStyleType: 'none',
        display: 'flex',
        margin: 0,
        padding: 0,
    },
    content: {
        display: 'flex',
        height: 'auto',
        padding: '0px',
        margin: '0px',
        minHeight: '200px',
    }

});

class Tab extends React.Component {

    constructor(props) {
        super(props)
        this.getPanes()
        this.getHeaders()
        let currentPaneIndex = this.props.initialPaneIndex <= this.panes.length - 1 ? this.props.initialPaneIndex : 0
        this.state = {currentPaneIndex}
    }

    getPanes() {

        let children = React.Children.toArray(this.props.children)

        if (children.length > 0) {

            this.panes = children.filter((item) => {
                return item.type.Naked !== undefined && item.type.Naked.name === 'Pane'
            })

        } else {

            this.panes =  []
        }
    }

    getHeaders() {

        this.headers = this.panes.map((item, index) => {
            return item.props.title !== undefined ? item.props.title : 'Pane ' + (index + 1)
        })
    }

    handleHeaderClick = (index, header) => () => {
        this.setState({currentPaneIndex: index})
        this.props.onChangePane(header, index)
    }

    render() {
        const {classes, className} = this.props;
        const {currentPaneIndex} = this.state

        return (
            <div className={classNames(classes.root, className)}>
                <div className={classes.tabs}>
                    <ul className={classes.nav}>
                        {
                            this.headers.map((header, index) => (

                                <li key={index} className={classNames(classes.tab, {[classes.tabSelected]: index === currentPaneIndex})}
                                    onClick={this.handleHeaderClick(index, header)}>

                                    {header}

                                </li>
                            ))
                        }
                    </ul>
                </div>

                <div className={classes.content}>
                    {this.panes[currentPaneIndex]}
                </div>

            </div>
        );
    }
}

Tab.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    initialPaneIndex: PropTypes.number.isRequired,
    onChangePane: PropTypes.func.isRequired,
};

Tab.defaultProps = {
    className: '',
    strings: {},
    initialPaneIndex: 0,
};

export default withStyles(styles)(Tab);