import {combineReducers} from 'redux'
import registration from './registration'
import user from './user'
import * as facilities from './facilities'
import * as patients from './patients'
import * as staff from './staff'
import * as settings from './settings'
import newPatient from './newPatient'
import * as currentOrganization from './currentOrganization'
import * as currentFacility from './currentFacility'
import * as home from './home'
import * as noShowPatients from './noShowPatients'
import * as appointments from './appointments'



const reducer = combineReducers({
    registration,
    ...facilities,
    ...patients,
    ...staff,
    ...settings,
    newPatient,
    ...currentOrganization,
    ...currentFacility,
    user,
    ...home,
    ...noShowPatients,
    ...appointments,
})

export default reducer