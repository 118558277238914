import React, { Component } from 'react'
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


const styles = (theme) => ({
    root: {
        flexGrow: 1,
        padding: '95px 10px 40px 40px',
        backgroundColor: 'white',
        minHeight: 'calc(100vh - 70px)'
    },
    title: {
        color: '#43425D',
        fontSize: '30px',
        fontFamily: 'Source Sans Pro, Regular',
    },
    grow: {
        flexGrow: 1
    }
});


class Empty extends Component {

    render() {
        const { classes, history } = this.props;
        return (
            <Grid container className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item sm={12} xs={12}>
                        <Typography noWrap className={classes.title}>
                            {`"${history.location.pathname}" doesn't match with any component`}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
        );
    }
}

Empty.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Empty)
