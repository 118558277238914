import { connect } from 'react-redux'
import PatientProfilePage from '../components/PatientProfilePage'


const mapStateToProps = state => {
    return {
        settings: state.settings.item,
        selectedFacility: state.selectedFacility,
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const PatientProfilePageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientProfilePage)

export default PatientProfilePageContainer