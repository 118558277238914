import {combineReducers} from 'redux'
import {
    SET_SETTINGS,
    SET_SETTINGS_FETCHING_STATUS,
} from '../actions'


const isFetching = (state = false, action) => {

    switch (action.type) {

        case SET_SETTINGS_FETCHING_STATUS:

            return action.value

        default:

            return state
    }
}

const  item = (state = {}, action) => {

    switch (action.type) {

        case SET_SETTINGS:

            return action.value

        default:

            return state
    }
}

export const settings = combineReducers({
    isFetching,
    item,
})


