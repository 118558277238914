import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {FormControl, InputLabel, Select, OutlinedInput, MenuItem} from '@material-ui/core'
import classNames from 'classnames'
import Icons from '../../../../../../common/assets/icon'
import IconButton from "@material-ui/core/IconButton/IconButton";
import Strings from '../../../../../services/strings'

const strPatientProfile = Strings.PatientProfileSection

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        minHeight: '660px',
        maxWidth: '1260px',
        width: '100%',
        padding: '46px 36px',
        border: '1px solid #707070',
        borderRadius: '0px 6px 0px 0px',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        color: '#43425D',
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    infoLine: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        minHeight: '40px',
        height: 'auto',
        marginTop: '21px',
        '&:first-child': {
            marginTop: '0px',
        }
    },
    infoLineNotes: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        marginTop: '21px',

    },
    infoLineAddress: {
        alignItems: 'flex-start',
    },
    infoLabel: {
        position: 'relative',
        display: 'flex',
        width: '110px',
        justifyContent: 'flex-end',
        margin: '0px 37px 0px 0px',
        fontWeight: 'bold',
    },
    infoValue: {
        minWidth: '150px',
        maxWidth: '500px',
    },
    infoNotesValue: {
        display: 'flex',
        alignItems: 'flex-start',
        maxWidth: '500px',
        height: 'auto',
        minWidth: '350px',
        minHeight: '180px',
        padding: '16px 14px',
        border: '1px solid #707070',
        backgroundColor: 'white',
    },
    infoInput: {
        height: '40px',
        width: '400px',
        padding: '12px 16px 11px 16px',
        fontFamily: 'Source Sans pro',
        fontSize: '13px',
        color: '#4D565C',
        border: '1px solid gainsboro',
        borderRadius: '4px',
        '&:focus': {
            outline: '0 !important',
            border: '1px solid #3B86FF',
        },
        '&::placeholder': {
            color: '#E8E9EC'
        },
        '&:placeholder': {
            color: '#E8E9EC'
        }
    },
    infoNotesInput: {
        height: 'auto',
        width: '400px',
        padding: '12px 16px 11px 16px',
        fontFamily: 'Source Sans pro',
        fontSize: '13px',
        color: '#4D565C',
        border: '1px solid gainsboro',
        borderRadius: '4px',
        '&:focus': {
            outline: '0 !important',
            border: '1px solid #3B86FF',
        },
        '&::placeholder': {
            color: '#E8E9EC'
        },
        '&:placeholder': {
            color: '#E8E9EC'
        }
    },
    iconButton: {
        padding: '12px',
        marginLeft: '80px',
        '&:focus': {
            outline: 0,
        },
    },
    icon: {
        width: '16px',
        height: '16px',
    },
    formControlSelect: {
        margin: '0px',
        minWidth: 120,
    },


});

class ProfileSection extends React.Component {


    handleProfileChange = (name, e) => {

        let profile =  Object.assign({}, this.state.profile)

        if (name !== 'address') {

            profile[name] = e.target.value

        } else if (name === 'address') {

            profile.address.address1 = e.target.value
        }

        this.setState({profile: profile})
    }

    render() {

        const {classes, className, editing} = this.props;
        const {gender, specialty, address, email, dateOfBirth, placeOfBirth, ethnicity, notes} = this.props.info

        return (
            <div className={classNames(classes.root, className)}>

                <div className={classes.infoLine}>
                    <label className={classes.infoLabel} htmlFor="gender"> Specialty : </label>

                    <span className={classes.infoValue}>
                            {specialty}
                    </span>
                </div>

                <div className={classes.infoLine}>
                    <label className={classes.infoLabel} htmlFor="gender"> {strPatientProfile.gender} : </label>

                    {!editing &&
                        <span className={classes.infoValue}>
                            {gender}
                        </span>
                    }
                </div>

                <div className={classNames(classes.infoLine, classes.infoLineAddress)}>
                    <label className={classes.infoLabel} htmlFor="address"> {strPatientProfile.address} : </label>

                    {!editing &&
                        <span className={classNames(classes.infoValue)}>
                            {address.address1} <br/>
                            {address.address2}
                        </span>
                    }
                    {!editing &&
                        <IconButton  color="inherit" className={classes.iconButton} aria-label="inbox icon">
                            <img src={Icons['icon_inbox']} className={classes.icon} alt="inbox" />
                        </IconButton>
                    }
                </div>

                <div className={classes.infoLine}>
                    <label className={classes.infoLabel} htmlFor="email"> {strPatientProfile.email} : </label>

                    {!editing &&
                        <span className={classes.infoValue}>
                                {email}
                        </span>
                    }
                    {!editing &&
                        <IconButton  color="inherit" className={classes.iconButton} aria-label="inbox icon">
                            <img src={Icons['icon_inbox']} className={classes.icon} alt="inbox" />
                        </IconButton>
                    }

                </div>

                <div className={classes.infoLine}>
                    <label className={classes.infoLabel} htmlFor="dateOfBirth"> {strPatientProfile.dateOfBirth} : </label>

                    {!editing &&
                        <span className={classes.infoValue}>
                            {dateOfBirth}
                        </span>
                    }
                </div>

                <div className={classes.infoLine}>
                    <label className={classes.infoLabel} htmlFor="placeOfBirth"> {strPatientProfile.placeOfBirth} : </label>

                    {!editing &&
                        <span className={classes.infoValue}>
                            {placeOfBirth}
                        </span>
                    }
                </div>

                {/*<div className={classes.infoLine}>
                    <label className={classes.infoLabel} htmlFor="ethnicity"> {strPatientProfile.ethnicity} : </label>

                    {editing &&
                        <input type="email" className={classes.infoInput} placeholder="Placeholder" name="ethnicity" id="ethnicity"
                               value={ethnicity}
                               onChange={(e) => this.handleProfileChange('ethnicity', e)}
                        />
                    }

                    {!editing &&
                        <span className={classes.infoValue}>
                            {ethnicity}
                        </span>
                    }
                </div>*/}

                <div className={classes.infoLineNotes}>
                    <label className={classes.infoLabel} htmlFor="notes"> {strPatientProfile.notes} : </label>

                    {!editing &&
                        <span className={classes.infoNotesValue}>
                            {/*{notes}*/}
                        </span>
                    }
                </div>

            </div>
        );
    }
}

ProfileSection.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
    info: PropTypes.object.isRequired,
};

ProfileSection.defaultProps = {
    className: '',
    strings: {},
    editing: false,
    info: {},
};

export default withStyles(styles)(ProfileSection);