import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton'
import Icons from '../../../../common/assets/icon'
import classNames from 'classnames'

const styles = theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '401px',
        height: 'auto',
        minHeight: '82px',
        padding: '13px 31px 15px 37px',
        border: 'none',
        fontFamily: 'Source Sans Pro',
        color: '#4D4F5C',
        backgroundColor: 'white',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#E5E5E5'
        }
    },
    selected: {
      backgroundColor: '#E5E5E5'
    },
    studentName: {
        marginBottom: '6px',
        fontSize: '18px',
        fontWeight: 'bold',
    },
    statusText: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '14px'
    },
    iconButton: {
        position: 'absolute',
        right: '18px',
        top: '47px',
        padding: '12px',
        marginTop: '-12px',
        '&:focus': {
            outline: 0,
        },
    },
    icon: {
        width: '16px',
        height: '16px',
    },
    parents: {
        marginTop: '5px',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    parentItem: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '6px',
        paddingLeft: '29px',
        fontSize: '14px',
        minWidth: '0px',
    },
    parentName: {
        width: '100px',
    },
    parentPhone: {
        width: '75px',
        marginLeft: '10px',
        textAlign: 'left'
    },
    parentEmail: {
        width: '120px',
        lineHeight: '14px',
        textAlign: 'right',
    },
    noWrap: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    button: {
        display: 'flex',
        width: '185px',
        height: '50px',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: '15px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#43425D',
        fontSize: '18px',
        textTransform: 'uppercase',
        color: 'white',
        outline: '0 !important',
        cursor: 'pointer'
    }
});

class Student extends React.Component {

    constructor(props) {

        super(props)
        this.state = {status: 'normal', attending: this.props.attending}

    }

    handleRootClick = () => {
        this.setState(state => ({
            status: state.status === 'selected' ? 'normal' : 'selected'
        }))
    }

    handleButtonClick = (e) => {
        e.stopPropagation()
        this.setState(state => ({
            attending: !state.attending
        }))
    }

    render() {
        const {classes, strings, name, statusText, parents, className} = this.props;
        const { status, attending } = this.state

        return (
            <div className={classNames(classes.root, className, {[classes.selected]: status === 'selected'})} onClick={this.handleRootClick}>

                <div className={classes.studentName}>
                    {name.lastName + ', ' + name.firstName}
                </div>

                {attending &&
                    <div className={classes.statusText}>
                        <span>{statusText}</span>
                    </div>
                }

                <IconButton className={classes.iconButton}>
                    <img src={Icons['icon_chat-notification']} alt="message" className={classes.icon}/>
                </IconButton>

                {status === 'selected' &&
                    <div className={classes.parents}>
                        {strings.parents}
                    </div>
                }

                {status === 'selected' &&
                    parents.map((parent, index) => (
                        <div className={classes.parentItem} key={index}>
                            <span className={classNames(classes.parentName, classes.noWrap)}>
                                {parent.name}
                            </span>
                            <span className={classNames(classes.parentPhone, classes.noWrap)}>
                                {parent.phone}
                            </span>
                            <span  className={classNames(classes.parentEmail, classes.noWrap)}>
                                {parent.email}
                            </span>
                        </div>
                    ))
                }

                {status === 'selected' &&
                    <button className={classes.button} onClick={this.handleButtonClick}>
                        { attending ? strings.checkOut : strings.checkIn}
                    </button>
                }

            </div>
        );
    }
}

Student.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.object.isRequired,
    attending: PropTypes.bool.isRequired,
    statusText: PropTypes.string.isRequired,
    parents: PropTypes.array.isRequired,
    strings: PropTypes.object.isRequired,
}

Student.defaultProps = {
    name: {firstName: '', lastName: ''},
    attending: false,
    statusText: '',
    parents: [],
    strings: {parents: '', checkOut: '', checkIn: ''},
}


export default withStyles(styles)(Student);