import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames'
// import Strings from '../../../../common/services/strings';
import PersonList from '../../../../../../common/components/PersonList/index'
import moment from "moment";

// const strDashboardHome = Strings.ClinicDashboardHome;


const styles = (theme) => ({
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        padding: '95px 20px 40px 40px',
        backgroundColor: '#F0F0F7',
    },
    primaryContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        width: '100%',
        height: 'auto',

    },
    sideSelectorContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '60px',
        height: '100%',
        paddingTop: '70px',
    },
    sideSelector: {
        display: 'flex',
        flexFlow: 'column nowrap',
        fontFamily: 'Helvetica Neue',
        fontSize: '20px',
        color: '#707070',
        textTransform: 'uppercase',
        lineHeight: '24px',
    },
    sideSelectorItem: {
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:first-child': {
            fontSize: '30px',
            fontWeight: 'bold',
        }
    },
    sideSelectorItemSelected: {
        color: '#C40909',
    },
    personsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: 'calc(100% - 60px)',
        maxWidth: '1150px',
        margin: '0px',
        padding: '0px !important',
        [theme.breakpoints.up('xl')]: {
            maxWidth: '1170px',
        },
    },
    '@media (max-width: 1300px)' : {
        primaryContainer: {
            flexFlow: 'column nowrap',
        },
        sideSelectorContainer: {
            flexFlow: 'row nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            height: '60px',
            paddingTop: '0px',
        },
        sideSelector: {
            flexFlow: 'row nowrap',
            lineHeight: '24px',
        },
        sideSelectorItem: {
            marginRight: '5px',
            '&:first-child': {
                marginRight: '10px',
            }
        },
        personsContainer: {
            width: '100%',
        },
    },
});

const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

class Patients extends Component {

    constructor(props) {

        super(props)

        this.state = {
            selectedSideSelector: '',
            appointments: this.filterAppointments()
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.appointments.items !== this.props.appointments.items) {

            this.setState({appointments: this.filterAppointments()})
        }
    }

    filterAppointments() {

        const appointments = this.props.appointments.items
        let type = this.props.match.params.appointmentStatus

        let filteredAppointments

        if (type.toLowerCase() === 'all') {

            filteredAppointments = appointments.filter(() => true)
        }

        if (type.toLowerCase() === 'active') {

            filteredAppointments = appointments.filter((appointment) => {

                if (appointment.appointment.status === 'booked' && moment(appointment.appointment.start).diff(moment(new Date()), 'minutes') >= -10) {

                    return true

                } else if (appointment.status && appointment.status.active) {

                    return true
                }

                return false
            })
        }

        filteredAppointments = filteredAppointments.map((appointment) => {

            let copy = Object.assign({}, appointment)

            copy.info = 'Seeing Dr ' + appointment.practitioner.actor.display.toUpperCase() + ' AT ' + moment(appointment.appointment.start).format('LT')

            return copy
        })

        return filteredAppointments
    }

    handleSideSelectorClick = (sideSelectorItem) => () => {

        this.setState({selectedSideSelector: sideSelectorItem})
    }

    navigateToCreatePatient() {

        let { history, match } = this.props
        history.push(match.url + '/create-new-patient')
    }

    navigateToPatientItem(patientId) {

        let { history, match } = this.props
        history.push(match.url + '/' + patientId  + '/view')
    }

    handlePersonListBtnClick = () => {

        this.navigateToCreatePatient()
    }

    handlePersonClick = (patient) => () => {

        this.navigateToPatientItem(patient.id)
    }


    render() {
        const { classes} = this.props
        const { selectedSideSelector, appointments } = this.state
        return (
            <Grid container className={classes.root}>

                <div className={classes.primaryContainer}>

                    <div className={classes.sideSelectorContainer}>
                        <span className={classes.sideSelector}>
                            <span className={classNames(classes.sideSelectorItem, {[classes.sideSelectorItemSelected]: selectedSideSelector === ''})}
                                  onClick={this.handleSideSelectorClick('')}>
                                *
                            </span>
                            {
                                letters.map((letter, index) => (
                                    <span key={index} className={classNames(classes.sideSelectorItem, {[classes.sideSelectorItemSelected]: selectedSideSelector === letter})}
                                          onClick={this.handleSideSelectorClick(letter)}>
                                        {letter}
                                    </span>
                                ))
                            }
                        </span>
                    </div>

                    <div  className={classes.personsContainer}>
                        <PersonList list={appointments} countLabel={{one: 'Patient', many: 'Patients'}} itemPerPage={8} countable paginate
                                    buttonLabel="Create New Patient" onButtonClick={this.handlePersonListBtnClick} onItemClick={this.handlePersonClick}
                                    startingWith={selectedSideSelector}
                        />
                    </div>

                </div>
            </Grid>
        );
    }
}

Patients.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Patients)
