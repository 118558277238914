import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import Inbox from '../../../components/Inbox'
import defaultAvatar from "../../../assets/img/avatar.png";
import moment from 'moment'
import MessageView from "../../../components/Inbox/components/MessageView";

const styles = theme => ({
    root: {
        width: '100%',
        minHeight: '100vh',
        padding: '95px 40px 40px 40px',
        backgroundColor: '#F0F0F7',
        fontFamily: 'Source Sans Pro',
        color: '#43425D',
    },
    title: {
        display: 'flex',
        fontSize: '28px',
        marginBottom: '30px',
    },
    inboxContainer: {
        position: 'relative',
        display: 'flex',
        maxWidth: '1900px',
        width: '100%',
        height: '752px',
        '@media (min-width: 1500px)': {
            height: '850px'
        },
        '@media (min-width: 1800px)': {
            height: '900px'
        }
    }
});


let menuItems = [

    {
        label: 'Inbox',
        name: 'inbox'
    },
    {
        label: 'Sent',
        name: 'sent'
    },
    {
        label: 'Drafts',
        name: 'drafts'
    },
    {
        label: 'Trash',
        name: 'trash'
    },
    {
        label: 'Important',
        name: 'important'
    },
    {
        label: 'Spam',
        name: 'spam'
    },
    {
        label: 'Starred',
        name: 'starred'
    },
]

let menuNotif = {
    inbox: 5,
    important: 2,
}

let tagItems = [
    {
        name: 'sales',
        label: 'Sales',
        color: '#A3A0FB'
    },
    {
        name: 'marketing',
        label: 'Marketing',
        color: '#3B86FF'
    },
    {
        name: 'design',
        label: 'Design',
        color: '#FF6565'
    },
]

let FHIRconversations = [
    [
        {
            resourceType: "Bundle",
            type: "message",
            entry: [
                {
                    fullUrl: "urn:uuid:85c317d5-4e88-4a93-87e5-a095c9711b8d",
                    resource: {
                        id: "1",
                        resourceType: "MessageHeader",
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Lorem ipsum dolor sit amet</div>"
                        },
                        event: {
                            system: "http://example.org/fhir/message-events",
                            code: "patient-discussion"
                        },
                        timestamp: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        source: {
                            endpoint: "http://example.org/clients/ehr-lite"
                        },
                        focus: [
                            {
                                reference: "urn:uuid:8852eafc-4a77-4a88-b134-447dc92a8abe"
                            },
                            {
                                reference: "urn:uuid:09c676ef-6131-4b41-9eee-f3c292d49629"
                            }
                        ]
                    }
                },
                {
                    fullUrl: "urn:uuid:85c317d5-4e88-4a93-87e5-a095c9711b8d",
                    resource:
                    {
                        resourceType: "Communication",
                        id: "example",
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Lorem ipsum dolor sit amet</div>"
                        },
                        identifier: [
                            {
                                system: "urn:oid:1.3.4.5.6.7",
                                value: "2345678901"
                            }
                        ],
                        instantiatesUri: [
                            "http://example.org/hyperkalemia"
                        ],
                        topic: {
                            display: "Conversation title, lorem ipsum"
                        },
                        medium: [
                            {
                                coding: [
                                    {
                                        system: "http://terminology.hl7.org/CodeSystem/v3-ParticipationMode",
                                        code: "EMAIL",
                                        display: "email"
                                    }
                                ]
                            }
                        ],
                        sent: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        received: '',
                        recipient: [
                            {
                                reference: "Patient/P02"
                            }
                        ],
                        sender: {
                            reference: "Patient/P01"
                        },
                        payload: [
                            {
                                contentString: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                            }
                        ]
                    }
                },
                {
                    fullUrl: "urn:uuid:8852eafc-4a77-4a88-b134-447dc92a8abe",
                    resource: {
                        resourceType: "Patient",
                        identifier: [
                            {
                                value: "P01",
                                system: "http://hl7.org/fhir/sid/us-ssn"
                            },
                        ],
                        name: [
                            {
                                family: "James Dalas",
                                given: [
                                    "Timothy",
                                    "Paul"
                                ]
                            }
                        ],
                        gender: "male",
                        birthDate: "2008-01-06",
                        telecom: [
                            {
                                system: "phone",
                                use: "home",
                                value: "+18088675301",
                            }
                        ],
                    }
                },
                {
                    fullUrl: "urn:uuid:09c676ef-6131-4b41-9eee-f3c292d49629",
                    resource: {
                        resourceType: "Patient",
                        identifier: [
                            {
                                value: "P02",
                                system: "http://hl7.org/fhir/sid/us-ssn"
                            },
                        ],
                        name: [
                            {
                                family: "Charlotte Hale",
                                given: [
                                    "Granite",
                                    "Pat"
                                ]
                            }
                        ],
                        gender: "female",
                        birthDate: "2002-11-25",
                        telecom: [
                            {
                                system: "phone",
                                use: "home",
                                value: "+18023545301",
                            }
                        ],
                    }
                }
            ]
        },
        {
            resourceType: "Bundle",
            type: "message",
            entry: [
                {
                    fullUrl: "urn:uuid:85c317d5-4e88-4a93-87e5-a095c9711b8d",
                    resource: {
                        id: "2",
                        resourceType: "MessageHeader",
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Patient has very high serum potassium</div>"
                        },
                        event: {
                            system: "http://example.org/fhir/message-events",
                            code: "patient-discussion"
                        },
                        timestamp: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        source: {
                            endpoint: "http://example.org/clients/ehr-lite"
                        },
                        focus: [
                            {
                                reference: "urn:uuid:8852eafc-4a77-4a88-b134-447dc92a8abe"
                            },
                            {
                                reference: "urn:uuid:09c676ef-6131-4b41-9eee-f3c292d49629"
                            }
                        ]
                    }
                },
                {
                    fullUrl: "urn:uuid:85c317d5-4e88-4a93-87e5-a095c9711b8d",
                    resource:
                    {
                        resourceType: "Communication",
                        id: "example",
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Patient has very high serum potassium</div>"
                        },
                        identifier: [
                            {
                                system: "urn:oid:1.3.4.5.6.7",
                                value: "2345678901"
                            }
                        ],
                        instantiatesUri: [
                            "http://example.org/hyperkalemia"
                        ],
                        topic: {
                            display: "Serum Potassium Observation"
                        },
                        medium: [
                            {
                                coding: [
                                    {
                                        system: "http://terminology.hl7.org/CodeSystem/v3-ParticipationMode",
                                        code: "EMAIL",
                                        display: "email"
                                    }
                                ]
                            }
                        ],
                        sent: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        received: '',
                        recipient: [
                            {
                                reference: "Patient/P01"
                            }
                        ],
                        sender: {
                            reference: "Patient/P02"
                        },
                        payload: [
                            {
                                contentString: "Patient 1 has a very high serum potassium value (7.2 mmol/L on 2014-Dec-12 at 5:55 pm)"
                            }
                        ]
                    }
                },
                {
                    fullUrl: "urn:uuid:09c676ef-6131-4b41-9eee-f3c292d49629",
                    resource: {
                        resourceType: "Patient",
                        identifier: [
                            {
                                value: "P02",
                                system: "http://hl7.org/fhir/sid/us-ssn"
                            },
                        ],
                        name: [
                            {
                                family: "Charlotte Hale",
                                given: [
                                    "Granite",
                                    "Pat"
                                ]
                            }
                        ],
                        gender: "female",
                        birthDate: "2002-11-25",
                        telecom: [
                            {
                                system: "phone",
                                use: "home",
                                value: "+18023545301",
                            }
                        ],
                    }
                },
                {
                    fullUrl: "urn:uuid:8852eafc-4a77-4a88-b134-447dc92a8abe",
                    resource: {
                        resourceType: "Patient",
                        identifier: [
                            {
                                value: "P01",
                                system: "http://hl7.org/fhir/sid/us-ssn"
                            },
                        ],
                        name: [
                            {
                                family: "James Dalas",
                                given: [
                                    "Timothy",
                                    "Paul"
                                ]
                            }
                        ],
                        gender: "male",
                        birthDate: "2008-01-06",
                        telecom: [
                            {
                                system: "phone",
                                use: "home",
                                value: "+18088675301",
                            }
                        ],
                    }
                }
            ]
        },
    ],
    [
        {
            resourceType: "Bundle",
            type: "message",
            entry: [
                {
                    fullUrl: "urn:uuid:85c317d5-4e88-4a93-87e5-a095c9711b8d",
                    resource: {
                        id: "3",
                        resourceType: "MessageHeader",
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Zulip by default only displays the most current discussions.</div>"
                        },
                        event: {
                            system: "http://example.org/fhir/message-events",
                            code: "patient-discussion"
                        },
                        timestamp: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        source: {
                            endpoint: "http://example.org/clients/ehr-lite"
                        },
                        focus: [
                            {
                                reference: "urn:uuid:8852eafc-4a77-4a88-b134-447dc92a8abe"
                            },
                            {
                                reference: "urn:uuid:09c676ef-6131-4b41-9eee-f3c292d49629"
                            }
                        ]
                    }
                },
                {
                    fullUrl: "urn:uuid:85c317d5-4e88-4a93-87e5-a095c9711b8d",
                    resource:
                    {
                        resourceType: "Communication",
                        id: "example",
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Zulip by default only displays the most current discussions. However, there is a vast searchable history of topics.</div>"
                        },
                        identifier: [
                            {
                                system: "urn:oid:1.3.4.5.6.7",
                                value: "2345678901"
                            }
                        ],
                        instantiatesUri: [
                            "http://example.org/hyperkalemia"
                        ],
                        topic: {
                            display: "Conversation title, Zulip"
                        },
                        medium: [
                            {
                                coding: [
                                    {
                                        system: "http://terminology.hl7.org/CodeSystem/v3-ParticipationMode",
                                        code: "EMAIL",
                                        display: "email"
                                    }
                                ]
                            }
                        ],
                        sent: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        received: '',
                        recipient: [
                            {
                                reference: "Patient/P02"
                            }
                        ],
                        sender: {
                            reference: "Patient/P01"
                        },
                        payload: [
                            {
                                contentString: 'Zulip by default only displays the most current discussions. However, there is a vast searchable history of topics. Before you post, pleast check if your question has already been aswered by using the powerful Zulip search.',
                            }
                        ]
                    }
                },
                {
                    fullUrl: "urn:uuid:8852eafc-4a77-4a88-b134-447dc92a8abe",
                    resource: {
                        resourceType: "Patient",
                        identifier: [
                            {
                                value: "P01",
                                system: "http://hl7.org/fhir/sid/us-ssn"
                            },
                        ],
                        name: [
                            {
                                family: "Nicolas Terry",
                                given: [
                                    "Timothy",
                                    "Paul"
                                ]
                            }
                        ],
                        gender: "male",
                        birthDate: "2008-01-06",
                        telecom: [
                            {
                                system: "phone",
                                use: "home",
                                value: "+18088675301",
                            }
                        ],
                    }
                },
                {
                    fullUrl: "urn:uuid:09c676ef-6131-4b41-9eee-f3c292d49629",
                    resource: {
                        resourceType: "Patient",
                        identifier: [
                            {
                                value: "P02",
                                system: "http://hl7.org/fhir/sid/us-ssn"
                            },
                        ],
                        name: [
                            {
                                family: "Mary Doe",
                                given: [
                                    "Granite",
                                    "Pat"
                                ]
                            }
                        ],
                        gender: "female",
                        birthDate: "2002-11-25",
                        telecom: [
                            {
                                system: "phone",
                                use: "home",
                                value: "+18023545301",
                            }
                        ],
                    }
                }
            ]
        },
        {
            resourceType: "Bundle",
            type: "message",
            entry: [
                {
                    fullUrl: "urn:uuid:85c317d5-4e88-4a93-87e5-a095c9711b8d",
                    resource: {
                        id: "2",
                        resourceType: "MessageHeader",
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Patient has very high serum potassium</div>"
                        },
                        event: {
                            system: "http://example.org/fhir/message-events",
                            code: "patient-discussion"
                        },
                        timestamp: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        source: {
                            endpoint: "http://example.org/clients/ehr-lite"
                        },
                        focus: [
                            {
                                reference: "urn:uuid:8852eafc-4a77-4a88-b134-447dc92a8abe"
                            },
                            {
                                reference: "urn:uuid:09c676ef-6131-4b41-9eee-f3c292d49629"
                            }
                        ]
                    }
                },
                {
                    fullUrl: "urn:uuid:85c317d5-4e88-4a93-87e5-a095c9711b8d",
                    resource:
                    {
                        resourceType: "Communication",
                        id: "example",
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Patient has very high serum potassium</div>"
                        },
                        identifier: [
                            {
                                system: "urn:oid:1.3.4.5.6.7",
                                value: "2345678901"
                            }
                        ],
                        instantiatesUri: [
                            "http://example.org/hyperkalemia"
                        ],
                        topic: {
                            display: "Serum Potassium Observation"
                        },
                        medium: [
                            {
                                coding: [
                                    {
                                        system: "http://terminology.hl7.org/CodeSystem/v3-ParticipationMode",
                                        code: "EMAIL",
                                        display: "email"
                                    }
                                ]
                            }
                        ],
                        sent: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        received: '',
                        recipient: [
                            {
                                reference: "Patient/P01"
                            }
                        ],
                        sender: {
                            reference: "Patient/P02"
                        },
                        payload: [
                            {
                                contentString: "Patient 1 has a very high serum potassium value (7.2 mmol/L on 2014-Dec-12 at 5:55 pm)"
                            }
                        ]
                    }
                },
                {
                    fullUrl: "urn:uuid:09c676ef-6131-4b41-9eee-f3c292d49629",
                    resource: {
                        resourceType: "Patient",
                        identifier: [
                            {
                                value: "P02",
                                system: "http://hl7.org/fhir/sid/us-ssn"
                            },
                        ],
                        name: [
                            {
                                family: "Charlotte Hale",
                                given: [
                                    "Granite",
                                    "Pat"
                                ]
                            }
                        ],
                        gender: "female",
                        birthDate: "2002-11-25",
                        telecom: [
                            {
                                system: "phone",
                                use: "home",
                                value: "+18023545301",
                            }
                        ],
                    }
                },
                {
                    fullUrl: "urn:uuid:8852eafc-4a77-4a88-b134-447dc92a8abe",
                    resource: {
                        resourceType: "Patient",
                        identifier: [
                            {
                                value: "P01",
                                system: "http://hl7.org/fhir/sid/us-ssn"
                            },
                        ],
                        name: [
                            {
                                family: "James Dalas",
                                given: [
                                    "Timothy",
                                    "Paul"
                                ]
                            }
                        ],
                        gender: "male",
                        birthDate: "2008-01-06",
                        telecom: [
                            {
                                system: "phone",
                                use: "home",
                                value: "+18088675301",
                            }
                        ],
                    }
                }
            ]
        },
    ],
    [
        {
            resourceType: "Bundle",
            type: "message",
            entry: [
                {
                    fullUrl: "urn:uuid:85c317d5-4e88-4a93-87e5-a095c9711b8d",
                    resource: {
                        id: "4",
                        resourceType: "MessageHeader",
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Patient has a very high serum potassium value</div>"
                        },
                        event: {
                            system: "http://example.org/fhir/message-events",
                            code: "patient-discussion"
                        },
                        timestamp: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        source: {
                            endpoint: "http://example.org/clients/ehr-lite"
                        },
                        focus: [
                            {
                                reference: "urn:uuid:8852eafc-4a77-4a88-b134-447dc92a8abe"
                            },
                            {
                                reference: "urn:uuid:09c676ef-6131-4b41-9eee-f3c292d49629"
                            }
                        ]
                    }
                },
                {
                    fullUrl: "urn:uuid:85c317d5-4e88-4a93-87e5-a095c9711b8d",
                    resource:
                    {
                        resourceType: "Communication",
                        id: "example",
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Patient has a very high serum potassium valuet</div>"
                        },
                        identifier: [
                            {
                                system: "urn:oid:1.3.4.5.6.7",
                                value: "2345678901"
                            }
                        ],
                        instantiatesUri: [
                            "http://example.org/hyperkalemia"
                        ],
                        topic: {
                            display: "Serum Potassium Observation"
                        },
                        medium: [
                            {
                                coding: [
                                    {
                                        system: "http://terminology.hl7.org/CodeSystem/v3-ParticipationMode",
                                        code: "EMAIL",
                                        display: "email"
                                    }
                                ]
                            }
                        ],
                        sent: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        received: '',
                        recipient: [
                            {
                                reference: "Patient/P02"
                            }
                        ],
                        sender: {
                            reference: "Patient/P01"
                        },
                        payload: [
                            {
                                contentString: 'Patient 1 has a very high serum potassium value (7.2 mmol/L on 2014-Dec-12 at 5:55 pm)',
                            }
                        ]
                    }
                },
                {
                    fullUrl: "urn:uuid:8852eafc-4a77-4a88-b134-447dc92a8abe",
                    resource: {
                        resourceType: "Patient",
                        identifier: [
                            {
                                value: "P01",
                                system: "http://hl7.org/fhir/sid/us-ssn"
                            },
                        ],
                        name: [
                            {
                                family: "James Dalas",
                                given: [
                                    "Timothy",
                                    "Paul"
                                ]
                            }
                        ],
                        gender: "male",
                        birthDate: "2008-01-06",
                        telecom: [
                            {
                                system: "phone",
                                use: "home",
                                value: "+18088675301",
                            }
                        ],
                    }
                },
                {
                    fullUrl: "urn:uuid:09c676ef-6131-4b41-9eee-f3c292d49629",
                    resource: {
                        resourceType: "Patient",
                        identifier: [
                            {
                                value: "P02",
                                system: "http://hl7.org/fhir/sid/us-ssn"
                            },
                        ],
                        name: [
                            {
                                family: "Charlotte Hale",
                                given: [
                                    "Granite",
                                    "Pat"
                                ]
                            }
                        ],
                        gender: "female",
                        birthDate: "2002-11-25",
                        telecom: [
                            {
                                system: "phone",
                                use: "home",
                                value: "+18023545301",
                            }
                        ],
                    }
                }
            ]
        },
        {
            resourceType: "Bundle",
            type: "message",
            entry: [
                {
                    fullUrl: "urn:uuid:85c317d5-4e88-4a93-87e5-a095c9711b8d",
                    resource: {
                        id: "2",
                        resourceType: "MessageHeader",
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Patient has very high serum potassium</div>"
                        },
                        event: {
                            system: "http://example.org/fhir/message-events",
                            code: "patient-discussion"
                        },
                        timestamp: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        source: {
                            endpoint: "http://example.org/clients/ehr-lite"
                        },
                        focus: [
                            {
                                reference: "urn:uuid:8852eafc-4a77-4a88-b134-447dc92a8abe"
                            },
                            {
                                reference: "urn:uuid:09c676ef-6131-4b41-9eee-f3c292d49629"
                            }
                        ]
                    }
                },
                {
                    fullUrl: "urn:uuid:85c317d5-4e88-4a93-87e5-a095c9711b8d",
                    resource:
                    {
                        resourceType: "Communication",
                        id: "example",
                        text: {
                            status: "generated",
                            div: "<div xmlns=\"http://www.w3.org/1999/xhtml\">Patient has very high serum potassium</div>"
                        },
                        identifier: [
                            {
                                system: "urn:oid:1.3.4.5.6.7",
                                value: "2345678901"
                            }
                        ],
                        instantiatesUri: [
                            "http://example.org/hyperkalemia"
                        ],
                        topic: {
                            display: "Serum Potassium Observation"
                        },
                        medium: [
                            {
                                coding: [
                                    {
                                        system: "http://terminology.hl7.org/CodeSystem/v3-ParticipationMode",
                                        code: "EMAIL",
                                        display: "email"
                                    }
                                ]
                            }
                        ],
                        sent: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        received: '',
                        recipient: [
                            {
                                reference: "Patient/P01"
                            }
                        ],
                        sender: {
                            reference: "Patient/P02"
                        },
                        payload: [
                            {
                                contentString: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?"
                            }
                        ]
                    }
                },
                {
                    fullUrl: "urn:uuid:09c676ef-6131-4b41-9eee-f3c292d49629",
                    resource: {
                        resourceType: "Patient",
                        identifier: [
                            {
                                value: "P02",
                                system: "http://hl7.org/fhir/sid/us-ssn"
                            },
                        ],
                        name: [
                            {
                                family: "Charlotte Hale",
                                given: [
                                    "Granite",
                                    "Pat"
                                ]
                            }
                        ],
                        gender: "female",
                        birthDate: "2002-11-25",
                        telecom: [
                            {
                                system: "phone",
                                use: "home",
                                value: "+18023545301",
                            }
                        ],
                    }
                },
                {
                    fullUrl: "urn:uuid:8852eafc-4a77-4a88-b134-447dc92a8abe",
                    resource: {
                        resourceType: "Patient",
                        identifier: [
                            {
                                value: "P01",
                                system: "http://hl7.org/fhir/sid/us-ssn"
                            },
                        ],
                        name: [
                            {
                                family: "James Dalas",
                                given: [
                                    "Timothy",
                                    "Paul"
                                ]
                            }
                        ],
                        gender: "male",
                        birthDate: "2008-01-06",
                        telecom: [
                            {
                                system: "phone",
                                use: "home",
                                value: "+18088675301",
                            }
                        ],
                    }
                }
            ]
        },
    ],
];

let conversations = [
    {
        id: 1,
        unread: 2,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 1,
                date: '12-24-2018',
                isSender: false,
                sender: {
                    id: 2,
                    name: 'James Dalas',
                    avatar: defaultAvatar,
                    tag: 'design',
                },
                recipient: {
                    id: 1,
                    name: 'Jane Doe',
                    avatar: 'C',
                    tag: '',
                    isCc: false,
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: false,
            },
            {
                id: 2,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Dalas',
                    avatar: defaultAvatar,
                    tag: 'sales'
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    tag: 'sales',
                    avatar: 'C',
                    isCc: false,
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: false,
            },
        ]
    },
    {
        id: 2,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 3,
                date: new Date(),
                isSender: true,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: 'sales',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: 'marketing',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
            {
                id: 4,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 2,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: '',
                },
                recipient: {
                    id: 1,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    tag: '',
                    isCc: false,
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: false,
            },
        ]
    },
    {
        id: 3,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: 's',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 4,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: '',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 5,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: 'marketing',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: 'sales',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 6,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: '',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 7,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: 'sales',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Loremo ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 8,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: 'design',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 9,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: '',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Loremo ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 10,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: '',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Loremo ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 11,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: 'sales',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
]

class InboxPage extends React.Component {

    getMessageActor = (resource, isRecipient) => {
        let user = {};
        user.id = resource.identifier[0].value;
        user.name = resource.name[0].family;
        user.avatar = 'C';
        if(isRecipient){
            user.isCc = false;
        }
        user.tag = '';
        return user;
    };

    getMessageContent = (content) => {
        let body = '';
        content.forEach(function(text){
            body = text.contentString + '\n';
        });
        return body;
    };

    fetchFHIRmessages = (conversation) => {
        let messages = [];
        for (let bundle of conversation) {
            //console.log(bundle);
            let message = {};
            message.id = bundle.entry[0].resource.id;
            message.date = bundle.entry[0].resource.timestamp;
            message.isSender = (bundle.entry[1].resource.received === undefined || bundle.entry[1].resource.received === '');
            message.sender = this.getMessageActor(bundle.entry[2].resource, false);
            message.recipient = this.getMessageActor(bundle.entry[3].resource, true);
            message.content = this.getMessageContent(bundle.entry[1].resource.payload);
            //console.log(bundle.entry[1].resource.payload);
            message.read = true;
            messages.push(message);
        }
        //console.log(messages);
        return messages;
    };

    fetchFHIRconvesations = (conversationLists) => {
        let conversations = [];
        let conversationId = 1;
        //console.log(conversationLists.length);
        for (let FHIRconversation of conversationLists) {
            //console.log(FHIRconversation);
            let conversation = {};
            conversation.id = conversationId++;
            conversation.unread = 1;
            conversation.subject = FHIRconversation[0].entry[1].resource.topic.display;
            conversation.messages = this.fetchFHIRmessages(FHIRconversation);
            conversations.push(conversation);
        }
        //console.log(conversations);
        return conversations;
    };

    render() {
        const {classes, className} = this.props;

        return (
            <div className={classNames(classes.root, className)}>

                <span className={classes.title}>Inbox</span>

                <div className={classes.inboxContainer}>

                    <Inbox menuItems={menuItems}
                        menuNotif={menuNotif}
                        tagItems={tagItems}
                        //conversations={conversations}
                        conversations={this.fetchFHIRconvesations(FHIRconversations)}
                    />

                </div>

            </div>
        );
    }
}

InboxPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

InboxPage.defaultProps = {
    className: '',
    strings: {},
};

export default withStyles(styles)(InboxPage);