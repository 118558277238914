import React, { Component } from "react";

import ResetForm from "./ResetForm";


class RightSection extends Component {

    state = {
        resetForm: {
            inputs: [
                {
                    type: "email",
                    id: "inputEmail",
                    className: "form-control inputLogin",
                    placeholder: "Email address",
                    required: true,
                    autoFocus: true
                }
            ]
        }
    };
    render() {
        const { resetForm } = this.state;
        return (
            <div
                id="right-section"
                className="col-lg-6 col-md-auto col-sm-auto col-10 rounded rounded-lg-0 bg-white pr-3 pl-3 pr-sm-5 pl-sm-5 pt-3 pb-3  d-flex justify-content-center align-items-center align-self-center flex-column"
            >
                <div className="flex-grow-1 col d-flex justify-content-center align-items-center p-0">
                    <div className="w-lg-75">
                        <ResetForm resetForm={resetForm} />
                    </div>
                </div>
                <div className="row" style={{}}>
                    <p className=" mb-0 text-muted col-lg-12 text-center termsOfUse">
                        Term of use. Privacy policy
                    </p>
                </div>
            </div>
        );
    }
}

export default RightSection;
