import { connect } from 'react-redux'
import PatientsPage from '../components/PatientsPage'


const mapStateToProps = state => {
    return {
        patients: state.patients,
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const PatientsPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientsPage)

export default PatientsPageContainer