import React, { Component } from "react";

import LeftSection from "../../../components/LeftSection";
import RightSection from "./RightSection";

class Reset extends Component {
    state = {};
    render() {
        return (
            <React.Fragment>
                <div className="container-fuild">
                    <div className="row mh-100vh m-0 justify-content-center align-items-stretch">
                        <LeftSection />
                        <RightSection />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Reset;
