import {combineReducers} from 'redux'
import {
    SET_APPOINTMENTS_FETCHING_STATUS,
    SET_APPOINTMENTS_VALUE,
} from '../actions'


const isFetching = (state = true, action) => {


    switch (action.type) {

        case SET_APPOINTMENTS_FETCHING_STATUS:

            return action.value

        default:

            return state
    }
}

const  items = (state = [], action) => {


    switch (action.type) {

        case SET_APPOINTMENTS_VALUE:

            return action.value

        default:

            return state
    }
}

export const appointments = combineReducers({
    isFetching,
    items,
})


