import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import ReactQuill from 'react-quill'
import Button from '../../Button'
import 'react-quill/dist/quill.snow.css'
import '../../../assets/css/quillEditor.css'


const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        padding: '0px',
    },
    noEditing: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: '1000px',
        height: '200px',
        padding: '20px',
        borderRadius: '4px',
        backgroundColor: '#F5F6FA',
        color: 'rgba(77, 79, 92, 1)'
    },
    replyLabel: {
        color: '#3B86FF',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    editing: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: '1000px',
        height: 'auto',
        color: 'rgba(77, 79, 92, 1)'
    },
    editor: {
        width: '50%',
        height: '100%',
    },
    button: {
        alignSelf: 'flex-end',
        width: '155px',
        height: '35px',
        marginTop: '35px',
        backgroundColor: '#3B86FF !important',
    }

});

class Reply extends React.Component {

    constructor(props) {

        super(props)
        this.state = {
        }
    }

    handleChange = (value, childName, subChildName) => {

        if (subChildName === undefined) {
            console.log(subChildName, childName, value)
            this.setState({[childName] : value})

        } else {

            let child = Object.assign({}, this.state[childName])
            child[subChildName] = value
            this.setState({[childName]: child})
        }
    }


    render() {
        const {classes, className, text, onChangeText, onSendClick, onReplyClick, editing} = this.props;

        return (
            <div className={classNames(classes.root, className)}>

                {!editing &&
                    <div className={classes.noEditing}>
                        <span>Click here to </span> &nbsp;
                        <span className={classes.replyLabel} onClick={onReplyClick}>
                            Reply
                        </span>
                    </div>

                }

                {editing &&
                    <div className={classes.editing}>

                        <ReactQuill value={text} onChange={(text) => onChangeText(text, 'editingMessage')}
                            theme="snow" modules={Reply.modules} formats={Reply.formats}
                        />

                        <Button className={classes.button} onClick={onSendClick}>Send</Button>

                    </div>
                }

            </div>
        );
    }
}

Reply.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}

Reply.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
]

Reply.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    editing: PropTypes.bool.isRequired,
    onChangeText: PropTypes.func,
    onSendClick: PropTypes.func,
    onReplyClick: PropTypes.func,
};

Reply.defaultProps = {
    className: '',
    strings: {},
    text: '',
    editing: false,
};

export default withStyles(styles)(Reply);