import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FieldTrips from '../../../../../../common/components/FieldTrip'
import classNames from 'classnames'
import Strings from '../../../../../services/strings'

const strActivities = Strings.Activities

const styles = theme => ({
    main: {
        position: 'relative',
        flexGrow: 1,
        padding: '95px 10px 40px 40px',
        backgroundColor: 'white',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        fontFamily: 'Source Sans Pro'
    },
    title: {
        color: '#43425D',
        fontSize: '30px',
        fontFamily: 'Source Sans Pro',
        marginBottom: '25px',
    },
    createButton: {
        position: 'absolute',
        top: '105px',
        left: '1450px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        width: '185px',
        border: 'none',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro !important',
        fontSize: '18px',
        textTransform: 'uppercase',
        color: 'white !important',
        backgroundColor: '#3B86FF !important',
        boxShadow: 'none !important',
        outline: '0 !important',
        cursor: 'pointer',
        [theme.breakpoints.down('lg')]: {
            left: '716px',
        }
    },
    createCard: {
        position: 'absolute',
        top: '165px',
        left: '1435px',
        zIndex: '2',
        display: 'flex',
        flexDirection: 'column',
        width: '199px',
        height: '196px',
        padding: '10px 15px 10px 15px',
        fontSize: '13px',
        color: '#4D565C',
        backgroundColor: 'white',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
        [theme.breakpoints.down('lg')]: {
            left: '701px',
        }
    },
    arrow: {
        position: 'absolute',
        display: 'flex',
        top: '-4px',
        left: '140px',
        boxShadow: '-2px -2px 1px rgba(0, 0, 0, 0.03)',
        width: '10px',
        height: '10px',
        transform: 'rotate(45deg)',
        backgroundColor: 'white',
    },
    createCardItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '43px',
        borderTop: '1px solid #F1F1F3',
        cursor: 'pointer',
        '&:hover': {
            fontSize: '14px',
            fontWeight: 'bold'
        }
    },
    createCardFirstItem: {
        fontWeight: 'bold',
        border: 'none !important'
    },
    noDisplay: {
        display: 'none !important'
    }
});

const trips = [
    {
        class: '1st Grade',
        untitled: 'Field trip at century link stadium',
        coord: '200.895.45',
        status: {
            visible: 6,
            outOfBounds: 0,
            missing: 0
        },
        students: [
            {
                name: 'Allen Johnson',
                status: 'visible'
            },
            {
                name: 'Addy Bailey',
                status: 'visible'
            },
            {
                name: 'Alex Jackson',
                status: 'visible'
            },
            {
                name: 'Jim Carey',
                status: 'visible'
            },
            {
                name: 'Mary Martin',
                status: 'visible'
            },
            {
                name: 'Ryan Carlson',
                status: 'visible'
            },
        ],
        chaperones: [
            {
                name: 'Allen Johnson',
                phone: '425.452.4152'
            },
            {
                name: 'Addy Bailey',
                phone: '425.452.4152'
            },
            {
                name: 'Alex Jackson',
                phone: '425.452.4152'
            }
        ],
        timeline: [
            {
                hour: '1:00PM',
                label: 'Class departed from school'
            },
            {
                hour: '1:45PM',
                label: 'Class arrived at Century Link Field'
            },
            {
                hour: '3:30PM',
                label: 'Class left Century Link Field'
            },
        ],
        location: { lat: 47.5951554, lng: -122.3338281 },
    },
    {
        class: '6th Grade',
        coord: '477.5.087',
        untitled: 'Field trip at Staples Center',
        status: {
            visible: 4,
            outOfBounds: 2,
            missing: 0
        },
        students: [
            {
                name: 'Allen Johnson',
                status: 'visible'
            },
            {
                name: 'Addy Bailey',
                status: 'visible'
            },
            {
                name: 'Alex Jackson',
                status: 'outOfBounds'
            },
            {
                name: 'Jim Carey',
                status: 'visible'
            },
            {
                name: 'Mary Martin',
                status: 'visible'
            },
            {
                name: 'Ryan Carlson',
                status: 'outOfBounds'
            },
        ],
        chaperones: [
            {
                name: 'Allen Johnson',
                phone: '425.452.4152'
            },
            {
                name: 'Addy Bailey',
                phone: '425.452.4152'
            },
            {
                name: 'Alex Jackson',
                phone: '425.452.4152'
            }
        ],
        timeline: [
            {
                hour: '1:00PM',
                label: 'Class departed from school'
            },
            {
                hour: '1:45PM',
                label: 'Class arrived at Staples Center'
            },
            {
                hour: '3:30PM',
                label: 'Class left Staples Center'
            },
        ],
        location: { lat: 34.0430219, lng: -118.2694428 },
    },
    {
        class: '7th Grade',
        coord: '200.895.45',
        untitled: 'Field trip at American Museum of Natural History',
        status: {
            visible: 4,
            outOfBounds: 1,
            missing: 1
        },
        students: [
            {
                name: 'Allen Johnson',
                status: 'visible'
            },
            {
                name: 'Addy Bailey',
                status: 'visible'
            },
            {
                name: 'Alex Jackson',
                status: 'outOfBounds'
            },
            {
                name: 'Jim Carey',
                status: 'missing'
            },
            {
                name: 'Mary Martin',
                status: 'visible'
            },
            {
                name: 'Ryan Carlson',
                status: 'visible'
            },
        ],
        chaperones: [
            {
                name: 'Allen Johnson',
                phone: '425.452.4152'
            },
            {
                name: 'Addy Bailey',
                phone: '425.452.4152'
            },
            {
                name: 'Alex Jackson',
                phone: '425.452.4152.'
            }
        ],
        timeline: [
            {
                hour: '1:00PM',
                label: 'Class departed from school'
            },
            {
                hour: '1:45PM',
                label: 'Class arrived at American Museum of Natural History'
            },
            {
                hour: '3:30PM',
                label: 'Class left American Museum of Natural History'
            },
        ],
        location: { lat: 40.7815556, lng: -73.9754259 },
    },
]


class ActivitiesPage extends React.Component {

    constructor(props) {
        super(props)
        this.createCardRef = React.createRef()
        this.state = {createCardVisibility: false}
    }

    handleCreateButtonClick = (e) => {
        e.preventDefault()
        if (!this.state.createCardVisibility) {
            this.setState((state) => ({
                createCardVisibility: true
            }))
        }
    }

    handleCreateActivityClick = (activity) => () => {
        const {match, history} = this.props
        if (activity === strActivities.fieldTrip) {
            history.push(match.url + '/planning-field-trip')
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideCreateCard);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideCreateCard);
    }

    handleClickOutsideCreateCard = (event) => {
        if (this.state.createCardVisibility && this.createCardRef && !this.createCardRef.current.contains(event.target)) {
            this.setState({createCardVisibility: false})
        }
    }

    render() {
        const {classes} = this.props;
        const {createCardVisibility} = this.state

        return (
            <Grid container className={classes.main}>

                <Typography noWrap className={classes.title}>
                    St Monica Parish School &nbsp;&nbsp; > &nbsp;&nbsp; Activities
                </Typography>

                <Button variant="contained" className={classes.createButton} onClick={this.handleCreateButtonClick}>
                    {strActivities.createNew}
                </Button>

                <div className={classNames(classes.createCard, {[classes.noDisplay]: !createCardVisibility})} ref={this.createCardRef}>
                    <span className={classes.arrow}/>
                    <span className={classNames(classes.createCardItem, classes.createCardFirstItem)} onClick={this.handleCreateActivityClick(strActivities.fieldTrip)}>
                        {strActivities.fieldTrip}
                    </span>
                    <span className={classes.createCardItem} onClick={this.handleCreateActivityClick(strActivities.churchCleaning)}>
                        {strActivities.churchCleaning}
                    </span>
                    <span className={classes.createCardItem} onClick={this.handleCreateActivityClick(strActivities.otherActivity)}>
                        {strActivities.otherActivity}
                    </span>
                    <span className={classes.createCardItem} onClick={this.handleCreateActivityClick(strActivities.physicalEducation)}>
                        {strActivities.physicalEducation}
                    </span>
                </div>

                <FieldTrips trips={trips}/>

            </Grid>

        );
    }
}

ActivitiesPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActivitiesPage);