import React, { Component } from "react";

import LoginForm from "./LoginForm";
import Strings from "../../../../../services/strings"

const forgotPwdColor = {
    color: "#43425D",
    fontFamily: ["Source Sans Pro", "sans-serif"],
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "13px"
};

class RightSection extends Component {
    state = {
        loginForm: {
            inputs: [
                {
                    type: "text",
                    id: "inputText",
                    className: "form-control inputLogin",
                    placeholder: "Username",
                    required: true,
                    autoFocus: true
                },
                {
                    type: "password",
                    id: "inputPassword",
                    className: "form-control inputLogin",
                    placeholder: "Password",
                    required: true,
                    autoFocus: false
                }
            ]
        }
    };


    render() {
        const { loginForm } = this.state;
        const strings = Strings.LoginPage;

        return (
            <div
                id="right-section"
                className="col-lg-6 col-md-auto col-sm-auto col-10 rounded rounded-lg-0 bg-white pr-3 pl-3 pr-sm-5 pl-sm-5 pt-3 pb-3  d-flex justify-content-center align-items-center align-self-center flex-column"
            >
                <div className="flex-grow-1 col d-flex justify-content-center align-items-center p-0">
                    <div className="w-lg-75">
                        <LoginForm loginForm={loginForm} forgotPwdColor={forgotPwdColor} />
                    </div>
                </div>
                <div className="row" style={{}}>
                    <p className=" mb-0 text-muted col-lg-12 text-center termsOfUse">
                        {strings.termsOfUse}    |    {strings.privacyPolicy}
                    </p>
                </div>
            </div>
        );
    }
}

export default RightSection;
