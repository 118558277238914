import React, { Component } from 'react'
import Async from 'react-promise'
import moment from 'moment';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Tab, Pane} from "../../../../../../common/components/Tab"
import Button from '../../../../../../common/components/Button'
import ProfileSection from './ProfileSection'
import AppointmentsSection from './AppointmentsSection'
import MedicationsSection from './MedicationsSection'
import TestResultsSection from './TestResultsSection'
import MessagesSection from './MessagesSection'
import Avatar from "@material-ui/core/Avatar/Avatar";
import defaultAvatar from "../../../../../../common/assets/img/avatar.png";
import deepOrange from "@material-ui/core/colors/deepOrange";
import Strings from '../../../../../services/strings'
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const strPatientProfile = Strings.patientProfileStrings


const styles = (theme) => ({
    wrapper: {
        display: 'flex',
        flexGrow: 1,
        height: 'auto',
        minHeight: '100vh',
        backgroundColor: 'white'
    },
    loadingContainer: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingErrorLabel: {
        color: '#43425D',
        fontSize: '26px',
        fontFamily: 'Source Sans Pro',
    },
    loadingProgress: {
        color: '#6798e5',
        animationDuration: '700ms',
    },
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        padding: '95px 10px 40px 40px',
        backgroundColor: 'white',
        minHeight: '100vh'
    },
    title: {
        color: '#43425D',
        fontSize: '36px',
        fontFamily: 'Source Sans Pro',
        textTransform: 'Capitalize',
    },
    tabWrapper: {
        width: '100%',
    },
    pane: {
        width: '100%',
        maxWidth: '1260px',
        minHeight: '660px',
    },
    info: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '1260px',
        padding: '0px',
        margin: '44px 0px 0px 0px',
    },
    personInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '500px',
        height: '110px',
        padding: '0px',
        margin: '0px',
    },
    personAvatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '150px',
    },
    mainPersonInfo: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        padding: '16px',
    },
    personName: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#4D4F5C',
        textTransform: 'Capitalize'
    },
    personPhone: {
        marginTop: '12px',
        fontSize: '24px',
    },
    appointmentInfo: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '20px 0px 0px 0px',
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#43425D',
        textTransform: 'uppercase',
    },
    avatar: {
        color: '#fff',
        backgroundColor: deepOrange[500],
        width: '108px',
        height: '108px',
        marginTop: 2,

    },
    button: {
        width: '185px',
        height: '50px',
        margin: '13px 0px',
        backgroundColor: '#3B86FF !important',
        fontSize: '18px',
        color: 'white',
    },

});

const patient = {
    name: 'Gleb Kuznetsov',
    phone: '871.567.4877',
    info: 'Seeing DR ALAN SMITH AT 1:30 PM',
    avatar: ''
}

const infoProfile = {
    gender:'Male',
    address: {address1: '18008, 3rd Avenue S', address2: 'Seattle, WA 98104'},
    email: 'gleb@mail.com',
    dateOfBirth: '3/18/1998',
    placeOfBirth: 'Seattle, WA',
    ethnicity: 'Caucasian, white',
    notes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
}

let menuItems = [

    {
        label: 'Inbox',
        name: 'inbox'
    },
    {
        label: 'Sent',
        name: 'sent'
    },
    {
        label: 'Drafts',
        name: 'drafts'
    },
    {
        label: 'Trash',
        name: 'trash'
    },
    {
        label: 'Important',
        name: 'important'
    },
    {
        label: 'Spam',
        name: 'spam'
    },
    {
        label: 'Starred',
        name: 'starred'
    },
]

let menuNotif = {
    inbox: 5,
    important: 2,
}

let tagItems = [
    {
        name: 'sales',
        label: 'Sales',
        color: '#A3A0FB'
    },
    {
        name: 'marketing',
        label: 'Marketing',
        color: '#3B86FF'
    },
    {
        name: 'design',
        label: 'Design',
        color: '#FF6565'
    },
]

let conversations = [
    {
        id: 1,
        unread: 2,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 1,
                date: '12-24-2018',
                isSender: false,
                sender: {
                    id: 2,
                    name: 'James Dalas',
                    avatar: defaultAvatar,
                    tag: 'design',
                },
                recipient: {
                    id: 1,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    tag: '',
                    isCc: false,
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: false,
            },
            {
                id: 2,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Dalas',
                    avatar: defaultAvatar,
                    tag: 'sales'
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    tag: 'sales',
                    avatar: 'C',
                    isCc: false,
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: false,
            },
        ]
    },
    {
        id: 2,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 3,
                date: new Date(),
                isSender: true,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: 'sales',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: 'marketing',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
            {
                id: 4,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 2,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: '',
                },
                recipient: {
                    id: 1,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    tag: '',
                    isCc: false,
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: false,
            },
        ]
    },
    {
        id: 3,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: 's',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 4,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: '',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 5,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: 'marketing',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: 'sales',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 6,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: '',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 7,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: 'sales',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Loremo ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 8,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: 'design',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 9,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: '',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Loremo ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 10,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: '',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Loremo ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
    {
        id: 11,
        unread: 1,
        subject: 'Conversation title, lorem ipsum',
        messages: [
            {
                id: 5,
                date: new Date(),
                isSender: false,
                sender: {
                    id: 1,
                    name: 'James Delos',
                    avatar: defaultAvatar,
                    tag: 'sales',
                },
                recipient: {
                    id: 2,
                    name: 'Charlotte Hale',
                    avatar: 'C',
                    isCc: false,
                    tag: '',
                },
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae commodi cupiditate earum eos in iste necessitatibus non nulla odio porro quibusdam quidem quis recusandae sed soluta suscipit, vitae. Corporis, eligendi?',
                read: true,
            },
        ]
    },
]

class PatientProfilePage extends Component {

    constructor(props){
        super(props)
        this.state = {
            currentPane: strPatientProfile.profile,
            patient: {},
            loading: true,
            loadingError: false,
            IsFetchingPatient: true
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getPatient();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.isFetchingPatient !== this.state.isFetchingPatient) {

            this.setState({loading: this.getLoading()})
        }
    }

    navigateToCreateAppointment() {

        const {history, match} = this.props
        history.push(match.url.replace('/view', '') + '/create-new-appointment')
    }

    getLoading() {

        return this.state.isFetchingPatient
    }

    handleActionButtonClick = () => {

        const {currentPane} = this.state

        if (currentPane.toLowerCase() === strPatientProfile.appointments.toLowerCase()) {

            this.navigateToCreateAppointment()
        }
    }

    handleChangePane = (paneTitle) =>  {
        this.setState({currentPane: paneTitle})
    };

    fetchPastAppointments = async  (url) =>  {
        try {
            let pastAppointments = [];
            let res = await fetch(url + 'fulfilled',  {
                mode: 'cors',
            });
            if (res.status === 200) {

                let jsonRes = await res.json()
                //console.log(jsonRes)

                for (let entry of jsonRes.entry) {
                    let members = entry.resource.participant;
                    let participatingPractitioner;
                    for(let member of members){
                        let ref = member.actor.reference;
                        if (ref.split('/')[0].toLowerCase() === 'practitioner'){
                            participatingPractitioner = member.actor.display;
                            break;
                        }
                    }
                    const appointment = {
                        id: entry.resource.id,
                        date: moment(entry.resource.requestedPeriod[0].end).format('L'),
                        time: moment(entry.resource.requestedPeriod[0].end).format('LT'),
                        provider: participatingPractitioner,
                        notes: entry.resource.comment,
                    };
                    pastAppointments.push(appointment);
                }
            }
            pastAppointments.sort(function(first, second){
                if (first.date === second.date){
                    return first.time < second.time ? 1 : -1;
                }
                return first.date < second.date ? 1 : -1;
            });
            return pastAppointments;
        } catch (e) {
            throw e
        }

    };

    fetchNextAppointment = async  (url) =>  {
        try {
            let nextAppointments = [];
            let res = await fetch(url + 'booked',  {
                mode: 'cors',
            });
            if (res.status === 200) {

                let jsonRes = await res.json()
                //console.log(jsonRes)

                for (let entry of jsonRes.entry) {
                    let members = entry.resource.participant;
                    let participatingPractitioner;
                    for(let member of members){
                        let ref = member.actor.reference;
                        if (ref.split('/')[0].toLowerCase() === 'practitioner'){
                            participatingPractitioner = member.actor.display;
                            break;
                        }
                    }
                    const appointment = {
                        date: moment(entry.resource.start).format('dddd MMMM Do YYYY') + ' at ' + moment(entry.resource.start).format('LT'),
                        provider: participatingPractitioner,
                    };
                    if(moment(entry.resource.start).diff(moment(new Date()), 'minutes') >= 0) {
                        nextAppointments.push(appointment);
                    }
                }
            }
            nextAppointments.sort(function(first, second){
                return first.date < second.date ? 1 : -1;
            });
            return nextAppointments[0];
        } catch (e) {
            throw e
        }

    }

    fetchAppointmentList = async  () =>  {
        try {
            let url = this.props.settings.fhirServer.baseUrl + '/appointment' + '?patient=' + this.props.match.params.patientId + '&status=';
            let pastAppointments = await this.fetchPastAppointments(url);
            let nextAppointment = await this.fetchNextAppointment(url);
            return {
                pastAppointments: pastAppointments,
                nextAppointment: nextAppointment
            };
        } catch (e) {
            throw e
        }
    };

    getPatient = async () => {

        let res = await fetch(this.props.settings.fhirServer.baseUrl + '/patient/' + this.props.match.params.patientId,  {
            mode: 'cors',
        });

        if (res.status === 200) {
            let jsonRes = await res.json();
            //console.log(jsonRes);
            let name = ''
            let gender = ''
            let dateOfBirth = ''
            let placeOfBirth = ''
            let info = ''
            let address = {
                address1: '',
                address2: ''
            }
            let avatar = ''
            let phone = ''
            let email = ''
            let ethnicity = ''
            let notes = ''

            if (jsonRes.name && jsonRes.name.length > 0) {

                if (jsonRes.name[0].family) {
                    name = jsonRes.name[0].family.charAt(0).toUpperCase() + jsonRes.name[0].family.slice(1).toLowerCase();
                }

                if (jsonRes.name[0].given && jsonRes.name[0].given.length > 0) {

                    for (let given of jsonRes.name[0].given) {

                        name += ' ' + given.charAt(0).toUpperCase() + given.slice(1).toLowerCase() + ' '
                    }
                }
            }

            if (jsonRes.gender && jsonRes.gender.length > 0) {
                gender = jsonRes.gender.charAt(0).toUpperCase() + jsonRes.gender.slice(1).toLowerCase()
            }

            if (jsonRes.address && jsonRes.address.length > 0) {
                placeOfBirth = jsonRes.address[0].text.charAt(0).toUpperCase() + jsonRes.address[0].text.slice(1).toLowerCase()
            }

            if (jsonRes.birthDate && jsonRes.birthDate.length > 0) {
                dateOfBirth = moment(jsonRes.birthDate).format('MM/DD/YYYY');
            }

            if (jsonRes.telecom && jsonRes.telecom.length > 0) {

                let phones = jsonRes.telecom.filter(item => item.system === 'phone')

                if (phones.length > 0) {
                    phone = phones[0].value
                }

                let emails = jsonRes.telecom.filter(item => item.system === 'email')

                if (emails.length > 0) {
                    email = emails[0].value
                }
            }

            if (jsonRes.address && jsonRes.address.length > 0) {

                let city = jsonRes.address[0].city ? jsonRes.address[0].city.charAt(0).toUpperCase() + jsonRes.address[0].city.slice(1).toLowerCase() : ''
                let state = jsonRes.address[0].state ? jsonRes.address[0].state.charAt(0).toUpperCase() + jsonRes.address[0].state.slice(1).toLowerCase() : ''
                let postalCode = jsonRes.address[0].postalCode ? jsonRes.address[0].postalCode : ''
                let street = (jsonRes.address[0].line && jsonRes.address[0].line.length > 0) ? jsonRes.address[0].line[0].charAt(0).toUpperCase() + jsonRes.address[0].line[0].slice(1).toLowerCase() : ''

                address = {address1: city + ', ' + state + ' ' + postalCode, address2: street}
            }

            if (jsonRes.photo && jsonRes.photo.length > 0) {

                if (jsonRes.photo[0].data && jsonRes.photo[0].data !== '') {
                    avatar = jsonRes.photo[0].data
                }

                if (jsonRes.photo[0].url && jsonRes.photo[0].url !== '') {
                    avatar = jsonRes.photo[0].url
                }
            }

            let resp = await fetch(this.props.settings.fhirServer.baseUrl + '/appointment?patient=' + this.props.match.params.patientId + '&date' + moment(new Date()).format('YYYY-MM-DD'),  {
                mode: 'cors',
            });

            if (resp.status === 200) {

                let jsonRes = await resp.json();
                //console.log('appointment', jsonRes)

                for (let entry of jsonRes.entry) {
                    let participants = entry.resource.participant;
                    let participatingPractitioner = '';
                    for (let participant of participants) {
                        let ref = participant.actor.reference;
                        if (ref.split('/')[0].toLowerCase() === 'practitioner') {
                            participatingPractitioner = participant.actor.display;
                        }
                    }
                    info = 'Seeing Dr ' + participatingPractitioner.toUpperCase() + ' AT ' + moment(entry.resource.start).format('LT');
                }
            }

            const patient = {
                name: name,
                gender: gender,
                dateOfBirth: dateOfBirth,
                placeOfBirth: placeOfBirth,
                address: address,
                info: info,
                avatar: avatar,
                phone: phone,
                email: email,
                ethnicity: ethnicity,
                notes: notes,
            };

            //console.log(patient)

            this.setState({patient: patient, isFetchingPatient: false});

        } else {

            this.setState({loadingError: 'Oups! Patient not found'})
        }
    };

    render() {
        const { classes, selectedFacility} = this.props;
        const { currentPane, loading, loadingError } = this.state
        let buttonLabel

        //console.log(this.props)

        //console.log(this.props.match.params)

        if (currentPane.toLowerCase() === strPatientProfile.profile.toLowerCase()) {
            buttonLabel = strPatientProfile.editProfile
        } else if (currentPane.toLowerCase() === strPatientProfile.appointments.toLowerCase()) {
            buttonLabel = strPatientProfile.newAppointment
        } else {
            buttonLabel = strPatientProfile.undefinedAction
        }

        return (

            <div className={classes.wrapper}>

                {loadingError &&
                    <div className={classes.loadingContainer}>
                        <span className={classes.loadingErrorLabel}>{loadingError}</span>
                    </div>
                }

                {loading && !loadingError &&
                    <div className={classes.loadingContainer}>
                        <CircularProgress
                            variant="indeterminate"
                            className={classes.loadingProgress}
                            size={24}
                            thickness={4}
                        />
                    </div>
                }

                {!loading && !loadingError &&
                    <div className={classes.root}>

                        <Typography noWrap className={classes.title}>
                            {selectedFacility.item.name}
                        </Typography>

                        <div className={classes.info}>
                            <div className={classes.personInfo}>
                                <div className={classes.personAvatarContainer}>
                                    {(this.state.patient !== '') ?
                                        <Avatar className={classes.avatar} alt={'patient'} src={this.state.patient.avatar}/> :
                                        <Avatar className={classes.avatar} alt={'patient'} src={defaultAvatar}/>
                                    }
                                </div>
                                <div className={classes.mainPersonInfo}>
                                    <span className={classes.personName}>{this.state.patient.name}</span>
                                    <span className={classes.personPhone}> {strPatientProfile.mobile} : {this.state.patient.phone} </span>
                                </div>
                            </div>
                            <div className={classes.appointmentInfo}>
                                {this.state.patient.info}
                            </div>
                        </div>

                        <Button className={classes.button} onClick={this.handleActionButtonClick}> {buttonLabel} </Button>

                        <div className={classes.tabWrapper}>
                            <Tab onChangePane={this.handleChangePane}>

                                <Pane title={strPatientProfile.profile} className={classes.pane}>
                                    <ProfileSection info={this.state.patient}/>
                                </Pane>

                                <Pane title={strPatientProfile.appointments} className={classes.pane}>
                                    <Async promise={this.fetchAppointmentList()} then={(appointmentList) =>
                                        <AppointmentsSection
                                            list={appointmentList.pastAppointments}
                                            nextAppointment={appointmentList.nextAppointment}
                                            itemPerPage={8}/>}
                                    />
                                </Pane>

                                <Pane title={strPatientProfile.messages} className={classes.pane}>
                                    <MessagesSection menuItems={menuItems} menuNotif={menuNotif} tagItems={tagItems} conversations={conversations} />
                                </Pane>

                                <Pane title={strPatientProfile.testResults} className={classes.pane}>
                                    <TestResultsSection />
                                </Pane>

                                <Pane title={strPatientProfile.medications} className={classes.pane}>
                                    <MedicationsSection />
                                </Pane>
                            </Tab>
                        </div>

                    </div>
                }
            </div>
        );
    }
}

PatientProfilePage.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PatientProfilePage)
