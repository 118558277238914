import globalContext from "./context";

export function welcomePageRedirect(props) {

    const welcomePagePath = '/dashboard-clinic/facilities'


    let {history} = props

    if (history.location.pathname.indexOf(welcomePagePath) <= -1) {
        let facility = globalContext.facility
        if (facility == null || (Array.isArray(facility) && facility.length <= 0)) {
            history.push('/dashboard-clinic/facilities')
        }
    }
}

export function isEmptyObject(obj) {

    return Object.keys(obj).length === 0 && obj.constructor === Object
}

export function objectToFormFormat (a, traditional ) {

    let buildParams = ( prefix, obj, traditional, add ) => {

        let name;  let rbracket = /\[\]$/;

        if ( Array.isArray( obj ) ) {

            obj.forEach(function( v, i ) {

                if ( traditional || rbracket.test( prefix ) ) {

                    add( prefix, v );

                } else {

                    buildParams(
                        prefix + "[" + ( typeof v === "object" && v != null ? i : "" ) + "]",
                        v,
                        traditional,
                        add
                    );
                }
            } );

        } else if ( !traditional && typeof obj === "object" ) {

            for ( name in obj ) {
                buildParams( prefix + "[" + name + "]", obj[ name ], traditional, add );
            }

        } else {

            add( prefix, obj );
        }
    }

    var prefix, s = [],

        add = function( key, valueOrFunction ) {

            var value = typeof valueOrFunction === "function" ?
                valueOrFunction() :
                valueOrFunction;

            s[ s.length ] = encodeURIComponent( key ) + "=" +
                encodeURIComponent( value == null ? "" : value );
        };


    for (prefix in a) {
        buildParams(prefix, a[prefix], traditional, add);
    }

    return decodeURIComponent(s.join( "&" ));
};