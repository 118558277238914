import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        padding: '0px',
        margin: '0px',
    },
    tagLabel: {
        marginBottom: '19px',
        fontWeight: 'bold',
    },
    list: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        margin: '0px',
        padding: '0px',
        listStyle: 'none',
    },
    tagItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        marginBottom: '15px',
        '&:last-child': {
            marginBottom: '0px'
        }
    },
    color: {
        display: 'flex',
        width: '10px',
        height: '10px',
        marginRight: '13px',
        borderRadius: '50%',
    }


});

class Tags extends React.Component {

    render() {
        const {classes, className, tagItems} = this.props;

        return (
            <div className={classNames(classes.root, className)}>
                <span className={classes.tagLabel}>Tag</span>
                <ul className={classes.list}>
                    {
                        tagItems.map((tagItem, index) => (
                            <li className={classNames(classes.tagItem)} key={index}>
                                <span className={classes.color} style={{backgroundColor: tagItem.color}}/>
                                <span className="">{tagItem.label}</span>
                            </li>
                        ))
                    }
                </ul>
            </div>
        );
    }
}

Tags.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    tagItems: PropTypes.array.isRequired,

};

Tags.defaultProps = {
    className: '',
    strings: {},
    tagItems: [],
};

export default withStyles(styles)(Tags);