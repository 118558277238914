import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '647px',
        maxWidth: '1260px',
        width: '100%',
        padding: '46px 36px',
        border: '1px solid #707070',
        borderRadius: '0px 6px 0px 0px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },

});

class MedicationsSection extends React.Component {

    render() {
        const {classes, className} = this.props;

        return (
            <div className={classNames(classes.root, className)}>

            </div>
        );
    }
}

MedicationsSection.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

MedicationsSection.defaultProps = {
    className: '',
    strings: {},
};

export default withStyles(styles)(MedicationsSection);