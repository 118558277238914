import React, { Component } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Grid, Menu, MenuItem, AppBar, Toolbar, Avatar, Input, IconButton, Typography } from '@material-ui/core';
import { IoMdArrowDropdown } from 'react-icons/io';
import Icons from '../../../assets/icon/index'
import defaultAvatar from '../../../assets/img/avatar.png'
import Strings from '../../../services/strings';
import globalContext from '../../../services/context';
import firebase from "firebase";
import { createBrowserHistory } from 'history';

const strings = Strings.navBarMenu;

const drawerWidth = 240;

const styles = (theme) => ({
	appBar: {
		// position: 'absolute',
		marginLeft: drawerWidth,
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${drawerWidth}px)`
		},
		backgroundColor: '#ffffff',
		height: "70px",
		boxShadow: "0px 2px 6px -4px rgba(0,0,0,0.4)"

	},
	toolbar: theme.mixins.toolbar,
	navIconHide: {
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25)
		},
		marginRight: theme.spacing.unit * 2,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing.unit * 3,
			width: 'auto'
		},
		marginLeft: '5px !important',
		fontFamily: 'Source Sans Pro, Regular',
		alignSelf: 'center'
	},
	searchIcon: {
		width: theme.spacing.unit * 9,
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#BCBCCB'
	},
	inputRoot: {
		color: 'inherit',
		width: '100%'
	},
	inputInput: {
		paddingTop: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		paddingBottom: theme.spacing.unit,
		paddingLeft: theme.spacing.unit * 10,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200
		}
	},
	avatar: {
		marginLeft: '10px'
	},
	sectionDesktop: {
		display: 'none',
		justifyContent: 'flex-end',
		alignItems: 'center',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		}
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	grow: {
		flexGrow: 1
	},
	font: {
		fontFamily: 'Source Sans Pro, Regular',
        marginRight: "10px",
	},
    menuWrapper: {
        border: 'none',
        boxShadow: 'none',
		outline: '0px !important'
    },
    facility: {
        borderTop: '1px solid gainsboro',
        borderBottom: '1px solid gainsboro',
    },
	badge: {
		backgroundColor: "#FFC06A",
		top: "-10px",
		right: "-8px",
		width: "8px",
		height: "8px",
		padding: "8px"
	},
	iconButton: {
		padding: '12px',
		'&:focus': {
			outline: 0,
		}
	},
	iconButtonNotification: {
		padding: '9px',
		top: '-3px',
		'&:focus': {
			outline: 0,
		}
	},
	icon: {
		width: "16px",
		height: "16px",
	},
	iconNotification: {
		width: "22px",
		height: "22px",
	},
	toolbarSecond: {
		minHeight: '70px !important',
	}
});


class PrimarySearchAppBar extends Component {

	constructor(props) {
		super(props)
		this.state = {
			anchorEl: null,
			mobileMoreAnchorEl: null,
			border: "aa",
		};
	}

	componentDidMount() {
		// get the current user

		var pSelf = this
		this.setState({user: firebase.auth().currentUser})

		firebase.auth().onAuthStateChanged(function (user) {
			if (user) {
				// user.updateProfile({
				// 	displayName:'Serge Kameni',
				// 	photoURL: "https://firebasestorage.googleapis.com/v0/b/i-m-here-99d05.appspot.com/o/users%2FwQ7N9MO9qgWLZXHoYzZ70CnRqiZ2%2Fprofile.jpg?alt=media&token=7a951389-2daf-424d-aedb-f43218da0222"
				// }).then(function() {
				// 	pSelf.setState({ user: user })
				// })
				pSelf.setState({ user: user })
			} else {
				// No user is signed in. Navigate to login

			}
		});
	}

	handleProfileMenuOpen = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleMenuClose = () => {
		this.setState({ anchorEl: null });
		this.handleMobileMenuClose();
	};

	handleMobileMenuOpen = (event) => {
		this.setState({ mobileMoreAnchorEl: event.currentTarget });

	};

	handleMobileMenuClose = () => {
		this.setState({ mobileMoreAnchorEl: null });
	};

	handleLogout = () => {
		const h = createBrowserHistory({forceRefresh: true})
		firebase.auth().signOut().then(() => {
			globalContext.clear()
			
			h.push('/')
		})
	}


	handleClick = event => {
		
	};

	render() {
		const { classes, handleDrawerToggle, facilities, selectedFacility, onFacilityClick} = this.props;

		const { anchorEl, mobileMoreAnchorEl, user } = this.state;
		const isMenuOpen = Boolean(anchorEl);
		const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

		if (this.state.user == null) {
			return null
		}

		const renderMenu = (
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={isMenuOpen}
				onClose={this.handleMenuClose}
				style={{ top: "8%" }}
			>
                {((facilities !== null || facilities !== undefined) && (facilities.length >= 2)) ?
                    <div className={classes.menuWrapper}>
						<MenuItem onClick={this.handleClose} className={classNames(classes.avatar, classes.font)}>{strings.myProfile}</MenuItem>
						<MenuItem onClick={this.handleClose} className={classNames(classes.avatar, classes.font)}> {strings.accountSettings}</MenuItem>
						 <div className={classes.facility}>
							 {facilities.map((facility, index) => {
								return(
									<MenuItem key={index} onClick={() => onFacilityClick(facility)} className={classNames(classes.avatar, classes.font)}
                                              style={facility.id === selectedFacility.id ? { fontWeight: 'bold'} : {}}>
                                        {facility.name}
                                    </MenuItem>
								);
							 })}
						 </div>
						<MenuItem onClick={this.handleLogout} className={classNames(classes.avatar, classes.font)}>{strings.logout}</MenuItem>
                    </div> :
                    <div className={classes.menuWrapper}>
                        <MenuItem onClick={this.handleClose} className={classNames(classes.avatar, classes.font)}>{strings.profile}</MenuItem>
                        <MenuItem onClick={this.handleClose} className={classNames(classes.avatar, classes.font)}> {strings.account}</MenuItem>
                        <MenuItem onClick={this.handleLogout} className={classNames(classes.avatar, classes.font)}>{strings.logout}</MenuItem>
                    </div>
                }
			</Menu>
		);

		const renderMobileMenu = (
			<Menu
				anchorEl={mobileMoreAnchorEl}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={isMobileMenuOpen}
				onClose={this.handleMobileMenuClose}
			>
				<MenuItem className={classNames(classes.avatar, classes.font)}>
					<img src={Icons['icon_help-center']} className={classes.icon} alt='help-center icon' />
					&nbsp;&nbsp;{strings.helpCenter}
				</MenuItem>
				<MenuItem className={classNames(classes.avatar, classes.font)}>
					<img src={Icons['icon_chat-notification']} className={classes.icon} alt='chat-notification icon' />
					&nbsp;&nbsp;{strings.chatRoom}
				</MenuItem>
				<MenuItem className={classNames(classes.avatar, classes.font)}>
					<img src={Icons['icon_notification']} className={classes.iconNotification} alt='notification icon' />
					<span style={{ marginLeft: '5px' }} className={classNames(classes.avatar, classes.font)}>{strings.notifications}</span>
				</MenuItem>
				<MenuItem onClick={this.handleProfileMenuOpen}>
					<IconButton
						style={{
							borderRadius: '0px',
							marginLeft: '15px',
							padding: '0px'
						}}
					>
						<MenuItem>
							<Typography variant="body2" id="" className={classNames(classes.avatar, classes.font)}>
								{user.displayName}
							</Typography>
							<IoMdArrowDropdown />
							<Avatar
								alt={user.displayName}
								src={(user.photoURL !== '' && user.photoURL !== undefined) ? user.photoURL : defaultAvatar}
								className={classNames(classes.avatar)}
							/>
						</MenuItem>
					</IconButton>
				</MenuItem>
			</Menu>
		);

		return (
			<React.Fragment>
				<AppBar className={classes.appBar}>
					<Toolbar className={classes.toolbarSecond}>

						<IconButton
							color="inherit"
							aria-label="Open drawer"
							onClick={handleDrawerToggle}
							className={classNames(classes.navIconHide, classes.iconButton)}
							style={{ color: '#3c3b54' }}
						>
							<MenuIcon />
						</IconButton>

						<Grid container>

							<Grid item container sm={6} xs={6} md={6}>
								<div className={classes.search}>
									<div className={classes.searchIcon}>
										<img src={Icons['icon_search']} className={classes.icon} alt='search icon' />
									</div>
									<Input
										placeholder={strings.searchBarPlaceHolder}
										disableUnderline
										classes={{
											root: classes.inputRoot,
											input: classes.inputInput
										}}
										style={{ color: '#43425D', marginLeft: '-20px', marginTop: "3px" }}
									/>
								</div>
							</Grid>

							<Grid item sm={6} xs={6} md={6}>
								<div className={classes.sectionDesktop}>
									<IconButton color="inherit" className={classes.iconButton} aria-label="help-center icon" onClick={this.handleClick}>
										<img src={Icons['icon_help-center']} className={classes.icon} alt='help-center icon' />
									</IconButton>
									<IconButton color="inherit" className={classes.iconButton} aria-label="chat-notification icon" onClick={this.handleClick}>
										<img src={Icons['icon_chat-notification']} className={classes.icon} alt='chat-notification icon' />
									</IconButton>
									<IconButton color="inherit" className={classes.iconButtonNotification} aria-label="chat-notification icon" onClick={this.handleClick}>
										<img src={Icons['icon_notification']} className={classes.iconNotification} alt='notification icon' />
									</IconButton>

									<IconButton
										style={{
											borderLeft: '1px solid #EBEBF2',
											borderRadius: '0px',
											marginLeft: '15px',
											padding: '0px'
										}}
										aria-haspopup="true"
									>
										<MenuItem onClick={this.handleProfileMenuOpen}>
											<Typography variant="body2" id="">
												{user.displayName}
											</Typography>
											<IoMdArrowDropdown />
											<Avatar
												alt={user.displayName}
												src={(user.photoURL !== '' && user.photoURL !== undefined) ? user.photoURL : defaultAvatar}
												className={classNames(classes.avatar)}
											/>
										</MenuItem>
									</IconButton>
								</div>

								<Grid item sm={12} xs={12} className={classes.sectionMobile}>
									<IconButton
										aria-haspopup="true"
										onClick={this.handleMobileMenuOpen}
										className={classes.iconButton}
										style={{ color: '#BCBCCB !important', right: "-80%" }}
									>
										<MoreIcon />
									</IconButton>
								</Grid>
							</Grid>

						</Grid>
					</Toolbar>
				</AppBar>

				{renderMenu}
				{renderMobileMenu}
			</React.Fragment>
		);
	}
}

PrimarySearchAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
}

PrimarySearchAppBar.defaultProps = {
	user: { name: 'username', avatar: '' }
}

export default withStyles(styles)(PrimarySearchAppBar);
