import {combineReducers} from 'redux'
import {
    SET_NO_SHOW_PATIENTS,
} from '../actions'


const  items = (state = [], action) => {

    switch (action.type) {

        case SET_NO_SHOW_PATIENTS:

            return action.patients

        default:

            return state
    }
}

export const noShowPatients = combineReducers({
    items,
})



