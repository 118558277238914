import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import moment from 'moment'
import {Avatar} from "@material-ui/core";
import defaultAvatar from "../../../assets/img/avatar.png";

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        padding: '0px',
        marginBottom: '30px',
    },
    contactInfo: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        width: '100%',
        height: '85px',
        padding: '0px 30px 30px 30px',
        cursor: 'pointer',
        borderBottom: '1px solid gainsboro',
    },
    avatarContainer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '42px',
        height: '100%',
        padding: '0px',
    },
    avatar: {
        width: '42px',
        height: '42px',
    },
    info: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: 'calc(100% - 45px)',
        height: '100%',
        marginLeft: '3px',
        padding: '5px 0px',
    },
    contactName: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    date: {
        position: 'absolute',
        top: '20px',
        right: '30px',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        width: 'auto',
        height: 'auto',
    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        padding: '30px',
        margin: '0px',
        borderBottom: '1px solid gainsboro',
    }


});

class MessageView extends React.Component {

    constructor(props) {

        super(props)
        this.state = {
            expanded: this.props.initialExpanded
        }
    }


    handleExpandClick = () => {

        this.setState((state) => ({expanded: !state.expanded}))
    }

    render() {
        const {classes, className, message} = this.props
        const {expanded} = this.state

        let contact = (message.isSender) ? message.recipient : message.sender


        return (
            <div className={classNames(classes.root, className)}>

                <div className={classNames(classes.contactInfo)} onClick={this.handleExpandClick}>
                    <div className={classes.avatarContainer}>
                        {(contact.avatar !== undefined && contact.avatar.trim() !== '' && contact.avatar === null) ?
                            <Avatar className={classes.avatar} src={contact.avatar} alt={''}/> :
                            <Avatar className={classes.avatar} src={defaultAvatar}/>
                        }
                    </div>

                    <div className={classes.info}>
                        <span className={classes.contactName}>{(message.isSender) ? 'Me' : contact.name}</span>
                        <span>to <span className={classes.contactName}>{(message.isSender) ? contact.name : ' me'}</span></span>
                    </div>
                    <div className={classes.date}>{moment(message.date).format('MMM D, YYYY')}</div>
                </div>

                {expanded &&

                    <span className={classes.contentContainer}>
                        {message.content}
                    </span>

                }



            </div>
        );
    }
}

MessageView.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    initialExpanded: PropTypes.bool.isRequired,
};

MessageView.defaultProps = {
    className: '',
    strings: {},
    message: {},
    initialExpanded: false
};

export default withStyles(styles)(MessageView);