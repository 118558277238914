import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'

const styles = theme => ({
    root: {
        /*display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '32px',
        height: '32px',
        backgroundColor: '#f50057',
        border: '2px solid white',
        borderRadius: '50%',
        fontFamily: 'Source Sans Pro',
        fontSize: '0.75rem',
        color: 'white',*/
    },
    wave: {
        position: 'relative',
        // marginTop: '50vh',
        textAlign: 'center',
        width: '100px',
        // height: '100px',
        // marginLeft: 'auto',
        // marginRight: 'auto',
    },
    dot: {
        display: 'inline-block',
        width: '8px',
        height: '8px',
        borderRadius:'50%',
        marginRight: '3px',
        background:'#bcbccb',
        animation: 'wave 0.8s linear infinite',
        '&:nth-child(2)': {
            animationDelay: '-0.7s',
        },
        '&:nth-child(3)': {
            animationDelay: '-0.6s',
        }
    },
    '@keyframes wave' : {
        '0%, 60%, 100%' : {
            transform: 'initial'
        },
        '30%' : {
            transform: 'translateY(-11px)'
        }
    }
});

class TypingIcon extends React.Component {

    render() {
        const {classes, className, color, borderColor, bgColor} = this.props;

        return (
            <span className={classes.wave}>
                <span className={classes.dot}></span>
                <span className={classes.dot}></span>
                <span className={classes.dot}></span>
            </span>
        );
    }
}

TypingIcon.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    color: PropTypes.string,
    borderColor: PropTypes.string,
    bgColor: PropTypes.string,
};

TypingIcon.defaultProps = {
    className: '',
    strings: {},
    color: '',
    borderColor: '',
    bgColor: '',
};

export default withStyles(styles)(TypingIcon);