import {combineReducers} from 'redux'
import {
    SET_SELECTED_FACILITY,
    SET_FACILITIES,
    SET_FACILITIES_FETCHING_STATUS,
    SET_SELECTED_FACILITY_CHECKED
} from '../actions'


const  isFetching = (state = true, action) => {

    switch (action.type) {

        case SET_FACILITIES_FETCHING_STATUS:

            return action.value

        default:

            return state
    }
}

const  items = (state = [], action) => {

    switch (action.type) {

        case SET_FACILITIES:

            return action.facilities

        default:

            return state
    }
}

export const facilities = combineReducers({
    isFetching,
    items,
})

const item = (state = {}, action) => {


    switch (action.type) {

        case SET_SELECTED_FACILITY:

            return action.facility

        default:

            return state
    }
}

const checked = (state = false, action) => {

    switch (action.type) {

        case SET_SELECTED_FACILITY_CHECKED:

            return action.value

        default:

            return state
    }
}

export const selectedFacility = combineReducers({
    checked,
    item,
})


