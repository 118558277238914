import {combineReducers} from 'redux'
import {
    SET_CURRENT_ORGANIZATION,
    SET_CURRENT_ORGANIZATION_FETCHING_STATUS,
} from '../actions'


const isFetching = (state = false, action) => {

    switch (action.type) {

        case SET_CURRENT_ORGANIZATION_FETCHING_STATUS:

            return action.value

        default:

            return state
    }
}

const  item = (state = {}, action) => {

    switch (action.type) {

        case SET_CURRENT_ORGANIZATION:

            return action.value

        default:

            return state
    }
}

export const currentOrganization = combineReducers({
    isFetching,
    item,
})


