import LocalizedStrings from 'react-localization';

let patientProfileStrings = new LocalizedStrings(
    {
        en: {
            companyName: 'Highland Medical Clinic',
            mobile: 'Mobile',
            editProfile: 'Edit Profile',
            newAppointment: 'New Appointment',
            undefinedAction: 'Undefined Action',
            profile: 'Profile',
            appointments: 'Appointments',
            messages: 'Messages',
            testResults: 'Test Results',
            medications: 'Medications',
        }
    }
)

let patientProfileSectionStrings = new LocalizedStrings(
    {
        en: {
            gender: 'Gender',
            address: 'Address',
            email: 'Email',
            dateOfBirth: 'Date of birth',
            placeOfBirth: 'Place of birth',
            ethnicity: 'Ethnicity',
            notes: 'Notes',
        }
    }
)

let patientAppointmentsSectionStrings = new LocalizedStrings(
    {
        en: {
            nextAppointment: 'Next appointment',
            with: 'with',
            dr: 'DR',
            pastAppointment: 'Past appointments',
            reschedule: 'RESCHEDULE',
            date: 'Date',
            time: 'Time',
            provider: 'Provider',
            notes: 'Notes',
            view: 'View'
        }
    }
)


let staffPageStrings = new LocalizedStrings(
    {
        en:{
            clinicName: 'Highland Medical Clinic',
            newStaff: 'New Staff',
            newPatient: 'New Patient',
            demographics: 'Demographics',
            primaryInsuranceInfo: 'Primary Insurance Info',
            insuranceCompany: 'Insurance Company',
            planName: 'Plan Name',
            phoneNumber: 'phone Number',
            insuredMemberName: 'Insured Member Name',
            groupId: 'Group ID',
            memberId: 'Member ID',
            copay: 'Copay',
            insuranceCard: 'Insurance Card',
            front: 'FRONT',
            back: 'BACK',
            clickToScan: 'Click to scan',
            gender: 'Gender',
            male: 'Male',
            female: 'Female',
            other: 'Other',
            firstName: 'First Name',
            middleName: 'Middle Name',
            lastName: 'Last Name',
            placeOfBirth: 'Place Of Birth',
            dateOfBirth: 'Date Of Birth',
            city: 'City',
            state: 'State',
            country: 'Country',
            takePhoto: 'Click to take photo',
            imagePath: 'Picture',
            type: 'Type',
            doctor: 'Doctor',
            nurse: 'Nurse',
            adminStaff: 'Administrative Staff',
            speciality: 'Speciality',
            maritalStatus: 'Marital Status',
            single: 'Single',
            married: 'Married',
            widowed: 'Widowed',
            homePhone: 'Home Phone',
            workPhone: 'Work Phone',
            cellPhone: 'Cell Phone',
            emailAddress: 'Email Address',
            save: 'SAVE',
            previous: '< PREVIOUS',
            next: 'NEXT >',
            insuranceInfo: 'Insurance Info',
            secondaryInsuranceInfo: 'Secondary Insurance Info',
            formsAndDocs: 'Forms & Documents'
        }
    }
)

let Strings = {
    patientProfileStrings: patientProfileStrings,
    PatientProfileSection: patientProfileSectionStrings,
    StaffPageStrings: staffPageStrings,
    PatientAppointmentsSection: patientAppointmentsSectionStrings
}

export default Strings