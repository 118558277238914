import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom'
import PrimarySearchAppBar from '../../../../common/components/PrimarySearchAppBar'
import LeftSideBar from '../../../../common/components/LeftSideBar';
import HomePage from '../../Home'
import ActivitiesPage from '../../Activities'
import ClassroomPage from '../../Classroom'
import InvoicesPage from '../../../../common/scenes/Invoices'
import InboxPage from '../../../../common/scenes/Inbox'
import EmptyPage from '../../Empty'
import String from '../../../../common/services/strings'

let strings = String.SchoolSideBar

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        height: 'auto',
        minHeight: '100vh',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
        fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif !important',
        backgroundColor: "#43425d",
    },
    main: {
        display: 'flex',
        flexGrow: 1,
        margin: '0px',
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            marginLeft: '240px',
        },
    },
});

const menuItems = [
    {
        name: 'home',
        label: strings.home,
        icon: 'icon_home',
        icon_active: 'icon_home-active',
        action: ''
    },
    {
        name: 'activities',
        label: strings.activities,
        icon: 'icon_dashboard',
        icon_active: 'icon_dashboard-active',
        action: '/activities'
    },
    {
        name: 'inbox',
        label: strings.inbox,
        icon: 'icon_inbox',
        icon_active: 'icon_inbox-active',
        action: '/inbox'
    },
    {
        name: 'products',
        label: strings.products,
        icon: 'icon_products',
        icon_active: 'icon_products-active',
        action: '/products'
    },
    {
        name: 'invoices',
        label: strings.invoices,
        icon: 'icon_Invoices',
        icon_active: 'icon_Invoices-active',
        action: '/invoices'
    },
    {
        name: 'students',
        label: strings.students,
        icon: 'icon_customers',
        icon_active: 'icon_customers-active',
        action: '/students'
    },
    {
        name: 'chatRoom',
        label: strings.chatRoom,
        icon: 'icon_chat-room',
        icon_active: 'icon_chat-room-active',
        action: '/chat-room'
    },
    {
        name: 'calendar',
        label: strings.calendar,
        icon: 'icon_calendar',
        icon_active: 'icon_calendar-active',
        action: '/calendar'
    },
    {
        name: 'helpCenter',
        label: strings.helpCenter,
        icon: 'icon_help-center',
        icon_active: 'icon_help-center-active',
        action: '/help-center'
    },
    {
        name: 'settings',
        label: strings.settings,
        icon: 'icon_setting',
        icon_active: 'icon_setting-active',
        action: '/Settings'
    },

]

const notifications = {
    home: 0,
    activities: 2,
    inbox: 0,
    providers: 0,
    invoices: 0,
    patients: 0,
    chatRoom: 0,
    calendar: 0,
    helpCenter: 0,
    settings: 0,
}

class SchoolPage extends Component {

    constructor(props) {

        super(props)

        let activeSideBarItem = this.getActiveSideBarItem()

        this.state = {
            mobileOpen: false,
            activeSideBarItem: activeSideBarItem
        };
        this.handleSideBarItemClick = this.handleSideBarItemClick.bind(this)
    }


    getActiveSideBarItem() {

        let { match, history } = this.props
        let activeSideBarItem
        let index = history.location.pathname.indexOf(match.url)

        if (index > -1) {

            let matchedStr = history.location.pathname.substring(index)
            matchedStr = matchedStr.replace(match.url, '').substring(1).split('/')

            if (matchedStr[0] !== '') {
                activeSideBarItem = matchedStr[0]
            } else {
                activeSideBarItem = 'home'
            }

        } else {
            activeSideBarItem = 'home'
        }

        return activeSideBarItem
    }

    handleDrawerToggle = () => {
        this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
        console.log(this.state.mobileOpen);
    };

    handleSideBarItemClick(menuName, menuAction) {
        this.setState({ activeSideBarItem: menuName })
        let { history, match } = this.props
        history.push(match.path + menuAction)
    }

    render() {
        const { classes, match } = this.props;
        let { mobileOpen, activeSideBarItem } = this.state

        const facility = this.props.location.state

        return (
            <div className={classes.root}>

                <PrimarySearchAppBar handleDrawerToggle={this.handleDrawerToggle} match={match} />

                <LeftSideBar menuItems={menuItems} notifications={notifications} match={match}
                    activeMenuItem={activeSideBarItem} handleDrawerToggle={this.handleDrawerToggle}
                    mobileOpen={mobileOpen} onClickMenuItem={this.handleSideBarItemClick}
                />

                <main className={classes.main}>
                    <Switch>
                        <Route path={`${match.url}`} exact render={(props) =>
                            <HomePage facility={facility} {...props} />
                        } />
                        <Route path={`${match.url}/activities`} component={ActivitiesPage} />
                        <Route path={`${match.url}/classroom`} component={ClassroomPage} exact/>
                        <Route path={`${match.url}/invoices`} component={InvoicesPage} exact/>
                        <Route path={`${match.url}/inbox`} component={InboxPage} />
                        <Route component={EmptyPage} />
                    </Switch>
                </main>

            </div>
        );
    }
}

SchoolPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SchoolPage);
