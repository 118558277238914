import LocalizedStrings from 'react-localization';

let loginPageStrings = new LocalizedStrings(
    {
        en: {
            userName: "Username",
            password: "Password",
            termsOfUse: "Terms of use",
            privacyPolicy: "Privacy Policy",
            rememberMe: "Remember me",
            forgotPassword: "Forgot Password",
            login: "Login",
            loginWithGoogle: "Login with Google",
            aquiiAdmin: "AQUII ADMIN",
            welcomeText: "Welcome! Please login to your account",
            wrongCredentialsText: "Wrong user name or password",
            wrongUserText: "You cannot login to this site with the credentials you provided. Please contact your administrator.",
            dashboard: {
                primarySearchAppBar: {

                }
            }
        }
    }
)


let resetPasswordPageStrings = new LocalizedStrings(
    {
        en: {
            aquiiAdmin: "AQUII ADMIN",
            title: "Enter your email and we send you a password reset link.",
            send: "Reset Password",
            login: "Back to Login",
            success: "Reset pasword instructions where sent to {0}",
        }
    }
)

let wrongRouteStrings = new LocalizedStrings(
    {
        en: {
            title: "Hm... You landed nowhere. Let's try this again.",
        }
    }
)

let clinicSideBarStrings = new LocalizedStrings(
    {
        en: {
            home: 'Home',
            activities: 'Activities',
            inbox: 'Inbox',
            staff: 'Staff',
            invoices: 'Invoices',
            patients: 'Patients',
            chatRoom: 'Chat Room',
            calendar: 'Calendar',
            helpCenter: 'Help Center',
            settings: 'Settings',
        }
    }
)

let schoolSideBarStrings = new LocalizedStrings(
    {
        en: {
            home: 'Home',
            activities: 'Activities',
            inbox: 'Inbox',
            products: 'Products',
            invoices: 'Invoices',
            students: 'Students',
            chatRoom: 'Chat Room',
            calendar: 'Calendar',
            helpCenter: 'Help Center',
            settings: 'Settings',
        }
    }
)

let contentMenuString = new LocalizedStrings(
    {
        en: {
            contentHeaderText: "St Monica Parish School",
            contentSecondaryText: "Students",
            showall: "Show All"
        }
    }
)

let clinicDashboardHomeStrings = new LocalizedStrings(
    {
        en: {
            contentHeaderText: "Swedish Fortania Clinic",
            contentSecondaryText: "In Waiting Room",
            showall: "SHOW ALL",
            emptyWaitingRoom: "No registered patients in waiting room"
        },
        fr: {
            contentHeaderText: "Swedish Fortania Clinic",
            contentSecondaryText: "En salle d'attente",
            showall: "TOUT AFFICHER",
            emptyWaitingRoom: "Pas de patients enregistres en salle d'attente"
        }
    }
)

let navBarMenuString = new LocalizedStrings(
    {
        en: {
            helpCenter: "Help Center",
            chatRoom: "Chat Room",
            notifications: "Notifications",
            logout: "Log Out",
            searchBarPlaceHolder: "Search...",
            profile : "Profile",
            myProfile : "My Profile",
            account : "My Account",
            accountSettings : "Account Settings",
            Logout : "Log Out"
        }
    }
)

let personListStrings = new LocalizedStrings(
    {
        en: {
            altAvatar: 'image avatar',
            mobileLabel: 'Mobile',
            searchPlaceHolder: 'Search Name…',
            altIconSearch: 'search icon',
            altIconEdit: 'edit icon',
            altIconClose: 'close icon',
            altIconInbox: 'inbox icon',
            defaultCountLabel: {one: 'Person', many: 'Persons'}
        }
    }
)

let counterCardMenuString = new LocalizedStrings(
    {
        en: {
            listOne: [
                {
                    title: "ATTENDANCE AT 9 AM",
                    number: "190",
                    percentage: "13.8",
                    color1: "#55D8FE",
                    color2: "rgba(84, 216, 255, 0.5)"
                },
                {
                    title: "ABSENCES",
                    number: "8",
                    percentage: "13.8",
                    color1: "#A3A0FB",
                    color2: "rgba(163, 160, 251, 0.5)"
                },
                {
                    title: "OFF CAMPUS",
                    number: "8",
                    percentage: "-6",
                    color1: "#5EE2A0",
                    color2: "rgba(94, 226, 160, 0.5)"
                }
            ]

        }
    }
)

let clinicCounterCardMenuString = new LocalizedStrings(
    {
        en: {
            listOne: [
                {
                    title: "PATIENTS SERVED TODAY",
                    number: "190",
                    percentage: "-11",
                    color1: "#55D8FE",
                    color2: "rgba(84, 216, 255, 0.5)"
                },
                {
                    title: "NO SHOW",
                    number: "8",
                    percentage: "13.8",
                    color1: "#A3A0FB",
                    color2: "rgba(163, 160, 251, 0.5)"
                },
                {
                    title: "IN WAITING ROOM",
                    number: "28",
                    percentage: "-6",
                    color1: "#5EE2A0",
                    color2: "rgba(94, 226, 160, 0.5)"
                }
            ]

        }
    }
)


let attendanceString = new LocalizedStrings(
    {
        en: {
            list: [
                {
                    id: "0",
                    classtitle: "PRE-K",
                    present: "25",
                    absent: "3",
                    status: "IN CAMPUS",
                    attendance: "88%",
                },
                {
                    id: "1",
                    classtitle: "1ST GRADE",
                    present: "20",
                    absent: "2",
                    status: "IN CAMPUS",
                    attendance: "90%"
                },
                {
                    id: "2",
                    classtitle: "2ND GRADE",
                    present: "25",
                    absent: "3",
                    status: "IN CAMPUS",
                    attendance: "88%"
                },
                {
                    id: "3",
                    classtitle: "3RD GRADE",
                    present: "15",
                    absent: "1",
                    status: "FIELD TRIP",
                    attendance: "93%"
                },
                {
                    id: "4",
                    classtitle: "4TH GRADE",
                    present: "12",
                    absent: "1",
                    status: "IN CAMPUS",
                    attendance: "91%"
                },
                {
                    id: "5",
                    classtitle: "5TH GRADE",
                    present: "30",
                    absent: "0",
                    status: "OUTSIDE (LOWER FIELD)",
                    attendance: "100%"
                },

            ]

        }
    }
)

let StaffListStrings = new LocalizedStrings(
    {
        en: {
            title: 'APPOINTMENTS TODAY',
            name: 'NAME',
            appointements: 'APPOINTMENTS',
            noShow: 'NO SHOW',
            available: 'AVAILABLE',
            showMore: 'SHOW MORE',
        }
    }
)

let invoicesPageStrings = new LocalizedStrings(
    {
        en: {
            invoices: 'invoices',
            invoice: 'invoice',
            print: 'print',
            invoiceTo: 'Invoice to',
            orderDate: 'order date',
            orderStatus: 'order status',
            orderId: 'order id',
            item: 'item',
            description: 'description',
            quantity: 'quantity',
            price: 'price',
            subTotal: 'Sub-total',
            discount: 'Discount',
            vat: 'vat',
            usd: 'usd',
            submit: 'submit'
        }
    }
)


let Strings = {
    LoginPage: loginPageStrings,
    NotFoundPage: wrongRouteStrings,
    ResetPassword: resetPasswordPageStrings,
    ContentMenu: contentMenuString,
    ClinicDashboardHome: clinicDashboardHomeStrings,
    navBarMenu: navBarMenuString,
    counterCardMenu: counterCardMenuString,
    ClinicCounterCardMenu: clinicCounterCardMenuString,
    ClinicSideBar: clinicSideBarStrings,
    SchoolSideBar: schoolSideBarStrings,
    PersonList: personListStrings,
    AttendanceList: attendanceString,
    StaffList: StaffListStrings,
    InvoicesPage: invoicesPageStrings
}

export default Strings