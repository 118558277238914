import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import FacilityLabels from './FacilityLabels'
import Header from "./Header";

const styles = theme => ({
    container: {
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        padding: '95px 40px 40px 40px',
        fontFamily: 'Helvetica Neue',
        fontSize: '36px',
        color: '#43425D',
        backgroundColor: '#FFFFFF',
    },
    wrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: '1703px',
        minHeight: '100vh',
    },
    tableHead: {
        display: 'flex',
        alignSelf: 'flex-start',
        width: '70%',
    },
    tableContent: {
        display: 'flex',
        justifyContent: 'center',
        width: '70%',
        fontWeight: 'bold',
        paddingTop: '122px',
        textAlign: 'center',
        opacity: '.6',
    },
    linkToNewFacility:{
        color: '#3B86FF',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
});

class Home extends React.Component{
    render() {
        const {classes, className} = this.props;
        return(
            <div className={classNames(classes.container, className)}>
                <div className={classes.wrapper}>

                    <Header name={'John'} label={'Your Facilities:'}/>

                    <div className={classes.tableHead}>
                        <FacilityLabels title={'Name & Address'} manager={'Manager'} contact={'Contact'}/>
                    </div>

                    <div className={classes.tableContent}>
                        <span>Please add a <span onClick={this.handleActionButtonClick}  className={classes.linkToNewFacility}> new facility</span> to get started</span>
                    </div>

                </div>
            </div>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

Home.defaultProps = {
    className: '',
    strings: {},
};
export default withStyles(styles)(Home);