import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../../components/Button'
import classNames from 'classnames'
import logo from '../../../assets/img/logo.png';
import creditCardImage from '../../../assets/img/credit-card.png';
// import paypalLogo from '../../../assets/img/paypal-logo.png';
// import amazonLogo from '../../../assets/img/amazon-logo.png';
import visaLogo from '../../../assets/img/visa-logo.png'
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@material-ui/core";
import validate from "validate.js";
import {objectToFormFormat} from '../../../services/Util'
import firebase from 'firebase'


const styles = theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh',
        // height: '1080px',
        width: '100%',
        fontFamily: 'Source Sans Pro',
        fontSize: '24px',
        color: '#707070',
    },
    header: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        height: '158px',
        width: '100%',
        borderBottom: '1px solid #707070',
    },
    logoTenkolo: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        width: '200px',
        height: '100px',
        marginLeft: '64px',
    },
    logo: {
        width: '200px',
        height: '100px',
    },
    headerTitle: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignSelf: 'flex-start',
        width: '534px',
        height: '97px',
        marginTop: '57px',
        textAlign: 'center',
    },
    top: {
        fontSize: '36px',
        margin: '0px'
    },
    bottom: {
        fontSize: '30px',
        margin: '4px 0px 0px 0px'
    },
    special: {
        color: '#3B86FF',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    help: {
        display: 'flex',
        alignSelf: 'flex-end',
        width: 'auto',
        height: '97px',
        marginRight: '33px',
        fontSize: '26px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#3B86FF',
        '&:last-child': {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginBottom: '7.5px'
        },
    },
    steeper: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'stretch',
        height: '61px',
        width: '1011px',
        margin: '34.5px 0px 66px 0px',
    },
    stepLabelLabel: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        color: '#4D4F5C !important',
    },
    stepLabelActive: {
        color: '#4D4F5C !important',
        fontWeight: 'bold !important'
    },
    stepLabelIconActive: {
        color: '#3B86FF !important',
    },
    stepLabelIconCompleted: {
        color: '#3B86FF !important',
    },
    content: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        width: '1216px',
        height: 'auto',
    },
    sectionTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '13.5px',
        marginBottom: '34.5px',
        borderBottom: '1px solid #707070',
        fontWeight: 'bold'
    },
    controlLabelRoot: {
        marginBottom: '0px',
    },
    controlLabelLabel: {
        fontFamily: 'Source Sans Pro',
        fontSize: '26px',
        color: '#707070',
    },
    paymentMethodContainer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        margin: '5px 0px 11px 0px',
        padding: '0px 155px 0px 155px'
    },
    paymentMethodImgContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '260px',
        height: '120px',
        padding: '39px 90px',
        margin: '0px',
        borderRadius: '6px',
    },
    paymentMethodImg: {
        width: '80px',
        height: 'auto',
        minHeight: '30px',
        cursor: 'pointer',
    },
    paymentMethodSelected: {
        border: '2px solid #BCE0FD',
    },
    infoContainer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '820px',
        height: 'auto',
        fontFamily: 'arial',
        color: '#2699FB',
    },
    paymentInfoContainer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '330px',
        height: 'auto',
    },
    infoTitle: {
        marginBottom: '30px',
        fontWeight: 'bold',
    },
    infoLine: {
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '100%',
        marginBottom: '20px',
    },
    label: {
        margin: '0px 0px 8px 0px',
        fontSize: '10px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    input: {
        width: '100%',
        height: '48px',
        padding: '0px 15px',
        border: '1px solid #BCE0FD',
        borderRadius: '4px',
        fontSize: '14px',
        color: '#2699FB',
        '&:focus': {
            outline: 0,
        }
    },
    errorLabel: {
        display: 'flex',
        width: '100%',
        fontSize: '13px',
        color: "#D7373F"
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100px',
        height: '48px',
        border: '1px solid #BCE0FD',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    cardNumberInput: {
        width: '219px',
        marginRight: '8px',
    },
    row: {
        display: 'flex',
        flexFlow: 'row nowrap',
        width: '100%'
    },
    middleInput: {
        width: 'calc(50% - 4px)',
        '&:last-child': {
            marginLeft: '8px'
        }
    },
    formControlSelect: {
        margin: '0px',
        // minWidth: 120,
    },
    noDisplay: {
        display: 'none !important'
    },
    selectRoot: {
        width: '394px',
        height: '43px',
        padding: '0px 0px',
        border: 'none',
        borderRadius: '4px',
        fontSize: '14px',
        color: "#2699FB",
    },
    selectRootCard: {
        width: '100px',
        height: '43px',
        padding: '0px 0px',
        border: 'none',
        borderRadius: '4px',
        fontSize: '14px',
        color: "#2699FB",
    },
    selectSelect: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '0px 15px',
        border: '1px solid #BCE0FD',
        borderRadius: '4px',
        fontFamily: 'arial',
        fontSize: '14px',
        boxSizing: 'border-box',
        '&:focus': {
            border: '1px solid #BCE0FD',
            borderRadius: '4px',
            backgroundColor: 'white'
        }
    },
    selectNotchedOutline: {
        border: 'none',
    },
    selectItem: {
        padding: '10px 15px',
        fontFamily: 'arial',
        fontSize: '14',
        color: '#2699FB'
    },
    cvvLine: {
        width: '50%',
    },
    optionContainer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
    },
    totalAmountContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        width: '820px',
        height: 'auto',
        padding: '20px 30px',
        margin: '18px 0px 0px 0px',
        borderRadius: '4px',
        backgroundColor: '#F1F9FF',
        fontFamily: 'arial',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#2699FB',
    },
    infoAmountContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        width: '820px',
        height: 'auto',
        padding: '0px 30px',
        margin: '11px 0px 0px 0px',
        fontFamily: 'arial',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#707070',
        textTransform: 'uppercase',
    },
    infoTotalAmount: {
        color: '#2699FB',
    },
    footer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '1216px',
        height: 'auto',
        margin: '32px 0px 50px 0px',
    },
    backButton: {
        height: '50px',
        width: '185px',
        marginLeft: '12px',
        fontSize: '18px',
        textTransform: 'uppercase',
        backgroundColor: '#3B86FF !important',
    },
    purchaseButton: {
        height: '50px',
        width: '185px',
        marginLeft: '12px',
        fontSize: '18px',
        textTransform: 'uppercase',
        backgroundColor: '#0D891C !important'
    },
    purchaseButtonDisabled: {
        height: '50px',
        width: '185px',
        marginLeft: '12px',
        fontSize: '18px',
        textTransform: 'uppercase',
        backgroundColor: 'rgba(0, 0, 0, 0.12) !important'
    },

    errorBorder: {
        border: '1px solid #D7373F !important'
    },
    errorColor: {
        color: '#D7373F !important',
    },
});

const countries = [
    'Angola',
    'Belgium',
    'Canada',
    'United State',
]

const steps = [ 'YOUR ORGANIZATION', 'YOUR PLAN', 'PAYMENT', 'FINISHED' ];

const erroFormatlMessage = 'This field is not to the good format'
const errorRequiredMessage = 'This field is required'

class RegistrationPage3 extends React.Component {

    constructor(props) {

        super(props);

        let {plan, onChange, currentStep} = this.props

        if (currentStep < 3) {
            this.handleBackClick(currentStep)
        }

        let planName = 'premium'

        if (this.props.match.params.plan !== undefined && this.props.match.params.plan.toLowerCase() === 'starter') {
            planName = 'starter'
        } else if (this.props.match.params.plan !== undefined && this.props.match.params.plan.toLowerCase() === 'pro') {
            planName = 'pro'
        }

        if (planName !== plan.name) {
            onChange('plan', {name: planName})
        }

        this.state = {
            // next: false,
            next: true,
            errors: {},
        }

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.billInfo !== this.props.billInfo || prevProps.cardInfo !== this.props.cardInfo) {

            this.softValidation()
        }
    }

    navigateToStep4() {
        const {history, match} = this.props
        history.push(`${match.url.replace('step-3', 'step-4')}`)
    }

    navigateToPreviousStep(step) {
        const {history, match} = this.props
        history.push(`${match.url.replace('step-3', 'step-' + step)}`)
    }

    softValidation() {

        const {billInfo, cardInfo} = this.props

        let input = {billInfo, cardInfo}

        let constraints = {
            'billInfo.fullname': {
                presence: {allowEmpty: false}
            },
            'billInfo.address': {
                presence: {allowEmpty: false}
            },
            'billInfo.city': {
                presence: {allowEmpty: false}
            },
            'billInfo.zipCode': {
                presence: {allowEmpty: false}
            },
            'billInfo.country': {
                presence: {allowEmpty: false}
            },

            'cardInfo.cardNumber': {
                presence: {allowEmpty: false}
            },
            'cardInfo.cardType': {
                presence: {allowEmpty: false}
            },
            'cardInfo.cardholder': {
                presence: {allowEmpty: false}
            },
            'cardInfo.expireDate': {
                presence: {allowEmpty: false}
            },
            'cardInfo.cvv': {
                presence: {allowEmpty: false}
            },
        }

        let errors = validate(input, constraints)

        if (errors) {

            // this.setState({next: false})
            this.setState({next: true})

        } else {

            this.setState({next: true})
        }
    }

    hardValidation() {

        const {billInfo, cardInfo} = this.props

        let input = {billInfo, cardInfo}

        let constraints = {
            'billInfo.fullname': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'billInfo.address': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'billInfo.city': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'billInfo.zipCode': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'billInfo.country': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },

            'cardInfo.cardNumber': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
                format: {
                    pattern: /(\d|\s)*/,
                    message: erroFormatlMessage
                }
            },
            'cardInfo.cardType': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'cardInfo.cardholder': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'cardInfo.expireDate': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'cardInfo.cvv': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },

        }

        let errors = validate(input, constraints, {fullMessages: false})

        let error = {}

        if (errors) {

            for (let key in errors) {

                let keys = key.split('.')

                if (!error.hasOwnProperty(keys[0])) {
                    error[keys[0]] = {}
                }

                error[keys[0]][keys[1]] = errors[key][0]
            }
        }

        this.setState({errors: error})

        return !errors
        // return true
    }

    generateOrganizationFHIR() {

        const {organization, primaryContact} = this.props

        let org = {
            resourceType: 'Organization',
            // identifier: [],
            // active: true,
            // type: [],
            name: organization.name.toLowerCase(),
            // alias: [],
            telecom: [
                {
                    system:'phone',
                    value: organization.phone,
                    use: 'work',
                },
            ],
            address: [
                {
                    use: 'work',
                    type: 'both',
                    line: [
                        organization.address.toLowerCase(),
                        organization.address2.toLowerCase()
                    ],
                    city: organization.city.toLowerCase(),
                    state: organization.state.toLowerCase(),
                    postalCode: organization.postalCode.toLowerCase(),
                    country: organization.country.toLowerCase(),
                }
            ],
            // partOf: {},
            contact: [
                {
                    // purpose: {},
                    name: {
                        use: 'official',
                        text: primaryContact.firstName.toLowerCase() + ' ' + primaryContact.lastName.toLowerCase(),
                        family: primaryContact.lastName.toLowerCase(),
                        given: [primaryContact.firstName.toLowerCase()],
                        prefix: [primaryContact.salutation.toLowerCase()],
                        // suffix: [],
                        // period: {}
                    },
                    telecom: [
                        {
                            system:'phone',
                            value: primaryContact.phone
                        },
                        {
                            system:'email',
                            value: primaryContact.email.toLowerCase()
                        },
                    ]
                }
            ],
            // endpoint: []
        }

        return org
    }

    generateAccountFHIR(organizationRef) {

        let {plan, planPrice, paymentMethod, billInfo, cardInfo, organization} = this.props

        let account = {
            resourceType: 'Account',
            meta: {
                profile: {
                    plan,
                    planPrices: planPrice,
                    paymentMethod,
                    billInfo,
                    cardInfo,
                }
            },
            // identifier: [],
            status: true,
            // type: {},
            name: 'Account: ' + organization.name.toLowerCase(),
            subject:
                {
                    reference: 'Organization/' + organizationRef,
                    display: organization.name.toLowerCase(),
                }
            ,
            // servicePeriod: {},
            // coverage: [],
            // owner: {},
            // description: {},
            // guarantor: {},
            // partOf: {},
        }

        return account
    }


    handlePurchaseClick = async () => {

        let validation = this.hardValidation()

        if (validation) {

            const {settings} = this.props

            let res = await fetch(settings.fhirServer.baseUrl + '/organization',  {
                method: 'POST',
                mode: 'cors',
                body: objectToFormFormat(this.generateOrganizationFHIR()),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (res.status === 201) {

                let location = res.headers.get('location');
                location = location.split('/');
                let organizationId = location[location.length - 1];

                let resAccount = await fetch(settings.fhirServer.baseUrl + '/account',  {
                    method: 'POST',
                    body: objectToFormFormat(this.generateAccountFHIR(organizationId)),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });

                await firebase.firestore().collection('Settings').add({
                    fhirServer: {
                        baseUrl: 'http://localhost:3000/3_0_1'
                    },
                    organization: {
                        reference: 'Organization/' + organizationId
                    }
                })

                if (resAccount.status === 201) {

                    this.props.onChange('currentStep', 4)
                    this.navigateToStep4()
                }

            } else {


            }

        }
    }

    handleBackClick = (step) => {
        this.navigateToPreviousStep(step)
    }


    render() {
        const {next, errors} = this.state
        const {classes, className, plan, paymentMethod, billInfo, cardInfo, onChange, planPrice} = this.props
        const proximityTotalAmount = plan.proximitySensorsQuantity * planPrice.proximitySensorsMonthlyAmount
        const checkInPadsTotalAmount = plan.checkInPadsQuantity * planPrice.checkInPadsMonthlyAmount
        const totalMonthlyAmount = proximityTotalAmount + checkInPadsTotalAmount + planPrice.featureAmount


        return (
            <div className={classNames(classes.root, className)}>

                <div className={classes.header}>
                    <div className={classes.logoTenkolo}>
                        <img src={logo} alt="logo" className={classes.logo}/>
                    </div>

                    <div className={classes.headerTitle}>
                        <span className={classes.top}>AQUII - Subscription</span>
                    </div>

                    <span className={classes.help}>Need help? Call us at (844) 250-9500</span>
                </div>

                <div className={classes.steeper}>
                    <Stepper activeStep={2} alternativeLabel>
                        {steps.map(label => {
                            return (
                                <Step key={label}>
                                    <StepLabel
                                        StepIconProps={{
                                            classes : {
                                                active: classes.stepLabelIconActive,
                                                completed: classes.stepLabelIconCompleted,
                                            }
                                        }}
                                        classes={{
                                            label: classes.stepLabelLabel,
                                            active: classes.stepLabelActive,
                                        }}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>

                <div className={classes.content}>

                    <div className={classes.sectionTitle}>
                        <span>Payment</span>
                    </div>

                    <div className={classes.paymentMethodContainer}>
                        <div className={classNames(classes.paymentMethodImgContainer, {[classes.paymentMethodSelected]: paymentMethod === 'card'})}>
                            <img src={creditCardImage} alt="Credit Card" className={classes.paymentMethodImg} onClick={() => onChange('paymentMethod', 'card')}/>
                        </div>
                        {/*<div className={classNames(classes.paymentMethodImgContainer, {[classes.paymentMethodSelected]: paymentMethod === 'paypal'})}>
                            <img src={paypalLogo} alt="Credit Card" className={classes.paymentMethodImg} onClick={() => onChange('paymentMethod', 'paypal')}/>
                        </div>
                        <div className={classNames(classes.paymentMethodImgContainer, {[classes.paymentMethodSelected]: paymentMethod === 'amazon'})}>
                            <img src={amazonLogo} alt="Credit Card" className={classes.paymentMethodImg} onClick={() => onChange('paymentMethod', 'amazon')}/>
                        </div>*/}
                    </div>

                    <div className={classes.infoContainer}>
                        <div className={classes.paymentInfoContainer}>
                            <span className={classes.infoTitle}>Billing Info</span>

                            <div className={classes.infoLine}>
                                <label htmlFor="fullname" className={classes.label}>FULL NAME</label>
                                <input type="text" className={classNames(classes.input, {[classes.errorBorder]: (errors.billInfo && errors.billInfo.fullname)})}
                                       id="fullname" value={billInfo.fullname} onChange={(e) => onChange('billInfo', {fullname: e.target.value})}
                                />

                                {(errors.billInfo && errors.billInfo.fullname) &&
                                    <span className={classes.errorLabel}>{errors.billInfo.fullname}</span>
                                }
                            </div>

                            <div className={classes.infoLine}>
                                <label htmlFor="address" className={classes.label}>ADDRESS</label>
                                <input type="text" className={classNames(classes.input, {[classes.errorBorder]: (errors.billInfo && errors.billInfo.address)})}
                                       id="address" value={billInfo.address} onChange={(e) => onChange('billInfo', {address: e.target.value})}
                                />

                                {(errors.billInfo && errors.billInfo.address) &&
                                    <span className={classes.errorLabel}>{errors.billInfo.address}</span>
                                }
                            </div>

                            <div className={classes.row}>
                                <div className={classNames(classes.infoLine, classes.middleInput)}>
                                    <label htmlFor="city" className={classes.label}>CITY</label>
                                    <input type="text" className={classNames(classes.input, {[classes.errorBorder]: (errors.billInfo && errors.billInfo.city)})}
                                           id="city" value={billInfo.city} onChange={(e) => onChange('billInfo', {city: e.target.value})}
                                    />

                                    {(errors.billInfo && errors.billInfo.city) &&
                                        <span className={classes.errorLabel}>{errors.billInfo.city}</span>
                                    }
                                </div>

                                <div className={classNames(classes.infoLine, classes.middleInput)}>
                                    <label htmlFor="zipCode" className={classes.label}>ZIP CODE</label>
                                    <input type="text" className={classNames(classes.input, {[classes.errorBorder]: (errors.billInfo && errors.billInfo.zipCode)})}
                                           id="zipCode" value={billInfo.zipCode} onChange={(e) => onChange('billInfo', {zipCode: e.target.value})}
                                    />
                                    {(errors.billInfo && errors.billInfo.zipCode) &&
                                        <span className={classes.errorLabel}>{errors.billInfo.zipCode}</span>
                                    }
                                </div>
                            </div>

                            <div className={classes.infoLine}>
                                <label htmlFor="country" className={classes.label}>COUNTRY</label>
                                <FormControl variant="outlined" className={classes.formControlSelect}>
                                    <InputLabel
                                        ref={ref => {
                                            this.InputLabelRef = ref;
                                        }}
                                        htmlFor="country"
                                        classes={{root: classes.noDisplay}}
                                    />
                                    <Select
                                        value={billInfo.country}
                                        onChange={(e) => onChange('billInfo', {country: e.target.value})}
                                        input={
                                            <OutlinedInput
                                                labelWidth={0}
                                                id="country"
                                                classes={{
                                                    notchedOutline: classes.selectNotchedOutline
                                                }}
                                            />
                                        }

                                        classes={{
                                            root: classes.selectRoot,
                                            select: classNames(classes.selectSelect, {[classes.errorBorder]: (errors.billInfo && errors.billInfo.country)}),
                                        }}
                                    >
                                        {
                                            countries.map((country, index) =>(
                                                <MenuItem key={index} value={country} className={classes.selectItem}> {country} </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>

                                {(errors.billInfo && errors.billInfo.country) &&
                                    <span className={classes.errorLabel}>{errors.billInfo.country}</span>
                                }
                            </div>
                        </div>
                        
                        <div className={classes.paymentInfoContainer}>
                            <span className={classes.infoTitle}>Credit Card Info</span>

                            <div className={classes.infoLine}>
                                <label htmlFor="cardnumber" className={classes.label}>CARD NUMBER</label>
                                <div className={classes.row}>
                                    <input type="text" className={classNames(classes.input, classes.cardNumberInput, {[classes.errorBorder]: (errors.cardInfo && errors.cardInfo.cardNumber)})}
                                           id="cardnumber"  value={cardInfo.cardNumber} onChange={(e) => onChange('cardInfo', {cardNumber: e.target.value})}
                                    />
                                    <span className={classes.cardContainer}>
                                        <img src={visaLogo} alt="card"/>
                                    </span>
                                </div>
                                {(errors.cardInfo && errors.cardInfo.cardNumber) &&
                                    <span className={classes.errorLabel}>{errors.cardInfo.cardNumber}</span>
                                }
                            </div>

                            <div className={classes.infoLine}>
                                <label htmlFor="cardholder" className={classes.label}>CARDHOLDER NAME</label>
                                <input type="text" className={classNames(classes.input, {[classes.errorBorder]: (errors.cardInfo && errors.cardInfo.cardholder)})}
                                       id="cardholder" value={cardInfo.cardholder} onChange={(e) => onChange('cardInfo', {cardholder: e.target.value})}
                                />

                                {(errors.cardInfo && errors.cardInfo.cardholder) &&
                                    <span className={classes.errorLabel}>{errors.cardInfo.cardholder}</span>
                                }
                            </div>

                            <div className={classes.infoLine}>
                                <label htmlFor="expireDate" className={classes.label}>expire date</label>
                                <input type="month" className={classNames(classes.input, {[classes.errorBorder]: (errors.cardInfo && errors.cardInfo.expireDate)})}
                                       id="expireDate" value={cardInfo.expireDate}  onChange={(e) => onChange('cardInfo', {expireDate: e.target.value})}
                                />

                                {(errors.cardInfo && errors.cardInfo.expireDate) &&
                                    <span className={classes.errorLabel}>{errors.cardInfo.expireDate}</span>
                                }
                            </div>

                            <div className={classNames(classes.infoLine, classes.cvvLine)}>
                                <label htmlFor="cvv" className={classes.label}>CVV</label>
                                <input type="text" className={classNames(classes.input, {[classes.errorBorder]: (errors.cardInfo && errors.cardInfo.cvv)})}
                                       id="cvv" value={cardInfo.cvv} onChange={(e) => onChange('cardInfo', {cvv: e.target.value})}
                                />

                                {(errors.cardInfo && errors.cardInfo.cvv) &&
                                    <span className={classes.errorLabel}>{errors.cardInfo.cvv}</span>
                                }
                            </div>
                        </div>
                    </div>

                    <div className={classes.optionContainer}>
                        <div className={classes.totalAmountContainer}>
                            <span>MONTHLY TOTAL AMOUNT</span>
                            <span>$ {Number(totalMonthlyAmount).toFixed(2)}</span>
                        </div>

                        <div className={classes.infoAmountContainer}>
                            FIRST MONTH BILL WILL INCLUDE ONE TIME SETUP FEE, FOR A TOTAL OF &nbsp;
                            <span className={classes.infoTotalAmount}>$ {Number(Number(totalMonthlyAmount) + Number(planPrice.setupFeeAmount)).toFixed(2)}</span>
                        </div>
                    </div>

                </div>

                <div className={classes.footer}>

                    <Button className={classNames(classes.backButton)} onClick={() => this.handleBackClick(2)}>
                        &lt; BACK
                    </Button>
                    <Button className={classNames(classes.purchaseButton)} disabledClassName={classes.purchaseButtonDisabled} onClick={this.handlePurchaseClick} disabled={!next}>
                        Purchase
                    </Button>
                </div>

            </div>
        );
    }
}

RegistrationPage3.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

RegistrationPage3.defaultProps = {
    className: '',
    strings: {},
};

export default withStyles(styles)(RegistrationPage3);