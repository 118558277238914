import {combineReducers} from 'redux'
import {
    SET_STAFF_FETCHED_STATUS,
    SET_STAFF
} from '../actions'


const fetched = (state = false, action) => {

    switch (action.type) {

        case SET_STAFF_FETCHED_STATUS:

            return action.value

        default:

            return state
    }
}

const items = (state = [], action) => {

    switch (action.type) {

        case SET_STAFF:

            return action.staff

        default:

            return state
    }
}

export const staff = combineReducers({
    fetched,
    items,
})



