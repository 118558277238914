import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import Button from "@material-ui/core/Button/Button";
import firebase from 'firebase';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const styles = theme => ({
    generalWrapper: {
        display: 'flex',
        flexGrow: 1,
        height: 'auto',
        minHeight: '100vh',
        backgroundColor: 'white'
    },
    loadingContainer: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingErrorLabel: {
        position: 'relative',
        bottom: '60px',
        color: '#43425D',
        fontSize: '26px',
        fontFamily: 'Source Sans Pro',
    },
    loadingProgress: {
        position: 'relative',
        bottom: '60px',
        color: '#6798e5',
        animationDuration: '700ms',
    },
    container: {
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        padding: '95px 40px 40px 40px',
        fontFamily: 'Helvetica Neue',
        fontSize: '36px',
        color: '#43425D',
        backgroundColor: '#FFFFFF',
    },
    wrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        maxWidth: '1703px',
        minHeight: '100vh',
    },
    salutation: {
        display: 'flex',
        alignSelf: 'flex-start',
        textAlign: 'left',
        fontSize: '36px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderBottom: '2px solid #707070',
    },
    settingWrapper: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '90%',
        fontSize: '25px',
        marginTop: '20px',
    },
    inputLabel: {
        width: '10%',
        marginBottom: '0px',
        textAlign: 'right',
        color: "#707070",
    },
    inputField: {
        width: '30%',
        height: '43px',
        marginLeft: '25px',
        padding: '0px 15px',
        border: '1px solid #707070',
        borderRadius: '4px',
        fontSize: '16px',
        color: "#707070",
        '&:focus': {
            border: '1px solid #3B86FF',
            outline: 0,
        },
    },
    button: {
        width: '100px',
        height: '43px',
        marginLeft: '25px',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro !important',
        fontSize: '18px',
        textAlign: 'center',
        textTransform: 'capitalize',
        color: 'white !important',
        backgroundColor: '#43425D !important',
        boxShadow: 'none !important',
        cursor: 'pointer',
        outline: '0px !important',
    },
});

const urlRegex = RegExp(/^(http[s]?:\/\/([a-zA-Z])+(:(\d)+)?)\/(\d_){2}[0-9]/);

class settings extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

            loading: true,
            loadingError: false,

            settings: {
                baseUrl: '',
            }
        };
    }

    componentDidMount() {

        const {settings} = this.props

        if (settings.isFetching) {

            this.initialize()

        } else {

            this.setState({settings: settings.item, loading: false})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {


        if (prevProps.settings.isFetching !== this.props.settings.isFetching) {

            this.setState({loading: this.props.settings.isFetching})
        }
    }

    async initialize() {

        try {

            let snaps = await firebase.firestore().collection('Settings').limit(1).get();

            let settings = [];

            snaps.forEach((snap) => {
                settings.push(snap.data())
            })

            if (settings.length < 0) {

                this.setState({loadingError: 'Oups, something went wrong !'})

            } else {

                this.props.onChangeSettings(settings[0])
                this.props.onChangeSettingsFetchingStatus(false);
                this.setState({settings: settings[0]})
            }

        } catch (e) {

            console.log('Settings error : ', e)
            this.setState({loadingError: 'Oups, something went wrong !'})
        }
    }

    handleInputChange = (event) => {
        event.preventDefault();
        let {settings} = this.state;
        let newSettings = Object.assign({}, settings, {baseUrl: event.target.value});
        this.setState({settings: newSettings});
        console.log(this.state.settings.baseUrl, urlRegex.test(this.state.settings.baseUrl));
    };

    handleSaveButton = () => {
        let {settings} = this.state;
        if(urlRegex.test(settings.baseUrl)) {
            firebase.firestore().collection('Settings').doc('fhirServerSettings').set(settings)
                .then(() => {
                    console.log('inserted');
                    // this.props.onChangeSettings(settings);
                    this.props.onChangeSettingsFetchingStatus(true);
                    this.initialize()
                })
                .catch((error) => {
                    console.log('error: ' + error);
                });
        }
    };
    render() {
        const {classes, className} = this.props;
        const {settings, loading, loadingError} = this.state;
        console.log(this.props);

        return(
            <div className={classes.generalWrapper}>

                {loadingError &&
                    <div className={classes.loadingContainer}>
                        <span className={classes.loadingErrorLabel}>{loadingError}</span>
                    </div>
                }

                {loading && !loadingError &&
                    <div className={classes.loadingContainer}>
                        <CircularProgress
                            variant="indeterminate"
                            className={classes.loadingProgress}
                            size={24}
                            thickness={4}
                        />
                    </div>
                }

                {!loading && !loadingError &&

                    <div className={classNames(classes.container, className)}>
                        <div className={classes.wrapper}>

                            <div className={classes.header}>
                                <span className={classes.title}>{'FHIR Server Settings'}</span>
                            </div>

                            <div className={classes.settingWrapper}>
                                <label className={classes.inputLabel} htmlFor={'baseUrl'}>{'Base url'}</label>
                                <input onChange={this.handleInputChange} className={classes.inputField} type="text" id={'baseUrl'} value={settings.baseUrl}/>
                                <Button onClick={this.handleSaveButton} className={classes.button}>{'Save'}</Button>
                            </div>

                        </div>
                    </div>

                }
            </div>

        );
    }
}

settings.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

settings.defaultProps = {
    className: '',
    strings: {},
};
export default withStyles(styles)(settings);