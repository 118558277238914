import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'

const styles = theme => ({

    root: {
        position: 'relative',
        display: 'flex',
        width: '370px',
        height: '133px',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Source Sans Pro',
        color: 'rgba(67, 66, 93, 0.5)',
        backgroundColor: 'white',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)',
    },
    title: {
        position: 'absolute',
        left: '20px',
        top: '21px',
        fontSize: '15px',
        opacity: '50%',
        textTransform: 'uppercase',
    },
    content: {
        fontSize: '50px',
        fontWeight: 'bold',
    },

});

class Card extends React.Component {

    constructor(props) {

        super(props)
        this.state = {}
    }

    render() {
        const {classes, title, content, color, className} = this.props;

        return (
            <div className={classNames(classes.root, className)}>
                <span className={classes.title}> {title} </span>
                <span className={classes.content} style={{color: color}}> {content} </span>
            </div>
        );
    }
}

Card.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
};

Card.defaultProps = {
    title: '',
    content: '',
    color: '',
};

export default withStyles(styles)(Card);