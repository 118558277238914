import {CHANGE_REGISTRATION_INFO} from '../actions'

export default function registration (state = {}, action) {

    switch (action.type) {

        case CHANGE_REGISTRATION_INFO:

            if (typeof action.value === 'object' && action.value !== null) {

                return Object.assign({}, state, {[action.info]: Object.assign({}, state[action.info], action.value)})

            } else {

                return Object.assign({}, state, {[action.info]: action.value})
            }

        default:
            return state
    }
}


