import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../../components/Button'
import classNames from 'classnames'
import logo from '../../../assets/img/logo.png';
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import OptionCard from "./OptionCard";

const styles = theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh',
        height: '1080px',
        width: '100%',
        fontFamily: 'Source Sans Pro',
        fontSize: '24px',
        color: '#707070',
    },
    header: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        height: '158px',
        width: '100%',
        borderBottom: '1px solid #707070',
    },
    logoTenkolo: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        width: '200px',
        height: '100px',
        marginLeft: '64px',
    },
    logo: {
        width: '200px',
        height: '100px',
    },
    headerTitle: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignSelf: 'flex-start',
        width: '534px',
        height: '97px',
        marginTop: '57px',
        textAlign: 'center',
    },
    top: {
        fontSize: '36px',
        margin: '0px'
    },
    bottom: {
        fontSize: '30px',
        margin: '4px 0px 0px 0px'
    },
    special: {
        color: '#3B86FF',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    help: {
        display: 'flex',
        alignSelf: 'flex-end',
        width: 'auto',
        height: '97px',
        marginRight: '33px',
        fontSize: '26px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#3B86FF',
        '&:last-child': {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginBottom: '7.5px'
        },
    },
    steeper: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'stretch',
        height: '61px',
        width: '1011px',
        margin: '34.5px 0px 66px 0px',
    },
    stepLabelLabel: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        color: '#4D4F5C !important',
    },
    stepLabelActive: {
        color: '#4D4F5C !important',
        fontWeight: 'bold !important'
    },
    stepLabelIconActive: {
        color: '#3B86FF !important',
    },
    stepLabelIconCompleted: {
        color: '#3B86FF !important',
    },
    content: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '1216px',
        height: 'auto',
    },
    sectionTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '13.5px',
        marginBottom: '34.5px',
        borderBottom: '1px solid #707070',
        fontWeight: 'bold'
    },
    planLabel: {
        marginLeft: '27px',
        fontSize: '26px',
        fontWeight: 'bold',
        color: '#3B86FF',
        textTransform: 'uppercase',
    },
    radioGroup: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        marginLeft: 'auto',
        marginRight: '60px',
        padding: '0px 0px',
        width: 'auto',
        height: 'auto',
        fontSize: '26px',
        color: "#707070",
    },
    noDisplay: {
        display: 'none !important'
    },
    controlLabelRoot: {
        marginBottom: '0px',
    },
    controlLabelLabel: {
        fontFamily: 'Source Sans Pro',
        fontSize: '26px',
        color: '#707070',
    },
    radioColor: {
        color: '#3B86FF !important',
    },
    optionContainer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
    },
    optionCard: {
        marginBottom: '13px',
    },
    totalAmountContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        width: '820px',
        height: 'auto',
        padding: '20px 30px',
        margin: '18px 0px 0px 0px',
        borderRadius: '4px',
        backgroundColor: '#F1F9FF',
        fontFamily: 'arial',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#2699FB',
    },
    infoAmountContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        width: '820px',
        height: 'auto',
        padding: '0px 30px',
        margin: '11px 0px 0px 0px',
        fontFamily: 'arial',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#707070',
        textTransform: 'uppercase',
    },
    infoTotalAmount: {
        color: '#2699FB',
    },
    footer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '1216px',
        height: 'auto',
        margin: '32px 0px 50px 0px',
    },
    backButton: {
        height: '50px',
        width: '185px',
        marginLeft: '12px',
        fontSize: '18px',
        textTransform: 'uppercase',
        backgroundColor: '#3B86FF !important',
    },
    nextButtonAbsolute: {
        position: 'absolute',
        right: '0px',
        top: '-48px',
    },
});

const steps = [ 'YOUR ORGANIZATION', 'YOUR PLAN', 'PAYMENT', 'FINISHED' ];

class RegistrationPage2 extends React.Component {

    constructor(props) {

        super(props);

        let {plan, onChange, currentStep} = this.props

        if (currentStep < 2) {
            this.handleBackClick(currentStep)
        }

        let planName = 'premium'

        if (this.props.match.params.plan !== undefined && this.props.match.params.plan.toLowerCase() === 'starter') {
            planName = 'starter'
        } else if (this.props.match.params.plan !== undefined && this.props.match.params.plan.toLowerCase() === 'pro') {
            planName = 'pro'
        }

        if (planName !== plan.name) {
            onChange('plan', {name: planName})
        }

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    navigateToStep3() {
        const {history, match} = this.props
        history.push(`${match.url.replace('step-2', 'step-3')}`)
    }

    navigateToPreviousStep(step) {
        const {history, match} = this.props
        history.push(`${match.url.replace('step-2', 'step-' + step)}`)
    }

    handleNextClick = () => {
        this.props.onChange('currentStep', 3)
        this.navigateToStep3()
    }

    handleBackClick = (step) => {
        this.navigateToPreviousStep(step)
    }


    render() {
        const {classes, className, plan, onChange, planPrice} = this.props
        const proximitySensorsTotalAmount = plan.proximitySensorsQuantity * planPrice.proximitySensorsMonthlyAmount
        const checkInPadsTotalAmount = plan.checkInPadsQuantity * planPrice.checkInPadsMonthlyAmount
        const totalMonthlyAmount = proximitySensorsTotalAmount + checkInPadsTotalAmount + planPrice.featureAmount


        return (
            <div className={classNames(classes.root, className)}>

                <div className={classes.header}>
                    <div className={classes.logoTenkolo}>
                        <img src={logo} alt="logo" className={classes.logo}/>
                    </div>

                    <div className={classes.headerTitle}>
                        <span className={classes.top}>AQUII - Subscription</span>
                    </div>

                    <span className={classes.help}>Need help? Call us at (844) 250-9500</span>
                </div>

                <div className={classes.steeper}>
                    <Stepper activeStep={1} alternativeLabel>
                        {steps.map(label => {
                            return (
                                <Step key={label}>
                                    <StepLabel
                                        StepIconProps={{
                                            classes : {
                                                active: classes.stepLabelIconActive,
                                                completed: classes.stepLabelIconCompleted,
                                            }
                                        }}
                                        classes={{
                                            label: classes.stepLabelLabel,
                                            active: classes.stepLabelActive,
                                        }}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>

                <div className={classes.content}>

                    <div className={classes.sectionTitle}>
                        <span>Your Plan:</span>
                        <span className={classes.planLabel}>AQIII {plan.name} PLAN</span>
                        <RadioGroup aria-label="period" name="period" className={classes.radioGroup} value={plan.period} onChange={ (e) => onChange('plan', {period: e.target.value})} id="period">
                            <FormControlLabel
                                value="monthly"
                                control={<Radio classes={{root: classes.radioColor, checked: classes.radioColor }}/>}
                                label="Monthly"
                                labelPlacement="end"
                                classes={{
                                    root: classes.controlLabelRoot,
                                    label: classes.controlLabelLabel,
                                }}
                            />
                            <FormControlLabel
                                value="annually"
                                control={<Radio classes={{root: classes.radioColor, checked: classes.radioColor }}/>}
                                label="Annually"
                                labelPlacement="end"
                                classes={{
                                    root: classes.controlLabelRoot,
                                    label: classes.controlLabelLabel,
                                }}
                            />
                        </RadioGroup>
                    </div>

                    <div className={classes.optionContainer}>
                        <OptionCard className={classes.optionCard} title={'PREMIUM FEATURES'} info1={'6000 check ins per month'} info2={'20 service calls'}
                                    totalAmount={Number(planPrice.featureAmount)}

                        />
                        <OptionCard className={classes.optionCard} title={'PROXIMITY SENSORS'} info1={'We recommend 3 sensors'} info2={'for each waiting room'}
                                    monthlyAmount={Number(planPrice.proximitySensorsMonthlyAmount)} quantity={Number(plan.proximitySensorsQuantity)}
                                    totalAmount={Number(proximitySensorsTotalAmount)} onChangeQuantity={(e) => onChange('plan', {proximitySensorsQuantity: Number(e.target.value)})}
                        />
                        <OptionCard className={classes.optionCard} title={'CHECK-IN PADS'} info1={'We recommend 1 device for every 3 seats'} info2={'in your waiting room'}
                                    monthlyAmount={Number(planPrice.checkInPadsMonthlyAmount)} quantity={Number(plan.checkInPadsQuantity)}
                                    totalAmount={Number(checkInPadsTotalAmount)}  onChangeQuantity={(e) => onChange('plan', {checkInPadsQuantity: Number(e.target.value)})}
                        />
                        <OptionCard className={classes.optionCard} title={'ONE TIME SETUP FEE'}
                                    totalAmount={Number(planPrice.setupFeeAmount)}
                        />

                        <div className={classes.totalAmountContainer}>
                            <span>MONTHLY TOTAL AMOUNT</span>
                            <span>$ {Number(totalMonthlyAmount).toFixed(2)}</span>
                        </div>

                        <div className={classes.infoAmountContainer}>
                            FIRST MONTH BILL WILL INCLUDE ONE TIME SETUP FEE, FOR A TOTAL OF &nbsp;
                            <span className={classes.infoTotalAmount}>$ {Number(Number(totalMonthlyAmount) + Number(planPrice.setupFeeAmount)).toFixed(2)}</span>
                        </div>
                    </div>

                </div>

                <div className={classes.footer}>

                    <Button className={classNames(classes.backButton)} onClick={() => this.handleBackClick(1)}>
                        &lt; BACK
                    </Button>
                    <Button className={classNames(classes.backButton)} onClick={this.handleNextClick}>
                        NEXT &gt;
                    </Button>
                </div>

            </div>
        );
    }
}

RegistrationPage2.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

RegistrationPage2.defaultProps = {
    className: '',
    strings: {},
};

export default withStyles(styles)(RegistrationPage2);