import React, {Component} from 'react'
import { Switch, Route } from 'react-router-dom'
import moment from 'moment'
import ActivitiesPage from '../scenes/Activities'
import PlanningFieldTripPage from '../scenes/Planning'
import EmptyPage from '../../Empty'

const trips = [
    {
        class: '1st Grade',
        date: moment('2018-10-29')
    },
    {
        class: '6th Grade',
        status: {
            outOfBounds: 2,
        }
    },
    {
        class: '7h Grade',
    },
]

class Routing extends Component {

    render() {

        const {match} = this.props

        return (
            <Switch>
                <Route path={`${match.url}`} component={ActivitiesPage} exact />
                <Route path={`${match.url}/planning-field-trip`} exact render={(props) =>
                    <PlanningFieldTripPage trips={trips} {...props} />
                } />
                <Route component={EmptyPage} />
            </Switch>
        )
    }
}

export default Routing


