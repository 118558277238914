import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'

const styles = theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '820px',
        height: '106px',
        padding: '23px 30px',
        border: '1px solid #BCE0FD',
        borderRadius: '4px',
        fontFamily: 'arial',
        fontSize: '20px',
        color: '#2699FB',
    },
    infoContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
        width: '265px',
    },
    title: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    info1: {
        fontSize: '14px',
    },
    info2: {
        fontSize: '14px',
    },
    monthlyAmount: {
        position: 'relative',
        bottom: '5px',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        height: 'auto',
        width: 'auto',
        padding: '0px 48px 10px 0px',
        borderBottom: '1px solid #2699FB',
        fontSize: '14px',
    },
    quantity: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        height: 'auto',
        width: '104px',
    },
    noDisplay: {
        display: 'none !important'
    },
    quantityInput: {
        padding: '0px 0px',
        border: 'none',
        borderBottom: '1px solid #2699FB',
        borderRadius: '0px',
        fontSize: '14px',
        color: '#2699FB',
        outline: 0,
        '&:focus': {
            borderBottom: '2px solid #2699FB',
        },
        '&:hover': {
            borderBottom: '2px solid #2699FB',
        }
    },
    totalAmountContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        height: '100%',
        width: 'auto',
        fontWeight: 'bold',
    },

});




class OptionCard extends React.Component {

    render() {
        const {classes, className, title, info1, info2, monthlyAmount, quantity, totalAmount, onChangeQuantity} = this.props;

        return (
            <div className={classNames(classes.root, className)}>

                <div className={classes.infoContainer}>
                    <span className={classes.title}> {title} </span>
                    {info1 !== undefined &&
                        <span className={classes.info1}> {info1} </span>
                    }
                    {info2 !== undefined &&
                        <span className={classes.info2}> {info2} </span>
                    }
                </div>

                {monthlyAmount !== undefined &&
                    <div className={classes.monthlyAmount}>
                        ${Number(monthlyAmount).toFixed(2)} per month
                    </div>
                }

                {quantity !== undefined &&
                    <div className={classes.quantity}>
                        <input type="number" min={0} className={classes.quantityInput} value={quantity} onChange={(e) => onChangeQuantity(e)}/>
                    </div>
                }

                {totalAmount !== undefined &&
                    <div className={classes.totalAmountContainer}>
                        $ {Number(totalAmount).toFixed(2)}
                    </div>
                }

            </div>
        );
    }
}

OptionCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    info1: PropTypes.string,
    info2: PropTypes.string,
    monthlyAmount: PropTypes.number,
    quantity: PropTypes.number,
    totalAmount: PropTypes.number,
    onChangeQuantity: PropTypes.func,
};

OptionCard.defaultProps = {
    className: '',
    strings: {},
    title: '',
};

export default withStyles(styles)(OptionCard);