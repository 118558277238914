import { connect } from 'react-redux'
import { changeRegistrationInfo } from '../../../services/actions'
import RegistrationPage from '../components/RegistrationPage4'


const mapStateToProps = state => {
    return {
        plan: state.registration.plan,
        currentStep: state.registration.currentStep
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (info, value) => {
            dispatch(changeRegistrationInfo(info, value))
        }
    }
}

const RegistrationPage4 = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationPage)

export default RegistrationPage4