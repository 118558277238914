import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import moment from 'moment'
import {Avatar} from '@material-ui/core'
import defaultAvatar from '../../../assets/img/avatar.png'

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
    },
    contactInfo: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        width: '100%',
        height: '85px',
        padding: '15px 7px 15px 0px',
        cursor: 'pointer',
        borderBottom: '1px solid gainsboro',
        '&:hover': {
            backgroundColor: 'rgba(77, 79, 92, 0.04)'
        }
    },
    selected: {
        backgroundColor: 'rgba(77, 79, 92, 0.08) !important'
    },
    avatarContainer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '15%',
        height: '100%',
        padding: '0px',
    },
    avatar: {
        width: '42px',
        height: '42px',
    },
    info: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '79%',
        height: '100%',
        marginLeft: '1%',
        padding: '5px 0px',
    },
    contactName: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    message: {
        width: '95%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    date: {
        position: 'absolute',
        top: '20px',
        right: '7px',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        width: 'auto',
        height: 'auto',
    },

});

class ConversationList extends React.Component {

    render() {
        const {classes, className, list, selectedConversation, tags, onChange} = this.props

        return (
            <div className={classNames(classes.root, className)}>
                
                {list.length > 0 &&
                    list.map((conversation, index) => {
                        
                        let avatar, name, tag
                        
                        if (conversation.messages[0].isSender) {
                            let recipient = conversation.messages[0].recipient
                            avatar = recipient.avatar
                            name = recipient.name

                            if (recipient.tag !== undefined && recipient.tag !== null && recipient.tag.trim() !== '') {
                                tag = tags.find((tag) => tag.name.toLowerCase() === recipient.tag.toLowerCase())
                            }
                        } else {
                            let sender = conversation.messages[0].sender
                            avatar = sender.avatar
                            name = sender.name

                            if (sender.tag !== undefined && sender.tag !== null && sender.tag.trim() !== '') {
                                tag = tags.find((tag) => tag.name.toLowerCase() === sender.tag.toLowerCase())
                            }
                        }

                        let subject = conversation.subject
                        let style = tag !== undefined ? {borderLeft: '3px solid ' + tag.color} : {}
                        let selected = selectedConversation !== null && Number(selectedConversation.id) === Number(conversation.id)
                        let date = moment(conversation.messages[0].date)

                        return (
                            <div className={classNames(classes.contactInfo, {[classes.selected]: selected})} key={index}
                                 onClick={() => onChange(conversation)} style={style}
                            >
                                <div className={classes.avatarContainer}>
                                    {(avatar !== undefined && avatar.trim() !== '' && avatar === null) ?
                                        <Avatar className={classes.avatar} src={avatar} alt={''}/> :
                                        <Avatar className={classes.avatar} src={defaultAvatar}/>
                                    }

                                </div>
                                <div className={classes.info}>
                                    <span className={classes.contactName}>{name}</span>
                                    <span className={classes.message}>{subject}</span>
                                </div>
                                <div className={classes.date}>{date.format('MMM D')}</div>
                            </div>
                        )
                    })
                }

            </div>
        );
    }
}

ConversationList.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    selectedConversation: PropTypes.object,
    tags: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

ConversationList.defaultProps = {
    className: '',
    strings: {},
    list: [],
    selectedConversation: null,
    tags: [],
};

export default withStyles(styles)(ConversationList);