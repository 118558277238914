import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import ButtonMaterial from '@material-ui/core/Button'
import classNames from 'classnames'

const styles = theme => ({
    root: {
        display: 'flex',
        minWidth: '100px',
        height: '35px',
        minHeight: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 20px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#43425D !important',
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontWeight: 'normal',
        textTransform: 'capitalize',
        color: 'white',
        boxShadow: 'none !important',
        outline: '0 !important',
        cursor: 'pointer'
    },
});

class Button extends React.Component {

    handleClick = () => {
        this.props.onClick()
    }

    render() {
        const {classes, className, disabledClassName, disabled} = this.props;

        return (
            <ButtonMaterial className={classNames(classes.root, {[className]: !disabled || (disabled && !disabledClassName)}, {[disabledClassName]: disabled} )} onClick={this.handleClick} disabled={disabled}>
                {this.props.children}
            </ButtonMaterial>
        );
    }
}

Button.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    disabledClassName: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

Button.defaultProps = {
    className: '',
    disabled: false,
};

export default withStyles(styles)(Button);