import React from 'react';
// import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import {Typography} from '@material-ui/core';
import Strings from '../../../services/strings';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.4)"
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    row: {
        borderBottom: '1px solid #F1F1F3',
        height: '50px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F1F1F3'
        }
    },
    cell: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        color: '#4D4F5C',
        padding: '0 0 0 20px',
        border: 'none'
    },
});

class AttendanceList extends React.Component {

    constructor(props) {

        super(props)

        this.state = {
            rows: Strings.AttendanceList.list,
            page: 0,
            rowsPerPage: 5,
        };
    }


  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClick = (id) => {
    console.log('attendance list handle click')
  }

  onMouseEnter = () => {
    document.body.style.cursor = "pointer";
  }
  onMouseLeave = () => {
    document.body.style.cursor = "default";
  }

  render() {
    const { classes } = this.props;
    const { rows, rowsPerPage, page } = this.state;
    
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    
    const CustomTableCell = withStyles(theme => ({
        head: {
            backgroundColor: "#F5F6FA",
            color: "#A3A6B4",
            fontSize: 13,
            fontWeight: 'bold',
            textTransform: "uppercase",
            fontFamily: 'Source Sans Pro, Regular',
            border: 'none !important'
        },
        body: {
          fontSize: 13,
          color: "#674D47",
          textTransform: "uppercase",
          fontFamily: 'Source Sans Pro, Regular'
        },
      }))(TableCell);

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
            <Typography noWrap className={classes.title} style={{color: "#4D4F5C", textTransform: "uppercase", float: "left", padding: "0px 0px 25px 2px", fontSize: "18px", fontFamily: 'Source Sans Pro, Regular'}}>
				ATTENDANCE
			</Typography>
          <Table className={classes.table}>
            <TableHead>
                <TableRow className={classes.head}>
                    <CustomTableCell style={{textAlign: "left", width: '20%', paddingRight: '20px'}}>CLASS</CustomTableCell>
                    <CustomTableCell numeric style={{textAlign: "center", width: '20%', paddingRight: '20px'}}>PRESENT</CustomTableCell>
                    <CustomTableCell numeric style={{textAlign: "center", width: '20%', paddingRight: '20px'}}>ABSENT</CustomTableCell>
                    <CustomTableCell numeric style={{textAlign: "center", width: '20%', paddingRight: '20px'}}>STATUS</CustomTableCell>
                    <CustomTableCell numeric style={{textAlign: "center", width: '20%', paddingRight: '20px'}}>ATTENDANCE</CustomTableCell>
                </TableRow>
            </TableHead>
            <TableBody style={{fontSize: 13, color: "#674D47", textTransform: "uppercase", fontFamily: 'Source Sans Pro, Regular'}}>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                return (
                  <TableRow className={classes.row} key={row.id} onClick={() => this.handleClick(row.id)} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                    <TableCell component="th" scope="row" className={classes.cell} style={{color: "#4D4F5C", textAlign: "left"}}>
                      {row.classtitle}
                    </TableCell>
                    <TableCell numeric className={classes.cell} style={{color: "#4D4F5C", textAlign: "center"}}>{row.present}</TableCell>
                    <TableCell numeric className={classes.cell} style={{color: "#4D4F5C", textAlign: "center"}}>{row.absent}</TableCell>
                    <TableCell numeric className={classes.cell} style={{color: "#674D47", textAlign: "center"}}>{row.status}</TableCell>
                    <TableCell numeric className={classes.cell} style={{color: "#4D4F5C", textAlign: "center"}}>{row.attendance}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

AttendanceList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AttendanceList);