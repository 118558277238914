import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Student from './Student'
import Card from './Card'
import classNames from 'classnames'
import Strings from '../../../services/strings'

const strStudent = Strings.Student
const strClassroom = Strings.Classroom

const styles = (theme) => ({
    main: {
        flexGrow: 1,
        padding: '95px 10px 40px 40px',
        backgroundColor: 'white',
        alignItems: 'flex-start',
        alignContent: 'flex-start',

    },
    title: {
        width: '100%',
        color: '#43425D',
        fontSize: '30px',
        fontFamily: 'Source Sans Pro, Regular',
        marginBottom: '25px',
    },
    card: {
        marginRight: '30px',
    },
    content: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        marginTop: '40px',
        justifyContent: 'flex-start',
        alignItems: 'stretch'
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        width: '402px',
        marginBottom: '100px',
        fontFamily: 'Source Sans Pro',
        color: '#4D4F5C',
    },
    listHead: {
        display: 'flex',
        flexDirection: 'column',
        width: '402px',
        height: '71px',
        border: 'none',
    },
    listTitle: {
        fontSize: '30px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
    },
    redTitle: {
        color: '#EA380D'
    },
    greenTitle: {
        color: '#317E58'
    },
    listSubTitle: {
        marginLeft: '10px',
        fontSize: '18px',
        color: '#B0B4D1'
    },
    listBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexGrow: 1,
        width: '402px',
        paddingTop: '50px',
        borderLeft: '1px solid #4D4F5C',
    },
    listBlank: {
        width: '400px',
        height: '50px',
        border: 'none',
        backgroundColor: 'inherit',
    },
    borderLeft: {
        position: 'relative',
        left: '1px',
        width: '400px !important'
    }

});

const attendingStudent = [
    {firstName: 'Allisson', lastName: 'Wally'},
    {firstName: 'Amber', lastName: 'Jackson'},
    {firstName: 'Jack', lastName: 'Ben'},
    {firstName: 'Richard', lastName: 'Black'},
    {firstName: 'Jim', lastName: 'Carson'},
    {firstName: 'Irina', lastName: 'Dawson'}
]

const absentStudent = [
    {firstName: 'Bill', lastName: 'Paterson'},
    {firstName: 'Laura', lastName: 'King'},
    {firstName: 'Ryan', lastName: 'Mary'},
    {firstName: 'Bush', lastName: 'Jonny'},
    {firstName: 'Olivia', lastName: 'McCain'},
    {firstName: 'John', lastName: 'Doe'}
]


class Classroom extends Component {

    render() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.main}>

                <Typography noWrap className={classes.title}>
                    St Monica Parish School Pre-K
                </Typography>

                <Card title={strClassroom.attendance} content="190" color="#317E58" className={classes.card}/>
                <Card title={strClassroom.abscences} content="8" color="#EA380D" className={classes.card}/>
                <Card title={strClassroom.staff} content="6" color="#4D4F5C"/>

                <div className={classes.content}>

                    <div className={classes.list}>
                        <div className={classes.listHead}>
                            <span className={classNames(classes.listTitle, classes.redTitle)}>{strClassroom.attendance}</span>
                            <span className={classes.listSubTitle}>{strClassroom.clkCheckOut}</span>
                        </div>
                        <div className={classes.listBody}>
                            {
                                attendingStudent.map((item, index) => (
                                    <Student strings={strStudent} className={classes.borderLeft}
                                             name={item}
                                             attending={true}
                                             statusText='Checked-in by Mom at 7:31am'
                                             parents={[
                                                 {name:'Dawson, Jon', phone: '425-425-425', email: 'djon@test.com'},
                                                 {name:'Dawson, Mary', phone: '425-425-425', email: 'djon@test.com'}
                                             ]}
                                    />
                                ))
                            }
                        </div>
                    </div>

                    <div className={classes.list}>
                        <div className={classes.listHead}>
                            <span className={classNames(classes.listTitle, classes.greenTitle)}>{strClassroom.absent}</span>
                            <span className={classes.listSubTitle}>{strClassroom.clkCheckIn}</span>
                        </div>
                        <div className={classes.listBody}>
                            {
                                absentStudent.map((item, index) => (
                                    <Student strings={strStudent} className={classes.borderLeft}
                                             name={item}
                                             attending={false}
                                             statusText='Checked-in by Mom at 7:31am'
                                             parents={[
                                                 {name:'Dawson, Jon', phone: '425-425-425', email: 'djon@test.com'},
                                                 {name:'Dawson, Mary', phone: '425-425-425', email: 'djon@test.com'}
                                             ]}
                                    />
                                ))
                            }
                        </div>
                    </div>

                    <div className={classes.list}>
                        <div className={classes.listHead}>
                            <span className={classes.listTitle}>{strClassroom.staff}</span>
                        </div>
                        <div className={classes.listBody}>
                            <Student strings={strStudent} className={classes.borderLeft}
                                     name={{firstName: 'Jane', lastName: 'Kelly'}}
                                     attending={true}
                                     statusText='Teacher'
                            />
                            <Student strings={strStudent} className={classes.borderLeft}
                                     name={{firstName: 'Mary', lastName: 'Wing'}}
                                     attending={true}
                                     statusText='Teacher'
                            />
                            <Student strings={strStudent} className={classes.borderLeft}
                                     name={{firstName: 'Maria', lastName: 'Esposito'}}
                                     attending={true}
                                     statusText='Assistance'
                            />
                            <Student strings={strStudent} className={classes.borderLeft}
                                     name={{firstName: 'Richard', lastName: 'Black'}}
                                     attending={true}
                                     statusText='Principal'
                            />
                            <Student strings={strStudent} className={classes.borderLeft}
                                     name={{firstName: 'Jim', lastName: 'Carson'}}
                                     attending={true}
                                     statusText='Main Office'
                            />
                            <Student strings={strStudent} className={classes.borderLeft}
                                     name={{firstName: 'Irina', lastName: 'Dawson'}}
                                     attending={true}
                                     statusText='Security'
                            />
                        </div>
                    </div>

                </div>
            </Grid>

        );
    }
}

Classroom.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Classroom)
