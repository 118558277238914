import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {withStyles} from '@material-ui/core/styles';
import photo from '../../../../../../common/assets/img/photo.png'
import Strings from "../../../../../services/strings";
import Button from "@material-ui/core/Button/Button";
import Webcam from "react-webcam";
import Radio from "@material-ui/core/Radio/Radio";
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@material-ui/core";
import {objectToFormFormat} from "../../../../../../common/services/Util";

const strStaff = Strings.StaffPageStrings;

const styles = theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        //flexGrow: '1',
        minHeight: '100vh',
        //height: '100vh',
        width: '100%',
        fontFamily: 'Helvetica',
        fontSize: '18px',
        color: '#707070',
        padding: '95px 40px 40px 40px',
        backgroundColor: 'white',
        //border : '1px solid green',
    },
    wrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        //flexGrow: '1',
        width: '100%',
        maxWidth: '1703px',
        //border: '1px solid blue',
    },
    header: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        //flexGrow: '1',
        width: '100%',
        marginBottom: '36.5px',
        fontFamily: 'Helvetica',
        fontSize: '36px',
        borderBottom: '4px solid #707070',
    },
    coloredTitle: {
        display: 'flex',
        alignSelf: 'flex-start',
        fontSize: '30px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#3B86FF',
    },
    genderContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: '60px',
        paddingBottom: '38.5px',
        borderBottom: '2px solid rgba(67, 66, 93, .25)',
    },
    sectionLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '20px',
        minWidth: '180px',
        width: '5%',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    radioGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '10%',
        paddingRight: '110px',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
    },
    radio: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        margin: '0px',
    },
    radioLabel: {
        marginBottom: '0px',
    },
    formControlSelect: {
        margin: '0px 0px 0px 25px',
        width: '100%',
    },
    noDisplay: {
        display: 'none !important'
    },
    selectRoot: {
        width: '100%',
        height: '43px',
        padding: '0px 0px',
        border: 'none',
        borderRadius: '4px',
        fontSize: '20px',
        color: "#707070",
    },
    selectSelect: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '0px 15px',
        border: '1px solid #707070',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro',
        fontSize: '20px',
        boxSizing: 'border-box',
        '&:focus': {
            border: '1px solid #3B86FF',
            borderRadius: '4px',
            backgroundColor: 'white'
        }
    },
    selectNotchedOutline: {
        border: 'none',
    },
    selectItem: {
        padding: '10px 15px',
        fontFamily: 'Source Sans Pro',
        fontSize: '20px',
        color: '#707070'
    },
    inputGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '40%',
        textAlign: 'right',
    },
    inputSection: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: '10px',
    },
    inputWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '60%',
    },
    errorMessage: {
        color: '#D7373F',
        fontFamily: 'Helvetica',
        fontSize: '12px',
        fontWeight: 'normal',
    },
    inputLabel: {
        //width: '40%',
        width: '170px',
        marginBottom: '0px',
        marginTop: '10px',
        fontWeight: 'bold',
        textAlign: 'right',
    },
    inputField: {
        width: '100%',
        height: '43px',
        marginLeft: '25px',
        padding: '0px 15px',
        borderRadius: '4px',
        fontSize: '16px',
        color: "#707070",
        '&:focus': {
            border: '1px solid #3B86FF !important',
            outline: 0,
        },
    },
    selection: {
        padding: '0px 14px',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
    },
    photoGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20%',
        textAlign: 'center',
    },
    photo: {
        width: '120px',
        maxWidth: '120px',
        height: '120px',
        maxHeight: '120px',
        cursor: 'pointer',
    },
    typeContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        width: '100%',
        marginTop: '49.5px',
        paddingBottom: '94.5px',
        borderBottom: '2px solid rgba(67, 66, 93, .25)',
    },
    typeWrapper: {
        display: 'flex',
        flexFlow: 'row nowrap',
        marginBottom: '10px',
        flexGrow: '1',
    },
    typeGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        fontSize: '16px',
        '&:last-child':{
            marginLeft: '66px',
            width: '50%',
        },
    },
    maritalStatusContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        width: '100%',
        paddingBottom: '16.5px',
        paddingTop: '32.5px',
        borderBottom: '2px solid rgba(67, 66, 93, .25)',
    },
    addressContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignSelf: 'center',
        width: '100%',
        paddingTop: '32.5px',
        paddingBottom: '28px',
        borderBottom: '2px solid rgba(67, 66, 93, .25)',
    },
    addressGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        alignSelf: 'flex-start',
        width: '40%',
        maxWidth: '530px',
        //flexGrow: '1',
        marginBottom: '5px',
        //paddingRight: '79px',
    },
    saveButton: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        height: '50px',
        width: '185px',
        marginTop: '25px',
        border: 'none',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro !important',
        fontSize: '18px',
        textTransform: 'uppercase',
        color: 'white !important',
        backgroundColor: '#3B86FF !important',
        boxShadow: 'none !important',
        cursor: 'pointer',
        outline: '0px !important',
    },

    errorBorder: {
        border: '1px solid #D7373F !important'
    },


    '@media (max-width: 1600px)' : {
        inputSection: {
            justifyContent: 'flex-start',
        },
        inputLabel:{
            width: '170px',
        },
        addressContainer:{
            justifyContent: 'space-between',
        },
        addressGroup: {
            width: 'calc(50% - 20px)',
        },
        saveButton: {
            marginRight: '14px',
        }
    },
    '@media (max-width: 800px)' : {
        genderContainer: {
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
        },
        radioGroup: {
            flexFlow: 'row nowrap',
        },
        typeContainer: {
            flexFlow: 'column nowrap',
        },
        typeWrapper: {
            flexFlow: 'column nowrap',
        },
        typeGroup: {
            flexFlow: 'row nowrap',
        },
        maritalStatusContainer: {
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            //flexGrow: '1',
        },
        addressContainer: {
            flexFlow: 'column nowrap',
            justifyContent: 'space-between',
            alignSelf: 'space-around',
        },
        addressGroup: {
            width: '100%',
        },
        inputGroup: {
            width: '100%',
        },
        inputLabel: {
            textAlign: 'right',
        },
        sectionLabel:{
            width: '100%',
        },
    },
});

const emailRegex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const phoneRegex = RegExp(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/);

const specialities = ['Oncologist', 'Pediatrist', 'Gynecologist', 'Dental Care', 'Cancer Care', 'Nurse']

class StaffCreationPage extends React.Component{

    constructor(props) {

        super(props);

        this.state = {
            gender: 'male',
            type: 'doctor',
            status: 'single',
            firstName: '',
            middleName: '',
            speciality: '',
            homePhone: '',
            cellPhone: '',
            workPhone: '',
            emailAddress: '',
            cameraSet: false,
            imagePath: null,
            errors: {
                firstName: '',
                middleName: '',
                speciality: '',
                homePhone: '',
                cellPhone: '',
                workPhone: '',
                emailAddress: '',
                sending: false,
            },
            isSending: false,
        };
    }

    resetState() {

        this.setState({
            gender: 'male',
            type: 'doctor',
            status: 'single',
            firstName: '',
            middleName: '',
            speciality: '',
            homePhone: '',
            cellPhone: '',
            workPhone: '',
            emailAddress: '',
            cameraSet: false,
            imagePath: null,
            errors: {
                firstName: '',
                middleName: '',
                speciality: '',
                homePhone: '',
                cellPhone: '',
                workPhone: '',
                emailAddress: '',
                sending: false,
            },
            isSending: false,
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    setRef = webcam => {
        this.webcam = webcam;
    };

    capture = event => {
        if(this.state.cameraSet){
            this.setState({ imagePath: this.webcam.getScreenshot() });
        }
        this.setState({ cameraSet: !this.state.cameraSet });
        console.log("Captured!" + this.state.imagePath);
    };

    handleInputChange = (field, value) => {
        this.setState({[field]: value});
        this.softValidation([field], value);
    };

    fieldsEmpty = errorList => {
        let empty = false;
        Object.values(errorList).forEach(error => {
            error.length > 0 && (empty = true);
        });

        return empty;
    };

    softValidation(field, value){
        let errors = this.state.errors;
        //console.log(value);
        errors[field] = (value === null || value.length === 0) ? strStaff[field] + ' should not be empty!' : '';
        this.setState({errors: errors});
    }

    validateInputDetails = () => {

        let errors = this.state.errors;
        errors.firstName = this.state.firstName.length == 0 ? 'First Name should not be empty!' : '';
        errors.middleName = this.state.middleName.length  == 0 ? 'Middle Name should not be empty!' :'';
        errors.speciality = this.state.speciality.trim() === '' ? 'Speciality should not be empty!' : false;
        //input validation of homePhone
        if (this.state.homePhone.length === 0){
            errors.homePhone = 'Home Phone Field should not be empty!';
        } else if(!phoneRegex.test(this.state.homePhone)){
            errors.homePhone = 'Invalid Phone Number!';
        } else {
            errors.homePhone = '';
        }

        //input validation of cellPhone
        if (this.state.cellPhone.length === 0){
            errors.cellPhone = 'Cell Phone Field should not be empty!';
        } else if(!phoneRegex.test(this.state.cellPhone)){
            errors.cellPhone = 'Invalid Phone Number!';
        } else {
            errors.cellPhone = '';
        }

        //input validation of workPhone
        if (this.state.workPhone.length === 0){
            errors.workPhone = 'Work Phone Field should not be empty!';
        } else if(!phoneRegex.test(this.state.workPhone)){
            errors.workPhone = 'Invalid Phone Number!';
        } else {
            errors.workPhone = '';
        }

        //input validation of emailAddress
        if (this.state.emailAddress.length === 0){
            errors.emailAddress = 'Email Address Field should not be empty!';
        } else if(!emailRegex.test(this.state.emailAddress)){
            errors.emailAddress = 'Invalid Email Address!';
        } else {
            errors.emailAddress = '';
        }
        this.setState({errors: errors});
    };
    
    generatePractitionerFHIR() {

        const {firstName, middleName, homePhone, cellPhone, workPhone, emailAddress, gender, imagePath} = this.state;
        
        const staff = {

            resourceType: "Practitioner",
            // identifier: [],
            active: true,
            name: [
                {
                    use: 'official',
                    text: firstName.toLowerCase() + ' ' + middleName.toLowerCase(),
                    family: middleName.toLowerCase(),
                    given: [
                        firstName.toLowerCase()
                    ],
                    // prefix: [],
                    // suffix: [],
                    // period: {},
                }
            ],
            telecom: [
                {
                    system: 'phone',
                    value: homePhone,
                    use: 'home'
                },
                {
                    system: 'phone',
                    value: cellPhone,
                    use: 'mobile',
                },
                {
                    system: 'phone',
                    value: workPhone,
                    use: 'work',
                },
                {
                    system: 'email',
                    value: emailAddress,
                    use: 'work',
                }
            ],
            // address: [],
            gender: gender,
            // birthDate: '',
            photo: [
                {
                    contentType: 'jpeg',
                    // language: '',
                    data: imagePath,
                    // url: '',
                    // size: '',
                    // hash: '',
                    // title: '',
                    creation: Date()
                }
            ],
            // qualification: [],
            // communication: [],
        };

        return staff;
    }

    generatePractitionerRoleFHIR(practitionerRef) {

        const {speciality, firstName, middleName} = this.state;
        const {currentOrganization, selectedFacility} = this.props

        const practitionerRole = {

            resourceType: 'PractitionerRole',
            // identifier: [],
            active: true,
            // period: {},
            practitioner: {
                reference: 'Practitioner/' + practitionerRef,
                display: firstName.toLowerCase() + ' ' + middleName.toLowerCase(),
            },
            organization: {
                reference: 'Organization/' + currentOrganization.item.id
            },
            // code: [],
            specialty: [
                {
                    coding: [
                        {
                            // system: '',
                            code: speciality,
                            display: speciality,
                        }
                    ]
                }
            ],
            location: [
                {
                    reference: 'Location/' + selectedFacility.item.id
                }
            ],
            // healthcareService: [],
            // telecom: [],
            // availableTime: [],
            // notAvailable: [],
            // availabilityExceptions: '',
            // endpoint: {},
        };

        return practitionerRole;
    }

    handleSave = async event => {

        event.preventDefault();
        let errors = this.state.errors;
        this.validateInputDetails();

        if (this.fieldsEmpty(errors)) {

            console.log('invalid inputs');

        } else {

            const {settings} = this.props;

            this.setState({isSending: true});

            let res = await fetch(settings.fhirServer.baseUrl + '/practitioner',  {
                method: 'POST',
                mode: 'cors',
                body: objectToFormFormat(this.generatePractitionerFHIR()),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (res.status === 201) {

                let location = res.headers.get('location');
                location = location.split('/');
                let practitionerId = location[location.length - 1];

                let resPractitionerRole = await fetch(settings.fhirServer.baseUrl + '/practitionerrole',  {
                    method: 'POST',
                    body: objectToFormFormat(this.generatePractitionerRoleFHIR(practitionerId)),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });

                if (resPractitionerRole.status === 201) {

                    this.resetState();

                } else {

                    this.setState((state) => (
                        {errors : Object.assign({}, state.errors, {sending: 'something went wrong'})}
                    ))
                }

            } else {

                this.setState((state) => (
                    {errors : Object.assign({}, state.errors, {sending: 'something went wrong'})}
                ))
            }
        }
    };

    render() {

        const {classes, className} = this.props;
        const {errors, speciality} = this.state;
        const videoConstraints = {
            width: 120,
            height: 120,
            facingMode: "user"
        };

        // console.log(this.props)

        let imageSrc = this.state.imagePath !== null ? this.state.imagePath : photo;

        return(

            <div className={classNames(classes.container, className)}>

                <div className={classes.wrapper}>

                    <div className={classes.header}>
                        <span>{strStaff.clinicName}</span>
                        <span className={classes.coloredTitle}>{strStaff.newStaff}</span>
                    </div>

                    <span className={classes.coloredTitle}>{strStaff.demographics}</span>

                    <div className={classes.genderContainer}>

                        <span className={classes.sectionLabel}>{strStaff.gender}</span>

                        <div className={classes.radioGroup}>
                            <div className={classes.radio}>
                                <Radio id={strStaff.male} checked={this.state.gender === 'male'} onChange={event => this.handleInputChange('gender', event.target.value)} value='male' color="default" name="radio-button-demo" aria-label={strStaff.male}/>
                                <label className={classes.radioLabel} htmlFor={strStaff.male}>{strStaff.male}</label>
                            </div>
                            <div className={classes.radio}>
                                <Radio id={strStaff.female} checked={this.state.gender === 'female'} onChange={event => this.handleInputChange('gender', event.target.value)} value='female' color="default" name="radio-button-demo" aria-label={strStaff.female}/>
                                <label className={classes.radioLabel} htmlFor={strStaff.female}>{strStaff.female}</label>
                            </div>
                            <div className={classes.radio}>
                                <Radio id={strStaff.other} checked={this.state.gender === 'other'} onChange={event => this.handleInputChange('gender', event.target.value)} value='other' color="default" name="radio-button-demo" aria-label={strStaff.other}/>
                                <label className={classes.radioLabel} htmlFor={strStaff.other}>{strStaff.other}</label>
                            </div>
                        </div>

                        <div className={classes.inputGroup}>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strStaff.firstName}>{strStaff.firstName}</label>
                                <div className={classes.inputWrapper}>
                                    <input onChange={(e) => this.handleInputChange('firstName', e.target.value)} className={classes.inputField} style={errors.firstName.length > 0 ? {border: '1px solid #D7373F'} : {border: '1px solid #707070'}} type="text" name={strStaff.firstName} id={strStaff.firstName} value={this.state.firstName}/>
                                    {errors.firstName.length > 0 && (
                                        <span className={classes.errorMessage}>{errors.firstName}</span>
                                    )}
                                </div>
                            </div>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strStaff.middleName}>{strStaff.middleName}</label>
                                <div className={classes.inputWrapper}>
                                    <input onChange={(e) => this.handleInputChange('middleName', e.target.value)} className={classes.inputField} style={errors.middleName.length > 0 ? {border: '1px solid #D7373F'} : {border: '1px solid #707070'}} type="text" name={strStaff.middleName} id={strStaff.middleName} value={this.state.middleName}/>
                                    {errors.middleName.length > 0 && (
                                        <span className={classes.errorMessage}>{errors.middleName}</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={classes.photoGroup}>
                            <div className={classes.photo} onClick={this.capture}>
                                { !this.state.cameraSet ?
                                    <img src={imageSrc} alt={strStaff.takePhoto}/> :
                                    <Webcam audio={false} height={120} ref={this.setRef} screenshotFormat="image/jpeg" width={120} videoConstraints={videoConstraints} />
                                }
                            </div>
                            <span>{strStaff.takePhoto}</span>
                        </div>

                    </div>

                    <div className={classes.typeContainer}>

                        <span className={classes.sectionLabel}>{strStaff.type}</span>

                        <div className={classes.typeWrapper}>

                            <div className={classes.typeGroup}>
                                <div className={classes.radio}>
                                    <Radio id={strStaff.doctor} checked={this.state.type === 'doctor'} onChange={event => this.handleInputChange('type', event.target.value)} value='doctor' color="default" name="radio-button-demo" aria-label={strStaff.doctor}/>
                                    <label className={classes.radioLabel} htmlFor={strStaff.doctor}>{strStaff.doctor}</label>
                                </div>
                                <div className={classes.radio}>
                                    <Radio id={strStaff.nurse} checked={this.state.type === 'nurse'} onChange={event => this.handleInputChange('type', event.target.value)} value='nurse' color="default" name="radio-button-demo" aria-label={strStaff.nurse}/>
                                    <label className={classes.radioLabel} htmlFor={strStaff.nurse}>{strStaff.nurse}</label>
                                </div>
                                <div className={classes.radio}>
                                    <Radio id={strStaff.adminStaff} checked={this.state.type === 'adminStaff'} onChange={event => this.handleInputChange('type', event.target.value)} value='adminStaff' color="default" name="radio-button-demo" aria-label={strStaff.adminStaff}/>
                                    <label className={classes.radioLabel} htmlFor={strStaff.adminStaff}>{strStaff.adminStaff}</label>
                                </div>
                            </div>

                            <div className={classes.typeGroup}>
                                <div className={classes.inputSection}>
                                    <label className={classes.inputLabel} htmlFor={strStaff.speciality}>{strStaff.speciality}</label>
                                    <div className={classes.inputWrapper}>
                                        <FormControl variant="outlined" className={classes.formControlSelect}>
                                            <InputLabel classes={{root: classes.noDisplay}}/>
                                            <Select
                                                value={speciality}
                                                onChange={(e) => this.handleInputChange('speciality', e.target.value)}
                                                input={<OutlinedInput labelWidth={0} id="country" classes={{notchedOutline: classes.selectNotchedOutline}}/>}
                                                classes={{root: classes.selectRoot, select: classNames(classes.selectSelect, {[classes.errorBorder]: (errors.speciality)}),}}
                                            >
                                                {
                                                    specialities.map((speciality, index) => (
                                                        <MenuItem key={index} value={speciality} className={classes.selectItem}> {speciality} </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>

                                        {errors.speciality &&
                                            <span className={classes.errorMessage}>{errors.firstName}</span>
                                        }
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                    <div className={classes.maritalStatusContainer}>

                        <span className={classes.sectionLabel}>{strStaff.maritalStatus}</span>

                        <div className={classes.radioGroup}>
                            <div className={classes.radio}>
                                <Radio id={strStaff.single} checked={this.state.status === 'single'} onChange={event => this.handleInputChange('status', event.target.value)} value='single' color="default" name="radio-button-demo" aria-label={strStaff.single}/>
                                <label className={classes.radioLabel} htmlFor={strStaff.single}>{strStaff.single}</label>
                            </div>
                            <div className={classes.radio}>
                                <Radio id={strStaff.married} checked={this.state.status === 'married'} onChange={event => this.handleInputChange('status', event.target.value)} value='married' color="default" name="radio-button-demo" aria-label={strStaff.married}/>
                                <label className={classes.radioLabel} htmlFor={strStaff.married}>{strStaff.married}</label>
                            </div>
                            <div className={classes.radio}>
                                <Radio id={strStaff.widowed} checked={this.state.status === 'widowed'} onChange={event => this.handleInputChange('status', event.target.value)} value='widowed' color="default" name="radio-button-demo" aria-label={strStaff.widowed}/>
                                <label className={classes.radioLabel} htmlFor={strStaff.widowed}>{strStaff.widowed}</label>
                            </div>
                        </div>

                    </div>

                    <div className={classes.addressContainer}>
                        <div className={classes.addressGroup}>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strStaff.homePhone}>{strStaff.homePhone}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.homePhone.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleInputChange('homePhone', event.target.value, /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/)} type="text" value={this.state.homePhone} name={'homePhone'} id={'homePhone'}/>
                                    {
                                        this.state.errors.homePhone.length > 1 && (
                                            <span className={classes.errorMessage}>{this.state.errors.homePhone}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strStaff.cellPhone}>{strStaff.cellPhone}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.cellPhone.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleInputChange('cellPhone', event.target.value)} type="text" value={this.state.cellPhone} name={'cellPhone'} id={'cellPhone'}/>
                                    {
                                        this.state.errors.cellPhone.length > 1 && (
                                            <span className={classes.errorMessage}>{this.state.errors.cellPhone}</span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={classes.addressGroup}>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strStaff.workPhone}>{strStaff.workPhone}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.workPhone.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleInputChange('workPhone', event.target.value)} type="text" value={this.state.workPhone} name={'workPhone'} id={'workPhone'}/>
                                    {
                                        this.state.errors.workPhone.length > 1 && (
                                            <span className={classes.errorMessage}>{this.state.errors.workPhone}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={classes.inputSection}>
                                <label className={classes.inputLabel} htmlFor={strStaff.emailAddress}>{strStaff.emailAddress}</label>
                                <div className={classes.inputWrapper}>
                                    <input className={classes.inputField} style={(this.state.errors.emailAddress.length > 1) ? {border: '1px solid #D7373F '} : {border: '1px solid #707070'}} onChange={event => this.handleInputChange('emailAddress', event.target.value)} type="email" value={this.state.emailAddress} name={'emailAddress'} id={'emailAddress'}/>
                                    {
                                        this.state.errors.emailAddress.length > 1 && (
                                            <span className={classes.errorMessage}>{this.state.errors.emailAddress}</span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <Button onClick={this.handleSave} className={classes.saveButton}>{strStaff.save}</Button>

                </div>

            </div>
        );
    }
}
StaffCreationPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

StaffCreationPage.defaultProps = {
    className: '',
    strings: {},
};
export default withStyles(styles)(StaffCreationPage);