import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar';
import deepOrange from '@material-ui/core/colors/deepOrange';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import CheckedIcon from '@material-ui/icons/CheckCircle';
import iconEdit from '../../../assets/icon/icon_edit.svg'
import iconClose from '../../../assets/icon/icon_close.svg'
import iconInbox from '../../../assets/icon/icon_inbox.svg'
import TypingIcon from '../../TypingIcon'
// import defaultAvatar from '../../../assets/img/avatar.png'
import Strings from '../../../services/strings'

const strings = Strings.PersonList

const styles = theme => ({

    root: {
        maxWidth: '570px',
        height: '160.35px',
        color: '#4D4F5C',
        fontFamily: 'Source Sans Pro',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100%) !important',
            margin: '10px 0px !important',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100%)',
            margin: '10px 0px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '140px'
        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(50% - 15px) !important',
            margin: '10px 0px !important',
        },

    },
    paper: {
        width: '100%',
        height: '100%',
        padding: '22px 0px 46px 0px',
        borderRadius: 0,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)',
        [theme.breakpoints.down('xs')]: {
            padding: '17px 0px 30px 0px',

        },
    },
    mainGrid: {
        height: '100%',
        borderBottom: '1px solid #eeeeee'
    },
    activeStatus: {
        borderBottom: '5px solid #19b144a6 !important'
    },
    errorStatus: {
        borderBottom: '5px solid #f74c4c !important'
    },
    checkedStatus: {
        color: 'green'
    },
    leftGrid: {
        width: '120px',
        [theme.breakpoints.down('xs')]: {
            width: '21%',
        },
    },
    centralGrid: {

    },
    rightGrid: {
        width: '90px',
        paddingRight: '10px',
        [theme.breakpoints.down('xs')]: {
            width: '70px',
        },
    },
    avatar: {
        color: '#fff',
        backgroundColor: deepOrange[500],
        width: 72,
        height: 72,
        marginTop: 2,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            width: '60px',
            height: '60px',
        },
    },
    userName: {
        width: '100%',
        fontSize: '18px',
        fontWeight: 'bold',
        paddingTop: '4px',
        minHeight: '27px',
        maxHeight: '31px',
        marginBottom: 0,
        paddingBottom: '8px',
        fontFamily: 'Source Sans Pro',
        color: '#4D4F5C',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
        },

    },
    userInfo: {
        width: '100%',
        fontSize: '15px',
        opacity: 0.5,
        minHeight: '31px',
        marginBottom: 0,
        paddingBottom: '8px',
        fontFamily: 'Source Sans Pro',
        color: '#43425D',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '13px',
        },
    },
    userContact: {
        width: '175px',
        fontSize: '15px',
        opacity: 0.5,
        minHeight: '19px',
        marginBottom: 0,
        marginRight: '30px',
        fontFamily: 'Source Sans Pro',
        color: '#43425D',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '13px',
        },
    },
    iconChecked: {
        width: '30.25px',
        height: '30.25px',
        color: '#19b144a6',
        marginLeft: '40px',
    },
    icon: {
        width: '16px',
        height: '16px',

    },
    iconClose: {
        width: '14.25px',
        height: '14.25px'
    },
    iconButton: {
        padding: '12px',
        marginTop: '-12px',
        '&:focus': {
            outline: 0,
        },
        [theme.breakpoints.down('xs')]: {
            padding: '7px',
            marginTop: '-7px'
        },
    }
});

class PersonItem extends React.Component {

    constructor(props) {

        super(props)
        this.state = {}
    }

    render() {
        const {classes} = this.props;
        const {id, name, info, avatar, phone, initials, onClick, status} = this.props;

        return (
            <Grid className={classes.root} container item>
                <Paper className={classes.paper}>
                    <Grid container className={classNames(classes.mainGrid, {[classes.activeStatus]: (status && status.active && !status.error)},
                        {[classes.errorStatus]: (status && status.error)})} spacing={0}>

                        <Grid item className={classes.leftGrid}  container justify='center'>
                            {(avatar !== undefined && avatar.trim() !== '' && avatar !== null) ?
                                (onClick) ?
                                    <Avatar className={classes.avatar} alt={initials} src={avatar} onClick={onClick({id, name, info, avatar, phone})}/>
                                    :
                                    <Avatar className={classes.avatar} alt={initials} src={avatar}/>
                                :
                                (onClick) ?
                                    <Avatar className={classes.avatar} alt={initials} onClick={onClick({id, name, info, avatar, phone})}>{initials}</Avatar>
                                    :
                                    <Avatar className={classes.avatar} alt={initials}>{initials}</Avatar>
                            }
                        </Grid>

                        <Grid item className={classes.centralGrid} xs container direction='column' justify='flex-start' alignItems='flex-start'>

                            {(onClick) ?
                                <FormLabel className={classes.userName}  onClick={onClick({id, name, info, avatar, phone})}>
                                    {name}

                                    {status && status.typing && !status.checked &&
                                        <TypingIcon/>
                                    }

                                    {status && status.checked &&
                                        <CheckedIcon className={classes.iconChecked}/>
                                    }
                                </FormLabel>
                                :
                                <FormLabel className={classes.userName}>
                                    {name}

                                    {status && status.typing && !status.checked &&
                                        <TypingIcon/>
                                    }

                                    {status && status.checked &&
                                        <CheckedIcon className={classes.iconChecked}/>
                                    }
                                </FormLabel>
                            }

                            <FormLabel className={classes.userInfo}>{info}</FormLabel>
                            <Grid item container xs justify='flex-start' alignItems='flex-start'>
                                <FormLabel className={classes.userContact}>
                                    {strings.mobileLabel + ' : ' + phone}
                                </FormLabel>
                                <IconButton  color="inherit" className={classes.iconButton} aria-label="inbox icon">
                                    <img src={iconInbox} className={classes.icon} alt={strings.altIconInbox} />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid item className={classes.rightGrid} container justify='space-between' alignItems='flex-start'>
                            <IconButton  color="inherit" className={classes.iconButton} aria-label="edit icon">
                                <img src={iconEdit} className={classes.icon} alt={strings.altIconEdit} />
                            </IconButton>
                            <IconButton  color="inherit" className={classes.iconButton} aria-label="close icon">
                                <img src={iconClose} className={classes.iconClose} alt={strings.altIconClose} />
                            </IconButton>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>
        );
    }
}

PersonItem.propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

PersonItem.defaultProps = {
    id: '0',
    name: '',
    info: '',
    avatar: '',
    phone: '',
};

export default withStyles(styles)(PersonItem);