import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import ArrowForward from '@material-ui/icons/ChevronRight'
import ArrowBack from '@material-ui/icons/ChevronLeft'
import classNames from "classnames";

const styles = theme => ({

    root: {

    },

    nav: {
        display: 'flex',
        listStyleType: 'none',
        marginBottom: '0px',
        padding: 0,
    },
    selected: {
        borderTop: 'none !important',
        borderBottom: 'none !important',
        color: 'white !important',
        backgroundColor: '#3B86FF !important',
        cursor: 'default',
        pointerEvents: 'none',
    },

    item: {
        display: 'flex',
        width: '32px',
        height: '32px',
        backgroundColor: 'white',
        borderLeft: '1px solid #D7DAE2',
        borderTop: '1px solid #D7DAE2',
        borderBottom: '1px solid #D7DAE2',
        borderRadius: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        color: '#4D4F5C',
        '&:first-child': {
            marginLeft: '0px',
            borderRadius: '4px 0px 0px 4px',
        },
        '&:last-child': {
            marginRight: '0px',
            borderRight: '1px solid #D7DAE2',
            borderRadius: '0px 4px 4px 0px',
        }
    },


    disabled: {
        cursor: 'default',
        pointerEvents: 'none',
        backgroundColor: '#fefefe',
        opacity: 0.6
    },

    icon: {
        fontSize: '25px',
        color: '#808495',
    }
});

class PaginationCollapse extends React.Component {

    constructor(props) {

        super(props)
        this.state = {offset: 0}
        this.handleOffsetChange = this.handleOffsetChange.bind(this)
    }

    handleOffsetChange(value, event) {
        this.setState(state => ({
            offset: state.offset + value
        }))
    }

    handleSelectedPageChange(newSelectedIndex) {
        let {startIndex} = this.props
        let {offset} = this.state
        this.setState({offset: 0})
        this.props.onChange(newSelectedIndex, startIndex + offset)
    }

    render() {
        const {classes, startIndex, selectedIndex, maxIndex, numberDisplayedIndex, className } = this.props
        let index = [], offset = this.state.offset

        for (let i = (startIndex + offset); i < (startIndex + offset + numberDisplayedIndex); i++) {

            if (i === selectedIndex) {
                index.push({value: i, isSelected: true})
            } else {
                index.push({value: i, isSelected: false})
            }
        }

        return (
            <Grid  item container className={classNames(classes.root, className)}>
                <ul className={classes.nav}>
                    <li className={classNames(classes.item, {[classes.disabled]: (startIndex + offset) <= 1})}
                        onClick={(e) => this.handleOffsetChange(-1, e)}>
                        <ArrowBack className={classes.icon}/>
                    </li>
                    {
                        index.map((index, idx) =>{

                            if (index.isSelected) {
                                return <li key={idx} className={classNames(classes.item, classes.selected)}
                                           onClick={(e) => this.handleSelectedPageChange(index.value)}>{index.value}</li>
                            } else {
                                return <li key={idx} className={classes.item}
                                           onClick={(e) => this.handleSelectedPageChange(index.value)}>{index.value}</li>
                            }
                        })
                    }
                    <li className={classNames(classes.item, {[classes.disabled]: (startIndex + offset + numberDisplayedIndex) > maxIndex})}
                        onClick={(e) => this.handleOffsetChange(1, e)}>
                        <ArrowForward className={classes.icon}/>
                    </li>
                </ul>
            </Grid>
        );
    }
}

PaginationCollapse.propTypes = {
    classes: PropTypes.object.isRequired,
    startIndex: PropTypes.number.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    maxIndex: PropTypes.number.isRequired,
    numberDisplayedIndex: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
};

PaginationCollapse.defaultProps = {
    startIndex: 1,
    selectedIndex: 1,
    maxIndex: 5,
    numberDisplayedIndex: 5,
    className: '',
};

export default withStyles(styles)(PaginationCollapse);