import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import 'react-dates/initialize'
import { DayPickerSingleDateController } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import AttendanceList from './AttendanceList'
import classNames from 'classnames'
import Icons from '../../../../../../common/assets/icon'
import Strings from '../../../../../services/strings'


const strings = Strings.CreateFieldTrip

const styles = (theme) => ({
    main: {
        flexGrow: 1,
        padding: '95px 10px 40px 40px',
        backgroundColor: 'white',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        fontFamily: 'Source Sans Pro',

    },
    title: {
        width: '100%',
        color: '#43425D',
        fontSize: '36px',
        fontFamily: 'Source Sans Pro',
        marginBottom: '25px',
    },
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: 'auto',
        width: '100%',
    },
    tabs: {
        position: 'relative',
        height: '45px',
        width: '1130px',
        borderBottom: '1px solid #E9E9F0'
    },
    tab: {
        position: 'relative',
        top: '-5px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '14px 14px 12px 14px',
        marginRight: '3px',
        backgroundColor: '#F8F8F8',
        color: '#43425D',
        border: '1px solid #E9E9F0',
        borderRadius: '4px 4px 0 0',
        fontFamily: 'Source Sans Pro',
        fontSize: '15px',
        cursor: 'pointer',
        minWidth: '100px',
    },
    tabSelected: {
        backgroundColor: 'white !important',
        color: '#3B86FF !important',
        fontWeight: 'bold',
        borderBottom: 'none'
    },
    tabNotification: {
        height: '16px',
        width: '16px',
        position: 'absolute',
        top: '-5px',
        right: '-1px',
    },
    nav: {
        listStyleType: 'none',
        display: 'flex',
        margin: 0,
        padding: 0,
    },
    caracteristic: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: 'auto',
        padding: '10px 0px 0px 0px',
    },
    tripInfo: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: 'auto',
        width: '650px',
        padding: '28px 60px 0px 0px',
        color: '#4D565C',
    },
    infoLine: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '40px',
        marginTop: '25px',
    },
    infoLabel: {
        position: 'relative',
        display: 'flex',
        width: '160px',
        justifyContent: 'flex-end',
        margin: '0px 30px 0px 0px',
        fontSize: '18px',
        textTransform: 'capitalize'
    },
    infoInput: {
        height: '40px',
        width: '400px',
        padding: '12px 16px 11px 16px',
        fontFamily: 'Source Sans pro',
        fontSize: '13px',
        color: '#4D565C',
        border: '1px solid gainsboro',
        borderRadius: '4px',
        '&:focus': {
            outline: '0 !important',
            border: '1px solid #3B86FF',
        },
        '&::placeholder': {
            color: '#E8E9EC'
        },
        '&:placeholder': {
            color: '#E8E9EC'
        }
    },
    inputIndication: {
        margin: '15px 0px 0px 190px',
        fontSize: '13px',
        color: '#808495',
    },
    dateContainer: {
        position: 'relative',
        top: '130px',
        left: '2px',
        display: 'flex',
        alignItems: 'flex-start'
    },
    departure: {
        marginTop: '290px'
    },
    beaconInfo: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: 'auto',
        minHeight: '745px',
        padding: '10px 0px 0px 100px',
        borderLeft: '1px solid #E9E9F0',
    },
    beaconTitle: {
        marginBottom: '24px',
        fontSize: '18px',
        color: '#4D565C',
        textTransform: 'capitalize'
    },
    buttonContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '76px 0px 30px 0px',
        padding: '0px 0px 0px 125px'
    },
    button: {
        display: 'flex',
        minWidth: '185px',
        height: '50px',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        margin: '0px 52px 0px 0px',
        padding: '0px 20px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#43425D !important',
        fontFamily: 'Source Sans Pro',
        fontSize: '18px',
        textTransform: 'capitalize',
        color: 'white',
        boxShadow: 'none !important',
        outline: '0 !important',
        cursor: 'pointer'
    }
});

const attendanceRows = [
    {
        student: 'James Delos',
        beacon: 'AZURE 1',
        battery: 'GOOD',
    },
    {
        student: 'Addy Bailey',
        beacon: 'AZURE 2',
        battery: 'GOOD',
    },
    {
        student: 'John Doe',
        beacon: 'AZURE 3',
        battery: 'FAIR',
    },
    {
        student: 'Alex Jackson',
        beacon: 'AZURE 4',
        battery: 'LOW',
    },
    {
        student: 'Allen Johnson',
        beacon: 'AZURE 5',
        battery: 'LOW',
    },
    {
        student: 'John Molly',
        beacon: 'AZURE 6',
        battery: 'LOW',
    },
]


class PlanningFieldTripPage extends Component {

    constructor(props) {
        super(props)
        const {trips} = props
        this.currentTrip = trips.length > 0 ? trips[0] : {}
        this.currentTrip.date = this.currentTrip.date === undefined ? moment() : this.currentTrip.date
        let selectedTab = (trips.length > 0 && (trips[0].class !== undefined)) ? trips[0].class : ''
        this.state = {trips, selectedTab}
    }

    handleTabClick = (selectedTab) => () => {
        const {trips} = this.state
        this.setState({selectedTab: selectedTab})
        this.currentTrip = trips.find((trip) => trip.class.toLowerCase() === selectedTab.toLowerCase())
        this.currentTrip.date = this.currentTrip.date === undefined ? moment() : this.currentTrip.date
    }

    handleButtonClick = (name) => () => {
        console.log(this.currentTrip)
    }

    handleInputChange = (name, e) => {
        let {trips, selectedTab} = this.state
        this.currentTrip[name] = e.target.value
        let index = trips.findIndex((trip) => trip.class.toLowerCase() === selectedTab.toLowerCase())
        trips[index] = this.currentTrip
        this.setState({trips: trips})
    }

    handleDateChange = (date) => {
        let {trips, selectedTab} = this.state
        this.currentTrip.date = date
        let index = trips.findIndex((trip) => trip.class.toLowerCase() === selectedTab.toLowerCase())
        trips[index] = this.currentTrip
        this.setState({trips: trips})
    }

    handleFocusChange = () => {
        // Force the focused states to always be truthy so that date is always selectable
        // this.setState({ focused: true });
    }

    render() {
        const { classes, trips } = this.props;
        let {selectedTab} = this.state

        return (
            <Grid container className={classes.main}>

                <Typography noWrap className={classes.title}>
                    {strings.title}
                </Typography>

                <div className={classes.root}>
                    <div className={classes.tabs}>
                        <ul className={classes.nav}>
                            {
                                trips.map((trip, index) => (
                                    <li key={index} className={classNames(classes.tab, {[classes.tabSelected]: (index === 0 && selectedTab === '') || (trip.class.toLowerCase() === selectedTab.toLowerCase())})}
                                        onClick={this.handleTabClick(trip.class)}>
                                        {trip.class}

                                        {
                                            trip.status !== undefined && trip.status.missing > 0 ?
                                                <img src={Icons['icon_ellipse-red']} className={classes.tabNotification} alt="icon notif"/>
                                                : trip.status !== undefined && trip.status.outOfBounds > 0 ?
                                                <img src={Icons['icon_ellipse-orange']} className={classes.tabNotification} alt="icon notif"/>
                                                : null
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                    <div className={classes.caracteristic}>

                        <div className={classes.tripInfo}>

                            <div className={classes.infoLine}>
                                <label className={classes.infoLabel} htmlFor="location">{strings.location}</label>
                                <input type="text" className={classes.infoInput} placeholder="Placeholder" name="location" id="location"
                                       value={this.currentTrip.location !== undefined ? this.currentTrip.location : ''}
                                       onChange={(e) => this.handleInputChange('location', e)}
                                />
                            </div>
                            <span className={classes.inputIndication}>{strings.locationIndication}</span>

                            <div className={classes.infoLine}>
                                <label className={classes.infoLabel} htmlFor="date">{strings.date}</label>
                                <div className={classes.dateContainer}>
                                    <DayPickerSingleDateController
                                        onDateChange={this.handleDateChange}
                                        onFocusChange={this.handleFocusChange}
                                        focused={true}
                                        date={this.currentTrip.date !== undefined ? this.currentTrip.date : moment()}
                                    />
                                </div>
                            </div>

                            <div className={classNames(classes.infoLine, classes.departure)}>
                                <label className={classes.infoLabel} htmlFor="departure">{strings.departure}</label>
                                <input type="text" className={classes.infoInput} placeholder="Placeholder" name="departure" id="departure"
                                       value={this.currentTrip.departure !== undefined ? this.currentTrip.departure : ''}
                                       onChange={(e) => this.handleInputChange('departure', e)}
                                />
                            </div>
                            <span className={classes.inputIndication}>{strings.departureIndication}</span>

                            <div className={classes.infoLine}>
                                <label className={classes.infoLabel} htmlFor="return">{strings.return}</label>
                                <input type="text" className={classes.infoInput} placeholder="Placeholder" name="return" id="return"
                                       value={this.currentTrip.return !== undefined ? this.currentTrip.return : ''}
                                       onChange={(e) => this.handleInputChange('return', e)}
                                />
                            </div>
                            <span className={classes.inputIndication}>{strings.returnIndication}</span>

                            <div className={classes.infoLine}>
                                <label className={classes.infoLabel} htmlFor="cost">{strings.cost}</label>
                                <input type="number" className={classes.infoInput} placeholder="Placeholder" name="cost" id="cost"
                                       value={this.currentTrip.cost !== undefined ? this.currentTrip.cost : ''}
                                       onChange={(e) => this.handleInputChange('cost', e)}
                                />
                            </div>
                            <span className={classes.inputIndication}>{strings.costIndication}</span>

                            <div className={classes.infoLine}>
                                <label className={classes.infoLabel} htmlFor="transportationMode">{strings.transportationMode}</label>
                                <input type="text" className={classes.infoInput} placeholder="Placeholder" name="transportationMode" id="transportationMode"
                                       value={this.currentTrip.transportationMode !== undefined ? this.currentTrip.transportationMode : ''}
                                       onChange={(e) => this.handleInputChange('transportationMode', e)}
                                />
                            </div>
                        </div>

                        <div className={classes.beaconInfo}>
                            <span className={classes.beaconTitle}>{strings.beacons}</span>
                            <AttendanceList rows={attendanceRows}/>
                        </div>
                    </div>

                    <div className={classes.buttonContainer}>
                        <Button variant="contained" className={classes.button} onClick={this.handleButtonClick('save')}>
                            {strings.save}
                        </Button>
                        <Button variant="contained" className={classes.button} onClick={this.handleButtonClick('draft')}>
                            {strings.saveDraft}
                        </Button>
                        <Button variant="contained" className={classes.button} onClick={this.handleButtonClick('permision')}>
                            {strings.sendPermissionSlip}
                        </Button>
                    </div>

                </div>
            </Grid>

        );
    }
}

PlanningFieldTripPage.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PlanningFieldTripPage)
