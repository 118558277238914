import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import validate from 'validate.js'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../../components/Button'
import classNames from 'classnames'
import logo from '../../../assets/img/logo.png';
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import firebase from 'firebase'

const styles = theme => ({
    wrapper: {
        display: 'flex',
        flexGrow: 1,
        height: 'auto',
        minHeight: '100vh',
        backgroundColor: 'white'
    },
    loadingContainer: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingErrorLabel: {
        position: 'relative',
        bottom: '60px',
        color: '#43425D',
        fontSize: '26px',
        fontFamily: 'Source Sans Pro',
    },
    loadingProgress: {
        position: 'relative',
        bottom: '60px',
        color: '#6798e5',
        animationDuration: '700ms',
    },
    root: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh',
        height: 'auto',
        width: '100%',
        fontFamily: 'Source Sans Pro',
        fontSize: '24px',
        color: '#707070',
    },
    header: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        height: '158px',
        width: '100%',
        borderBottom: '1px solid #707070',
    },
    logoTenkolo: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        width: '200px',
        height: '100px',
        marginLeft: '64px',
    },
    logo: {
        width: '200px',
        height: '100px',
    },
    headerTitle: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignSelf: 'flex-start',
        width: '534px',
        height: '97px',
        marginTop: '21px',
        textAlign: 'center',
    },
    top: {
        fontSize: '36px',
        margin: '0px'
    },
    bottom: {
        fontSize: '30px',
        margin: '4px 0px 0px 0px'
    },
    special: {
        color: '#3B86FF',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    help: {
        display: 'flex',
        alignSelf: 'flex-end',
        width: 'auto',
        height: '97px',
        marginRight: '33px',
        fontSize: '26px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#3B86FF',
        '&:last-child': {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginBottom: '7.5px'
        },
    },
    steeper: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'stretch',
        height: '61px',
        width: '1011px',
        margin: '34.5px 0px 66px 0px',
    },
    stepLabelLabel: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        color: '#4D4F5C !important',
    },
    stepLabelActive: {
        color: '#4D4F5C !important',
        fontWeight: 'bold !important'
    },
    stepLabelIconActive: {
        color: '#3B86FF !important',
    },
    stepLabelIconCompleted: {
        color: '#3B86FF !important',
    },
    content: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '85%',
        maxWidth: '1800px',
        height: 'auto',
    },
    sectionTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '13.5px',
        marginBottom: '34.5px',
        borderBottom: '1px solid #707070',
        fontWeight: 'bold'
    },
    infoLine: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        marginBottom: '13px',
    },
    infoLabel: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '43px',
        width: '175px',
        marginRight: '25px',
        marginBottom: '0px'
    },
    inputContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexGrow: '1',
        flexBasis: '0px',
        height: 'auto',
        marginRight: '30px',
        marginBottom: '3px',

    },
    infoInput: {
        width: '100%',
        height: '43px',
        padding: '0px 15px',
        border: '1px solid #707070',
        borderRadius: '4px',
        fontSize: '20px',
        color: "#707070",
        '&:focus': {
            border: '1px solid #3B86FF',
            outline: 0,
        },
        '&:last-child': {
            marginRight: '0px',
        },
    },
    errorLabel: {
        display: 'flex',
        width: '100%',
        fontSize: '15px',
        color: "#D7373F"
    },
    formControlSelect: {
        margin: '0px',
        width: '100%',
    },
    noDisplay: {
        display: 'none !important'
    },
    selectRoot: {
        width: '100%',
        height: '43px',
        padding: '0px 0px',
        border: 'none',
        borderRadius: '4px',
        fontSize: '20px',
        color: "#707070",
    },
    selectSelect: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '0px 15px',
        border: '1px solid #707070',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro',
        fontSize: '20px',
        boxSizing: 'border-box',
        '&:focus': {
            border: '1px solid #3B86FF',
            borderRadius: '4px',
            backgroundColor: 'white'
        }
    },
    selectNotchedOutline: {
        border: 'none',
    },
    selectItem: {
        padding: '10px 15px',
        fontFamily: 'Source Sans Pro',
        fontSize: '20px',
        color: '#707070'
    },
    salutationInput: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        padding: '0px 0px',
        width: '394px',
        height: '43px',
        fontSize: '26px',
        color: "#707070",
        '&:last-child': {
            marginRight: '0px',
        },
    },
    controlLabelRoot: {
        marginBottom: '0px',
    },
    controlLabelLabel: {
        fontFamily: 'Source Sans Pro',
        fontSize: '26px',
        color: '#707070',
    },
    radioColor: {
        color: '#3B86FF !important',
    },
    waitingRoomContainer: {
        width: '100%',
        height: 'auto',
        marginTop: '20.5px',
        paddingTop: '51.5px',
        borderTop: '1px solid #707070',

    },
    waitingRoom: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '710px',
        height: '100%',
        marginLeft: '200px',
        '@media (max-width: 1230px)': {
            marginLeft: '0px',
        }
    },
    wRoomLabel: {
        display: 'flex',
        width: '545px',
        height: '31px',
        margin: '0px',
        fontWeight: 'bold'
    },
    wRoomInput: {
        display: 'flex',
        width: '96px',
        height: '43px',
        paddingLeft: '42px',
        paddingRight: '6px',
        border: '1px solid #707070',
        borderRadius: '4px',
        fontSize: '24px',
        fontWeight: 'bold',
        color: "#707070",
        '&:focus': {
            border: '1px solid #3B86FF',
            outline: 0,
        }
    },
    footer: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '85%',
        maxWidth: '1800px',
        height: 'auto',
        marginBottom: '50px',
    },
    backButton: {
        position: 'absolute',
        right: '0px',
        top: '-48px',
        height: '50px',
        width: '185px',
        fontSize: '18px',
        textTransform: 'uppercase',
        backgroundColor: '#3B86FF !important',
    },
    nextButtonDisabled: {
        position: 'absolute',
        right: '0px',
        top: '-48px',
        height: '50px',
        width: '185px',
        fontSize: '18px',
        textTransform: 'uppercase',
        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },

    errorBorder: {
        border: '1px solid #D7373F !important'
    },
    errorColor: {
        color: '#D7373F !important',
    },

    blankTitle: {
        display: 'flex',
        flexBasis: '230px',
        flexGrow: '1',
    }
});

const steps = ['YOUR ORGANIZATION', 'YOUR PLAN', 'PAYMENT', 'FINISHED'];

const states = [
    'Alabama',
    'Alaska',
    'Missouri',
    'Nevada',
    'Texas',
    'New York',
    'California',
]

const countries = [
    'Angola',
    'Belgium',
    'Canada',
    'United State',
]

const errorEmailMessage = 'This field must be a valid email'
const errorRequiredMessage = 'This field is required'
const erroFormatlMessage = 'This field is not to the good format'


class RegistrationPage1 extends React.Component {

    constructor(props) {

        super(props)

        let planName = 'premium'

        if (this.props.match.params.plan !== undefined && this.props.match.params.plan.toLowerCase() === 'starter') {
            planName = 'starter'
        } else if (this.props.match.params.plan !== undefined && this.props.match.params.plan.toLowerCase() === 'pro') {
            planName = 'pro'
        }

        this.props.onChange('plan', {name: planName})

        this.state = {
            next: false,
            errors: {},
            loading: this.props.planPrices.isFetching && this.props.settings.isFetching,
            loadingError: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.initialize();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.organization !== this.props.organization || prevProps.primaryContact !== this.props.primaryContact || prevProps.plan !== this.props.plan) {

            this.softValidation()
        }

        if (prevProps.planPrices.isFetching !== this.props.planPrices.isFetching || prevProps.settings.isFetching !== this.props.settings.isFetching) {

            this.setState({loading: this.props.planPrices.isFetching && this.props.settings.isFetching})
        }
    }

    async initialize() {

        const {setSettings, setSettingsFetchingStatus} = this.props

        let promises = []

        promises.push(firebase.firestore().collection('DefaultSettings').limit(1).get())
        promises.push(firebase.firestore().collection('PlanPricing').limit(1).get())

        let snaps = await Promise.all(promises)

        let snapsSettings = snaps[0]
        let snapsPricing = snaps[1]

        let prices = []
        let settings = []
        const {onChange} = this.props

        snapsSettings.forEach((snap) => {
            settings.push(snap.data())
        })

        snapsPricing.forEach((snap) => {
            prices.push(snap.data())
        })

        if (settings.length > 0) {

            settings = settings[0]

            setSettings(settings)
            setSettingsFetchingStatus(false)

        } else {

            this.setState({loadingError: 'Oups, something went wrong !'});
        }


        if (prices.length > 0) {

            let planPrices = prices[0];

            if (planPrices.premium && planPrices.pro && planPrices.starter) {

                onChange('planPrices', {isFetching: false, item: prices[0]})

            } else {

                this.setState({loadingError: 'Oups, something went wrong !'});
            }

        } else {

            this.setState({loadingError: 'Oups, something went wrong !'});
        }

    }

    navigateToStep2() {

        const {history, match} = this.props
        history.push(`${match.url.replace('step-1', 'step-2')}`)
    }

    softValidation() {
        const {organization, primaryContact, plan} = this.props

        let input = {organization, primaryContact, plan}

        let constraints = {
            'organization.name': {
                presence: {allowEmpty: false}
            },
            'organization.phone': {
                presence: {allowEmpty: false}
            },
            'organization.address': {
                presence: {allowEmpty: false}
            },
            'organization.address2': {
                presence: {allowEmpty: false}
            },
            'organization.city': {
                presence: {allowEmpty: false}
            },
            'organization.state': {
                presence: {allowEmpty: false}
            },
            'organization.postalCode': {
                presence: {allowEmpty: false}
            },
            'organization.country': {
                presence: {allowEmpty: false}
            },

            'primaryContact.salutation': {
                presence: {allowEmpty: false}
            },
            'primaryContact.firstName': {
                presence: {allowEmpty: false}
            },
            'primaryContact.lastName': {
                presence: {allowEmpty: false}
            },
            'primaryContact.email': {
                presence: {allowEmpty: false}
            },
            'primaryContact.phone': {
                presence: {allowEmpty: false}
            },
            'primaryContact.title': {
                presence: {allowEmpty: false}
            },

            'plan.numberWaitingRoom': {
                presence: {allowEmpty: false}
            },

        }

        let errors = validate(input, constraints)

        if (errors) {

            this.setState({next: false})

        } else {

            this.setState({next: true})
        }
    }

    hardValidation() {

        const {organization, primaryContact, plan} = this.props

        let input = {organization, primaryContact, plan}

        let constraints = {
            'organization.name': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'organization.phone': {
                presence: {allowEmpty: false},
                format: {
                    pattern: /^[+]*[(]?[0-9]{1,3}[)]?(\d|\s|-)*$/,
                    message: erroFormatlMessage
                }
            },
            'organization.address': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'organization.address2': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'organization.city': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'organization.state': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'organization.postalCode': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'organization.country': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },

            'primaryContact.salutation': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'primaryContact.firstName': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'primaryContact.lastName': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            'primaryContact.email': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
                email: {
                    message: errorEmailMessage
                },
            },
            'primaryContact.phone': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage,
                },
                format: {
                    pattern: /^[+]*[(]?[0-9]{1,3}[)]?(\d|\s|-)*$/,
                    message: erroFormatlMessage
                }
            },
            'primaryContact.title': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },

            'plan.numberWaitingRoom': {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },

        }

        let errors = validate(input, constraints, {fullMessages: false})

        let error = {}

        if (errors) {

            for (let key in errors) {

                let keys = key.split('.')

                if (!error.hasOwnProperty(keys[0])) {
                    error[keys[0]] = {}
                }

                error[keys[0]][keys[1]] = errors[key][0]
            }
        }

        this.setState({errors: error})

        return !errors
    }

    handleNextClick = async () => {

        let validation = this.hardValidation()

        if (validation) {

            this.props.onChange('currentStep', 2)
            this.navigateToStep2()
        }
    }

    render() {
        const {next, errors, loading, loadingError} = this.state
        const {classes, className, organization, primaryContact, plan, onChange} = this.props;
        const { numberWaitingRoom, name : planName } = plan;

        return (
            <div className={classes.wrapper}>

                {loadingError &&
                    <div className={classes.loadingContainer}>
                        <span className={classes.loadingErrorLabel}>{loadingError}</span>
                    </div>
                }

                {loading && !loadingError &&
                    <div className={classes.loadingContainer}>
                        <CircularProgress
                            variant="indeterminate"
                            className={classes.loadingProgress}
                            size={24}
                            thickness={4}
                        />
                    </div>
                }

                {!loading && !loadingError &&

                    <div className={classNames(classes.root, className)}>

                        <div className={classes.header}>
                            <div className={classes.logoTenkolo}>
                                <img src={logo} alt="logo" className={classes.logo}/>
                            </div>
                            <div className={classes.headerTitle}>
                                <span className={classes.top}>Thank you for choosing AQUII</span>
                                <span className={classes.bottom}>Let’s get started with your <span className={classes.special}>{planName}</span> Plan</span>
                            </div>
                            <span className={classes.help}>Need help? Call us at (844) 250-9500</span>
                        </div>

                        <div className={classes.steeper}>
                            <Stepper activeStep={0} alternativeLabel>
                                {steps.map(label => {
                                    return (
                                        <Step key={label}>
                                            <StepLabel
                                                StepIconProps={{
                                                    classes : {
                                                        active: classes.stepLabelIconActive,
                                                        completed: classes.stepLabelIconCompleted,
                                                    }
                                                }}
                                                classes={{
                                                    label: classes.stepLabelLabel,
                                                    active: classes.stepLabelActive,
                                                }}
                                            >
                                                {label}
                                            </StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </div>

                        <div className={classes.content}>

                            <span className={classes.sectionTitle}>Your Organization:</span>

                            <div className={classes.infoLine}>

                                <label htmlFor="institution" className={classes.infoLabel}>Institution Name</label>

                                <div className={classes.inputContainer}>
                                    <input type="text" className={classNames(classes.infoInput, {[classes.errorBorder]: (errors.organization && errors.organization.name)})}
                                           id="institution" value={organization.name} onChange={(e) => onChange('organization', {name: e.target.value})}
                                    />

                                    {(errors.organization && errors.organization.name) &&
                                    <span className={classes.errorLabel}>{errors.organization.name}</span>
                                    }
                                </div>

                                <label htmlFor="phoneNum" className={classes.infoLabel}>Phone Number</label>

                                <div className={classes.inputContainer}>
                                    <input type="text" className={classNames(classes.infoInput, {[classes.errorBorder]: (errors.organization && errors.organization.phone)})}
                                           id="phoneNum" value={organization.phone}  onChange={(e) => onChange('organization', {phone: e.target.value})}
                                    />

                                    {(errors.organization && errors.organization.phone) &&
                                        <span className={classes.errorLabel}>{errors.organization.phone}</span>
                                    }
                                </div>

                            </div>

                            <div className={classes.infoLine}>

                                <label htmlFor="address" className={classes.infoLabel}>Address</label>
                                <div className={classes.inputContainer}>
                                    <input type="text" className={classNames(classes.infoInput, {[classes.errorBorder]: (errors.organization && errors.organization.address)})}
                                           id="address" value={organization.address}  onChange={(e) => onChange('organization', {address: e.target.value})}
                                    />

                                    {(errors.organization && errors.organization.address) &&
                                        <span className={classes.errorLabel}>{errors.organization.address}</span>
                                    }
                                </div>

                                <label htmlFor="address2" className={classes.infoLabel}>Address 2</label>

                                <div className={classes.inputContainer}>
                                    <input type="text" className={classNames(classes.infoInput, {[classes.errorBorder]: (errors.organization && errors.organization.address2)})}
                                           id="address2" value={organization.address2}  onChange={(e) => onChange('organization', {address2: e.target.value})}
                                    />

                                    {(errors.organization && errors.organization.address2) &&
                                    <span className={classes.errorLabel}>{errors.organization.address2}</span>
                                    }
                                </div>

                            </div>

                            <div className={classes.infoLine}>

                                <label htmlFor="city" className={classes.infoLabel}>City</label>

                                <div className={classes.inputContainer}>
                                    <input type="text" className={classNames(classes.infoInput, {[classes.errorBorder]: (errors.organization && errors.organization.city)})}
                                           id="city" value={organization.city}  onChange={(e) => onChange('organization', {city: e.target.value})}
                                    />

                                    {(errors.organization && errors.organization.city) &&
                                        <span className={classes.errorLabel}>{errors.organization.city}</span>
                                    }
                                </div>

                                <label htmlFor="stateProvince" className={classes.infoLabel}>State/Province</label>

                                <div className={classes.inputContainer}>
                                    <FormControl variant="outlined" className={classes.formControlSelect}>
                                        <InputLabel
                                            ref={ref => {
                                                this.InputLabelRef = ref;
                                            }}
                                            htmlFor="stateProvince"
                                            classes={{root: classes.noDisplay}}
                                        />
                                        <Select
                                            value={organization.state}
                                            onChange={(e) => onChange('organization', {state: e.target.value})}
                                            input={
                                                <OutlinedInput
                                                    labelWidth={0}
                                                    id="stateProvince"
                                                    classes={{
                                                        notchedOutline: classes.selectNotchedOutline
                                                    }}
                                                />
                                            }

                                            classes={{
                                                root: classes.selectRoot,
                                                select: classNames(classes.selectSelect, {[classes.errorBorder]: (errors.organization && errors.organization.state)}),
                                            }}
                                        >
                                            {
                                                states.map((state, index) =>(
                                                    <MenuItem key={index} value={state} className={classes.selectItem}> {state} </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>

                                    {(errors.organization && errors.organization.state) &&
                                    <span className={classes.errorLabel}>{errors.organization.state}</span>
                                    }
                                </div>

                            </div>

                            <div className={classes.infoLine}>

                                <label htmlFor="postalCode" className={classes.infoLabel}>Postal Code</label>

                                <div className={classes.inputContainer}>
                                    <input type="text" className={classNames(classes.infoInput, {[classes.errorBorder]: (errors.organization && errors.organization.postalCode)})}
                                           id="postalCode" value={organization.postalCode} onChange={(e) => onChange('organization', {postalCode: e.target.value})}
                                    />

                                    {(errors.organization && errors.organization.postalCode) &&
                                    <span className={classes.errorLabel}>{errors.organization.postalCode}</span>
                                    }
                                </div>

                                <label htmlFor="country" className={classes.infoLabel}>Country</label>

                                <div className={classes.inputContainer}>
                                    <FormControl variant="outlined" className={classes.formControlSelect}>
                                        <InputLabel
                                            ref={ref => {
                                                this.InputLabelRef = ref;
                                            }}
                                            htmlFor="country"
                                            classes={{root: classes.noDisplay}}
                                        />
                                        <Select
                                            value={organization.country}
                                            onChange={(e) => onChange('organization', {country: e.target.value})}
                                            input={
                                                <OutlinedInput
                                                    labelWidth={0}
                                                    id="country"
                                                    classes={{
                                                        notchedOutline: classes.selectNotchedOutline
                                                    }}
                                                />
                                            }

                                            classes={{
                                                root: classes.selectRoot,
                                                select: classNames(classes.selectSelect, {[classes.errorBorder]: (errors.organization && errors.organization.country)}),
                                            }}
                                        >
                                            {
                                                countries.map((country, index) =>(
                                                    <MenuItem key={index} value={country} className={classes.selectItem}> {country} </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>

                                    {(errors.organization && errors.organization.country) &&
                                        <span className={classes.errorLabel}>{errors.organization.country}</span>
                                    }
                                </div>

                            </div>

                            <span className={classes.sectionTitle}>Primary Contact:</span>

                            <div className={classes.infoLine}>

                                <label htmlFor="salutation" className={classes.infoLabel}>Salutation</label>

                                <div className={classes.inputContainer}>
                                    <RadioGroup aria-label="gender" name="gender2" className={classes.salutationInput} value={primaryContact.salutation}  onChange={(e) => onChange('primaryContact', {salutation: e.target.value})} id="salutation">
                                        <FormControlLabel
                                            value="miss"
                                            control={<Radio classes={{root: classNames(classes.radioColor, {[classes.errorColor]: (errors.primaryContact && errors.primaryContact.salutation)}), checked: classes.radioColor }}/>}
                                            label="Miss"
                                            labelPlacement="end"
                                            classes={{
                                                root: classes.controlLabelRoot,
                                                label: classes.controlLabelLabel,
                                            }}
                                        />
                                        <FormControlLabel
                                            value="mrs"
                                            control={<Radio classes={{root: classNames(classes.radioColor, {[classes.errorColor]: (errors.primaryContact && errors.primaryContact.salutation)}), checked: classes.radioColor }}/>}
                                            label="Mrs"
                                            labelPlacement="end"
                                            classes={{
                                                root: classes.controlLabelRoot,
                                                label: classes.controlLabelLabel,
                                            }}
                                        />
                                        <FormControlLabel
                                            value="mr"
                                            control={<Radio classes={{root: classNames(classes.radioColor, {[classes.errorColor]: (errors.primaryContact && errors.primaryContact.salutation)}), checked: classes.radioColor }}/>}
                                            label="Mr"
                                            labelPlacement="end"
                                            classes={{
                                                root: classes.controlLabelRoot,
                                                label: classes.controlLabelLabel,
                                            }}
                                        />
                                    </RadioGroup>

                                    {(errors.primaryContact && errors.primaryContact.salutation) &&
                                    <span className={classes.errorLabel}>{errors.primaryContact.salutation}</span>
                                    }
                                </div>

                            </div>

                            <div className={classes.infoLine}>

                                <label htmlFor="firstName" className={classes.infoLabel}>First Name</label>

                                <div className={classes.inputContainer}>
                                    <input type="text" className={classNames(classes.infoInput, {[classes.errorBorder]: (errors.primaryContact && errors.primaryContact.firstName)})}
                                           id="firstName" value={primaryContact.firstName} onChange={(e) => onChange('primaryContact', {firstName: e.target.value})}
                                    />

                                    {(errors.primaryContact && errors.primaryContact.firstName) &&
                                    <span className={classes.errorLabel}>{errors.primaryContact.firstName}</span>
                                    }
                                </div>

                                <label htmlFor="lastName" className={classes.infoLabel}>Last Name</label>

                                <div className={classes.inputContainer}>
                                    <input type="text" className={classNames(classes.infoInput, {[classes.errorBorder]: (errors.primaryContact && errors.primaryContact.lastName)})}
                                           id="lastName" value={primaryContact.lastName} onChange={(e) => onChange('primaryContact', {lastName: e.target.value})}
                                    />

                                    {(errors.primaryContact && errors.primaryContact.lastName) &&
                                    <span className={classes.errorLabel}>{errors.primaryContact.lastName}</span>
                                    }
                                </div>

                            </div>

                            <div className={classes.infoLine}>

                                <label htmlFor="email" className={classes.infoLabel}>Email</label>

                                <div className={classes.inputContainer}>
                                    <input type="email" className={classNames(classes.infoInput, {[classes.errorBorder]: (errors.primaryContact && errors.primaryContact.email)})}
                                           id="email"  value={primaryContact.email} onChange={(e) => onChange('primaryContact', {email: e.target.value})}
                                    />

                                    {(errors.primaryContact && errors.primaryContact.email) &&
                                    <span className={classes.errorLabel}>{errors.primaryContact.email}</span>
                                    }
                                </div>

                                <label htmlFor="phone" className={classes.infoLabel}>Phone</label>

                                <div className={classes.inputContainer}>
                                    <input type="text" className={classNames(classes.infoInput, {[classes.errorBorder]: (errors.primaryContact && errors.primaryContact.phone)})}
                                           id="phone"  value={primaryContact.phone} onChange={(e) => onChange('primaryContact', {phone: e.target.value})}
                                    />

                                    {(errors.primaryContact && errors.primaryContact.phone) &&
                                    <span className={classes.errorLabel}>{errors.primaryContact.phone}</span>
                                    }
                                </div>

                            </div>

                            <div className={classes.infoLine}>
                                <label htmlFor="title" className={classes.infoLabel}>Title</label>

                                <div className={classes.inputContainer}>
                                    <input type="text" className={classNames(classes.infoInput, {[classes.errorBorder]: (errors.primaryContact && errors.primaryContact.title)})}
                                           id="title"  value={primaryContact.title} onChange={(e) => onChange('primaryContact', {title: e.target.value})}
                                    />

                                    {(errors.primaryContact && errors.primaryContact.title) &&
                                    <span className={classes.errorLabel}>{errors.primaryContact.title}</span>
                                    }
                                </div>

                                <div className={classes.blankTitle}/>
                            </div>

                            <div className={classes.waitingRoomContainer}>
                                <div className={classes.waitingRoom}>
                                    <label htmlFor="wRoom" className={classes.wRoomLabel}>How many waiting rooms will you equip with Aquii?</label>

                                    <div className={classes.inputContainer}>
                                        <input type="number" min={1} className={classNames(classes.wRoomInput, {[classes.errorBorder]: (errors.plan && errors.plan.numberWaitingRoom)})}
                                               value={numberWaitingRoom} id="wRoom" onChange={(e) => onChange('plan', {numberWaitingRoom: e.target.value})}
                                        />

                                        {(errors.plan && errors.plan.numberWaitingRoom) &&
                                        <span className={classes.errorLabel}>{errors.plan.numberWaitingRoom}</span>
                                        }
                                    </div>

                                </div>
                            </div>


                        </div>

                        <div className={classes.footer}>

                            <Button className={classNames(classes.backButton)} disabledClassName={classes.nextButtonDisabled} onClick={this.handleNextClick} disabled={!next}>
                                NEXT >
                            </Button>
                        </div>

                    </div>
                }

            </div>

        );
    }
}

RegistrationPage1.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

RegistrationPage1.defaultProps = {
    className: '',
    strings: {},
};

export default withStyles(styles)(RegistrationPage1);