import React, { Component } from 'react';
import PropTypes from 'prop-types'
import firebase from 'firebase'
import { withStyles } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import CircularProgress from '@material-ui/core/CircularProgress'
import HomePage from '../../Home'
import PatientsPage from '../../Patients'
import PatientProfilePage from '../../Patients/scenes/Profile'
import CreateAppointment from '../../Patients/scenes/NewAppointment'
import CreateAppointmentStaff from '../../Staff/scenes/NewAppointment'
import CreatePatient from '../../Patients/scenes/NewPatient'
import CreatePatient2 from '../../Patients/scenes/NewPatient/containers/NewPatientPage2Container'
import CreatePatient3 from '../../Patients/scenes/NewPatient/containers/NewPatientPage3Container'
import NewPatientForm from '../../Patients/scenes/NewPatientForm/components/FormAndDocuments'
import InvoicesPage from '../../../../common/scenes/Invoices'
import InboxPage from '../../../../common/scenes/Inbox'
import CreateStaffPage from '../../Staff/scenes/NewStaff'
import StaffProfilePage from '../../Staff/scenes/Profile'
import StaffPage from '../../Staff'
import StaffEditPage from '../../Staff/scenes/EditStaff'
import AppointmentsPage from '../../Appointments'
import NewEditFacilityPage from '../../Facility/scenes/NewEditFacility'
import FacilityListEmpty from '../../Facility/scenes/NewFacility/components/Home'
import Settings from '../../../../common/scenes/Settings'
import FacilityList from '../../Facility/scenes/NewFacility/components/FacilityList'
import EmptyPage from '../../Empty'
import LeftSideBar from '../../../../common/components/LeftSideBar/index'
import PrimarySearchAppBar from '../../../../common/components/PrimarySearchAppBar/index'
import {Switch, Route, Redirect} from 'react-router-dom'
import {isEmptyObject, objectToFormFormat} from '../../../../common/services/Util'
import Strings from '../../../../common/services/strings'
import globalContext from '../../../../common/services/context'
import moment from "moment";


const strings = Strings.ClinicSideBar

const styles = (theme) => ({
    wrapper: {
        display: 'flex',
        flexGrow: 1,
        height: 'auto',
        minHeight: '100vh',
        backgroundColor: 'white'
    },
    loadingContainer: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingErrorLabel: {
        position: 'relative',
        bottom: '60px',
        color: '#43425D',
        fontSize: '26px',
        fontFamily: 'Source Sans Pro',
    },
    loadingProgress: {
        position: 'relative',
        bottom: '60px',
        color: '#6798e5',
        animationDuration: '700ms',
    },
    root: {
        flexGrow: 1,
        height: 'auto',
        minHeight: '100vh',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
        fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif !important',
        backgroundColor: "#43425d",
    },
    main: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        margin: '0px',
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            marginLeft: '240px',
        }

    },
});

const menuItems = [
    {
        name: 'home',
        label: strings.home,
        icon: 'icon_home',
        icon_active: 'icon_home-active',
        action: ''
    },
    /*{
        name: 'activities',
        label: strings.activities,
        icon: 'icon_dashboard',
        icon_active: 'icon_dashboard-active',
        action: '/activities'
    },*/
    {
        name: 'inbox',
        label: strings.inbox,
        icon: 'icon_inbox',
        icon_active: 'icon_inbox-active',
        action: '/inbox'
    },
    {
        name: 'staff',
        label: strings.staff,
        icon: 'icon_products',
        icon_active: 'icon_products-active',
        action: '/staff'
    },
    /*{
        name: 'invoices',
        label: strings.invoices,
        icon: 'icon_Invoices',
        icon_active: 'icon_Invoices-active',
        action: '/invoices'
    },*/
    {
        name: 'patients',
        label: strings.patients,
        icon: 'icon_customers',
        icon_active: 'icon_customers-active',
        action: '/patients'
    },
    /*{
        name: 'chatRoom',
        label: strings.chatRoom,
        icon: 'icon_chat-room',
        icon_active: 'icon_chat-room-active',
        action: '/chat-room'
    },*/
    {
        name: 'calendar',
        label: strings.calendar,
        icon: 'icon_calendar',
        icon_active: 'icon_calendar-active',
        action: '/calendar'
    },
    {
        name: 'helpCenter',
        label: strings.helpCenter,
        icon: 'icon_help-center',
        icon_active: 'icon_help-center-active',
        action: '/help-center'
    },
    {
        name: 'settings',
        label: strings.settings,
        icon: 'icon_setting',
        icon_active: 'icon_setting-active',
        action: '/Settings'
    },

]

const notifications = {
    home: 0,
    // activities: 0,
    inbox: 0,
    staff: 0,
    // invoices: 0,
    patients: 0,
    // chatRoom: 0,
    calendar: 0,
    helpCenter: 0,
    settings: 0,
}



class ClinicPage extends Component {

    constructor(props) {

        super(props)

        let activeSideBarItem = this.getActiveSideBarItem()

        this.state = {
            mobileOpen: false,
            activeSideBarItem: activeSideBarItem,
            loading: true,
            loadingError: false
        }
        this.handleSideBarItemClick = this.handleSideBarItemClick.bind(this)
    }

    componentDidMount() {

        try {

            this.initialize()

        } catch (e) {

            this.setState({loadingError: e.message})
        }
    }

    componentWillUnmount() {

        if (this.syncPatientsQuery) {
            this.syncPatientsQuery.off()
        }

        if (this.syncStaffQuery) {
            this.syncStaffQuery.off()
        }

        this.clearGetPatientsInLoop()
    }

    initGetPatientsInLoop() {

        this.clearGetPatientsInLoop()

        this.getPatientsInLoopIntervalId = setInterval(() => this.getPatientsAppointedForTheDay(moment().format('YYYY-MM-DD')), 30000)
    }

    clearGetPatientsInLoop() {

        if (this.getPatientsInLoopIntervalId) {

            clearInterval(this.getPatientsInLoopIntervalId)
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps !== this.props) {


            if (prevProps.selectedFacility.item !== this.props.selectedFacility.item && this.props.selectedFacility.item) {

                globalContext.facility = this.props.selectedFacility.item

                if (isEmptyObject(this.props.selectedFacility.item)) {

                    this.props.setSelectedFacilityChecked(true)

                } else {

                    this.getSettings(this.props.selectedFacility.item.id)
                }

                let loading = this.getLoading()
                this.setState({loading})
            }

            if (prevProps.settings !== this.props.settings && !this.props.settings.isFetching) {

                /*if (this.syncPatientsQuery) {
                    this.syncPatientsQuery.off()
                    this.syncStorePatients()
                } else {
                    this.syncStorePatients()
                }

                if (this.syncStaffQuery) {
                    this.syncStaffQuery.off()
                    this.syncStoreStaff()
                } else {
                    this.syncStoreStaff()
                }*/

                this.getStaff()
                this.getPatients()
                this.getPatientsAppointedForTheDay(moment(new Date()).format('YYYY-MM-DD'));

                console.log('settings',prevProps.settings, this.props.settings)

            }

            if (prevProps.selectedFacility !== this.props.selectedFacility && this.props.selectedFacility.checked) {

                let loading = this.getLoading()
                this.setState({loading})
                this.redirectToFacilityChoiceIfNecessary()
            }

            if (prevProps.facilities.isFetching !== this.props.facilities.isFetching) {
                let loading = this.getLoading()
                this.setState({loading})
            }

            if (prevProps.patients.fetched !== this.props.patients.fetched) {

                if (this.props.patients.fetched) {

                    this.initGetPatientsInLoop()

                } else {

                    this.clearGetPatientsInLoop()
                }

                let loading = this.getLoading()
                this.setState({loading})
            }

            if (prevProps.staff.fetched !== this.props.staff.fetched) {
                let loading = this.getLoading()
                this.setState({loading})
            }

            if (prevProps.appointments.isFetching !== this.props.appointments.isFetching) {
                let loading = this.getLoading()
                this.setState({loading})
            }

            if ((prevProps.patients.fetched !== this.props.patients.fetched || prevProps.appointments.isFetching !== this.props.appointments.isFetching) &&
                    this.props.patients.fetched && !this.props.appointments.isFetching) {
                this.generatePatientsInfo()
            }
        }
    }

    navigateToFacilityChoice() {
        const welcomePagePath = '/dashboard-clinic/facilities'
        this.props.history.push(welcomePagePath)
    }

    navigateToMainPage(menuAction) {
        let { history, match } = this.props
        history.push(match.url + menuAction)
    }

    getLoading() {
        const {facilities, patients, staff, selectedFacility, settings, currentOrganization, appointments} = this.props
        return (currentOrganization.isFetching || facilities.isFetching || settings.isFetching || !patients.fetched || !staff.fetched || appointments.isFetching) && !isEmptyObject(selectedFacility.item)
    }

    async initialize() {

        const {setFacilities, setFacilitiesFetchingStatus, setSelectedFacility, setSelectedFacilityChecked,
                setCurrentOrganization, setCurrentOrganizationFetchingStatus, setSettings} = this.props

        let currentOrganization = await this.getCurrentOrganization()
        //console.log(currentOrganization);

        if (currentOrganization) {

            setCurrentOrganization(currentOrganization)
            setCurrentOrganizationFetchingStatus(false)

            let promises = []
            promises.push(this.getOrganizationSettings(currentOrganization.id))
            promises.push(this.getFacilities(currentOrganization.id))

            let result = await Promise.all(promises)
            let orgSettings = result[0]
            let facilities = result[1]


            if (orgSettings) {

                setSettings(orgSettings)

                let initialFacility = globalContext.facility
                let syncSelectedFacility

                if (!initialFacility || isEmptyObject(initialFacility)) {

                    syncSelectedFacility = null

                } else {

                    syncSelectedFacility = facilities.find((element) => element.id === initialFacility.id)
                }

                if (!syncSelectedFacility || isEmptyObject(syncSelectedFacility)) {

                    if (facilities.length === 1) {

                        setSelectedFacility(facilities[0])
                        globalContext.facility = facilities[0]

                    } else {

                        setSelectedFacility({})
                        globalContext.facility = {}
                    }

                } else {

                    setSelectedFacility(syncSelectedFacility)
                    globalContext.facility = syncSelectedFacility
                }

                setSelectedFacilityChecked(true)
                setFacilities(facilities)
                setFacilitiesFetchingStatus(false)

            } else {

                this.setState({loadingError: 'No organization settings found'})
            }

        } else {

            this.setState({loadingError: 'No organization found'})
        }
    }

    async getCurrentOrganization() {

        try {

            let snaps = await firebase.firestore().collection('Organization').where('name', '==', 'default').limit(1).get()
            let organizations = []

            snaps.forEach((snap) => {
                organizations.push({id: snap.id, ...snap.data()})
            })

            if (organizations.length > 0) {

                return organizations[0]

            } else {

                return null
            }

        } catch (e) {

            // this.setState({loadingError: e.message})
            throw e
        }
    }

    async getOrganizationSettings (organizationId) {

        try {

            let snaps = await firebase.firestore().collection('Settings')
                .where('organization.reference', '==', 'Organization/' + organizationId)
                .limit(1).get()

            let orgSettings = []

            snaps.forEach((snap) => {
                orgSettings.push(snap.data())
            })

            if (orgSettings.length > 0) {

                return orgSettings[0]

            } else {

                return null
            }

        } catch (e) {

            // this.setState({loadingError: e.message})
            throw e
        }
    }

    async getFacilities (organizationId) {

        try {

            let snaps = await firebase.firestore().collection('Location').where('managingOrganization.reference', '==', 'Organization/' + organizationId).get()
            let facilities = []

            snaps.forEach((snap) => {
                facilities.push({id: snap.id, ...snap.data()})
            })

            if (facilities.length > 0) {

                return facilities

            } else {

                return []
            }

        } catch (e) {

            // this.setState({loadingError: e.message})
            throw e
        }
    }

    async getSettings(locationId) {

        const {settings, setSettings, setSettingsFetchingStatus} = this.props

        try {

            let snaps = await firebase.firestore()
                        .collection('Settings')
                        .where('location.reference', '==', 'Location/' + locationId)
                        .limit(1).get();

            let locationSettings = [];

            snaps.forEach((snap) => {
                locationSettings.push(snap.data())
            })

            if (locationSettings.length < 0) {

                // this.setState({loadingError: 'Oups, something went wrong !'})
                setSettingsFetchingStatus(false)

            } else {

                setSettings(Object.assign({}, settings.item, locationSettings[0]))
                setSettingsFetchingStatus(false);
            }

        } catch (e) {

            throw (e)
        }
    }

    async getStaff() {

        const {settings, selectedFacility, setStaff, setStaffFetchedStatus} = this.props

        let res = await fetch(settings.item.fhirServer.baseUrl + '/practitionerrole?location=' + selectedFacility.item.id,  {
            mode: 'cors',
        });

        if (res.status === 200) {

            let practitionerRoles = []
            let practitioners = []
            let promises = []

            let jsonRes = await res.json()

            for (let entry of jsonRes.entry) {

                practitionerRoles.push(entry.resource)
            }

            for (let practitionerRole of practitionerRoles) {

                let practitionerId = practitionerRole.practitioner.reference
                practitionerId = practitionerId.split('/')
                practitionerId = practitionerId[practitionerId.length - 1]

                promises.push(fetch(settings.item.fhirServer.baseUrl + '/practitioner/' + practitionerId,  {
                    mode: 'cors',
                }))
            }

            let result = await Promise.all(promises)

            for (let i = 0; i < result.length; i++) {

                if (result[i].status === 200) {

                    let jsonRes = await result[i].json()
                    let name = ''
                    let firstName = ''
                    let lastName = ''
                    let info = ''
                    let avatar = ''
                    let phone, cellPhone, homePhone, workPhone = ''
                    let email = ''
                    let initials = ''
                    let id = jsonRes.id
                    let address = {address1: '', address2: ''}

                    if (jsonRes.name && jsonRes.name.length > 0) {

                        if (jsonRes.name[0].family) {
                            name = jsonRes.name[0].family
                            lastName = name
                        }

                        if (jsonRes.name[0].given && jsonRes.name[0].given.length > 0) {

                            for (let given of jsonRes.name[0].given) {
                                name += ' ' + given
                                firstName += given + ' '
                            }

                            firstName = firstName.trim()
                        }

                        initials = name.match(/\b\w/g) || [];
                        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
                    }

                    if (jsonRes.telecom && jsonRes.telecom.length > 0) {

                        let phones = jsonRes.telecom.filter(item => item.system === 'phone')
                        let cellPhones = jsonRes.telecom.filter(item => item.system === 'phone' && item.use === 'mobile')
                        let workPhones = jsonRes.telecom.filter(item => item.system === 'phone' && item.use === 'work')
                        let homePhones = jsonRes.telecom.filter(item => item.system === 'phone' && item.use === 'home')
                        let emails = jsonRes.telecom.filter(item => item.system === 'email')

                        phone = (phones.length > 0) ? phones[0].value : phone
                        cellPhone = (cellPhones.length > 0) ? cellPhones[0].value : cellPhone
                        workPhone = (workPhones.length > 0) ? workPhones[0].value : workPhone
                        homePhone = (homePhones.length > 0) ? homePhones[0].value : homePhone
                        email = (emails.length > 0) ? emails[0].value : email
                    }

                    if (jsonRes.address && jsonRes.address.length > 0) {

                        let city = jsonRes.address[0].city ? jsonRes.address[0].city.charAt(0).toUpperCase() + jsonRes.address[0].city.slice(1).toLowerCase() : ''
                        let state = jsonRes.address[0].state ? jsonRes.address[0].state.charAt(0).toUpperCase() + jsonRes.address[0].state.slice(1).toLowerCase() : ''
                        let postalCode = jsonRes.address[0].postalCode ? jsonRes.address[0].postalCode : ''
                        let street = (jsonRes.address[0].line && jsonRes.address[0].line.length > 0) ? jsonRes.address[0].line[0].charAt(0).toUpperCase() + jsonRes.address[0].line[0].slice(1).toLowerCase() : ''

                        address = {address1: city + ', ' + state + ' ' + postalCode, address2: street}
                    }

                    if (practitionerRoles[i].specialty && practitionerRoles[i].specialty.length > 0) {

                        for (let specialty of practitionerRoles[i].specialty) {

                            if (specialty.coding && specialty.coding.length > 0) {

                                info += ', ' + specialty.coding[0].display
                            }
                        }

                        info = info.replace(/^,\s/, '')
                    }

                    if (jsonRes.photo && jsonRes.photo.length > 0) {

                        if (jsonRes.photo[0].data && jsonRes.photo[0].data !== '') {
                            avatar = jsonRes.photo[0].data
                        }

                        if (jsonRes.photo[0].url && jsonRes.photo[0].url !== '') {
                            avatar = jsonRes.photo[0].url
                        }
                    }


                    practitioners.push({practitionerRole: practitionerRoles[i], initialVersion: jsonRes, id, address,
                        name, info, avatar, phone, cellPhone, homePhone, workPhone, email, initials, firstName, lastName})
                }
            }

            setStaff(practitioners)
            setStaffFetchedStatus(true)

        } else {

            this.setState({loadingError: 'Staff cannot be fetched'})
        }
    }

    async getPatients() {

        const {settings, currentOrganization, setPatients, setPatientsFetchedStatus} = this.props

        let res = await fetch(settings.item.fhirServer.baseUrl + '/patient?managingOrganization=' + currentOrganization.item.id,  {
            mode: 'cors',
        });

        if (res.status === 200) {

            let patients = []
            let patientLists = []

            let jsonRes = await res.json()

            for (let entry of jsonRes.entry) {

                patients.push(entry.resource)
            }

            for (let i = 0; i < patients.length; i++) {
                let name = ''
                let firstName = ''
                let lastName = ''
                let info = ''
                let avatar = ''
                let phone = ''
                let email = ''
                let initials = ''
                let id = patients[i].id

                if (patients[i].name && patients[i].name.length > 0) {

                    if (patients[i].name[0].family) {
                        name = patients[i].name[0].family
                        firstName = name
                    }

                    if (patients[i].name[0].given && patients[i].name[0].given.length > 0) {

                        for (let given of patients[i].name[0].given) {
                            name += ' ' + given
                            lastName += given + ' '
                        }

                        lastName = lastName.trim()
                    }

                    initials = name.match(/\b\w/g) || [];
                    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
                }

                if (patients[i].telecom && patients[i].telecom.length > 0) {

                    let phones = patients[i].telecom.filter(item => item.system === 'phone')
                    let emails = patients[i].telecom.filter(item => item.system === 'email')

                    if (phones.length > 0) {
                        phone = phones[0].value
                    }

                    if (emails.length > 0) {
                        email = emails[0].value
                    }
                }

                if (patients[i].photo && patients[i].photo.length > 0) {

                    if (patients[i].photo[0].data && patients[i].photo[0].data !== '') {
                        avatar = patients[i].photo[0].data
                    }

                    if (patients[i].photo[0].url && patients[i].photo[0].url !== '') {
                        avatar = patients[i].photo[0].url
                    }
                }

                patientLists.push({initialVersion: patients[i], id, name, info, avatar, phone, email, initials, firstName, lastName})
                //console.log(patients[i], id, name, info, avatar, phone, email, initials, firstName, lastName)
            }

            patientLists.sort((first, second) => {
                return (first.name >= second.name) ? 1 : -1
            })

            setPatients(patientLists)
            setPatientsFetchedStatus(true)

        } else {

            this.setState({loadingError: 'Appointments cannot be fetched'})
        }
    }

    async getPatient(id) {

        let res = await fetch(this.props.settings.item.fhirServer.baseUrl + '/patient/' + id,  {
            mode: 'cors',
        });

        if (res.status === 200) {
            let jsonRes = await res.json();
            //console.log(jsonRes);
            let name = ''
            let firstName = ''
            let lastName = ''
            let info = ''
            let avatar = ''
            let phone = ''
            let email = ''
            let initials = ''
            let address = {address1: '', address2: ''}

            if (jsonRes.name && jsonRes.name.length > 0) {

                if (jsonRes.name[0].family) {
                    name = jsonRes.name[0].family
                    firstName = name
                }

                if (jsonRes.name[0].given && jsonRes.name[0].given.length > 0) {

                    for (let given of jsonRes.name[0].given) {
                        name += ' ' + given
                        lastName += given + ' '
                    }

                    lastName = lastName.trim()
                }

                initials = name.match(/\b\w/g) || [];
                initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
            }

            if (jsonRes.telecom && jsonRes.telecom.length > 0) {

                let phones = jsonRes.telecom.filter(item => item.system === 'phone')
                let emails = jsonRes.telecom.filter(item => item.system === 'email')

                if (phones.length > 0) {
                    phone = phones[0].value
                }

                if (emails.length > 0) {
                    email = emails[0].value
                }
            }

            if (jsonRes.address && jsonRes.address.length > 0) {

                let city = jsonRes.address[0].city ? jsonRes.address[0].city.charAt(0).toUpperCase() + jsonRes.address[0].city.slice(1).toLowerCase() : ''
                let state = jsonRes.address[0].state ? jsonRes.address[0].state.charAt(0).toUpperCase() + jsonRes.address[0].state.slice(1).toLowerCase() : ''
                let postalCode = jsonRes.address[0].postalCode ? jsonRes.address[0].postalCode : ''
                let street = (jsonRes.address[0].line && jsonRes.address[0].line.length > 0) ? jsonRes.address[0].line[0].charAt(0).toUpperCase() + jsonRes.address[0].line[0].slice(1).toLowerCase() : ''

                address = {address1: city + ', ' + state + ' ' + postalCode, address2: street}
            }

            if (jsonRes.photo && jsonRes.photo.length > 0) {

                if (jsonRes.photo[0].data && jsonRes.photo[0].data !== '') {
                    avatar = jsonRes.photo[0].data
                }

                if (jsonRes.photo[0].url && jsonRes.photo[0].url !== '') {
                    avatar = jsonRes.photo[0].url
                }
            }

            return {
                initialVersion: jsonRes,
                id,
                name,
                info,
                avatar,
                phone,
                email,
                initials,
                firstName,
                lastName,
                address
            };
            //console.log(entry.resource, id, name, info, avatar, phone, email, initials, firstName, lastName)
        } else {
            this.setState({loadingError: 'Patient cannot be fetched'})
        }
    }

    getPatientsAppointedForTheDayForStatus = async (date, status) => {

        const {settings} = this.props

        //console.log(date);

        try {

            let url = settings.item.fhirServer.baseUrl + '/appointment?status=' + status + '&date=' + date;
            let patientList = [];
            let res = await fetch(url,  {
                mode: 'cors',
            });

            if (res.status === 200) {

                let jsonRes = await res.json();
                //console.log('appointment', jsonRes)

                let promises = []
                let participatingPractitioners = []

                for (let entry of jsonRes.entry) {

                    let participants = entry.resource.participant;
                    let participatingPatientID;
                    let participatingPractitioner;

                    for (let participant of participants) {

                        let ref = participant.actor.reference;

                        if (ref.split('/')[0].toLowerCase() === 'practitioner') {
                            participatingPractitioner = participant
                            participatingPractitioner.id = ref.split('/')[1]
                        } else if (ref.split('/')[0].toLowerCase() === 'patient') {
                            participatingPatientID = ref.split('/')[1];
                        }
                    }

                    participatingPractitioners.push(participatingPractitioner)
                    participatingPatientID ? promises.push(this.getPatient(participatingPatientID)) : promises.push(participatingPatientID)
                }

                let participatingPatients = await Promise.all(promises)

                participatingPatients.forEach((participatingPatient, index) => {

                    if (participatingPatient && participatingPractitioners[index]) {

                        participatingPractitioners[index].appointment = jsonRes.entry[index].resource
                        participatingPractitioners[index].patient = participatingPatient
                        participatingPatient.practitioner = participatingPractitioners[index]
                        participatingPatient.appointment = jsonRes.entry[index].resource
                        patientList.push(participatingPatient);
                    }
                })

                return {patients: patientList, practitioners: participatingPractitioners};
            }

            return {patients: [], practitioners: []}

        } catch (e) {

            throw e
        }

    };

    getPatientsAppointedForTheDay = async (date) => {

        const {setAppointmentsValue, setAppointmentsFetchingStatus, setHomeCounter} = this.props

        try {

            let promises = []

            promises.push(this.getPatientsAppointedForTheDayForStatus(date, 'booked'))
            promises.push(this.getPatientsAppointedForTheDayForStatus(date, 'checked-in'))
            promises.push(this.getPatientsAppointedForTheDayForStatus(date, 'fulfilled'))

            let result = await Promise.all(promises)

            let {patients: bookedPatients, practitioners: bookedPractitioners} = result[0]
            let {patients: checkInPatients, practitioners: checkInPractitioners} = result[1]
            let {patients: fulfilledPatients, practitioners: fulfilledPractitioners} = result[2]

            let appointments = bookedPatients.concat(checkInPatients).concat(fulfilledPatients)

            let counterPerPractitioner = {}

            appointments.forEach((appointment) => {

                if (!counterPerPractitioner[appointment.practitioner.id]) {

                    counterPerPractitioner[appointment.practitioner.id] = {
                        appointments: 0,
                        noShow: 0,
                        available: 0,
                    }

                }

                counterPerPractitioner[appointment.practitioner.id].appointments++

                if (appointment.appointment.status === 'booked' && moment(appointment.appointment.end).diff(moment(new Date()), 'minutes') < 0) {

                    counterPerPractitioner[appointment.practitioner.id].noShow++
                }

                if (appointment.appointment.status === 'checked-in' && moment(appointment.appointment.end).diff(moment(new Date()), 'minutes') >= 0) {

                    counterPerPractitioner[appointment.practitioner.id].available++
                }

            })

            appointments.sort((first, second) => {

                let firstAppointedTime = moment(first.appointment.start);
                let secondAppointedTime = moment(second.appointment.start);

                return firstAppointedTime.diff(secondAppointedTime, 'minutes') ? 1 : -1;
            });

            let activeCheckInPatients = checkInPatients.filter((patient) => {
                return moment(patient.appointment.end).diff(moment(new Date()), 'minutes') >= 0
            })

           /* let activeCheckInPractitioners = checkInPractitioners.filter((practitioner) => {
                return moment(practitioner.appointment.end).diff(moment(new Date()), 'minutes') >= 0
            })*/

            activeCheckInPatients.forEach((patient, index) => {

                if (patient) {

                    patient.info = 'Seeing Dr ' + patient.practitioner.actor.display.toUpperCase() + ' AT ' + moment(patient.appointment.start).format('LT');
                    patient.status = 'active'
                }
            })

            /*activeCheckInPatients.sort(function(first, second) {

                let choppedInfo1 = first.info.split(' ');
                let choppedInfo2 = second.info.split(' ');
                let firstAppointedTime = moment(choppedInfo1[choppedInfo1.length - 2] + choppedInfo1[choppedInfo1.length - 1], 'HH:mm');
                let secondAppointedTime = moment(choppedInfo2[choppedInfo2.length - 2] + choppedInfo2[choppedInfo2.length - 1], 'HH:mm');

                return firstAppointedTime.diff(secondAppointedTime, 'minutes') ? 1 : -1;
            });*/

            let activeBookedPatients = bookedPatients.filter((patient, index) => {

                if (patient) {

                    return moment(patient.appointment.end).diff(moment(new Date()), 'minutes') >= 0
                }

                return false
            })

            /*let activeBookedPractitioners = bookedPractitioners.filter((practitioners, index) => {

                if (practitioners && bookedPatients[index]) {

                    return moment(practitioners.appointment.end).diff(moment(new Date()), 'minutes') >= 0
                }

                return false
            })*/

            activeBookedPatients.forEach((patient, index) => {

                if (patient) {

                    patient.info = 'Seeing Dr ' + patient.practitioner.actor.display.toUpperCase() + ' AT ' + moment(patient.appointment.start).format('LT');
                }
            })

            /*activeBookedPatients.sort(function(first, second) {

                let choppedInfo1 = first.info.split(' ');
                let choppedInfo2 = second.info.split(' ');
                let firstAppointedTime = moment(choppedInfo1[choppedInfo1.length - 2] + choppedInfo1[choppedInfo1.length - 1], 'HH:mm');
                let secondAppointedTime = moment(choppedInfo2[choppedInfo2.length - 2] + choppedInfo2[choppedInfo2.length - 1], 'HH:mm');

                return firstAppointedTime.diff(secondAppointedTime, 'minutes') ? 1 : -1;
            });*/

            let servedCounter = fulfilledPatients.length + (checkInPatients.length - activeCheckInPatients.length)
            let noShowCounter = bookedPatients.length - activeBookedPatients.length
            let inWaitingRoomCounter = activeCheckInPatients.length
            let scheduledCounter = servedCounter + noShowCounter + inWaitingRoomCounter + activeBookedPatients.length

            setHomeCounter('scheduled', scheduledCounter)
            setHomeCounter('served', servedCounter)
            setHomeCounter('noShow', noShowCounter)
            setHomeCounter('inWaitingRoom', inWaitingRoomCounter)
            setHomeCounter('perStaff', counterPerPractitioner)

            setAppointmentsValue(appointments)
            setAppointmentsFetchingStatus(false)

        } catch (e) {

            throw e
        }

    };

    generatePatientsInfo() {

        const patients = this.props.patients.items
        const appointments = this.props.appointments.items

        let newPatients = patients.map((patient) => {

            let copyPatient = Object.assign({}, patient)

            let appointment = appointments.find((appointment) => appointment.id === copyPatient.id)

            if (appointment) {

                copyPatient.info = 'Seeing Dr ' + appointment.practitioner.actor.display.toUpperCase() + ' AT ' + moment(appointment.appointment.start).format('LT')

            } else {

                copyPatient.info = 'No appointment scheduled today'
            }

            return copyPatient
        })

        this.props.setPatients(newPatients)
    }

    async getCurrentFacility() {

        try {

            let snaps = await firebase.firestore().collection('Location').limit(1).get();

            let location = [];

            snaps.forEach((snap) => {
                location.push(snap.data())
            })

            if (location.length < 0) {

                this.setState({loadingError: 'Oups, something went wrong !'})

            } else {

                this.props.setCurrentFacility(location[0])
                this.props.setCurrentFacilityFetchingStatus(false);
            }

        } catch (e) {

            console.log('location error : ', e)
            this.setState({loadingError: 'Oups, something went wrong !'})
        }
    }

    syncStorePatients() {

        const {setPatients, setPatientsFetchedStatus, selectedFacility: facility} = this.props

        if (facility && !isEmptyObject(facility)) {

            const facilityId = globalContext.facility.key;
            // const start = moment().startOf('day').unix();
            // const end = moment().endOf('day').unix();

            this.syncPatientsQuery = firebase.database()
                .ref("appointments/" + facilityId)
                .orderByChild("dateAppt")
            // .startAt(start)
            // .endAt(end);

            this.syncPatientsQuery.on('value', (data) => {

                let patients = []
                let appts = data.val();

                for (let x in appts) {

                    const val = appts[x];
                    let initials = val.metadata.name.match(/\b\w/g) || [];
                    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

                    const entry = {
                        id: val.patientID,
                        name: val.metadata.name,
                        info: "Seeing " + val.metadata.seeing + " at " + moment.unix(val.dateAppt).format("h:mm a"),
                        phone: val.metadata.phone,
                        avatar: initials
                    }

                    patients.push(entry);
                }

                setPatients(patients)
                setPatientsFetchedStatus(true)
            })
        }
    }

    syncStoreStaff() {
        // get staff for this facility

        const {setStaff, setStaffFetchedStatus, selectedFacility: facility} = this.props

        if (facility && !isEmptyObject(facility)) {

            let facilityId = globalContext.facility.key
            if (facilityId == null) {
                const h = createBrowserHistory({forceRefresh: true})
                h.push("/")
                return
            }

            this.syncStaffQuery = firebase.database()
                .ref("clinicStaff")
                .orderByChild("facilityId")
                .equalTo(facilityId)

            this.syncStaffQuery.on('value', async (snapshot) => {

                let promises = []

                snapshot.forEach((childSnapshot) => {
                    let staffId = childSnapshot.val().providerId
                    let pr = firebase.database().ref('providers').child(staffId).once('value')
                    promises.push(pr)
                })

                let staffSnapshot = await Promise.all(promises)

                const staff = staffSnapshot.map(aSnapshot => {

                    let prov = aSnapshot.val()
                    prov.key = aSnapshot.key

                    let name = prov.firstName.toUpperCase() + " " + prov.lastName.toUpperCase()

                    let initials = name.match(/\b\w/g) || [];
                    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

                    // name = "DR " + name

                    const data = { id: aSnapshot.key, name: name, appointement: 25, noShow: 3, available: 1, phone: prov.phoneNumber, info: 'Family Medicine', avatar: initials }
                    return data
                })

                setStaff(staff)
                setStaffFetchedStatus(true)
            })
        }
    }

    getActiveSideBarItem() {

        let { match, history } = this.props
        let activeSideBarItem
        let index = history.location.pathname.indexOf(match.url)

        if (index > -1) {

            let matchedStr = history.location.pathname.substring(index)
            matchedStr = matchedStr.replace(match.url, '').substring(1).split('/')

            if (matchedStr[0] === 'facilities') {
                activeSideBarItem = 'home'
            } else if (matchedStr[0] !== '') {
                activeSideBarItem = matchedStr[0]
            } else {
                activeSideBarItem = 'home'
            }

        } else {
            activeSideBarItem = 'home'
        }

        return activeSideBarItem
    }

    handleDrawerToggle = () => {
        this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
    }

    handleSideBarItemClick(menuName, menuAction) {

        const {selectedFacility} = this.props

        if (selectedFacility && selectedFacility.item && !isEmptyObject(selectedFacility.item)) {

            this.setState({ activeSideBarItem: menuName })
            this.navigateToMainPage(menuAction)
        }
    }

    redirectToFacilityChoiceIfNecessary() {

        const welcomePagePath = '/dashboard-clinic/facilities'

        let {history, selectedFacility} = this.props

        if (history.location.pathname.indexOf(welcomePagePath) <= -1) {

            if (!selectedFacility.item || isEmptyObject(selectedFacility.item)) {

                this.setState({ activeSideBarItem: 'home' })
                this.navigateToFacilityChoice()
            }
        }
    }


    render() {
        const { classes, match, selectedFacility, facilities, setSelectedFacility} = this.props
        const facility = selectedFacility.item ? selectedFacility.item : {}
        let { mobileOpen, activeSideBarItem, loading, loadingError } = this.state
        // console.log(this.props);

        return (
            <div className={classes.wrapper}>

                {loadingError &&
                    <div className={classes.loadingContainer}>
                        <span className={classes.loadingErrorLabel}>{loadingError}</span>
                    </div>
                }

                {loading && !loadingError &&
                    <div className={classes.loadingContainer}>
                        <CircularProgress
                            variant="indeterminate"
                            className={classes.loadingProgress}
                            size={24}
                            thickness={4}
                        />
                    </div>
                }

                {!loading && !loadingError &&
                    <div className={classes.root}>

                        <PrimarySearchAppBar handleDrawerToggle={this.handleDrawerToggle} match={match} selectedFacility={facility}
                                             facilities={facilities.items} onFacilityClick={setSelectedFacility}
                        />

                        <LeftSideBar menuItems={menuItems} notifications={notifications} match={match}
                                     activeMenuItem={activeSideBarItem} handleDrawerToggle={this.handleDrawerToggle}
                                     mobileOpen={mobileOpen} onClickMenuItem={this.handleSideBarItemClick}
                        />

                        <main className={classes.main}>
                            <Switch>
                                <Route path={`${match.url}`} exact component={HomePage} />

                                <Route path={`${match.url}/patients`}>
                                    <Switch>
                                        <Route path={`${match.url}/patients`} component={PatientsPage} exact />
                                        <Route path={`${match.url}/patients/:patientId/view`} component={PatientProfilePage} exact />
                                        <Route path={`${match.url}/patients/:patientId/create-new-appointment`} component={CreateAppointment} exact />
                                        <Route path={`${match.url}/patients/create-new-patient`} component={CreatePatient} exact />
                                        <Route path={`${match.url}/patients/create-new-patient2`} component={CreatePatient2} exact />
                                        <Route path={`${match.url}/patients/create-new-patient3`} component={CreatePatient3} exact />
                                        <Route path={`${match.url}/patients/new-patient-forms`} component={NewPatientForm} exact />
                                    </Switch>
                                </Route>

                                <Route path={`${match.url}/appointments`}>
                                    <Switch>
                                        <Route path={`${match.url}/appointments/:appointmentStatus`} component={AppointmentsPage} />
                                        <Redirect to={`${match.url}/appointments/all`} />
                                    </Switch>
                                </Route>

                                <Route path={`${match.url}/invoices`} component={InvoicesPage} exact />
                                <Route path={`${match.url}/inbox`} component={InboxPage} />

                                <Route path={`${match.url}/staff`}>
                                    <Switch>
                                        <Route path={`${match.url}/staff`} component={StaffPage} exact />
                                        <Route path={`${match.url}/staff/:id/view`} component={StaffProfilePage} exact />
                                        <Route path={`${match.url}/staff/:staffId/create-new-appointment`} component={CreateAppointmentStaff} exact />
                                        <Route path={`${match.url}/staff/:staffId/edit`} component={StaffEditPage} exact />
                                        <Route path={`${match.url}/staff/create-new-staff`} component={CreateStaffPage} exact />
                                    </Switch>
                                </Route>

                                <Route path={`${match.url}/facilities`}>
                                    <Switch>
                                        <Route path={`${match.url}/facilities`} component={FacilityList} exact />
                                        <Route path={`${match.url}/facilities/create-edit-facility`} component={NewEditFacilityPage} exact />
                                    </Switch>
                                </Route>

                                <Route path={`${match.url}/facilities-empty`} component={FacilityListEmpty} exact />
                                <Route path={`${match.url}/settings`} component={Settings} exact />

                                <Route path={`${match.url}`} component={EmptyPage} />
                            </Switch>
                        </main>

                    </div>
                }
            </div>


        );
    }
}

ClinicPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ClinicPage);
