import React, { Component } from "react";

class Input extends Component {
  state = {};
  render() {
    const { inputData } = this.props;
    return (
      <input
        type={inputData.type}
        id={inputData.id}
        className={inputData.className}
        placeholder={inputData.placeholder}
        required={inputData.required}
        autoFocus={inputData.autoFocus}
        onChange={this.props.onChangeText}
      />
    );
  }
}

export default Input;
