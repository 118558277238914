import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import PersonItem from './PersonItem'
import PersonCount from './PersonCount'
import {Pagination} from '../../Pagination'
import Button from '../../Button'
import classNames from 'classnames'
import Strings from '../../../services/strings'

const strings = Strings.PersonList

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'flex-start',
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: 'inherit',
    },
    search: {

    },
    firstLine: {
        marginBottom: '0px'
    },
    button: {
        height: '50px',
        width: 'auto',
        minWidth: '100px',
        margin: '0px 0px 0px 0px',
        Padding: '0px 15px',
        fontSize: '18px',
        backgroundColor: '#3B86FF !important',
    },
    blank: {
        maxWidth: '570px',
        height: '0px',
        margin: '5px 15px',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% - 20px)',
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 10px)',
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(50% - 30px) !important',
            display: 'flex !important'
        },
    },
    justify: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center !important'
        }
    },
    pagination: {
        maxWidth: '570px',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100%) !important',
            margin: '5px 0px !important',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100%)',
            justifyContent: 'center',
            margin: '5px 0px'
        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(50%) !important',
            margin: '5px 0px !important',
            justifyContent: 'flex-end',
        },
    }
});

class PersonList extends React.Component {

    constructor(props) {

        super(props)
        this.state = {searchInput: '', startPage: 1, selectedPage: 1}
        this.matchedItems = this.props.list
        this.updateMatchedItems()
        this.displayedItems = this.matchedItems.slice(0, this.props.itemPerPage)
        this.handlePageChange = this.handlePageChange.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        console.log('PersonList componentDidUpdate before props test : ', this.props, prevProps)

        if (prevProps.list !== this.props.list || prevProps.startingWith !== this.props.startingWith || prevProps.contains !== this.props.contains) {
            let newSelectedPage = this.updateMatchedItems()
            let newStartPage = this.state.selectedPage === newSelectedPage ? this.state.startPage : 1
            console.log('PersonList componentDidUpdate within props test section : ', this.props, prevProps)
            this.setState({selectedPage: newSelectedPage, startPage: newStartPage})
        }
    }

    handlePageChange(newSelectedPage, startPage) {
        newSelectedPage = this.updateDisplayedItems(newSelectedPage)
        this.setState({selectedPage: newSelectedPage, startPage: startPage})
    }

    updateMatchedItems() {

        const {startingWith, contains} = this.props
        const {selectedPage} = this.state
        this.matchedItems = this.props.list

        this.matchedItems = this.props.list.filter((person) => {

            let match = true
            let regex, pattern

            if (startingWith !== undefined && startingWith !== '' && startingWith !== null) {
                pattern = '^(' + startingWith.toLowerCase() + ')'
                regex = new RegExp(pattern)
                match = match && regex.test(person.name.toLowerCase())
            }

            if (match && contains !== undefined && contains !== '' && contains !== null) {
                pattern =  contains.toLowerCase()
                regex = new RegExp(pattern)
                match = match && regex.test(person.name.toLowerCase())
            }

            return match
        })

        let newSelectedPage = this.updateDisplayedItems(selectedPage)

        return newSelectedPage
    }

    updateDisplayedItems(selectedPage) {

        let {itemPerPage} = this.props
        let newSelectedPage = selectedPage

        if (this.matchedItems.length > 0) {

            this.displayedItems = this.matchedItems.slice((selectedPage - 1) * itemPerPage, selectedPage * itemPerPage)

            if (this.displayedItems.length <= 0) {
                newSelectedPage = 1
                this.displayedItems = this.matchedItems.slice(0, itemPerPage)
            }

        } else {
            this.displayedItems = []
        }

        return newSelectedPage
    }

    render() {

        let { classes, countLabel, itemPerPage, paginationNumberDisplayedIndex, justify, countable,
            withButton, paginate, className, buttonLabel, onButtonClick, onItemClick } = this.props;
        let {selectedPage, startPage} = this.state
        let totalPage = Math.ceil(this.matchedItems.length / itemPerPage)
        paginationNumberDisplayedIndex = Math.min(totalPage, paginationNumberDisplayedIndex)
        // console.log(this.props);

        return (
            <div className={classNames(classes.root, className)}  >
                {(countable || withButton) &&
                    <Grid container item xs={12} justify={justify} className={classes.firstLine + ' ' + classes.justify}>
                        {(this.matchedItems.length > 1 && countable &&
                            <PersonCount count={this.matchedItems.length} label={countLabel.many}/>)
                        }

                        {(this.matchedItems.length <= 1 && countable &&
                            <PersonCount count={this.matchedItems.length} label={countLabel.one}/>)
                        }

                        {withButton &&
                            <Button className={classes.button}  onClick={onButtonClick}> {buttonLabel} </Button>
                        }
                    </Grid>
                }

                <Grid container item xs={12} justify={justify} className={classes.justify}>
                    {
                        this.displayedItems.map((person, index) => {
                            return (
                                <PersonItem key={index}
                                            id={person.id}
                                            name={person.name}
                                            info={person.info}
                                            avatar={person.avatar}
                                            phone={person.phone}
                                            initials={person.initials}
                                            status={person.status}
                                            onClick={onItemClick}
                                />
                            )
                        })
                    }
                </Grid>

                {paginate &&
                    <Grid container item xs={12} justify={justify} className={classes.justify}>
                        <div className={classes.blank}/>
                        <Pagination className={classes.pagination} startIndex={startPage} selectedIndex={selectedPage} maxIndex={totalPage} numberDisplayedIndex={paginationNumberDisplayedIndex} onChange={this.handlePageChange}/>
                    </Grid>
                }
            </div>
        );
    }
}

PersonList.propTypes = {
    classes: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    countLabel: PropTypes.object.isRequired,
    itemPerPage: PropTypes.number.isRequired,
    paginationNumberDisplayedIndex: PropTypes.number.isRequired,
    justify: PropTypes.string.isRequired,
    countable: PropTypes.bool.isRequired,
    withButton: PropTypes.bool.isRequired,
    paginate: PropTypes.bool.isRequired,
    classNames: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func,
    onItemClick: PropTypes.func,
    startingWith: PropTypes.string,
    contains: PropTypes.string,
};

PersonList.defaultProps = {
    list: [],
    countLabel: strings.defaultCountLabel,
    itemPerPage: 4,
    paginationNumberDisplayedIndex: 5,
    justify: 'space-between',
    countable: false,
    withButton: false,
    paginate: false,
    classNames: '',
    buttonLabel: 'Create New Person',
};

export default withStyles(styles)(PersonList);