import {SET_HOME_COUNTER} from '../actions'
import {combineReducers} from "redux";


const counters = (state = {}, action) => {

    switch (action.type) {

        case SET_HOME_COUNTER:

            if (action.name.toLowerCase() === 'scheduled') {

                return Object.assign({}, state, {scheduled: action.value})

            } else if (action.name.toLowerCase() === 'served') {

                return Object.assign({}, state, {served: action.value})

            } else if (action.name.toLowerCase() === 'noshow') {

                return Object.assign({}, state, {noShow: action.value})

            } else if (action.name.toLowerCase() === 'inwaitingroom') {

                return Object.assign({}, state, {inWaitingRoom: action.value})

            } else if (action.name.toLowerCase() === 'perstaff') {

                return Object.assign({}, state, {perStaff: action.value})

            } else {

                return state
            }

        default:

            return state
    }
}

export const home = combineReducers({
    counters
})
