import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import Button from "@material-ui/core/Button/Button";

const styles = theme => ({
    container: {
        position: 'relative',
        width: '100%',
        fontFamily: 'Helvetica Neue',
        fontSize: '36px',
        color: '#43425D',
        backgroundColor: '#FFFFFF',
    },
    wrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: '1703px',
    },
    salutation: {
        display: 'flex',
        alignSelf: 'flex-start',
        textAlign: 'left',
        fontSize: '36px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingTop: '54px',
        paddingBottom: '10.5px',
        borderBottom: '2px solid #707070',
    },
    title: {
        display: 'flex',
        alignSelf: 'flex-start',
        fontWeight: 'bold',
        fontSize: '26px',
        textAlign: 'left',
    },
    buttonNew: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        height: '50px',
        width: '185px',
        border: 'none',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro !important',
        fontSize: '18px',
        textAlign: 'center',
        textTransform: 'capitalize',
        color: 'white !important',
        backgroundColor: '#3B86FF !important',
        boxShadow: 'none !important',
        cursor: 'pointer',
        outline: '0px !important',
    },
});

class Header extends React.Component{
    handleActionButtonClick = () => {
        const {history, match} = this.props;
        history.push(match.url + '/create-edit-facility');
    };
    render() {
        const {classes, className} = this.props;
        return(
            <div className={classNames(classes.container, className)}>
                <div className={classes.wrapper}>
                    <span className={classes.salutation}>{'Welcome, '+ this.props.name + '!'}</span>
                    <div className={classes.header}>
                        <span className={classes.title}>{this.props.label}</span>
                        <Button onClick={this.handleActionButtonClick} className={classes.buttonNew}>Add New Facility</Button>
                    </div>
                </div>
            </div>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

Header.defaultProps = {
    className: '',
    strings: {},
};
export default withStyles(styles)(Header);