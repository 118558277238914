import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {withStyles} from '@material-ui/core/styles'
import photo from '../../../../../../common/assets/img/photo.png'
import Strings from "../../../../../services/strings"
import Button from '../../../../../../common/components/Button'
import Webcam from "react-webcam"
import Radio from "@material-ui/core/Radio/Radio"
import {isEmptyObject} from "../../../../../../common/services/Util"
import firebase from "firebase"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";


const strStaff = Strings.StaffPageStrings

const styles = theme => ({
    wrapperRoot: {
        display: 'flex',
        flexGrow: 1,
        height: 'auto',
        minHeight: '100vh',
        backgroundColor: 'white'
    },
    loadingContainer: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingErrorLabel: {
        color: '#43425D',
        fontSize: '26px',
        fontFamily: 'Source Sans Pro',
    },
    loadingProgress: {
        color: '#6798e5',
        animationDuration: '700ms',
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        //flexGrow: '1',
        minHeight: '100vh',
        //height: '100vh',
        width: '100%',
        fontFamily: 'Helvetica',
        fontSize: '18px',
        color: '#707070',
        padding: '95px 40px 40px 40px',
        backgroundColor: 'white',
        //border : '1px solid green',
    },
    wrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        //flexGrow: '1',
        width: '100%',
        maxWidth: '1703px',
        //border: '1px solid blue',
    },
    header: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        //flexGrow: '1',
        width: '100%',
        marginBottom: '36.5px',
        fontFamily: 'Helvetica',
        fontSize: '36px',
        borderBottom: '4px solid #707070',
    },
    coloredTitle: {
        display: 'flex',
        alignSelf: 'flex-start',
        fontSize: '30px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#3B86FF',
    },
    genderContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: '60px',
        paddingBottom: '38.5px',
        borderBottom: '2px solid #707070',
    },
    sectionLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '20px',
        minWidth: '180px',
        width: '5%',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    radioGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '10%',
        paddingRight: '110px',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
    },
    radio: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        margin: '0px',
    },
    radioLabel: {
        marginBottom: '0px',
    },
    inputGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '40%',
        textAlign: 'right',
    },
    inputSection: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
    },
    inputWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '60%',
        marginLeft: '25px',
    },
    errorMessage: {
        color: 'red',
        fontSize: '12px',
    },
    inputLabel: {
        //width: '40%',
        width: '170px',
        marginBottom: '0px',
        fontWeight: 'bold',
        textAlign: 'right',
    },
    labelMargin: {
        marginRight: '25px',
    },
    inputField: {
        width: '100%',
        height: '43px',
        // marginLeft: '25px',
        padding: '0px 15px',
        borderRadius: '4px',
        fontSize: '16px',
        color: "#707070",
        '&:focus': {
            border: '1px solid #3B86FF !important',
            outline: 0,
        },
    },
    selection: {
        padding: '0px 14px',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
    },
    photoGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20%',
        textAlign: 'center',
    },
    photo: {
        width: '120px',
        maxWidth: '120px',
        height: '120px',
        maxHeight: '120px',
        cursor: 'pointer',
    },
    typeContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        width: '100%',
        marginTop: '49.5px',
        paddingBottom: '94.5px',
        borderBottom: '2px solid #43425D',
    },
    typeWrapper: {
        display: 'flex',
        flexFlow: 'row nowrap',
        marginBottom: '10px',
        flexGrow: '1',
    },
    typeGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        fontSize: '16px',
        '&:last-child':{
            marginLeft: '66px',
            width: '50%',
        },
    },
    maritalStatusContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        width: '100%',
        paddingBottom: '16.5px',
        paddingTop: '32.5px',
        borderBottom: '2px solid #43425D',
    },
    addressContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignSelf: 'center',
        width: '100%',
        paddingTop: '32.5px',
        paddingBottom: '28px',
        borderBottom: '2px solid #43425D',
    },
    addressGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        alignSelf: 'flex-start',
        width: '40%',
        maxWidth: '530px',
        //flexGrow: '1',
        marginBottom: '5px',
        //paddingRight: '79px',
    },
    saveButton: {
        alignSelf: 'flex-end',
        height: '50px',
        width: '185px',
        marginTop: '25px',
        fontFamily: 'Source Sans Pro !important',
        fontSize: '18px',
        textTransform: 'uppercase',
        backgroundColor: '#3B86FF !important',
    },
    saveButtonDisabled: {
        alignSelf: 'flex-end',
        height: '50px',
        width: '185px',
        marginTop: '25px',
        fontFamily: 'Source Sans Pro !important',
        fontSize: '18px',
        textTransform: 'uppercase',
        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },
    '@media (max-width: 1600px)' : {
        inputSection: {
            justifyContent: 'flex-start',
        },
        inputLabel:{
            width: '170px',
        },
        addressContainer:{
          justifyContent: 'space-between',
        },
        addressGroup: {
            width: 'calc(50% - 20px)',
        },
        saveButton: {
            marginRight: '14px',
        },
        saveButtonDisabled: {
            marginRight: '14px',
        }
    },
    '@media (max-width: 800px)' : {
        genderContainer: {
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
        },
        radioGroup: {
            flexFlow: 'row nowrap',
        },
        typeContainer: {
            flexFlow: 'column nowrap',
        },
        typeWrapper: {
            flexFlow: 'column nowrap',
        },
        typeGroup: {
            flexFlow: 'row nowrap',
        },
        maritalStatusContainer: {
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            //flexGrow: '1',
        },
        addressContainer: {
            flexFlow: 'column nowrap',
            justifyContent: 'space-between',
            alignSelf: 'space-around',
        },
        addressGroup: {
            width: '100%',
        },
        inputGroup: {
            width: '100%',
        },
        inputLabel: {
            textAlign: 'right',
        },
        sectionLabel:{
            width: '100%',
        },
    },
})

const emailRegex = RegExp(/^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
const phoneRegex = RegExp(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/)

class StaffEditPage extends React.Component{

    constructor(props) {

        super(props)

        this.state = {
            gender: 'male',
            type: 'doctor',
            status: 'single',
            firstName: '',
            middleName: '',
            speciality: '',
            homePhone: '',
            cellPhone: '',
            workPhone: '',
            emailAddress: '',
            cameraSet: false,
            imagePath: null,
            errors: {
                firstName: '',
                middleName: '',
                speciality: '',
                homePhone: '',
                cellPhone: '',
                workPhone: '',
                emailAddress: '',
            },
            initialStaff: {},
            loadingError: false,
            loading: true,
            next: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.initialize()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.initialStaff !== this.state.initialStaff) {

            this.copyInitialStaff()
        }
        
        if (prevState.gender !== this.state.gender || prevState.type !== this.state.type || prevState.status !== this.state.status || prevState.firstName !== this.state.firstName ||
            prevState.middleName !== this.state.middleName || prevState.speciality !== this.state.speciality || prevState.homePhone !== this.state.homePhone ||
            prevState.cellPhone !== this.state.cellPhone || prevState.workPhone !== this.state.workPhone || prevState.emailAddress !== this.state.emailAddress) {


            this.setState({next: true})
        }

        if (prevProps.selectedFacility.item !== this.props.selectedFacility.item) {

            this.initialize()
        }
    }

    async initialize() {

        try {
            this.setState({loading: true, loadingError: false})

            await this.getStaff()

            this.setState({loading: false})

        } catch (e) {
            this.setState({loadingError: 'Error: ' + e.message})
        }
    }


    async getStaff() {

        const {selectedFacility, match, staff} = this.props

        if (selectedFacility.item && !isEmptyObject(selectedFacility.item)) {

            /*let providerQuery = firebase.database().ref('providers/' + match.params.staffId)

            let providerSnapshot = await providerQuery.once('value')

            let provider = providerSnapshot.val()
            let key = providerSnapshot.key*/

            let matchStaff = staff.items.find((item) => item.id === match.params.staffId)


            if (matchStaff) {

                /*let staffClinicQuery = firebase.database().ref('clinicStaff').orderByChild('providerId').equalTo(match.params.staffId)
                let staffClinicSnap = await staffClinicQuery.once('value')

                let present = false

                staffClinicSnap.forEach((staffSnap) => {
                    let data = staffSnap.val()
                    if (data.facilityId === selectedFacility.item.key) {
                        present = true
                    }
                })

                if (present) {
                    this.setState({provider: {id: key, key, ...provider}, loadingError: false})
                } else {
                    this.setState({loadingError: 'No such provider in this clinic'})
                }*/

                this.setState({initialStaff: matchStaff, loadingError: false})

            } else {
                this.setState({loadingError: 'No such provider in this clinic'})
            }
        }
    }

    copyInitialStaff() {

        const {initialStaff} = this.state

        let gender = initialStaff.gender ? initialStaff.gender : this.state.gender
        let type = initialStaff.type ? initialStaff.type : this.state.type
        let status = initialStaff.status ? initialStaff.status : this.state.status
        let firstName = initialStaff.firstName ? initialStaff.firstName : this.state.firstName
        let middleName = initialStaff.lastName ? initialStaff.lastName : this.state.middleName
        let speciality = initialStaff.speciality ? initialStaff.speciality : this.state.speciality
        let homePhone = initialStaff.homePhone ? initialStaff.homePhone : this.state.homePhone
        let cellPhone = initialStaff.cellPhone ? initialStaff.cellPhone : this.state.cellPhone
        let workPhone = initialStaff.workPhone ? initialStaff.workPhone : this.state.workPhone
        let emailAddress = initialStaff.email ? initialStaff.email : this.state.emailAddress

        this.setState({gender, type, status, firstName, middleName, speciality, homePhone, cellPhone, workPhone, emailAddress})
    }

    compareToInitialStaff() {

        const {gender, type, status, firstName, middleName, speciality,
            homePhone, cellPhone, workPhone, emailAddress, initialStaff} = this.state

        let changes = {}

        if (initialStaff.gender && initialStaff.gender !== gender) {
            changes.gender = gender
        } else if (initialStaff.type && initialStaff.type !== type) {
            changes.type = type
        } else if (initialStaff.status && initialStaff.status !== status) {
            changes.status = status
        } else if (initialStaff.firstName && initialStaff.firstName !== firstName) {
            changes.firstName = firstName
        } else if (initialStaff.lastName && initialStaff.lastName !== middleName) {
            changes.middleName = middleName
        } else if (initialStaff.speciality && initialStaff.speciality !== speciality) {
            changes.speciality = speciality
        } else if (initialStaff.homePhone && initialStaff.homePhone !== homePhone) {
            changes.homePhone = homePhone
        } else if (initialStaff.cellPhone && initialStaff.cellPhone !== cellPhone) {
            changes.cellPhone = cellPhone
        } else if (initialStaff.phoneNumber && initialStaff.phoneNumber !== workPhone) {
            changes.workPhone = workPhone
        } else if (initialStaff.email && initialStaff.email !== emailAddress) {
            changes.emailAddress = emailAddress
        }

        return changes
    }

    updateNext() {

        let changes = this.compareToInitialStaff()

        if (!isEmptyObject(changes)) {

            this.setState({next: true})

        } else {

            this.setState({next: false})
        }
    }

    setRef = webcam => {
        this.webcam = webcam
    }

    capture = event => {
        if(this.state.cameraSet){
            this.setState({ imagePath: this.webcam.getScreenshot() })
        }
        this.setState({ cameraSet: !this.state.cameraSet })
        console.log("Captured!" + this.state.imagePath)
    }

    handleGenderChange = event => {
        this.setState({ gender: event.target.value })
    }

    handleTypeChange = event => {
        this.setState({ type: event.target.value })
    }

    handleStatusChange = event => {
        this.setState({ status: event.target.value })
    }

    handleInputChange = (field, value) => {
        this.setState({[field]: value})
    }

    fieldsEmpty = errorList => {
        let empty = false
        Object.values(errorList).forEach(error => {
            error.length > 0 && (empty = true)
        })

        return empty
    }

    validateInputDetails = () => {
        let errors = this.state.errors
        errors.firstName = this.state.firstName.length === 0 ? 'First Name should not be empty!' : ''
        errors.middleName = this.state.middleName.length  === 0 ? 'Middle Name should not be empty!' :''
        //input validation of homePhone
        if (this.state.homePhone.length === 0){
            errors.homePhone = 'Home Phone Field should not be empty!'
        } else if(!phoneRegex.test(this.state.homePhone)){
            errors.homePhone = 'Invalid Phone Number!'
        } else {
            errors.homePhone = ''
        }

        //input validation of cellPhone
        if (this.state.cellPhone.length === 0){
            errors.cellPhone = 'Cell Phone Field should not be empty!'
        } else if(!phoneRegex.test(this.state.cellPhone)){
            errors.cellPhone = 'Invalid Phone Number!'
        } else {
            errors.cellPhone = ''
        }

        //input validation of workPhone
        if (this.state.workPhone.length === 0){
            errors.workPhone = 'Work Phone Field should not be empty!'
        } else if(!phoneRegex.test(this.state.workPhone)){
            errors.workPhone = 'Invalid Phone Number!'
        } else {
            errors.workPhone = ''
        }

        //input validation of emailAddress
        if (this.state.emailAddress.length === 0){
            errors.emailAddress = 'Email Address Field should not be empty!'
        } else if(!emailRegex.test(this.state.emailAddress)){
            errors.emailAddress = 'Invalid Email Address!'
        } else {
            errors.emailAddress = ''
        }
        this.setState({errors: errors})
    }

    handleSave = event => {

        event.preventDefault()
        let errors = this.state.errors
        this.validateInputDetails()

        if (this.fieldsEmpty(errors)) {

        } else {
            const staff = {
                resourceType: "Practitioner",
                identifier: [],
                active: true,
                name: [
                    {
                        use: 'official',
                        family: this.state.firstName,
                        given: [
                            this.state.middleName
                        ],
                        suffix: [],
                    }
                ],
                telecom: [
                    {
                        system: 'phone',
                        value: this.state.homePhone,
                        use: 'home'
                    },
                    {
                        system: 'phone',
                        value: this.state.cellPhone,
                        use: 'home',
                    },
                    {
                        system: 'phone',
                        value: this.state.workPhone,
                        use: 'work',
                    },
                    {
                        system: 'email',
                        value: this.state.emailAddress,
                        use: 'work',
                    }
                ],
                address: [],
                gender: this.state.gender,
                birthDate: '',
                photo: this.state.imagePath,
                qualification: [],
                communication: [],
            }
            let parsedStaff = JSON.stringify(staff)
            console.log(parsedStaff)
        }
    }

    render() {

        const {classes, className} = this.props
        const {errors, next, loading, loadingError} = this.state
        const videoConstraints = {
            width: 120,
            height: 120,
            facingMode: "user"
        }

        let imageSrc = this.state.imagePath !== null ? this.state.imagePath : photo

        return(

            <div className={classes.wrapperRoot}>

                {loadingError &&
                    <div className={classes.loadingContainer}>
                        <span className={classes.loadingErrorLabel}>{loadingError}</span>
                    </div>
                }

                {loading && !loadingError &&
                    <div className={classes.loadingContainer}>
                        <CircularProgress
                            variant="indeterminate"
                            className={classes.loadingProgress}
                            size={24}
                            thickness={4}
                        />
                    </div>
                }

                {!loading && !loadingError &&

                    <div className={classNames(classes.container, className)}>

                        <div className={classes.wrapper}>

                            <div className={classes.header}>
                                <span>{strStaff.clinicName}</span>
                                <span className={classes.coloredTitle}>{strStaff.newProvider}</span>
                            </div>

                            <span className={classes.coloredTitle}>{strStaff.demographics}</span>

                            <div className={classes.genderContainer}>

                                <span className={classes.sectionLabel}>{strStaff.gender}</span>

                                <div className={classes.radioGroup}>
                                    <div className={classes.radio}>
                                        <Radio id={strStaff.male} checked={this.state.gender === 'male'} onChange={this.handleGenderChange} value='male' color="default" name="radio-button-demo" aria-label={strStaff.male}/>
                                        <label className={classes.radioLabel} htmlFor={strStaff.male}>{strStaff.male}</label>
                                    </div>
                                    <div className={classes.radio}>
                                        <Radio id={strStaff.female} checked={this.state.gender === 'female'} onChange={this.handleGenderChange} value='female' color="default" name="radio-button-demo" aria-label={strStaff.female}/>
                                        <label className={classes.radioLabel} htmlFor={strStaff.female}>{strStaff.female}</label>
                                    </div>
                                    <div className={classes.radio}>
                                        <Radio id={strStaff.other} checked={this.state.gender === 'other'} onChange={this.handleGenderChange} value='other' color="default" name="radio-button-demo" aria-label={strStaff.other}/>
                                        <label className={classes.radioLabel} htmlFor={strStaff.other}>{strStaff.other}</label>
                                    </div>
                                </div>

                                <div className={classes.inputGroup}>
                                    <div className={classes.inputSection}>
                                        <label className={classes.inputLabel} htmlFor={strStaff.firstName}>{strStaff.firstName}</label>
                                        <div className={classes.inputWrapper}>
                                            <input onChange={(e) => this.handleInputChange('firstName', e.target.value)} className={classes.inputField} style={errors.firstName.length > 0 ? {border: '1px solid red'} : {border: '1px solid #707070'}} type="text" name={strStaff.firstName} id={strStaff.firstName} value={this.state.firstName}/>
                                            {errors.firstName.length > 0 && (
                                                <span className={classes.errorMessage}>{errors.firstName}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={classes.inputSection}>
                                        <label className={classes.inputLabel} htmlFor={strStaff.middleName}>{strStaff.middleName}</label>
                                        <div className={classes.inputWrapper}>
                                            <input onChange={(e) => this.handleInputChange('middleName', e.target.value)} className={classes.inputField} style={errors.middleName.length > 0 ? {border: '1px solid red'} : {border: '1px solid #707070'}} type="text" name={strStaff.middleName} id={strStaff.middleName} value={this.state.middleName}/>
                                            {errors.middleName.length > 0 && (
                                                <span className={classes.errorMessage}>{errors.middleName}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.photoGroup}>
                                    <div className={classes.photo} onClick={this.capture}>
                                        { !this.state.cameraSet ?
                                            <img src={imageSrc} alt={strStaff.takePhoto}/> :
                                            <Webcam audio={false} height={120} ref={this.setRef} screenshotFormat="image/jpeg" width={120} videoConstraints={videoConstraints} />
                                        }
                                    </div>
                                    <span>{strStaff.takePhoto}</span>
                                </div>

                            </div>

                            <div className={classes.typeContainer}>

                                <span className={classes.sectionLabel}>{strStaff.type}</span>

                                <div className={classes.typeWrapper}>

                                    <div className={classes.typeGroup}>
                                        <div className={classes.radio}>
                                            <Radio id={strStaff.doctor} checked={this.state.type === 'doctor'} onChange={this.handleTypeChange} value='doctor' color="default" name="radio-button-demo" aria-label={strStaff.doctor}/>
                                            <label className={classes.radioLabel} htmlFor={strStaff.doctor}>{strStaff.doctor}</label>
                                        </div>
                                        <div className={classes.radio}>
                                            <Radio id={strStaff.nurse} checked={this.state.type === 'nurse'} onChange={this.handleTypeChange} value='nurse' color="default" name="radio-button-demo" aria-label={strStaff.nurse}/>
                                            <label className={classes.radioLabel} htmlFor={strStaff.nurse}>{strStaff.nurse}</label>
                                        </div>
                                        <div className={classes.radio}>
                                            <Radio id={strStaff.adminStaff} checked={this.state.type === 'adminStaff'} onChange={this.handleTypeChange} value='adminStaff' color="default" name="radio-button-demo" aria-label={strStaff.adminStaff}/>
                                            <label className={classes.radioLabel} htmlFor={strStaff.adminStaff}>{strStaff.adminStaff}</label>
                                        </div>
                                    </div>

                                    <div className={classes.typeGroup}>
                                        <div className={classes.inputSection}>
                                            <label className={classNames(classes.inputLabel, classes.labelMargin)} htmlFor={strStaff.speciality}>{strStaff.speciality}</label>
                                            <select className={classes.selection + " " + classes.inputField} name={strStaff.speciality} id={strStaff.speciality}>
                                                <option value="Oncologist">Oncologist</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className={classes.maritalStatusContainer}>

                                <span className={classes.sectionLabel}>{strStaff.maritalStatus}</span>

                                <div className={classes.radioGroup}>
                                    <div className={classes.radio}>
                                        <Radio id={strStaff.single} checked={this.state.status === 'single'} onChange={this.handleStatusChange} value='single' color="default" name="radio-button-demo" aria-label={strStaff.single}/>
                                        <label className={classes.radioLabel} htmlFor={strStaff.single}>{strStaff.single}</label>
                                    </div>
                                    <div className={classes.radio}>
                                        <Radio id={strStaff.married} checked={this.state.status === 'married'} onChange={this.handleStatusChange} value='married' color="default" name="radio-button-demo" aria-label={strStaff.married}/>
                                        <label className={classes.radioLabel} htmlFor={strStaff.married}>{strStaff.married}</label>
                                    </div>
                                    <div className={classes.radio}>
                                        <Radio id={strStaff.widowed} checked={this.state.status === 'widowed'} onChange={this.handleStatusChange} value='widowed' color="default" name="radio-button-demo" aria-label={strStaff.widowed}/>
                                        <label className={classes.radioLabel} htmlFor={strStaff.widowed}>{strStaff.widowed}</label>
                                    </div>
                                </div>

                            </div>

                            <div className={classes.addressContainer}>

                                <div className={classes.addressGroup}>
                                    <div className={classes.inputSection}>
                                        <label className={classes.inputLabel} htmlFor={strStaff.homePhone}>{strStaff.homePhone}</label>
                                        <div className={classes.inputWrapper}>
                                            <input onChange={(e) => this.handleInputChange('homePhone', e.target.value)} className={classes.inputField} style={errors.homePhone.length > 0 ? {border: '1px solid red'} : {border: '1px solid #707070'}} type="text" name={strStaff.homePhone} id={strStaff.homePhone} value={this.state.homePhone}/>
                                            {errors.homePhone.length > 0 && (
                                                <span className={classes.errorMessage}>{errors.homePhone}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={classes.inputSection}>
                                        <label className={classes.inputLabel} htmlFor={strStaff.cellPhone}>{strStaff.cellPhone}</label>
                                        <div className={classes.inputWrapper}>
                                            <input onChange={(e) => this.handleInputChange('cellPhone', e.target.value)} className={classes.inputField} style={errors.cellPhone.length > 0 ? {border: '1px solid red'} : {border: '1px solid #707070'}} type="text" name={strStaff.cellPhone} id={strStaff.cellPhone} value={this.state.cellPhone}/>
                                            {errors.cellPhone.length > 0 && (
                                                <span className={classes.errorMessage}>{errors.cellPhone}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.addressGroup}>
                                    <div className={classes.inputSection}>
                                        <label className={classes.inputLabel} htmlFor={strStaff.workPhone}>{strStaff.workPhone}</label>
                                        <div className={classes.inputWrapper}>
                                            <input onChange={(e) => this.handleInputChange('workPhone', e.target.value)} className={classes.inputField} style={errors.workPhone.length > 0 ? {border: '1px solid red'} : {border: '1px solid #707070'}} type="text" name={strStaff.workPhone} id={strStaff.workPhone} value={this.state.workPhone}/>
                                            {errors.workPhone.length > 0 && (
                                                <span className={classes.errorMessage}>{errors.workPhone}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={classes.inputSection}>
                                        <label className={classes.inputLabel} htmlFor={strStaff.emailAddress}>{strStaff.emailAddress}</label>
                                        <div className={classes.inputWrapper}>
                                            <input onChange={(e) => this.handleInputChange('emailAddress', e.target.value)} className={classes.inputField} style={errors.emailAddress.length > 0 ? {border: '1px solid red'} : {border: '1px solid #707070'}} type="text" name={strStaff.emailAddress} id={strStaff.emailAddress} value={this.state.emailAddress}/>
                                            {errors.emailAddress.length > 0 && (
                                                <span className={classes.errorMessage}>{errors.emailAddress}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <Button onClick={this.handleSave} className={classes.saveButton} disabledClassName={classes.saveButtonDisabled} disabled={!next}>{strStaff.save}</Button>

                        </div>

                    </div>
                }

            </div>

        )
    }
}
StaffEditPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
}

StaffEditPage.defaultProps = {
    className: '',
    strings: {},
}
export default withStyles(styles)(StaffEditPage)