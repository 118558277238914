import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import ReactQuill from "react-quill";
import Button from "../../Button";

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        marginBottom: '15px',
        padding: '0px 25px',
        border: '1px solid #E8E9EC',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro',
        fontSize: '14px',
        color: 'rgba(77, 79, 92, 1)',
        outline: '0px !important',
        '&::placeholder': {
            opacity: '0.2'
        },
        '&:placeholder': {
            opacity: '0.2'
        }
    },
    editing: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        color: 'rgba(77, 79, 92, 1)'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: '35px',
    },
    button: {
        alignSelf: 'flex-end',
        width: '155px',
        height: '35px',
        marginLeft: '10px',
        backgroundColor: '#3B86FF !important',
    },
    cancel: {
        backgroundColor: 'gainsboro !important',
        color: 'rgba(77, 79, 92, 1) !important'
    }

});

class Compose extends React.Component {

    render() {
        const {classes, className, compose, onChange} = this.props;

        return (
            <div className={classNames(classes.root, className)}>

                <input type="text" className={classes.input} placeholder={'To'} value={compose.to} onChange={(e) => onChange(e.target.value, 'compose', 'to')} />

                <input type="text" className={classes.input} placeholder={'CC'} value={compose.cc} onChange={(e) => onChange(e.target.value, 'compose', 'cc')} />

                <input type="text" className={classes.input} placeholder={'Subject'} value={compose.subject} onChange={(e) => onChange(e.target.value, 'compose', 'subject')} />

                <div className={classes.editing}>

                    <ReactQuill value={compose.content} onChange={(text) => onChange(text, 'compose', 'content')}
                                theme="snow" modules={Compose.modules} formats={Compose.formats}
                    />

                    <div className={classes.buttonContainer}>
                        <Button className={classNames(classes.button, classes.cancel)} onClick={() => onChange(false, 'compose', 'editing')}>
                            Cancel
                        </Button>
                        <Button className={classes.button} onClick={() => console.log('click send compose')}>
                            Send
                        </Button>
                    </div>

                </div>
            </div>
        );
    }
}

Compose.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}

Compose.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
]

Compose.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    compose: PropTypes.object.isRequired,
    onChange: PropTypes.func
};

Compose.defaultProps = {
    className: '',
    strings: {},
    compose: {},
};

export default withStyles(styles)(Compose);