export const CHANGE_REGISTRATION_INFO = 'CHANGE_REGISTRATION_INFO'
export const SET_SELECTED_FACILITY = 'SELECT_FACILITY'
export const SET_SELECTED_FACILITY_CHECKED = 'SET_SELECTED_FACILITY_CHECKED'
export const SET_FACILITIES = 'SET_FACILITIES'
export const SET_FACILITIES_FETCHING_STATUS = 'SET_FACILITIES_FETCHING_STATUS'
export const SET_PATIENTS = 'SET_PATIENTS'
export const SET_PATIENTS_FETCHED_STATUS = 'SET_PATIENTS_FETCHED_STATUS'
export const CHANGE_PATIENT_INFO = 'CHANGE_PATIENT_INFO'
export const SET_STAFF = 'SET_STAFF'
export const SET_STAFF_FETCHED_STATUS = 'SET_STAFF_FETCHED_STATUS'
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_SETTINGS_FETCHING_STATUS = 'SET_SETTINGS_FETCHING_STATUS'
export const SET_CURRENT_FACILITY = 'SET_CURRENT_FACILITY'
export const SET_CURRENT_FACILITY_FETCHING_STATUS = 'SET_CURRENT_FACILITY_FETCHING_STATUS'
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION'
export const SET_CURRENT_ORGANIZATION_FETCHING_STATUS = 'SET_CURRENT_ORGANIZATION_FETCHING_STATUS'
export const SET_USER = 'SET_USER'
export const SET_HOME_COUNTER = 'SET_HOME_COUNTER'
export const SET_NO_SHOW_PATIENTS = 'SET_NO_SHOW_PATIENTS'
export const SET_APPOINTMENTS_VALUE = 'SET_APPOINTMENTS_VALUE'
export const SET_APPOINTMENTS_FETCHING_STATUS = 'SET_APPOINTMENTS_FETCHING_STATUS'



export const changeRegistrationInfo = (info, value) =>  ({
    type: CHANGE_REGISTRATION_INFO,
    info,
    value,
})

export const changePatientInfo = (info, value) =>  ({
    type: CHANGE_PATIENT_INFO,
    info,
    value,
})

export const setSelectedFacility = (facility) => ({
    type: SET_SELECTED_FACILITY,
    facility
})

export const setSelectedFacilityChecked = (value) => ({
    type: SET_SELECTED_FACILITY_CHECKED,
    value
})

export const setFacilities = (facilities) => ({
    type: SET_FACILITIES,
    facilities
})

export const setFacilitiesFetchingStatus = (value) => ({
    type: SET_FACILITIES_FETCHING_STATUS,
    value
})

export const setPatients = (patients) => ({
    type: SET_PATIENTS,
    patients
})

export const setPatientsFetchedStatus = (value) => ({
    type: SET_PATIENTS_FETCHED_STATUS,
    value
})

export const setStaff = (staff) => ({
    type: SET_STAFF,
    staff
})

export const setStaffFetchedStatus = (value) => ({
    type: SET_STAFF_FETCHED_STATUS,
    value
})

export const setSettings = (value) => ({
    type: SET_SETTINGS,
    value
})

export const setSettingsFetchingStatus = (value) => ({
    type: SET_SETTINGS_FETCHING_STATUS,
    value
})

export const setCurrentFacility = (value) => ({
    type: SET_CURRENT_FACILITY,
    value
})

export const setCurrentFacilityFetchingStatus = (value) => ({
    type: SET_CURRENT_FACILITY_FETCHING_STATUS,
    value
})

export const setCurrentOrganization = (value) => ({
    type: SET_CURRENT_ORGANIZATION,
    value
})

export const setCurrentOrganizationFetchingStatus = (value) => ({
    type: SET_CURRENT_ORGANIZATION_FETCHING_STATUS,
    value
})

export const setUser = (value) => ({
    type: SET_USER,
    value
})

export const setHomeCounter = (name, value) => ({
    type: SET_HOME_COUNTER,
    name,
    value
})

export const setNoShowPatients = (patients) => ({
    type: SET_NO_SHOW_PATIENTS,
    patients
})

export const setAppointmentsValue = (value) => ({
    type: SET_APPOINTMENTS_VALUE,
    value
})

export const setAppointmentsFetchingStatus = (value) => ({
    type: SET_APPOINTMENTS_FETCHING_STATUS,
    value
})
