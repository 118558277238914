import React, { Component } from "react";
import {BrowserRouter, Route, Switch, withRouter} from "react-router-dom";
import {Provider} from 'react-redux'
import firebase from 'firebase';
import HomePage from './common/scenes/HomePage'
import LoginPage from './common/scenes/Authentication/scenes/Login'
import RegistrationPage from './common/scenes/Registration'
import ResetPage from './common/scenes/Authentication/scenes/Reset'
import Error from './common/scenes/Authentication/scenes/Error'
import ClinicPageContainer from './clinic'
import SchoolPage from './school'

import "./Style.css";
import "./common/assets/css/adjustment.css";
import "./common/assets/css/transition.css"


class App extends Component {

  constructor(props) {

    super(props)

    var config = {
      apiKey: "AIzaSyAtq4pudyB3OWkyqTyXlZQEABDNe8umHQI",
      authDomain: "i-m-here-99d05.firebaseapp.com",
      databaseURL: "https://i-m-here-99d05.firebaseio.com",
      projectId: "i-m-here-99d05",
      storageBucket: "i-m-here-99d05.appspot.com",
      messagingSenderId: "514180497027"
    };

    firebase.initializeApp(config);
    firebase.firestore().settings({
       timestampsInSnapshots: true
    });

  }


  render() {

      const OtherPage = withRouter(({ location }) => (


                  <Switch location={location}>
                      <Route path="/dashboard" component={SchoolPage} />
                      <Route path="/dashboard-clinic" component={ClinicPageContainer} />
                      <Route component={Error} />
                  </Switch>


      ))

    return (
      <Provider store={this.props.store}>
        <BrowserRouter>
            <Switch>
                <Route path="/" component={HomePage} exact />
                <Route path="/login" component={LoginPage} />
                <Route path="/resetPassword" component={ResetPage} />
                <Route path="/registration" component={RegistrationPage}/>
                <Route>
                    <OtherPage/>
                </Route>
            </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
