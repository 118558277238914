import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import Inbox from "../../../../../../common/components/Inbox";

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '647px',
        maxWidth: '1260px',
        width: '100%',
        padding: '0px',
        border: '1px solid #707070',
        borderRadius: '0px 6px 0px 0px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    inbox: {
        borderTopRightRadius: '4px',
    }

});

class MessagesSection extends React.Component {

    render() {
        const {classes, className, menuItems, menuNotif, tagItems, conversations} = this.props;

        return (
            <div className={classNames(classes.root, className)}>

                <Inbox menuItems={menuItems}
                       menuNotif={menuNotif}
                       tagItems={tagItems}
                       conversations={conversations}
                       className={classes.inbox}
                />

            </div>
        );
    }
}

MessagesSection.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

MessagesSection.defaultProps = {
    className: '',
    strings: {},
};

export default withStyles(styles)(MessagesSection);