import { connect } from 'react-redux'
import NewAppointmentPage from '../components/NewAppointmentPage'


const mapStateToProps = state => {
    return {
        staff: state.staff,
        selectedFacility: state.selectedFacility,
        patients: state.patients,
        settings: state.settings,
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const NewAppointmentPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewAppointmentPage)

export default NewAppointmentPageContainer