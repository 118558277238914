import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import Button from '../../../../../../common/components/Button'
import {PaginationCollapse} from '../../../../../../common/components/Pagination'
import classNames from 'classnames'
import Strings from '../../../../../services/strings'

const strAppointments = Strings.PatientAppointmentsSection

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '647px',
        maxWidth: '1260px',
        width: '100%',
        padding: '24px 47px 13px 47px',
        border: '1px solid #707070',
        borderRadius: '0px 6px 0px 0px',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        color: '#4D4F5C',
        backgroundColor: 'white',
    },
    nextAppointmentContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        paddingBottom: '13px',
        borderBottom: '1px solid #707070',
        fontSize: '18px',
        color: '#43425D',
    },
    nextAppointmentLabel: {
        fontWeight: 'bold',
    },
    appointmentDoctor: {
        fontWeight: 'bold',
        color: '#3B86FF',
        textDecoration: 'underline',
        textTransform: 'uppercase'
    },
    button: {
        minWidth: '103px',
        height: '28px',
        padding: '0px 19px',
        border: '1px solid #000000',
        borderRadius: '6px',
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: '#707070 !important',
    },
    rescheduleButton: {
        marginLeft: '44px',
    },
    pastAppointmentLabel: {
        marginTop: '70px',
        marginBottom: '24px',
        fontSize: '17px',
        fontWeight: 'bold',
    },
    appointmentTable: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'flex-end',
        width: '100%',
        height: '412px',
        border: '1px solid #707070',
        borderRadius: '4px',
    },
    appointmentLine: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '42px',
        padding: '0px 8px 0px 19px',
        '&:hover': {
            backgroundColor: 'gainsboro',
        },
        '&:first-child': {
            marginBottom: '10px',
            borderBottom: '1px solid #707070',
            borderRadius: '4px 4px 0px 0px',
            fontSize: '17px',
            fontWeight: 'bold',
            backgroundColor: 'white',
        },
    },
    appointmentCell: {
        display: 'flex',
        //width: '120px',
        width: '10%',
        paddingRight: '10px',
        '&:nth-child(3)': {
            //width: '240px',
            width: '15%',
        },
        '&:last-child': {
            justifyContent: 'space-between',
            //width: '630px',
            width: '65%',
            marginRight: '0px',
        },
    },
    appointmentNoteValue: {
        width: '500px',
        marginRight: '30px',
    },
    textOverflow: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: '12px',
    },
    pagination: {
        width: 'auto',
    },
    emptyAppointments: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        fontSize: '25px'
    }

});




class AppointmentsSection extends React.Component {

    constructor(props) {

        super(props)
        this.state = {startPage: 1, selectedPage: 1}
    }


    handlePageChange = (selectedPage, startPage) => {

        this.setState({selectedPage: selectedPage, startPage: startPage})
    }


    handleRescheduleClick = () => {
        console.log('reschedule click handle')
    }

    handleViewClick = (appointment) => () => {
        console.log(appointment)
    }

    render() {
        const {classes, className, nextAppointment, itemPerPage, list, paginationNumberDisplayedIndex} = this.props
        const {selectedPage, startPage} = this.state
        let displayedItems = list.slice((selectedPage - 1) * itemPerPage, selectedPage * itemPerPage)
        let totalPage = Math.ceil(list.length / itemPerPage)
        let paginationNumberIndex = Math.min(totalPage, paginationNumberDisplayedIndex)

        return (
            <div className={classNames(classes.root, className)}>

                { (nextAppointment.date.length > 0 && nextAppointment.provider.length > 0) ?
                    <div className={classes.nextAppointmentContainer}>
                        <span className={classes.nextAppointmentLabel}> {strAppointments.nextAppointment} : &nbsp;</span>
                        <span className={''}>{nextAppointment.date} {strAppointments.with} &nbsp;</span>
                        <Link className={classes.appointmentDoctor} to={'#'}>  { nextAppointment.provider} </Link>
                        <Button className={classNames(classes.button, classes.rescheduleButton)} onClick={this.handleRescheduleClick}>{strAppointments.reschedule}</Button>
                    </div> :
                    <div className={classes.nextAppointmentContainer}>
                        <span className={classes.nextAppointmentLabel}> {strAppointments.nextAppointment} : &nbsp;</span>
                        <span className={''}>{'No appointments'} &nbsp;</span>
                    </div>
                }

                <span className={classes.pastAppointmentLabel}> {strAppointments.pastAppointment} </span>

                <div className={classes.appointmentTable}>

                    <div className={classes.appointmentLine}>
                        <span className={classes.appointmentCell}>{strAppointments.date}</span>
                        <span className={classes.appointmentCell}>{strAppointments.time}</span>
                        <span className={classes.appointmentCell}>Patient</span>
                        <span className={classes.appointmentCell}>{strAppointments.notes}</span>
                    </div>

                    { displayedItems.length > 0 ?
                        displayedItems.map((appointment, index) => (
                            <div className={classes.appointmentLine} key={index}>
                                <span className={classes.appointmentCell}> {appointment.date} </span>
                                <span className={classes.appointmentCell}> {appointment.time} </span>
                                <span className={classNames(classes.appointmentCell, classes.textOverflow)}> {appointment.provider} </span>
                                <span className={classes.appointmentCell}>
                                    <span className={classNames(classes.appointmentNoteValue, classes.textOverflow)}>{appointment.notes}</span>
                                    <Button className={classNames(classes.button)} onClick={this.handleViewClick(appointment)}>{strAppointments.view}</Button>
                                </span>
                            </div>
                        )) :
                        <div className={classes.emptyAppointments}>
                            <span>{'No appointments'}</span>
                        </div>
                    }
                </div>

                <div className={classes.paginationContainer}>
                    <PaginationCollapse className={classes.pagination}  startIndex={startPage} selectedIndex={selectedPage}
                                maxIndex={totalPage} numberDisplayedIndex={paginationNumberIndex} onChange={this.handlePageChange}
                    />
                </div>
            </div>
        );
    }
}

AppointmentsSection.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    nextAppointment: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    itemPerPage: PropTypes.number.isRequired,
    paginationNumberDisplayedIndex: PropTypes.number.isRequired,
};

AppointmentsSection.defaultProps = {
    className: '',
    strings: {},
    nextAppointment: {date: '', provider: ''},
    list: [],
    itemPerPage: 8,
    paginationNumberDisplayedIndex: 8,
};

export default withStyles(styles)(AppointmentsSection);