import React from 'react';
// import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: "#F5F6FA",
        color: "#A3A6B4",
        fontSize: 13,
        fontWeight: 'bold',
        textTransform: "uppercase",
        fontFamily: 'Source Sans Pro, Regular',
        border: 'none !important'
    },
    body: {
        fontSize: 13,
        color: "#674D47",
        textTransform: "uppercase",
        fontFamily: 'Source Sans Pro, Regular'
    },
}))(TableCell);

const styles = theme => ({
    root: {
        width: '100%',
        padding: '30px 20px 0px 20px',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)'
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        minHeight: '360px'
    },
    row: {
        borderBottom: '1px solid #F1F1F3',
        height: '50px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F1F1F3'
        }
    },
    headCell: {
        textAlign: "center",
        width: '33.33%',
        paddingRight: '20px',
        '&:first-child': {
            textAlign: 'left'
        },
        '&:last-child': {
            textAlign: 'right',
            paddingRight: '35px'
        }
    },
    cell: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        color: '#4D4F5C',
        padding: '0 0 0 20px',
        border: 'none',
        textAlign: "center",
        '&:first-child': {
            textAlign: 'left !important'
        },
        '&:last-child': {
            fontWeight: 'bold',
            color: '#286A30',
        }
    },
    tableBody: {
        fontSize: 13,
        color: "#674D47",
        textTransform: "uppercase",
        fontFamily: 'Source Sans Pro'
    },
});


class AttendanceList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 5,
        };
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    }

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    }

    handleClick = (id) => {
        console.log('handle click attendance list')
    }

    render() {
    const { classes, rows } = this.props;
    const { rowsPerPage, page } = this.state;
    
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>

            <TableHead>
                <TableRow className={classes.head}>
                    <CustomTableCell className={classes.headCell}>STUDENT</CustomTableCell>
                    <CustomTableCell className={classes.headCell}>BEACON</CustomTableCell>
                    <CustomTableCell className={classes.headCell}>BATTERY</CustomTableCell>
                </TableRow>
            </TableHead>

            <TableBody className={classes.tableBody}>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow className={classes.row} key={index} onClick={() => this.handleClick(row)}>
                    <TableCell component="th" scope="row" className={classes.cell}>
                      {row.student}
                    </TableCell>
                    <TableCell numeric className={classes.cell}> {row.beacon} </TableCell>
                    <TableCell numeric className={classes.cell}> {row.battery} </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

AttendanceList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AttendanceList);