class AquiiContext {
    cFacility = null
    cCompany = null


    get facility() {
        if (this.cFacility == null) {
            const f = localStorage.getItem("facility")

            try {
                this.cFacility = JSON.parse(f)
            } catch (e) {
                this.cFacility = null
            }
        }

        return this.cFacility
    }

    set facility(val) {
        this.cFacility = val
        try {
            localStorage.setItem("facility", JSON.stringify(val))
        } catch (e) {
            // localStorage.setItem("facility", JSON.stringify(null))
        }
    }


    get company() {
        if (this.cCompany == null) {
            const f = localStorage.getItem("company")
            try {
                this.cCompany = JSON.parse(f)
            } catch (e) {
                this.cCompany = null
            }
        }

        return this.cCompany
    }

    set company(val) {
        this.cCompany = val
        try {
            localStorage.setItem("company", JSON.stringify(val))
        } catch (e) {
            // localStorage.setItem("company", JSON.stringify(null))
        }
    }

    clear() {
        this.facility = null
        this.company = null
    }
}

let globalContext = new AquiiContext()

export default globalContext