import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '32px',
        height: '32px',
        backgroundColor: '#f50057',
        border: '2px solid white',
        borderRadius: '50%',
        fontFamily: 'Source Sans Pro',
        fontSize: '0.75rem',
        color: 'white',
    },

});

class Badge extends React.Component {

    render() {
        const {classes, className, color, borderColor, bgColor} = this.props;

        let style = {}

        if (color) {
            style.color = color
        }

        if (borderColor) {
            style.color = borderColor
        }

        if (bgColor) {
            style.backgroundColor = bgColor
        }

        return (
            <span className={classNames(classes.root, className)} style={style}>
                {this.props.children}
            </span>
        );
    }
}

Badge.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
    color: PropTypes.string,
    borderColor: PropTypes.string,
    bgColor: PropTypes.string,
};

Badge.defaultProps = {
    className: '',
    strings: {},
    color: '',
    borderColor: '',
    bgColor: '',
};

export default withStyles(styles)(Badge);