import React, { Component } from 'react'
import { Typography, Grid, Divider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Strings from '../../../../common/services/strings';
import CounterCard from '../../../../common/components/CounterCard';
import PersonList from '../../../../common/components/PersonList'
import ProviderList from '../../../../common/components/ProviderList'
import Pie from '../../../../common/components/Pie'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Colors from '../../../../common/services/colors';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import moment from "moment";


const strDashboardHome = Strings.ClinicDashboardHome;
const strCounterCard = Strings.ClinicCounterCardMenu;

const styles = (theme) => ({
    wrapper: {
        display: 'flex',
        flexGrow: 1,
        height: 'auto',
        minHeight: '100vh',
        backgroundColor: 'white'
    },
    loadingContainer: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingProgress: {
        color: '#6798e5',
        animationDuration: '700ms',
    },
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        padding: '95px 40px 40px 40px',
        backgroundColor: '#0089ff45',
    },
    toolbar: theme.mixins.toolbar,
    title: {
        color: '#43425D',
        fontSize: '32px',
        fontFamily: 'Source Sans Pro, Regular',
        fontWeight: "bold",
        textTransform: 'capitalize',
    },
    noPatient: {
        borderRadius: 8,
        backgroundColor: Colors.paneBackground,
        minHeight: 180
    },
    waitingRoom: {
        color: Colors.subTitle,
        fontSize: '24px',
        fontFamily: 'Source Sans Pro, Regular',
    },
    grow: {
        flexGrow: 1
    },
    fluidity: {
        maxWidth: '375px',
        paddingTop: '22px',
    }
});

let counterCardData = {
    scheduled: {
        title: "SCHEDULED TODAY",
        number: "190",
        percentage: "+18",
        color1: "#000000",
        color2: "rgba(0, 0, 0, 0.5)"
    },
    served: {
        title: "PATIENTS SERVED TODAY",
        number: "190",
        percentage: "-11",
        color1: "#55D8FE",
        color2: "rgba(84, 216, 255, 0.5)"
    },
    noShow: {
        title: "NO SHOW",
        number: "8",
        percentage: "13.8",
        color1: "#A3A0FB",
        color2: "rgba(163, 160, 251, 0.5)"
    },
    inWaitingRoom: {
        title: "IN WAITING ROOM",
        number: "28",
        //percentage: "-6",
        color1: "#5EE2A0",
        color2: "rgba(94, 226, 160, 0.5)"
    }
}


class Home extends Component {

    constructor(props) {
        super(props)
        this.state = {loading: this.getLoading()}
    }


    componentDidMount() {
        window.scroll(0, 0)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.patients.fetched !== this.props.patients.fetched) {
            let loading = this.getLoading()
            this.setState({loading})
        }

        if (prevProps.staff.fetched !== this.props.staff.fetched) {
            let loading = this.getLoading()
            this.setState({loading})
        }
    }

    navigateToStaffItem(staffId) {

        let { history, match } = this.props
        history.push(match.url + '/staff/' + staffId  + '/view')
    }

    navigateToStaffListPage() {

        let { history, match } = this.props
        history.push(match.url + '/appointments/all')
    }

    navigateToPatientItem(patientId) {

        let { history, match } = this.props
        history.push(match.url + '/patients/' + patientId  + '/view')
    }

    getLoading() {
        const {patients, staff} = this.props
        return !patients.fetched || !staff.fetched
    }


    handleStaffItemClick = (staff) => {

        this.navigateToStaffItem(staff.id)
    }

    handleStaffButtonClick = () => {

        this.navigateToStaffListPage()
    }

    handlePatientClick = (patient) => () => {

        this.navigateToPatientItem(patient.id)
    }

    handleScheduledTodayClick = () => {
        const staffRef = document.getElementById('staffList').getBoundingClientRect()
        //console.log('clicked!', staffRef)
        window.scrollTo(0, staffRef.y - 40) //The numeric value is used to make the Title of the list appear
    }

    handleNoShowClick = () => {
        let { history, match } = this.props
        console.log('no-show clicked!')
        history.push(match.url + '/patients/no-show')
    }

    render() {

        const { classes, match, patients: patientsStore, staff: staffStore, selectedFacility, counters, appointments: appointmentsStore} = this.props
        const {loading} = this.state
        let appointments = appointmentsStore.items ? appointmentsStore.items : []
        let staff = staffStore.items ? staffStore.items : []
        const facility = selectedFacility.item ? selectedFacility.item : {}
        let colors = ['#FFC06A', '#f0f2f8']
        let data = [70, 30]

        staff = staff.map((staff) => {

            let count = counters.perStaff[staff.id] ? counters.perStaff[staff.id] : {appointment: 0, noShow: 0, available: 0}

            return Object.assign({}, {id: staff.id, name: 'Dr ' + staff.name}, count)
        })

        staff.sort((first, second) => first.name >= second.name ? 1 : -1)

        // console.log(this.props);
        counterCardData.scheduled.number = counters.scheduled
        counterCardData.served.number = counters.served
        counterCardData.noShow.number = counters.noShow
        counterCardData.inWaitingRoom.number = counters.inWaitingRoom
        counterCardData.served.percentage = ((100 * counters.served) / counters.scheduled < 0) ? '-' + ((100 * counters.served) / counters.scheduled).toFixed(1) : '+' + ((100 * counters.served) / counters.scheduled).toFixed(1);
        counterCardData.noShow.percentage = ((100 * counters.noShow) / counters.scheduled < 0) ? '-' + ((100 * counters.noShow) / counters.scheduled).toFixed(1) : '+' + ((100 * counters.noShow) / counters.scheduled).toFixed(1);
        appointments = appointments.map((appointment) => {

            return Object.assign({}, appointment, {
                info: 'Seeing Dr ' + appointment.practitioner.actor.display.toUpperCase() + ' AT ' + moment(appointment.appointment.start).format('LT')
            })
        })

        appointments = appointments.filter((appointment) => {

            return moment(appointment.appointment.end).diff(moment(new Date()), 'minutes') >= -10 &&
                    (appointment.appointment.status === 'booked') ||
                (moment(appointment.appointment.end).diff(moment(new Date()), 'minutes') >= 0 &&
                (appointment.appointment.status === 'booked' || appointment.appointment.status === 'checked-in' ||
                    appointment.appointment.status === 'form-filling' || appointment.appointment.status === 'form-filled' ||
                    appointment.appointment.status === 'form-filling-error'));
        })


        return (

            <div className={classes.wrapper}>
                {loading &&
                    <div className={classes.loadingContainer}>
                        <CircularProgress
                            variant="indeterminate"
                            className={classes.loadingProgress}
                            size={24}
                            thickness={4}
                        />
                    </div>
                }

                {!loading &&

                    <Grid container className={classes.root}>
                        <Grid container spacing={24}>
                            <Grid item sm={12} xs={12}>
                                <Typography noWrap className={classes.title}>
                                    {facility.name}
                                </Typography>
                            </Grid>

                            <Grid item sm={12} xs={12} md={12} lg={12}>
                                <Grid container spacing={16}>

                                    <Grid item sm={12} xs={12} lg={4} md={12}
                                          style={{maxWidth: '310px'}}>
                                        <CounterCard onClick={this.handleScheduledTodayClick} data={counterCardData.scheduled}/>
                                    </Grid>

                                    <Grid item sm={12} xs={12} lg={4} md={12}
                                          style={{maxWidth: '310px'}}>
                                        <CounterCard data={counterCardData.served}/>
                                    </Grid>

                                    <Grid item sm={12} xs={12} lg={4} md={12}
                                          style={{maxWidth: '310px'}}>
                                        <CounterCard onClick={this.handleNoShowClick} data={counterCardData.noShow}/>
                                    </Grid>

                                    <Grid item sm={12} xs={12} lg={4} md={12}
                                          style={{maxWidth: '310px'}}>
                                        <CounterCard data={counterCardData.inWaitingRoom}/>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item sm={12} xs={12} md={12} style={{marginTop: "60px", maxWidth: '1234px',}}
                                  className={classNames({[classes.noPatient]: appointments.length <= 0})}>

                                <Typography noWrap className={classes.waitingRoom}
                                            style={{float: "left", top: "-60px", position: "relative"}}>
                                    {strDashboardHome.contentSecondaryText}
                                </Typography>

                                {appointments.length > 0 &&
                                    <Link to={`${match.url}/appointments/active`}>
                                        <Typography noWrap style={{
                                            color: "#425276",
                                            textTransform: "capitalize",
                                            float: "right",
                                            fontSize: "16px",
                                            top: "-25px",
                                            textDecoration: "underline",
                                            position: "relative"
                                        }}>
                                            {strDashboardHome.showall}
                                        </Typography>
                                    </Link>
                                }

                                {appointments.length > 0 &&
                                    <Divider style={{border: "0.01em solid rgb(188, 188, 203)", background: "#BCBCCB"}}/>
                                }

                                {appointments.length > 0 &&
                                    <PersonList itemPerPage={10} list={appointments} countLabel={{one: 'Patient', many: 'Patients'}}
                                                onItemClick={this.handlePatientClick}/>
                                }

                                {appointments.length === 0 &&
                                    <Typography className={classes.title} style={{
                                        color: Colors.disabledText,
                                        textTransform: "uppercase",
                                        float: "left",
                                        marginTop: 40}}
                                    >
                                        {strDashboardHome.emptyWaitingRoom}
                                    </Typography>
                                }
                            </Grid>

                            <Grid container item sm={12} xs={12} md={12} style={{marginTop: '0px', maxWidth: '1235px'}}
                                  justify='flex-start'>
                                <Grid item xs={12} id={'staffList'} >
                                    <ProviderList onClickItem={this.handleStaffItemClick} list={staff} onButtonClick={this.handleStaffButtonClick}/>
                                </Grid>
                                {/*<Grid item xs={12} className={classes.fluidity}>
                                    <Paper style={{boxShadow: "0px 2px 6px -4px rgba(0,0,0,0.4)", padding: "10px"}}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography noWrap style={{
                                                    padding: '0px 10px 20px 10px',
                                                    color: "#4D4F5C",
                                                    textTransform: "capitalize",
                                                    fontSize: "18px",
                                                    position: "relative",
                                                    top: "10px",
                                                    fontFamily: 'Source Sans Pro',
                                                    textDecoration: "none"
                                                }}>
                                                    Fluidity
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    <Paper style={{
                                        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.4)",
                                        padding: "10px",
                                        textAlign: "center"
                                    }}>
                                        <Pie
                                            data={data}
                                            radius={135}
                                            hole={40}
                                            colors={colors}
                                            labels={true}
                                            percent={true}
                                            strokeWidth={3}
                                            stroke={'FFF'}
                                        />
                                    </Paper>
                                </Grid>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </div>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Home)
