export  default {

    //data related to registration page, got from user
    /*registration : {
        organization: {
            name: 'Kaiser Clinics',
            phone: '1 234 567 345',
            address: 'Lafayette avenue',
            address2: 'Street 344',
            city: 'Seattle',
            state: 'Washington',
            postalCode: 'P.O. Box 456',
            country: 'USA',
        },
        primaryContact: {
            salutation: 'mr',
            firstName: 'David',
            lastName: 'Dunt',
            email: 'dd@gmail.com',
            phone: '1 456 97 009',
            title: 'Glass',
        },
        plan: {
            name: 'premium',
            numberWaitingRoom: 1,
            period: 'annually',
            proximitySensorsQuantity: 3,
            checkInPadsQuantity: 1,
        },
        planPrices: {

            isFetching: true,

            item: {
                premium: {
                    featureAmount: 299,
                    proximitySensorsMonthlyAmount: 6,
                    checkInPadsMonthlyAmount: 14,
                    setupFeeAmount: 99,
                },
                pro: {
                    featureAmount: 199,
                    proximitySensorsMonthlyAmount: 6,
                    checkInPadsMonthlyAmount: 14,
                    setupFeeAmount: 99,
                },
                starter: {
                    featureAmount: 99,
                    proximitySensorsMonthlyAmount: 6,
                    checkInPadsMonthlyAmount: 14,
                    setupFeeAmount: 99,
                },
            }

        },
        paymentMethod: 'card',
        billInfo: {
            fullname: 'Harry Johnson',
            address: 'avenue Foch',
            city: 'Seattle',
            zipCode: '890',
            country: 'USA',
        },
        cardInfo: {
            cardNumber: '1 345 078 056',
            cardType: 'visa',
            cardholder: 'Harry johnson',
            expireDate: '',
            cvv: '456',
        },

        currentStep: 1,
    },*/

    registration : {
        organization: {
            name: '',
            phone: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
        },
        primaryContact: {
            salutation: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            title: '',
        },
        plan: {
            name: 'premium',
            numberWaitingRoom: 1,
            period: 'annually',
            proximitySensorsQuantity: 3,
            checkInPadsQuantity: 1,
        },
        planPrices: {

            isFetching: true,

            item: {
                premium: {
                    featureAmount: 299,
                    proximitySensorsMonthlyAmount: 6,
                    checkInPadsMonthlyAmount: 14,
                    setupFeeAmount: 99,
                },
                pro: {
                    featureAmount: 199,
                    proximitySensorsMonthlyAmount: 6,
                    checkInPadsMonthlyAmount: 14,
                    setupFeeAmount: 99,
                },
                starter: {
                    featureAmount: 99,
                    proximitySensorsMonthlyAmount: 6,
                    checkInPadsMonthlyAmount: 14,
                    setupFeeAmount: 99,
                },
            }

        },
        paymentMethod: 'card',
        billInfo: {
            fullname: '',
            address: '',
            city: '',
            zipCode: '',
            country: '',
        },
        cardInfo: {
            cardNumber: '',
            cardType: 'visa',
            cardholder: '',
            expireDate: '',
            cvv: '',
        },

        currentStep: 1,
    },

    facilities: {
        isFetching: true,
        //List of all facilities, fetched from database
        items: [],
    },

    //Facility currently selected by the user, stored in local storage, initially fetched from local storage
    selectedFacility: {
        checked: false,
        item: {},
    },

    patients: {
        fetched: false,
        //List of all patients, fetched from database
        items: [],
    },

    staff: {
        fetched: false,
        //List of all practitioners, fetched from database
        items: [],
    },

    appointments: {
        isFetching: true,
        //List of all appointments, fetched from database
        items: [],
    },

    settings: {

        isFetching: true,

        item: {},
    },

    newPatient: {
        demographics: {
            gender: '',
            firstName: '',
            middleName: '',
            lastName: '',
            imagePath: '',
        },
        placeOfBirth: {
            dateOfBirth: new Date(),
            city: '',
            state: '',
            country: ''
        },
        status: '',
        contact: {
            homePhone: '',
            workPhone: '',
            cellPhone: '',
            emailAddress: ''
        },
        primaryInsuranceInfo: {
            insuranceCompany: '',
            planName: '',
            phoneNumber: '',
            insuredMemberName: '',
            groupId: '',
            memberId: '',
            copay: '',
            frontCardPath: null,
            backCardPath: null,
        },
        secondaryInsuranceInfo: {
            insuranceCompany: '',
            planName: '',
            phoneNumber: '',
            insuredMemberName: '',
            groupId: '',
            memberId: '',
            copay: '',
            frontCardPath: null,
            backCardPath: null,
        },
        currentStep: 1,
    },

    /*newPatient: {
        demographics: {
            gender: 'Male',
            firstName: 'abdel',
            middleName: 'salam',
            lastName: 'abbo',
            imagePath: '',
        },
        placeOfBirth: {
            dateOfBirth: new Date(),
            city: 'garoua',
            state: 'benoue',
            country: 'cameroon'
        },
        status: 'Single',
        contact: {
            homePhone: '237693044662',
            workPhone: '237680795312',
            cellPhone: '237665838305',
            emailAddress: 'abdel@tenkolo.com'
        },
        primaryInsuranceInfo: {
            insuranceCompany: 'abc insurance',
            planName: 'tenkolo',
            phoneNumber: '237693044662',
            insuredMemberName: 'zi',
            groupId: '3',
            memberId: '123',
            copay: 'traditional',
            frontCardPath: null,
            backCardPath: null,
        },
        secondaryInsuranceInfo: {
            insuranceCompany: 'wonder insurance',
            planName: 'simba',
            phoneNumber: '237693044662',
            insuredMemberName: 'zi',
            groupId: '3',
            memberId: '123',
            copay: 'local',
            frontCardPath: null,
            backCardPath: null,
        },
        currentStep: 1,
    },*/

    currentOrganization: {
        isFetching: false,
        item: {
            id: '',
            name: 'default',
        },
    },

    currentFacility: {
        isFetching: false,
        item: {
            id: '',
            name: 'default',
        }
    },

    user: {},

    home: {
        counters: {
            scheduled: 0,
            served: 0,
            noShow: 0,
            inWaitingRoom: 0,
            perStaff: {}
        }

    },
    noShowPatients: {
        item: []
    }

}