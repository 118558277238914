import { connect } from 'react-redux'
import { changePatientInfo } from '../../../../../../common/services/actions'
import NewPatientPage2 from '../components/NewPatientPage2'


//Check if planPrices is set, if not fetch it from server by ajax request

const mapStateToProps = state => {
    return {
        primaryInsuranceInfo: state.newPatient.primaryInsuranceInfo,
        currentStep: state.newPatient.currentStep,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNewPatientChange: (info, value) => {
            dispatch(changePatientInfo(info, value))
        }
    }
}

const NewPatientPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewPatientPage2)

export default NewPatientPage