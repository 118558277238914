import { connect } from 'react-redux'
import StaffProfilePage from '../components/StaffProfilePage'


const mapStateToProps = state => {
    return {
        settings: state.settings.item,
        selectedFacility: state.selectedFacility,
        staff: state.staff
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const StaffProfilePageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffProfilePage)

export default StaffProfilePageContainer