import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import firebase from 'firebase'
import {isEmptyObject, objectToFormFormat} from '../../../../../../common/services/Util'
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Button from '../../../../../../common/components/Button'
import Calendar from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import '../../../../../../common/assets/css/appointmentAgenda.css'
import validate from "validate.js";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";


const localizer = Calendar.momentLocalizer(moment);


const styles = theme => ({
    wrapper: {
        display: 'flex',
        flexGrow: 1,
        height: 'auto',
        minHeight: '100vh',
        backgroundColor: 'white'
    },
    loadingContainer: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingErrorLabel: {
        color: '#43425D',
        fontSize: '26px',
        fontFamily: 'Source Sans Pro',
    },
    loadingProgress: {
        color: '#6798e5',
        animationDuration: '700ms',
    },
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        minHeight: '100vh',
        padding: '95px 40px 40px 40px',
        backgroundColor: 'white',
        fontFamily: 'Source Sans Pro',
        color: '#707070',
    },
    header: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '100%',
        height: 'auto',
        maxWidth: '1534px',
        paddingBottom: '15.5px',
        paddingRight: '20px',
        borderBottom: '4px solid #707070',
    },
    companyLabel: {
        fontFamily: 'helvetica',
        fontSize: '36px',
        textTransform: 'capitalize',
        color: '#43425D'
    },
    patientLabel: {
        fontFamily: 'helvetica neue',
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#43425D',
    },
    staffName: {
        textTransform: 'capitalize',
    },
    newAppointmentLabel: {
        color: '#3B86FF',
    },
    providerContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: '117px',
        maxWidth: '1534px',
        padding: '25px 20px 0px 0px',
        borderBottom: '2px solid rgba(67, 66, 93, 0.25)',
    },
    providerLabel: {
        margin: '0px 25px 5px 0px',
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#707070',
    },
    inputWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      width: 'auto',
    },
    formControlSelect: {
        margin: '0px',
    },
    noDisplay: {
        display: 'none !important'
    },
    selectRoot: {
        width: '365px',
        height: '43px',
        padding: '0px 0px',
        border: 'none',
        borderRadius: '4px',
        fontSize: '24px',
        color: "#43425D",
    },
    selectSelect: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '0px 15px 0px 42px',
        border: '1px solid #707070',
        borderRadius: '4px',
        fontFamily: 'Helvetica Neue',
        fontSize: '24px',
        textTransform: 'capitalize',
        boxSizing: 'border-box',
        '&:focus': {
            border: '1px solid #43425D',
            borderRadius: '4px',
            backgroundColor: 'white'
        }
    },
    selectNotchedOutline: {
        border: 'none',
    },
    selectItem: {
        padding: '10px 15px 10px 42px',
        fontFamily: 'Helvetica Neue',
        fontSize: '24px',
        textTransform: 'capitalize',
        color: '#43425D'
    },
    errorLabel: {
        display: 'flex',
        width: '100%',
        fontSize: '15px',
        color: "#D7373F"
    },
    detailsContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        width: '100%',
        height: 'auto',
        maxWidth: '1520px',
        marginTop: '15.5px',
        paddingRight: '20px',
    },
    reasonStart: {
        display: 'flex',
        width: 'auto',
        flexFlow: 'row nowrap',
    },
    reasonLabel: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '154px',
        width: 'auto',
        marginRight: '94px',
        fontFamily: 'helvetica neue',
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#707070',
    },
    reasonInputContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: 'auto',
        height: '100%',
    },
    radioGroup: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        marginLeft: 'auto',
        padding: '0px 0px',
        width: 'auto',
        height: 'auto',
        fontSize: '26px',
        color: "#707070",
    },
    controlLabelRoot: {
        marginBottom: '0px',
    },
    controlLabelLabel: {
        fontFamily: 'Source Sans Pro',
        fontSize: '26px',
        color: '#707070',
    },
    radioColor: {
        color: '#43425D !important',
    },
    notesContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: 'auto',
        height: 'auto',
    },
    notesLabel: {
        margin: '0px 30px 0px 0px',
        fontFamily: 'helvetica neue',
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#707070',
    },
    notesInput: {
        width: '405px',
        height: '154px',
        padding: '10px',
        marginBottom: '10px',
        border: '1px solid rgba(112, 112, 112, 0.45)',
        borderRadius: '6px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px',
        outline: '0px !important',
        fontFamily: 'helvetica neue',
        fontSize: '20px',
        color: '#43425D',
        '&:focus': {
            border: '1px solid rgba(112, 112, 112, 0.65)',
            boxShadow: 'rgba(0, 0, 0, 0.26) 0px 3px 6px',
        }
    },
    agendaContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        maxWidth: '1534px',
        marginTop: '15.5px',
        paddingRight: '20px',
    },
    agendaWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: '1500px',
        height: 'auto',
        marginBottom: '10px',
        padding: '40px 20px 20px 20px',
        backgroundColor: '#E6EAF0',
        color: '#4D4F5C',
    },

    createCard: {
        position: 'absolute',
        zIndex: '5',
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        height: '180px',
        padding: '10px 15px 10px 15px',
        fontSize: '13px',
        color: '#4D565C',
        backgroundColor: 'white',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
        [theme.breakpoints.down('lg')]: {
            left: '701px',
        }
    },
    arrow: {
        position: 'absolute',
        display: 'flex',
        top: '-4px',
        left: '150px',
        boxShadow: '-2px -2px 1px rgba(0, 0, 0, 0.03)',
        width: '10px',
        height: '10px',
        transform: 'rotate(45deg)',
        backgroundColor: 'white',
    },
    eventLabelContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0px 5px 0px',
    },
    eventLabel: {
        fontFamily: 'Source Sans Pro',
        fontSize: '18px',
        color: '#43425D',
    },
    eventInput: {
        width: '100%',
        height: '40px',
        padding: '5px 10px',
        marginBottom: '20px',
        border: 'none',
        borderBottom: '1px solid gainsboro',
        fontFamily: 'Source Sans Pro',
        fontSize: '14px',
        color: '#43425D',
        outline: '0px !important',
        '&:focus': {
            borderBottom: '2px solid #43425D',
        }
    },
    eventButtonContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        width: '100%'
    },
    eventButton: {
        width: '100px',
        '&:first-child': {
            backgroundColor: 'gainsboro !important',
            color: '#43425D'
        }
    },
    nextAppointment: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: '10px',
        fontFamily: 'helvetica neue',
        fontSize: '36px',
        fontWeight: 'bold',
        color: '#083180',
        opacity: '0.43',
    },
    buttonContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        // maxWidth: '1148px',
        maxWidth: '1500px',
        marginTop: '15.5px',
    },
    saveButton: {
        width: '180px',
        height: '50px',
        fontSize: '18px',
    },
    saveButtonDisabled: {
        width: '180px',
        height: '50px',
        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
        fontSize: '18px',
    },

    errorBorder: {
        border: '1px solid #D7373F !important'
    },
    errorColor: {
        color: '#D7373F !important',
    },
    errorNotes: {
        border: '1px solid #D7373F !important',
    },
    errorEvents: {
        backgroundColor: 'rgb(255, 224, 224) !important'
    }
});


// const errorEmailMessage = 'This field must be a valid email'
const errorRequiredMessage = 'This field is required'
// const erroFormatlMessage = 'This field is not to the good format'
const scheduleMessage = 'Please schedule your appointment'


class NewAppointmentPage extends React.Component {

    constructor(props) {

        super(props)
        this.createCardRef = React.createRef()
        this.state = {
            loading: true,
            loadingError: false,
            provider: {},
            patients: [],
            patientId: '',
            reason: '',
            notes: '',
            events: [],
            createCardVisibility: false,
            createCardPosition: {x: 0, y: 0},
            editingEvent: {
                title: '',
                start: null,
                end: null,
            },
            errors: {},
            next: false,
            isSending: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        document.addEventListener('mousedown', this.handleClickOutsideCreateCard)
        this.initialize()
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideCreateCard);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.patientId !== this.state.patientId || prevState.reason !== this.state.reason || prevState.notes !== this.state.notes || prevState.events !== this.state.events) {

            this.softValidation()
        }

        if (prevProps.selectedFacility.item !== this.props.selectedFacility.item) {

            this.initialize()
        }

        if (prevProps.staff.items !== this.props.staff.items) {

            this.getStaff()
        }
    }

    async initialize() {

        try {
            this.setState({loading: true, loadingError: false})

            await this.getPatients()
            await this.getStaff()

            // this.setState({loading: false})

        } catch (e) {
            this.setState({loadingError: 'Error: ' + e.message})
        }
    }

    async getPatients() {

        const {settings, currentOrganization} = this.props

        let res = await fetch(settings.item.fhirServer.baseUrl + '/patient?managingOrganization=' + currentOrganization.item.id,  {
            mode: 'cors',
        });

        if (res.status === 200) {

            let patients = []
            let patientLists = []

            let jsonRes = await res.json()

            for (let entry of jsonRes.entry) {

                patients.push(entry.resource)
            }

            for (let i = 0; i < patients.length; i++) {
                let name = ''
                let firstName = ''
                let lastName = ''
                let info = ''
                let avatar = ''
                let phone = ''
                let email = ''
                let initials = ''
                let id = patients[i].id

                if (patients[i].name && patients[i].name.length > 0) {

                    if (patients[i].name[0].family) {
                        name = patients[i].name[0].family
                        firstName = name
                    }

                    if (patients[i].name[0].given && patients[i].name[0].given.length > 0) {

                        for (let given of patients[i].name[0].given) {
                            name += ' ' + given
                            lastName += given + ' '
                        }

                        lastName = lastName.trim()
                    }

                    initials = name.match(/\b\w/g) || [];
                    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
                }

                if (patients[i].telecom && patients[i].telecom.length > 0) {

                    let phones = patients[i].telecom.filter(item => item.system === 'phone')
                    let emails = patients[i].telecom.filter(item => item.system === 'email')

                    if (phones.length > 0) {
                        phone = phones[0].value
                    }

                    if (emails.length > 0) {
                        email = emails[0].value
                    }
                }

                if (patients[i].photo && patients[i].photo.length > 0) {

                    if (patients[i].photo[0].data) {
                        // avatar = patients[i].photo[0].data
                    }
                }


                patientLists.push({initialVersion: patients[i], id, name, info, avatar, phone, email, initials, firstName, lastName})
                //console.log(patients[i], id, name, info, avatar, phone, email, initials, firstName, lastName)
            }

            this.setState({patients: patientLists, loading: false})

        } else {

            this.setState({loadingError: 'Appointments cannot be fetched'})
        }
    }

    async getStaff() {

        const {selectedFacility, match, staff} = this.props

        if (selectedFacility.item && !isEmptyObject(selectedFacility.item)) {

            /*let providerQuery = firebase.database().ref('providers/' + match.params.staffId)

            let providerSnapshot = await providerQuery.once('value')

            let provider = providerSnapshot.val()
            let key = providerSnapshot.key*/

            let matchStaff = staff.items.find((item) => item.id === match.params.staffId)

            console.log('match stff', matchStaff)


            if (matchStaff) {

                /*let staffClinicQuery = firebase.database().ref('clinicStaff').orderByChild('providerId').equalTo(match.params.staffId)
                let staffClinicSnap = await staffClinicQuery.once('value')

                let present = false

                staffClinicSnap.forEach((staffSnap) => {
                    let data = staffSnap.val()
                    if (data.facilityId === selectedFacility.item.key) {
                        present = true
                    }
                })

                if (present) {
                    this.setState({provider: {id: key, key, ...provider}, loadingError: false})
                } else {
                    this.setState({loadingError: 'No such provider in this clinic'})
                }*/

                this.setState({provider: matchStaff, loadingError: false})

            } else {

                this.setState({loadingError: 'No such provider in this clinic'})
            }
        }
    }

    generateAppointmentFHIR() {

        const {reason, events, notes, patientId, patients, provider} = this.state

        let patient = patients.find((item) => item.id === patientId)
        patient = patient ? patient : {}

        let event = events.length > 0 ? events[0] : {}

        let appointment = {
            resourceType: 'Appointment',
            // identifier: [],
            status: 'proposed',
            // cancelationReason: {},
            // serviceCategory: {},
            // serviceType: {},
            specialty: provider.practitionerRole.specialty,
            appointmentType: {
                coding: [
                    {
                        system: 'http://example.org/appointment-type',
                        code: reason.toLowerCase(),
                        display: reason.toLowerCase(),
                    }
                ],
                text: reason.toLowerCase()
            },
            reasonCode: [
                {
                    coding: [
                        {
                            system: 'http://example.org/appointment-reason',
                            code: reason.toLowerCase(),
                            display: reason.toLowerCase(),
                        }
                    ],
                    text: reason.toLowerCase()
                }
            ],
            // reasonReference: [],
            // priority: '',
            description: event.title.toLowerCase(),
            // supportingInformation: [],
            start: moment.utc(event.start).format('YYYY-MM-DD HH:mm'),
            end: moment.utc(event.end).format('YYYY-MM-DD HH:mm'),
            // minutesDuration: '',
            created: moment.utc().format('YYYY-MM-DD'),
            comment: notes.toLowerCase(),
            // patientInstruction: '',
            // basedOn: [],
            participant: [
                {
                    // type: [],
                    actor: {
                        reference: 'Patient/' + patient.id,
                        display: patient.firstName.toLowerCase() + ' ' + patient.lastName.toLowerCase()
                    },
                    required: 'required',
                    status: 'needs-action',
                    // period: {},
                },
                {
                    // type: [],
                    actor: {
                        reference: 'Practitioner/' + provider.id,
                        display: provider.firstName.toLowerCase() + ' ' + provider.lastName.toLowerCase(),
                    },
                    required: 'required',
                    status: 'needs-action',
                    // period: {},
                },

            ],
            requestedPeriod: [
                {
                    start: moment.utc(event.start).format('YYYY-MM-DD HH:mm'),
                    end: moment.utc(event.end).format('YYYY-MM-DD HH:mm'),
                }
            ],
        }

        return appointment
    }

    softValidation() {

        const {reason, notes, patientId, events} = this.state

        let input = {reason, notes, patientId}

        let constraints = {
            reason: {
                presence: {allowEmpty: false}
            },
            notes: {
                presence: {allowEmpty: false}
            },
            patientId: {
                presence: {allowEmpty: false}
            },
        }

        let errors = validate(input, constraints)

        if (events.length <= 0) {
            errors = !errors ? {} : errors
            errors.events = [scheduleMessage]
        }

        if (errors) {

            this.setState({next: false})

        } else {

            this.setState({next: true})
        }
    }

    hardValidation() {

        const {reason, notes, patientId, events} = this.state

        let input = {reason, notes, patientId}

        let constraints = {
            patientId: {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            reason: {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
            notes: {
                presence: {
                    allowEmpty: false,
                    message: errorRequiredMessage
                },
            },
        }

        let errors = validate(input, constraints, {fullMessages: false})

        if (events.length <= 0) {
            errors = !errors ? {} : errors
            errors.events = [scheduleMessage]
        }

        let error = {}

        if (errors) {

            for (let key in errors) {

                let keys = key.split('.')

                if (keys.length > 1) {

                    if (!error.hasOwnProperty(keys[0])) {
                        error[keys[0]] = {}
                    }
                    error[keys[0]][keys[1]] = errors[key][0]

                } else {
                    error[keys] = errors[key][0]
                }
            }
        }

        console.log(this.generateAppointmentFHIR())

        this.setState({errors: error})

        return !errors
    }

    handleChange = (value, childName, subChildName) => {

        if (subChildName === undefined) {
            console.log(subChildName, childName, value)
            this.setState({[childName] : value})

        } else {

            let child = Object.assign({}, this.state[childName])
            child[subChildName] = value
            this.setState({[childName]: child})
        }
    }

    handleSaveClick = async () => {

        console.log(this.generateAppointmentFHIR())

        let validation = this.hardValidation()

        if (validation) {

            const {settings} = this.props;

            this.setState({isSending: true});

            let res = await fetch(settings.item.fhirServer.baseUrl + '/appointment',  {
                method: 'POST',
                mode: 'cors',
                body: objectToFormFormat(this.generateAppointmentFHIR()),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (res.status === 201) {

                this.setState({notes: '', reason: '', patientId: ''})

            } else {

                this.setState((state) => (
                    {errors : Object.assign({}, state.errors, {sending: 'something went wrong'})}
                ))
            }

        } else {

            console.log('validation failed')
        }
    }

    handleAgendaClick = (e) => {

        console.log('avant', e)

        if ((e.box !== undefined && e.box !== null) || (e.bounds !== undefined && e.bounds !== null)) {

            let {x, y} = (e.box !== undefined && e.box !== null) ? e.box : e.bounds
            let {start, end} = e
            let editingEvent = Object.assign({}, this.state.editingEvent)
            editingEvent.start = start
            editingEvent.end = end

            this.setState((state) => ({
                createCardVisibility: !state.createCardVisibility,
                createCardPosition: {x, y},
                editingEvent,
            }))
        }

    }

    handleAddEvent = () => {

        const {events} = this.state
        let editingEventCopy = Object.assign({}, this.state.editingEvent)
        let newEvents = events.concat([editingEventCopy])
        // let newEvents = [editingEventCopy]

        this.setState({
            events: newEvents,
            editingEvent: {
                title: '',
                start: null,
                end: null
            },
            createCardVisibility: false,
        })

    }

    handleClickOutsideCreateCard = (event) => {
        if (this.state.createCardVisibility && this.createCardRef && !this.createCardRef.current.contains(event.target)) {
            this.setState({createCardVisibility: false})
        }
    }

    render() {
        const {classes, className, selectedFacility} = this.props
        const {patientId, notes, reason, events, createCardVisibility,
            createCardPosition, editingEvent, next, errors, patients, provider, loading, loadingError} = this.state

        return (

            <div className={classes.wrapper}>
                {loadingError &&
                    <div className={classes.loadingContainer}>
                        <span className={classes.loadingErrorLabel}>{loadingError}</span>
                    </div>
                }

                {loading && !loadingError &&
                    <div className={classes.loadingContainer}>
                        <CircularProgress
                            variant="indeterminate"
                            className={classes.loadingProgress}
                            size={24}
                            thickness={4}
                        />
                    </div>
                }

                {!loading && !loadingError &&

                    <div className={classNames(classes.root, className)}>
                        <div className={classes.header}>
                            <span className={classes.companyLabel}>{selectedFacility.item.name}</span>
                            <span className={classes.patientLabel}>
                            <span className={classes.staffName}>{'DR ' + provider.firstName + ' ' + provider.lastName}</span>:&nbsp;
                                <span className={classes.newAppointmentLabel}>New Appointment</span>
                            </span>
                        </div>

                        <div className={classes.providerContainer}>
                            <label htmlFor="patient" className={classes.providerLabel}>Desired Patient</label>

                            <div className={classes.inputWrapper}>
                                <FormControl variant="outlined" className={classes.formControlSelect}>
                                    <InputLabel classes={{root: classes.noDisplay}}/>
                                    <Select
                                        value={patientId}
                                        onChange={(e) => this.handleChange(e.target.value, 'patientId')}
                                        input={<OutlinedInput labelWidth={0} id="patient" classes={{notchedOutline: classes.selectNotchedOutline}}/>}
                                        classes={{root: classes.selectRoot, select: classNames(classes.selectSelect, {[classes.errorBorder]: (errors.providerId)}),}}
                                    >
                                        {
                                            patients.map((patient, index) => (
                                                <MenuItem key={index} value={patient.id} className={classes.selectItem}> {patient.firstName + ' ' + patient.lastName} </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>

                                {(errors.patientId && errors.patientId) &&
                                    <span className={classes.errorLabel}>{errors.patientId}</span>
                                }
                            </div>

                        </div>

                        <div className={classes.detailsContainer}>
                            <div className={classes.reasonStart}>
                            <span className={classes.reasonLabel}>
                                Reason
                            </span>

                                <div className={classes.inputWrapper}>
                                    <div className={classes.reasonInputContainer}>
                                        <RadioGroup aria-label="period" name="period" className={classes.radioGroup} value={reason} onChange={ (e) => this.handleChange(e.target.value, 'reason')} id="reason">
                                            <FormControlLabel
                                                value="health problem"
                                                control={<Radio classes={{root: classNames(classes.radioColor, {[classes.errorColor]: (errors.reason)}), checked: classes.radioColor }}/>}
                                                label="New Health Problem"
                                                labelPlacement="end"
                                                classes={{root: classes.controlLabelRoot, label: classes.controlLabelLabel,}}
                                            />
                                            <FormControlLabel
                                                value="annual exam"
                                                control={<Radio classes={{root: classNames(classes.radioColor, {[classes.errorColor]: (errors.reason)}), checked: classes.radioColor }}/>}
                                                label="Annual Exam"
                                                labelPlacement="end"
                                                classes={{root: classes.controlLabelRoot, label: classes.controlLabelLabel,}}
                                            />
                                            <FormControlLabel
                                                value="follow-up"
                                                control={<Radio classes={{root: classNames(classes.radioColor, {[classes.errorColor]: (errors.reason)}), checked: classes.radioColor }}/>}
                                                label="Follow-up"
                                                labelPlacement="end"
                                                classes={{root: classes.controlLabelRoot, label: classes.controlLabelLabel,}}
                                            />
                                        </RadioGroup>
                                    </div>

                                    {(errors.reason && errors.reason) &&
                                        <span className={classes.errorLabel}>{errors.reason}</span>
                                    }
                                </div>
                            </div>

                            <div className={classes.notesContainer}>
                                <span className={classes.notesLabel}>Notes</span>

                                <div className={classes.inputWrapper}>
                                <textarea value={notes} name="notes" className={classNames(classes.notesInput, {[classes.errorNotes]: (errors.notes)})}
                                          id="notes" onChange={(e) => this.handleChange(e.target.value, 'notes')}
                                />
                                    {(errors && errors.notes) &&
                                        <span className={classes.errorLabel}>{errors.notes}</span>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className={classes.agendaContainer}>
                            <div className={classNames(classes.agendaWrapper, {[classes.errorEvents]: (errors.events)})}>

                                <div className={classNames(classes.createCard, {[classes.noDisplay]: !createCardVisibility})}
                                     ref={this.createCardRef} style={{top: createCardPosition.y + 'px', left: (createCardPosition.x - 150) + 'px'}}>
                                    <span className={classes.arrow}/>
                                    <span className={classNames(classes.eventLabelContainer)}>
                                        <label  htmlFor="event-name" className={classes.eventLabel}>Appointment title</label>
                                    </span>
                                    <input type="text" className={classes.eventInput} id="event-name"
                                           value={editingEvent.title} onChange={(e) => this.handleChange(e.target.value, 'editingEvent', 'title')}
                                    />
                                    <div className={classes.eventButtonContainer}>
                                        <Button className={classes.eventButton} onClick={() => this.handleChange(false, 'createCardVisibility')}>Cancel</Button>
                                        <Button className={classes.eventButton} onClick={this.handleAddEvent}>OK</Button>
                                    </div>

                                </div>

                                <Calendar
                                    selectable
                                    onSelectSlot={this.handleAgendaClick}
                                    localizer={localizer}
                                    defaultDate={new Date()}
                                    defaultView="month"
                                    step={60}
                                    events={events}
                                    style={{ height: "859px", maxWidth: '1500px', width: '100%', backgroundColor: '#E6EAF0'}}
                                />
                                {events && events.length > 0 &&
                                    <span className={classes.nextAppointment}>
                                        Appointment scheduled: {moment(events[0].start).format('MMMM Do, YYYY') + ' at ' + moment(events[0].start).format('h a')}
                                    </span>
                                }

                            </div>

                            {(errors && errors.events) &&
                                <span className={classes.errorLabel}>{errors.events}</span>
                            }

                        </div>

                        <div className={classes.buttonContainer}>
                            <Button className={classes.saveButton} disabledClassName={classes.saveButtonDisabled} onClick={this.handleSaveClick} disabled={!next}>SAVE</Button>
                        </div>


                    </div>
                }
            </div>


        );
    }
}

NewAppointmentPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

NewAppointmentPage.defaultProps = {
    className: '',
    strings: {},
};

export default withStyles(styles)(NewAppointmentPage);