import {combineReducers} from 'redux'
import {
    SET_CURRENT_FACILITY,
    SET_CURRENT_FACILITY_FETCHING_STATUS,
} from '../actions'


const isFetching = (state = false, action) => {

    switch (action.type) {

        case SET_CURRENT_FACILITY_FETCHING_STATUS:

            return action.value

        default:

            return state
    }
}

const  item = (state = {}, action) => {

    switch (action.type) {

        case SET_CURRENT_FACILITY:

            return action.value

        default:

            return state
    }
}

export const currentFacility = combineReducers({
    isFetching,
    item,
})


