import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import Button from "@material-ui/core/Button/Button";
import Strings from "../../../../../services/strings";
import doc from "../../../../../../common/assets/img/doc.png";

const strPatient = Strings.StaffPageStrings;

const styles = theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        minHeight: '100vh',
        fontFamily: 'Source Sans Pro',
        fontSize: '18px',
        color: '#707070',
        padding: '95px 40px 40px 40px',
        backgroundColor: 'white',
    },
    wrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '100%',
        maxWidth: '1703px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '100%',
        borderBottom: '4px solid #707070',
        fontFamily: 'Helvetica',
        fontSize: '36px',
        textAlign: 'left',
    },
    coloredTitle: {
        fontSize: '30px',
        fontWeight: 'bold',
        alignSelf: 'flex-start',
        textAlign: 'center',
        color: '#3B86FF',
    },
    content: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingTop: '20px',
    },
    newFormButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        width: '185px',
        border: 'none',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro !important',
        fontSize: '18px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF !important',
        backgroundColor: '#43425D !important',
        boxShadow: 'none !important',
        cursor: 'pointer',
        outline: '0px !important',
    },
    formWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: '70px',
        borderBottom: '1px solid #707070',
    },
    formBody: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '80%',
    },
    message: {
        display: 'flex',
        //alignSelf: 'flex-start',
        fontSize: '26px',
        fontWeight: 'bold',
    },
    formGroup: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        paddingTop: '88px',
        paddingBottom: '250px',
        width: '100%',
    },
    formItem: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '195px',
        fontFamily: 'Helvetica Neue',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    footer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '15.5px',
    },
    previousContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
    },
    insurance: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        textAlign: 'center',
        marginTop: '5px',
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        width: '185px',
        border: 'none',
        borderRadius: '4px',
        fontFamily: 'Source Sans Pro !important',
        fontSize: '18px',
        textAlign: 'center',
        textTransform: 'capitalize',
        color: '#FFFFFF !important',
        backgroundColor: '#3B86FF !important',
        boxShadow: 'none !important',
        cursor: 'pointer',
        outline: '0px !important',
    },
});

class FormAndDocuments extends React.Component{

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    navigateToCreatePatient3() {
        const {history, match} = this.props;
        history.push(`${match.url.replace('new-patient-forms', 'create-new-patient3')}`);
    }

    handlePreviousButtonClick = () => {

        this.navigateToCreatePatient3()
    }

    render() {
        const {classes, className} = this.props;
        return(
            <div className={classNames(classes.container, className)}>
                <div className={classes.wrapper}>
                    <div className={classes.header}>
                        <span>{strPatient.clinicName}</span>
                        <span className={classes.coloredTitle}>{strPatient.newPatient}</span>
                    </div>
                    <div className={classes.content}>
                        <span className={classes.coloredTitle}>{strPatient.formsAndDocs}</span>
                        <Button className={classes.newFormButton}>ADD NEW FORM</Button>
                    </div>
                    <div className={classes.formWrapper}>
                        <div className={classes.formBody}>
                            <span className={classes.message}>{'The following forms will be sent to the user'}</span>
                            <div className={classes.formGroup}>
                                <div className={classes.formItem}>
                                    <img src={doc} alt={'doc'}/>
                                    <span>{'New patient form'}</span>
                                </div>
                                <div className={classes.formItem}>
                                    <img src={doc} alt={'doc'}/>
                                    <span>{'Privacy Policy'}</span>
                                </div>
                                <div className={classes.formItem}>
                                    <img src={doc} alt={'doc'}/>
                                    <span>{'Contact Sheet'}</span>
                                </div>
                                <div className={classes.formItem}>
                                    <img src={doc} alt={'doc'}/>
                                    <span>{'Medical History'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.footer}>
                        <div className={classes.previousContainer}>
                            <Button onClick={this.handlePreviousButtonClick} className={classes.button}>{'< PREVIOUS'}</Button>
                            <span className={classes.insurance}>{'Secondary Insurance Info'}</span>
                        </div>
                        <Button className={classes.button}>{'SAVE'}</Button>
                    </div>
                </div>
            </div>
        );
    }
}

FormAndDocuments.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    strings: PropTypes.object.isRequired,
};

FormAndDocuments.defaultProps = {
    className: '',
    strings: {},
};
export default withStyles(styles)(FormAndDocuments);