import React from "react";
import ReactDOM from "react-dom";
import {createStore} from 'redux'
import reducer from './common/services/reducers'
import initialAppState from './common/services/intialAppState'
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";



const store = createStore(reducer, initialAppState)

ReactDOM.render(<App store={store}/>, document.getElementById("root"));
registerServiceWorker();
