import React, { Component } from "react";

import Login from "../Authentication/scenes/Login";

class HomePage extends Component {
  state = {};
  render() {
    return <Login />;
  }
}

export default HomePage;
