import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Typography} from '@material-ui/core';
import Badge from '../../Badge'
import Icons from '../../../assets/icon'
import classNames from 'classnames';

const styles = (theme) => ({
	root: {
		padding: '0px !important'
	},
	textStyle: {
		fontFamily: 'Source Sans Pro, Regular',
		textTransform: 'capitalize',
		color: 'white !important',
		opacity: 1,
		fontSize: "15px",
		outlineOffset: "52px",
		textAlign: "center",
		float: "left"
		
	},
	menuItem: {
		backgroundColor: '#43425d',
		height: "53px",
		marginBottom : '6px',
		'&:hover': {
			backgroundColor: '#3c3b54 !important'
		},
	},
	badge: {
		backgroundColor: "#f50057",
		border: "2px solid white",
		width: "32px",
		height: "32px",
		textAlign: "center",
		float: "left",
		top: '-17px'
	},
	icon: {
		margin: 0, //theme.spacing.unit,
		color: "#A5A4BF",
		width: "20px",
		height: "16px",
		fontSize: "15px",
		outlineOffset: "25px",
		textAlign: "center",
		float: "left"
	},
	active: {
		borderLeft: '5px solid #A3A0FB',
		background: '#3c3b54',
	}

});

// TODO: this should be set after querying database

class MenuContent extends Component {


	handleClick(menuName, menuAction) {
		this.props.onClickMenuItem(menuName, menuAction)
	}

	render() {
		const { classes, menuItems, notifications, activeItem } = this.props;

		return (
			<List className={classes.root}>
				{
					menuItems.map((menuItem, index) => (
						<ListItem button className={classNames(classes.menuItem, {[classes.active]: menuItem.name === activeItem })}
								  key={index} onClick={() => this.handleClick(menuItem.name, menuItem.action)}>

							<img src={menuItem.name === activeItem ? Icons[menuItem.icon_active] : Icons[menuItem.icon]} className={classNames(classes.icon)} alt=""/>

							<ListItemText primary={
								<Typography type="body2" className={classes.textStyle}>
									{menuItem.label}
								</Typography>
							} />

							{notifications[menuItem.name] !== undefined &&  notifications[menuItem.name] > 0 &&
								<Badge>{notifications[menuItem.name]}</Badge>
							}
						</ListItem>
					))
				}
			</List>
		);
	}
}

MenuContent.propTypes = {
	classes: PropTypes.object.isRequired,
	tagItem: PropTypes.array.isRequired,
	notifications: PropTypes.object.isRequired,
	activeItem: PropTypes.string.isRequired,
	onClickMenuItem: PropTypes.func.isRequired,
};

MenuContent.defaultProps = {
	tagItem: [],
	notifications: {},
	activeItem: ''
}

export default withStyles(styles)(MenuContent);
