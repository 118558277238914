import React, { Component } from "react";
import firebase from "firebase";
import { withRouter } from 'react-router-dom'


import Input from "../../../components/Input";
import Strings from "../../../../../services/strings"
import globalContext from "../../../../../services/context"

const strings = Strings.LoginPage;

const IssueType = {
  noIssue: null,
  wrongUser: strings.wrongUserText,
  wrongCredentials: strings.wrongCredentialsText,
  issueWithAccount: strings.issueWithAccount,
  invalidEmail: strings.invalidEmail,
  genericError: strings.genericError
}


const dashboards = {
  school: "/dashboard",
  preschool: "/dashboard",
  clinic: "/dashboard-clinic"
}

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = { emailAddress: "", password: "", issueType: IssueType.noIssue };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  async handleLogin() {
    try {
      const userCred = await firebase
        .auth()
        .signInWithEmailAndPassword(
          this.state.emailAddress,
          this.state.password
        );

      // see if this user is an admin of any company we know. For now we are assuming one company per admin
      var snapshot = await firebase
        .database()
        .ref("admins")
        .orderByChild("userId")
        .equalTo(userCred.user.uid)
        .limitToFirst(1)
        .once("value");
      console.log(snapshot);

      const adminProps = snapshot.val();
      if (adminProps == null) {
        // disconnect the user
        await firebase.auth().signOut();
        this.setState({ issueType: IssueType.wrongUser });
        return false;
      }

      // Get the company data
      var companyId = null;

      snapshot.forEach(function (childSnapshot) {
        // const childKey = childSnapshot.key;
        var childData = childSnapshot.val();

        companyId = childData.companyId;
      });

      snapshot = await firebase
        .database()
        .ref("companies")
        .child(companyId)
        .once("value");

      const companyProps = snapshot.val();
      if (companyProps == null) {
        // this shouldnt happen !
        await firebase.auth().signOut();
        this.setState({ issueType: IssueType.issueWithAccount });
        return false;
      }

      companyProps.key = snapshot.key
      globalContext.company = companyProps

      // get the facilities:
      snapshot = await firebase
        .database()
        .ref("facilities")
        .orderByChild("companyID")
        .equalTo(companyId)
        .once("value");

      //const facilities = snapshot.val()
      let facilities = []

      snapshot.forEach(childSnapshot => {
        let facility = childSnapshot.val()
        const type = dashboards[facility.type]

        if (type != null) {
          facility.dashboard = type
          facility.key = childSnapshot.key
          facilities.push(facility)
        }
      })

      return facilities.length > 0 ? facilities : null

    } catch (error) {
      console.log("did not sign in ", error);
      if (error.code === "auth/wrong-password") {
        this.setState({ issueType: IssueType.wrongCredentials })
      } else if (error.code === "auth/invalid-email") {
        this.setState({ issueType: IssueType.invalidEmail })
      } else {
        this.setState({ issueType: IssueType.genericError })
      }

      return null
    }
  }


  handleInputChange(event) {
    this.setState({ issueType: IssueType.noIssue });

    if (event.target.id === "inputText") {
      this.setState({ emailAddress: event.target.value });
    } else if (event.target.id === "inputPassword") {
      this.setState({ password: event.target.value });
    }
  }

  render() {
    const { inputs } = this.props.loginForm;
    // const { forgotPwdColor } = this.props;
    const strings = Strings.LoginPage;

    const LoginButton = withRouter(({ history }) => (
      <button
        className="btn  btn-primary btn-block loginBtn mb-3"
        onClick={() => this.handleLogin().then((facilities) => {
          if (facilities) {
            const aFacility = facilities[0]
            globalContext.facility = aFacility

            history.push({
              pathname: aFacility.dashboard,
              state: aFacility
            })
          }

        })}>
        {strings.login}
      </button>
    ))

    return (
      <form className="form-signin" onSubmit={this.handleSubmit}>
        <h3 className="loginFormTitle">{strings.aquiiAdmin}</h3>
        <h6 className="loginFormSubtitle">{strings.welcomeText}</h6>

        {/* In case the login is not an admin role */}

        {this.state.issueType != null && (
          <h6 className="loginFormError">{this.state.issueType}</h6>
        )}

        {inputs.map(elt => (
          <Input
            key={elt.id}
            inputData={elt}
            onChangeText={this.handleInputChange}
          />
        ))}

        <div className="checkbox mb-3 row">
          <label className="col-6 text-left pr-0">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                id="remember-me"
                className="custom-control-input"
              />
              <label htmlFor="remember-me" className="custom-control-label">
                {strings.rememberMe}
              </label>
            </div>
          </label>
          <label className="col-6 text-right pl-0">
            <a href="/resetPassword" style={this.props.forgotPwdColor}>
              {strings.forgotPassword}
            </a>
          </label>
        </div>
        <div className="row">
          <div className="col-lg-5">
            <LoginButton />
          </div>
          <div className="col-lg-7">
            <button
              className="btn  btn-primary btn-block loginWithGoogleBtn"
            >
              {strings.loginWithGoogle}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default LoginForm;
