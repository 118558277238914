import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'
import Strings from '../../../services/strings'
import classNames from 'classnames'

const strings = Strings.StaffList

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#F5F6FA',
        fontFamily: 'Source Sans Pro',
        color: '#A3A6B4',
        fontSize: '13px',
        fontWeight: 'bold',
        textAlign: 'left',
        height: '45px !important',
        padding: '0 0 0 20px',
        border: 'none !important'

    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        padding: '21px 20px 8px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)'
    },
    table: {
        width: '100%',
        minWidth: '700px',
        marginBottom: '8px'
    },
    head: {
        height: '45px !important',
        maxHeight: '45px !important'
    },
    row: {
        borderBottom: '1px solid #F1F1F3',
        height: '50px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F1F1F3'
        }

    },
    cell: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        color: '#4D4F5C',
        padding: '0 0 0 20px',
        border: 'none'
    },
    nameCell: {
        textTransform: 'capitalize'
    },
    title: {
        fontFamily: 'Source Sans Pro',
        fontSize: '18px',
        color: '#4D4F5C',
        marginBottom: '20px',
        alignSelf: 'flex-start'
    },
    button: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        color: '#2196f3',
        '&:focus': {
            outline: 0,
        }
    },
    firstColumn: {
        width: '240px'
    },
    lastColumn: {
        width: '231px'
    }
});



class ProviderList extends React.Component {

    constructor(props) {

        super(props)
        this.handleShowMoreClick = this.handleShowMoreClick.bind(this)
        this.handleRowClick = this.handleRowClick.bind(this)
    }

    handleShowMoreClick() {
        console.log('show more click')
    }

    handleRowClick(staff) {

        this.props.onClickItem(staff)
        // const ref = firebase.database().ref('clinicStaff').push()
        // ref.set({ providerId: provider.id, facilityId: globalContext.facility.key, companyId: globalContext.company.key })
    }

    render() {
        const { classes, list, onButtonClick} = this.props
        let staff = list.slice(0, 10)

        return (
            <Paper className={classes.root}>

                <FormLabel className={classes.title}>{strings.title}</FormLabel>

                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.head}>
                            <CustomTableCell className={classes.firstColumn}>{strings.name}</CustomTableCell>
                            <CustomTableCell>{strings.appointements}</CustomTableCell>
                            <CustomTableCell>{strings.noShow}</CustomTableCell>
                            <CustomTableCell className={classes.lastColumn}>{strings.available}</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {staff.map((staff, index) => {
                            return (
                                <TableRow className={classes.row} key={staff.id} onClick={() => this.handleRowClick(staff)}>
                                    <CustomTableCell component="th" scope="row" className={classNames(classes.cell, classes.nameCell)}>
                                        {staff.name}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.cell}>{staff.appointment}</CustomTableCell>
                                    <CustomTableCell className={classes.cell}>{staff.noShow}</CustomTableCell>
                                    <CustomTableCell className={classes.cell}>{staff.available}</CustomTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>

                {list.length > 10 &&
                    <Button color='primary' className={classes.button} onClick={onButtonClick}>{strings.showMore}</Button>
                }

            </Paper>
        );
    }
}

ProviderList.propTypes = {
    classes: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    onClickItem: PropTypes.func.isRequired,
};

ProviderList.defaultProps = {
    list: [],
}

export default withStyles(styles)(ProviderList);