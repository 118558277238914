import { connect } from 'react-redux'
import {
    setFacilities,
    setSelectedFacility,
    setFacilitiesFetchingStatus,
    setSelectedFacilityChecked,
    setPatients,
    setPatientsFetchedStatus,
    setStaff,
    setStaffFetchedStatus,
    setSettings,
    setSettingsFetchingStatus,
    setCurrentFacility,
    setCurrentFacilityFetchingStatus,
    setCurrentOrganization,
    setCurrentOrganizationFetchingStatus,
    setHomeCounter,
    setNoShowPatients,
    setAppointmentsFetchingStatus,
    setAppointmentsValue
} from '../../../../common/services/actions'
import ClinicPage from '../components/ClinicPage'


//Check if planPrices is set, if not fetch it from server by ajax request

const mapStateToProps = state => {
    return {
        facilities: state.facilities,
        selectedFacility: state.selectedFacility,
        patients: state.patients,
        staff: state.staff,
        settings: state.settings,
        currentFacility: state.currentFacility,
        currentOrganization: state.currentOrganization,
        appointments: state.appointments,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSelectedFacility: (facility) => {
            dispatch(setSelectedFacility(facility))
        },
        setSelectedFacilityChecked: (checked) => {
            dispatch(setSelectedFacilityChecked(checked))
        },
        setFacilities: (facilities) => {
            dispatch(setFacilities(facilities))
        },
        setFacilitiesFetchingStatus: (status) => {
            dispatch(setFacilitiesFetchingStatus(status))
        },
        setPatients: (patients) => {
            dispatch(setPatients(patients))
        },
        setPatientsFetchedStatus: (status) => {
            dispatch(setPatientsFetchedStatus(status))
        },
        setStaff: (staff) => {
            dispatch(setStaff(staff))
        },
        setStaffFetchedStatus: (status) => {
            dispatch(setStaffFetchedStatus(status))
        },
        setSettings: (value) => {
            dispatch(setSettings(value))
        },
        setSettingsFetchingStatus: (value) => {
            dispatch(setSettingsFetchingStatus(value))
        },
        setCurrentFacility: (value) => {
            dispatch(setCurrentFacility(value))
        },
        setCurrentFacilityFetchingStatus: (value) => {
            dispatch(setCurrentFacilityFetchingStatus(value))
        },
        setCurrentOrganization: (value) => {
            dispatch(setCurrentOrganization(value))
        },
        setCurrentOrganizationFetchingStatus: (value) => {
            dispatch(setCurrentOrganizationFetchingStatus(value))
        },
        setHomeCounter: (name, value) => {
            dispatch(setHomeCounter(name, value))
        },
        setNoShowPatients: (patients) => {
            dispatch(setNoShowPatients(patients))
        },
        setAppointmentsValue: (value) => {
            dispatch(setAppointmentsValue(value))
        },
        setAppointmentsFetchingStatus: (value) => {
            dispatch(setAppointmentsFetchingStatus(value))
        },
    }
}

const ClinicPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ClinicPage)

export default ClinicPageContainer