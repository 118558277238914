import { connect } from 'react-redux'
import StaffEditPage from '../components/StaffEditPage'


const mapStateToProps = state => {
    return {
        selectedFacility: state.selectedFacility,
        staff: state.staff
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const StaffEditPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffEditPage)

export default StaffEditPageContainer