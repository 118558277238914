import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GoogleMap from 'google-map-react';
import classNames from 'classnames'
import Icons from '../../../assets/icon'

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#F5F6FA',
        fontFamily: 'Source Sans Pro',
        color: '#A3A6B4',
        fontSize: '13px',
        fontWeight: 'bold',
        textAlign: 'left',
        height: '45px !important',
        padding: '0 0 0 20px',
        border: 'none !important'

    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const styles = theme => ({
    main: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: '17px'
    },
    title: {
        color: '#43425D',
        fontSize: '30px',
        fontFamily: 'Source Sans Pro, Regular',
    },

    root: {
        minHeight: '832px',
        alignItems: 'flex-end',
        backgroundColor: 'inherit',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    mapBlock: {
        maxWidth: '902px',
        width: '902px',
        height: '832px',
        flexDirection: 'column',
        [theme.breakpoints.down('lg')]: {
            width: '95%'
        },
        [theme.breakpoints.down('sm')]: {
            height: '600px'
        },
    },
    tabs: {
        height: '45px',
        position: 'relative',
        top: '1px',
    },
    tab: {
        position: 'relative',
        top: '-1px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '14px 14px 12px 14px',
        marginRight: '3px',
        backgroundColor: '#F8F8F8',
        color: '#43425D',
        border: '1px solid #E9E9F0',
        borderRadius: '4px 4px 0 0',
        fontFamily: 'Source Sans Pro',
        fontSize: '15px',
        cursor: 'pointer',
        minWidth: '100px',
    },
    tabSelected: {
        backgroundColor: 'white !important',
        color: '#3B86FF !important',
        fontWeight: 'bold',

    },
    tabNotification: {
        height: '16px',
        width: '16px',
        position: 'absolute',
        top: '-5px',
        right: '-1px',
    },
    nav: {
        listStyleType: 'none',
        display: 'flex',
        margin: 0,
        padding: 0,
    },
    status: {
        alignItems: 'flex-end',
        height: '85px',
        padding: '10px 10px 10px 39px',
        backgroundColor: '#43425D',
        border: '1px solid #707070',
    },
    statusItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '61px',
        marginRight: '15.5%',
        fontFamily: 'Source Sans Pro',
        fontSize: '30px',
    },
    statusLabel: {
        textTransform: 'uppercase',
        fontSize: '14px',
        color: 'white',
    },
    statusCountVisible: {
        color: '#7BC77B',
    },
    statusCountOutOfBounds: {
        color: '#F5AB03',
    },
    statusCountMissing: {
        color: '#E57171',
    },
    head: {
        height: '45px',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 15px 0px 26px',
        backgroundColor: 'white',
        color: '#707070',
        fontFamily: 'Source Sans Pro',
        fontSize: '18px',
        fontWeight: 'bold',
        borderLeft: '1px solid #494862',
        borderRight: '1px solid #494862',
    },
    mapContainer: {
        backgroundColor: 'white',
        flexGrow: 1,
        borderLeft: '1px solid #494862',
        borderRight: '1px solid #494862',
        borderBottom: '1px solid #494862',
    },
    map: {
        width: 'calc(100% - 20px)',
        margin: '0px 10px 43px 10px',
        backgroundColor: '#7BC77B'
    },
    infoBlock: {
        maxWidth: '696px',
        width: '696px',
        height: '787px',
        position: 'relative',
        left: '-1px',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        fontFamily: 'Source Sans pro',
        fontSize: '14px',
        color: '#4D4F5C',
        backgroundColor: 'inherit',
        [theme.breakpoints.down('lg')]: {
            width: '95%',
            maxWidth: '902px',
            height: 'auto',
            marginTop: '50px',
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },

    },
    person: {
        width: '368px',
        height: '787px',
        flexDirection: 'column',
        padding: '20px',
        backgroundColor: 'white',
        border: '1px solid #494862',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            height: 'auto',

        }
    },
    personHeader: {
        height: '45px',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '18px',
        textTransform: 'uppercase',
    },
    personHeaderIcon: {
        width: '32px',
        height: '32px',
        marginRight: '36px',
    },
    student: {
        maxHeight: '464px',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    tableContainer: {
        maxHeight: '400px',
        overflow: 'auto',
    },
    table: {
        marginTop: '8px',
    },
    tableHead: {
        height: '45px !important',
        maxHeight: '45px !important',
        textTransform: 'uppercase',
    },
    tableRow: {
        borderBottom: '1px solid #F1F1F3',
        height: '50px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F1F1F3'
        }
    },
    tableCell: {
        fontSize: '13px',
        color: '#4D4F5C',
        padding: '0 10px 0 10px !important',
        border: 'none'
    },
    tableStudentIcon: {
        height: '16px',
        width: '16px',
    },
    tableRightPaddingLittle: {
        paddingRight: '35px !important',
    },
    tableRightPaddingGreat: {
        paddingRight: '45px !important',
    },
    AlignRight: {
        textAlign: 'right',
    },
    chaperone: {
        maxHeight: '300px',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    chaperoneIcon: {
        width: '16px',
        height: '16px',
        marginRight: '17px',
    },
    tableContainerChaperones: {
        maxHeight: '240px',
        overflow: 'auto',
    },
    timeline: {
        width: '305px',
        height: '787px',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        border: '1px solid #707070',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            height: 'auto',
            marginTop: '50px',
            marginBottom: '50px',
        }
    },
    timelineHead: {
        height: '76px',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: '18px',
        fontWeight: 'bold',
        borderBottom: '1px solid #707070',
        textTransform: 'uppercase',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'space-between',
            padding: '0px 20px'
        }

    },
    timelineIcon: {
        width: '48px',
        height: '48px',
    },
    timelineItemContainer: {
        height: '700px',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        overflow: 'auto',
        [theme.breakpoints.down('lg')]: {
            height: 'auto',
        }
    },
    timelineItem: {
        justifyContent: 'flex-start',
        minHeight: '61px',
        borderBottom: '1px solid #707070',
    },
    timelineHour: {
        display: 'flex',
        width: '80px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '10px 7px 10px 18px'
    },
    timelineLabel: {
        display: 'flex',
        maxWidth: '195px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '10px 18px 10px 7px',
        [theme.breakpoints.down('lg')]: {
            maxWidth: 'none',
        }
    }
});

const googleMapKey = 'AIzaSyAtq4pudyB3OWkyqTyXlZQEABDNe8umHQI'

class FieldTrips extends React.Component {

    constructor(props) {

        super(props)
        this.state = {selectedTab: ''}
        const {trips} = props
        this.currentTrip = trips.length > 0 ? trips[0] : {}
    }

    handleTabClick = (selectedTab) => () => {
        const {trips} = this.props
        this.setState({selectedTab: selectedTab})
        this.currentTrip = trips.find((trip) => trip.class.toLowerCase() === selectedTab.toLowerCase())
        console.log(this.currentTrip.localisation)
    }

    render() {
        const {classes, trips} = this.props;
        let {selectedTab} = this.state

        return (
            <Grid container className={classes.root}>

                <Grid item container className={classes.mapBlock}>
                    <Grid item container className={classes.tabs}>
                        <ul className={classes.nav}>
                            {
                                trips.map((trip, index) => (
                                    <li key={index} className={classNames(classes.tab, {[classes.tabSelected]: (index === 0 && selectedTab === '') || (trip.class.toLowerCase() === selectedTab.toLowerCase())})}
                                        onClick={this.handleTabClick(trip.class)}>
                                        {trip.class}

                                        {
                                            trip.status.missing > 0 ?
                                                <img src={Icons['icon_ellipse-red']} className={classes.tabNotification} alt="icon notif"/>
                                                : trip.status.outOfBounds > 0 ?
                                                <img src={Icons['icon_ellipse-orange']} className={classes.tabNotification} alt="icon notif"/>
                                                : null
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                    </Grid>

                    <Grid item container className={classes.status}>
                        <div className={classes.statusItem}>
                            <span className={classes.statusCountVisible}>{this.currentTrip.status.visible}</span>
                            <span className={classes.statusLabel}>VISIBLE</span>
                        </div>
                        <div className={classes.statusItem}>
                            <span className={classes.statusCountOutOfBounds}>{this.currentTrip.status.outOfBounds}</span>
                            <span className={classes.statusLabel}>OUT OF BOUNDS</span>
                        </div>
                        <div className={classes.statusItem}>
                            <span className={classes.statusCountMissing}>{this.currentTrip.status.missing}</span>
                            <span className={classes.statusLabel}>MISSING</span>
                        </div>
                    </Grid>

                    <Grid item container className={classes.head}>
                        <span>{this.currentTrip.untitled}</span>
                        <span>{this.currentTrip.coord}</span>
                    </Grid>

                    <Grid item container className={classes.mapContainer}>
                        <Grid item container className={classes.map}>

                            <GoogleMap
                                bootstrapURLKeys={{ key: googleMapKey }}
                                center={this.currentTrip.location}
                                defaultZoom={17}
                            >

                            </GoogleMap>

                        </Grid>
                    </Grid>

                </Grid>

                <Grid item container className={classes.infoBlock}>

                    <Grid item container className={classes.person}>
                        <Grid item container className={classes.student}>
                            <Grid item container className={classes.personHeader}>
                                <span>STUDENTS</span>
                                <img src={Icons['icon_notification-red']} alt="red bell" className={classes.personHeaderIcon}/>
                            </Grid>
                            <Grid item container className={classes.tableContainer} style={{height: `${(this.currentTrip.students.length * 50 + 80)}px`}}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow className={classes.tableHead}>
                                            <CustomTableCell>NAME</CustomTableCell>
                                            <CustomTableCell className={classNames(classes.AlignRight, classes.tableRightPaddingLittle)}>STATUS</CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.currentTrip.students.map((student, index) => {
                                            return (
                                                <TableRow className={classes.tableRow} key={index}>
                                                    <CustomTableCell component="th" scope="row" className={classes.tableCell}>
                                                        {student.name}
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classNames(classes.tableCell, classes.AlignRight, classes.tableRightPaddingGreat)}>
                                                        {student.status.toLowerCase() === 'missing' ?
                                                            <img src={Icons['icon_ellipse-red']} alt="red icon" className={classes.tableStudentIcon}/>
                                                            :   student.status.toLowerCase() === 'outOfBounds'.toLowerCase() ?
                                                                <img src={Icons['icon_ellipse-orange']} alt="orange icon" className={classes.tableStudentIcon}/>
                                                                :
                                                                <img src={Icons['icon_ellipse-green']} alt="green icon" className={classes.tableStudentIcon}/>
                                                        }
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>

                        <Grid item container className={classes.chaperone}>
                            <Grid item container className={classes.personHeader}>
                                <span>CHAPERONES</span>
                            </Grid>
                            <Grid item container className={classes.tableContainerChaperones} style={{height: `${(this.currentTrip.chaperones.length * 50 + 80)}px`}}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow className={classes.tableHead}>
                                            <CustomTableCell>NAME</CustomTableCell>
                                            <CustomTableCell className={classNames(classes.AlignRight, classes.tableRightPaddingLittle)}>PHONE</CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.currentTrip.chaperones.map((chaperones, index) => {
                                            return (
                                                <TableRow className={classes.tableRow} key={index}>
                                                    <CustomTableCell component="th" scope="row" className={classes.tableCell}>
                                                        <img src={Icons['icon_chat-room']} alt="contact icon" className={classes.chaperoneIcon}/>
                                                        {chaperones.name}
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classNames(classes.tableCell, classes.AlignRight)}>
                                                        {chaperones.phone}
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container className={classes.timeline}>
                        <Grid item container className={classes.timelineHead}>
                            <span>TIMELINE</span>
                            <img src={Icons['icon_time-countdown']} alt="time icon" className={classes.timelineIcon}/>
                        </Grid>
                        <Grid item container className={classes.timelineItemContainer}>
                            {
                                this.currentTrip.timeline.map((item, index) => (
                                    <Grid item container className={classes.timelineItem} key={index}>
                                        <span className={classes.timelineHour}>{item.hour}</span>
                                        <span className={classes.timelineLabel}>{item.label}</span>
                                    </Grid>
                                ))
                            }
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        );
    }
}

FieldTrips.propTypes = {
    classes: PropTypes.object.isRequired,
    trips: PropTypes.array.isRequired
};

FieldTrips.defaultProps = {
    trips: []
}

export default withStyles(styles)(FieldTrips);